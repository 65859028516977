<div class="row vsMb-2">
  <label class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
  <div class="col vsInputGroup">

    <!-- <div *ngIf="Disabled=='true'">

      <div class="p-inputgroup">
        <input  class="vsFormControl"
                formControlName="{{FrmCtlName}}"
                type="text"
                pInputText
                disabled
                />
        <button type="button" pButton pRipple icon="pi pi-question-circle" styleClass="p-button-warn" (click)=doButtonClick($event) disabled="true"></button>

      </div>

    </div> -->

      <div class="p-inputgroup" *ngIf="ReadOnly=='true'">
        <input  class="vsFormControl"
                formControlName="{{FrmCtlName}}"
                type="text"
                id="input-{{FrmCtlName}}"
                pInputText
                readonly
                />
        <button type="button" pButton pRipple icon="pi pi-search" styleClass="p-button-warn" (click)=doButtonClick($event) aria-label="open search dialogue" disabled="true"></button>
      </div>

      <div class="p-inputgroup" *ngIf="ReadOnly=='false'">
        <input  class="vsFormControl"
                formControlName="{{FrmCtlName}}"
                type="text"
                id="input-{{FrmCtlName}}"
                pInputText
                readonly
                />
        <button *ngIf="FormGroup.controls[FrmCtlName].disabled" type="button" aria-label="open search dialog" pButton pRipple icon="pi pi-search" styleClass="p-button-warn" (click)=doButtonClick($event) disabled></button>
        <button *ngIf="!FormGroup.controls[FrmCtlName].disabled" type="button" aria-label="open search dialog" pButton pRipple icon="pi pi-search" styleClass="p-button-warn" (click)=doButtonClick($event)></button>
      </div>


</div>

