//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


@Component({
  selector:       'schoolViewAntragEdit_PickDate',
  templateUrl:  './schoolViewAntragEdit_PickDate.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Student.component.scss']
  providers: [DialogService]
})
export class TschoolViewAntragEdit_PickDate extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public Anrede: any[];

  public LookupAnrede:                any[];
  public LookupTitel:                 any[];
  public filteredAnrede:              any[];

  public displayDialogError: boolean   = false;
  public data;

  //====== Constructor


  ref: DynamicDialogRef;

  constructor(
      protected router:                     Router
    , protected globalResources:            vsGlobalResources.TvsGlobalResources
    , protected HttpServiceComponent:       TvsHttpServiceComponent
    , public    dataModel:                  schoolDataModel.TschoolDataModel
    , public    dialogService:              DialogService
) {
super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schüler';

    this.ViewProperties.ProgFuncID      =  null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    //Schüler
    this.editForm.addControl('Checkbox_PickupHome_Monday',      new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Monday)));
    this.editForm.addControl('Checkbox_PickupHome_Tuesday',     new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Tuesday)));
    this.editForm.addControl('Checkbox_PickupHome_Wednesday',   new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Wednesday)));
    this.editForm.addControl('Checkbox_PickupHome_Thursday',    new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Thursday)));
    this.editForm.addControl('Checkbox_PickupHome_Friday',      new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Friday)));
    this.editForm.addControl('Checkbox_PickupHome_Saturday',    new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Saturday)));
    this.editForm.addControl('Checkbox_PickupHome_Sunday',      new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupHome?.Time_PickupHome_Sunday)));

    this.editForm.addControl('Checkbox_PickupSchool_Monday',    new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Monday)));
    this.editForm.addControl('Checkbox_PickupSchool_Tuesday',   new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Tuesday)));
    this.editForm.addControl('Checkbox_PickupSchool_Wednesday', new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Wednesday)));
    this.editForm.addControl('Checkbox_PickupSchool_Thursday',  new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Thursday)));
    this.editForm.addControl('Checkbox_PickupSchool_Friday',    new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Friday)));
    this.editForm.addControl('Checkbox_PickupSchool_Saturday',  new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Saturday)));
    this.editForm.addControl('Checkbox_PickupSchool_Sunday',    new FormControl(vsCommon.vsVarAssigend(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Sunday)));
    
    this.editForm.addControl('Time_PickupHome_Monday',          new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Monday));
    this.editForm.addControl('Time_PickupHome_Tuesday',         new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Tuesday));
    this.editForm.addControl('Time_PickupHome_Wednesday',       new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Wednesday));
    this.editForm.addControl('Time_PickupHome_Thursday',        new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Thursday));
    this.editForm.addControl('Time_PickupHome_Friday',          new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Friday));
    this.editForm.addControl('Time_PickupHome_Saturday',        new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Saturday));
    this.editForm.addControl('Time_PickupHome_Sunday',          new FormControl(this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Sunday));

    this.editForm.addControl('Time_PickupSchool_Monday',        new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Monday));
    this.editForm.addControl('Time_PickupSchool_Tuesday',       new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Tuesday));
    this.editForm.addControl('Time_PickupSchool_Wednesday',     new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Wednesday));
    this.editForm.addControl('Time_PickupSchool_Thursday',      new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Thursday));
    this.editForm.addControl('Time_PickupSchool_Friday',        new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Friday));
    this.editForm.addControl('Time_PickupSchool_Saturday',      new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Saturday));
    this.editForm.addControl('Time_PickupSchool_Sunday',        new FormControl(this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Sunday));

    this.editForm.addControl('AdditInfoMsg',                    new FormControl(this.dataModel.antr_Data_Individual.additInfo.AdditInfoMsg));
    this.editForm.addControl('Wheelchair',                      new FormControl(this.dataModel.antr_Data_Individual.additInfo.Wheelchair));
    this.editForm.addControl('Assistant',                       new FormControl(this.dataModel.antr_Data_Individual.additInfo.Assistant));


  } // ngOnInit


  ngAfterViewInit(): void {

  }

  public actionStepPrev(): void {
    this.dataSave();
    this.router.navigate(['antragEdit/school']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK) {
      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;
    }

    if (bOK) {
      this.dataSave();
      this.router.navigate(['antragEdit/doc']);
    }

  } // actionStepNext()



  public dataSave() :void{

    if(this.editForm.controls['Checkbox_PickupHome_Monday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Monday         = this.editForm.controls['Time_PickupHome_Monday'].value;
    }
    if(this.editForm.controls['Checkbox_PickupHome_Tuesday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Tuesday        = this.editForm.controls['Time_PickupHome_Tuesday'].value;  
    }
    if(this.editForm.controls['Checkbox_PickupHome_Wednesday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Wednesday      = this.editForm.controls['Time_PickupHome_Wednesday'].value;    
    }
    if(this.editForm.controls['Checkbox_PickupHome_Thursday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Thursday       = this.editForm.controls['Time_PickupHome_Thursday'].value;  
    }
    if(this.editForm.controls['Checkbox_PickupHome_Friday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Friday         = this.editForm.controls['Time_PickupHome_Friday'].value;
    }
    if(this.editForm.controls['Checkbox_PickupHome_Saturday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Saturday       = this.editForm.controls['Time_PickupHome_Saturday'].value;  
    }
    if(this.editForm.controls['Checkbox_PickupHome_Sunday'].value) {
      this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Sunday         = this.editForm.controls['Time_PickupHome_Sunday'].value;
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Monday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Monday     = this.editForm.controls['Time_PickupSchool_Monday'].value;
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Tuesday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Tuesday    = this.editForm.controls['Time_PickupSchool_Tuesday'].value;  
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Wednesday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Wednesday  = this.editForm.controls['Time_PickupSchool_Wednesday'].value;    
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Thursday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Thursday   = this.editForm.controls['Time_PickupSchool_Thursday'].value;  
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Friday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Friday     = this.editForm.controls['Time_PickupSchool_Friday'].value;
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Saturday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Saturday   = this.editForm.controls['Time_PickupSchool_Saturday'].value;  
    }
    if(this.editForm.controls['Checkbox_PickupSchool_Sunday'].value) {
      this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Sunday     = this.editForm.controls['Time_PickupSchool_Sunday'].value;
    }

    this.dataModel.antr_Data_Individual.additInfo.Wheelchair                        = this.editForm.controls['Wheelchair'].value;
    this.dataModel.antr_Data_Individual.additInfo.Assistant                         = this.editForm.controls['Assistant'].value;
    this.dataModel.antr_Data_Individual.additInfo.AdditInfoMsg                      = this.editForm.controls['AdditInfoMsg'].value;

  }

} // schoolViewAntragEdit_Student


