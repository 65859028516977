

<!-- Header: Standard ----------------------------------------------------------------------------->
  
<appHeader></appHeader>


<!-- Main Content --------------------------------------------------------------------------------->
  
<div class="container-xxl akMainContent">
  
  <form [formGroup]="this.editForm">


    <vsLayoutGroup class="col" Orientation="horizontal" MediumGutterWidth=true>

        <vsLayoutGroup class="col-xl-2 col-lg-1 col-md-0">
        </vsLayoutGroup>

        <vsLayoutGroup class="col-xl-8 col-lg-10 col-md-12" Orientation="vertical">
          <!--WebAccount-User-->

          <vsLayoutGroup class="col">
            <div class="col" style="margin-top: 30px; margin-bottom: 30px; display:flex; justify-content: center;">
              <h4 class="vsElternLoginTitle">{{'profile.profile_header'| translate}}</h4>
            </div>
          </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputAutoComplete FrmCtlName="Profile_ID_Anrede" label="{{'application.legal_rep.form_of_address'| translate}}" labelWidth="110px" [suggestions]="lookupAnrede" FieldName="Anrede_Name" MinLength=1 Dropdown=true placeholder="{{'application.input_required'| translate}}"></vsInputAutoComplete>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputAutoComplete FrmCtlName="Profile_ID_Titel" label="{{'application.legal_rep.title'| translate}}" labelWidth="110px" [suggestions]="lookupTitel" FieldName="Titel_Kuerzel" MinLength=1 Dropdown=true></vsInputAutoComplete>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_Vorname" label="{{'application.legal_rep.first_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_Nachname" label="{{'application.legal_rep.last_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputDateTime FrmCtlName="Profile_Geb_Datum" label="{{'application.legal_rep.date_birth'| translate}}" placeholder="{{'application.input_required'| translate}}"  labelWidth="110px">
                </vsInputDateTime>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <ng-template [ngIf]="KennStrassenVerz">
              <vsLayoutGroup class="col-12" Orientation="horizontal">
                <vsInputAutoComplete FrmCtlName="AntrStell_StrassenVerz" label="{{'general.address.address'| translate}}" labelWidth="110px" [suggestions]="lookupAdresse" FieldName="xAnzeige" 
                MinLength=1 Dropdown=true (onSelect)="doSelectAdresse($event)"></vsInputAutoComplete>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-12 col-md-12" Orientation="vertical">
                <vsInputCheckBox label="{{'general.address.address_not_existant'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="380px" FrmCtlName="KennAdrNotFoundProfile" (change)="doActivateManualInput()"></vsInputCheckBox>
              </vsLayoutGroup>
            </ng-template>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12" [class.hidden]="KennStrassenVerz && !KennAdrNotFoundProfile">
                <vsInputText FrmCtlName="Profile_Strasse" label="{{'general.address.address_street'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_HausNr" label="{{'general.address.address_house_number'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12">
              <vsInputText FrmCtlName="Profile_Adr_Zusatz" label="{{'general.address.address_addition'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12" [class.hidden]="KennStrassenVerz && !KennAdrNotFoundProfile">
              <vsInputText FrmCtlName="Profile_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal" [class.hidden]="KennStrassenVerz && !KennAdrNotFoundProfile">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_PLZ" label="{{'general.address.address_zip_code'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" [attr.aria-label]="'address_zip_code_full' | translate"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_Ort" label="{{'general.address.address_city'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsInputText FrmCtlName="Profile_eMail" label="{{'general.contact.email'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_Phone" label="{{'general.contact.phone'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Profile_Mobile" label="{{'general.contact.mobile'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <div style="height:31px"></div>

            <!-- <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Kto_Inh" label="{{'application.bank.account_owner'| translate}}" labelWidth="110px" placeholder="Kontoinhaber" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-6" Orientation="horizontal">
                <vsInputText id="IBAN"  aria-describedby="IBAN-help" (onBlur)="validateIBAN()" FrmCtlName="IBAN" label="{{'application.bank.IBAN'| translate}}" labelWidth="110px" placeholder="IBAN"></vsInputText>
            </vsLayoutGroup>

              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText Disabled="true" FrmCtlName="Bank_Name" label="{{'application.bank.bank_name'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-lg-6 col-md-12">
                <small *ngIf="!ibanValid" id="IBAN-help" class="p-error" style="margin-left: 120px;">{{'application.bank.no_valid_IBAN'| translate}}</small>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12">
              <vsInputText Disabled="true" FrmCtlName="Bank_BIC" disabled label="{{'application.bank.BIC'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup> -->

        </vsLayoutGroup>

        <vsLayoutGroup class="col-xl-2 col-lg-1 col-md-0">
        </vsLayoutGroup>

      </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <button pButton label="{{'profile.button_cancel'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
    <button pButton label="{{'profile.button_save'| translate}}" (click)="actionSave()" class="vsElternButton"></button>
  </div>

</div>

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- Footer --------------------------------------------------------------------------------------->

<appFooter></appFooter>


<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->
