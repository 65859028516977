//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsActionList.ts
//  Notes:  Container Action-Items
//
//
//  H I S T O R Y
//
//  2021-08-19  MH   Genesis
//--------------------------------------------------------------------------------------------------

// [Standard-Fall]
//
// Im einfachsten Fall wird eine neue AKtion der Actionlist hinzugefügt:   this.ActionList.ItemAdd(this, 'ActionTest', this.OnClick);
// Die Aktion kann jede Methode einer Klasse sein. Der Aufruf erfolgt über this.ActionList.ItemByName('ActionTest').Execute();
//
// [Contect-Menu]
//
// Bei Context-Menus (speziell PrimeNG) muss bei der Zuweisung des eigentlichen Events auch noch die aktuelle Instanz (this) mitgegeben werden
// Beispiel für den Inhalt eines Contect-Menus mit Aktion:
//
//   this.items = [
//   {
//       label:    'Datei',
//   },
//   {
//       label:    'Test',
//       disabled: this.ActionList.ItemByName('ActionTest').disabled,
//       command:  event => {this.ActionList.ItemByName('ActionTest').Execute(this)}  <-- hier muss die Instanz an die Execute-Methode übergeben werden
//   }
// ];
//
// In der aufgerufenen durch ActionTest aufgerufenen "OnClick"-Mehtode steht dann automatisch die Instanz in Form der Variable "a_caller" zur Verfügung.
// Immer wenn ansonsten "this" benutzt werden müsste, ersetzt man das durch "a_caller" und erreicht so die richtige Instanz.
//
// public OnClick(a_caller: any): void {
//
//   a_caller.ActionList.ItemByName('ActionTest').disabled = !a_caller.ActionList.ItemByName('ActionTest').disabled;
//   a_caller.RetrieveItems();
// }


import * as vsCommon        from './vsCommon';


//
// TvsActionItem
//
export class TvsActionItem {

    //====== Properties (PRIVATE)
    // ./.


    //====== Properties (PUBLIC)

    private  readonly    Owner:             any;
    private  readonly    Name:              String;
    public   readonly    OnExecute:         (a_caller, a_data?) => void;
    public               disabled:          boolean = false;

    //====== Constructor

    constructor(a_Owner: any, a_sName: String, a_Action: (a_caller, a_data) => void) {
      this.Name          = a_sName;
      this.OnExecute     = a_Action;
      this.Owner         = a_Owner;

    } // contructor

    public Execute(a_caller: any, a_data?: any): void {
      this.OnExecute(a_caller, a_data);
    }

} // TvsActionItem


//
// TvsActionList
//
// Container for Action-Items
//
export class TvsActionList extends Array {

    //====== Properties (PRIVATE)
    // ./.


    //====== Properties (PROTECTED)
    // ./.


    //====== Properties (PUBLIC)
    // ./.



    //====== Methods (PUBLIC)

    //----------------------------------------------------------------------------------------------
    // Method:  ItemByName
    //
    // Args:    a_sName:  Filtername
    //
    // Notes:   Retrieve item
    //----------------------------------------------------------------------------------------------

    public ItemByName(a_sName: String): TvsActionItem {
      let result: any = null;
      this.forEach(item => {
        if (item.Name == a_sName) {
          result = item;
        }
      });
      return result;
    }


    //----------------------------------------------------------------------------------------------
    // Method:  ItemAdd
    //
    // Args:    a_sFilterName:  Filtername (wird über eine Enumeration pro Liste gefüllt )
    //          a_vValue:       Wert       (null ist möglich, muss jedoch explizit angegeben werden)
    //
    // Notes:   Add a new item
    //----------------------------------------------------------------------------------------------

    public ItemAdd(a_Owner: any, a_sName: String, a_Action: (a_caller, a_data) => void): TvsActionItem {

        let bOK:        Boolean                 = true;
        let item:       TvsActionItem           = null;


        //---- Validierungen

        // Anmerkungen:
        // a_vValue     darf null sein
        // a_dfo        optionales Argument, wird initialisiert mit dfoEqual

        // a_sFieldName angegeben?
        if (bOK) {
            bOK = (a_sName !== '');
            if (!bOK) {
                //... Exception auslösen ...
            }
        }


        //---- Item erzeugen

        if (bOK) {
            item    = new TvsActionItem(a_Owner, a_sName, a_Action);
            this.push(item);
        }


        return item;

    } // ItemAdd


    //----------------------------------------------------------------------------------------------
    // Method:  ItemsClear
    //
    // Args:    none
    //
    // Notes:   Remove all items
    //----------------------------------------------------------------------------------------------

    public ItemsClear(): void {

        //
        // Clearing an array
        //
        // (1) Don't use the following method to clear an array:  a = []
        //
        //     This code will set the variable A to a new empty array.
        //     if code references this array from another variable or property, the original
        //     array will remain unchanged!
        //
        // (2) a.length = 0;
        //
        //     This code works fine in all circumstances. It's also as fast as method (1).
        //
        // Siehe: https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
        //

        this.length = 0;

    } // ItemsClear


} // TvsActionList
