<!-- Rev 2021-07-12 MP Klasse .vsForm hinzugefügt und InputStyleClass entfernt -->
<div class="vsInputCheckBox">
  <div class="row vsMb-2">

    <label *ngIf="labelPosition=='left' && !NoLabel" class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>

    <!-- <div class="col" [style.width]="checkBoxWidth"> -->
    <div [style.width]="checkBoxWidth">
      <div *ngIf="ReadOnly=='true'">
        <input formControlName={{FrmCtlName}} type="checkbox" [id]=inputId inputId="input-{{FrmCtlName}}" disabled>
      </div>
      <div *ngIf="ReadOnly=='false'">
        <input formControlName={{FrmCtlName}} type="checkbox" [id]=inputId inputId="input-{{FrmCtlName}}">
      </div>
    </div>

    <label *ngIf="labelPosition=='right' && !NoLabel" class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}" >{{labelContent}}</label>
  </div>
</div>


