//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


@Component({
  selector:       'schoolViewAntrag_Besondere',
  templateUrl:  './schoolViewAntragEdit_Besondere.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
})
export class TschoolViewAntragEdit_Besondere extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});


  public lookupArtName:               any[];

  public displayDialogError: boolean   = false;


  // public get Kenn_Bes_Schulweg_Danger():                    string {
  //   return this.editForm.controls['Inpt_Bes_Schulweg_Danger'].value ? "false" : "true"
  // }


  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Besondere Umstände';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

  
  // this.lookupArtName = [
  //   {Art_Name: 'Antrag auf Übernahme von Schülerfahrtkosten',      Art_ID: 'U'},
  //   {Art_Name: 'Antrag auf Erstattung von Schülerfahrtkosten',      Art_ID: 'E'}
  // ];


    // this.Anrede = [
    //   {Anrede: 'Herr',      Anrede_ID: 'HERR'},
    //   {Anrede: 'Frau',      Anrede_ID: 'FRAU'},
    //   // {Anrede: 'Divers',    Anrede_ID: 'DIVERS'},
    //   {Anrede: '<Unbek>',   Anrede_ID: 'UNBEK'}
    // ];


  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {


    // this.editForm.addControl('Inpt_Bes_Schulweg_Danger', new FormControl(this.dataModel.Kenn_Bes_Schulweg_Danger));
    this.editForm.addControl('Bes_Erlaeut',     new FormControl(this.dataModel.antr_Bes_Erlaeut));


  } // ngOnInit


  public actionStepPrev(): void {
    // this.dataSave();

    // if (vsCommon.vsStringIsNotNull(this.editForm.controls['Bes_Erlaeut'].value)) {
    this.saveInDataModel();
    // }

    if (this.dataModel.antrag_Art === 'E') {
      this.router.navigate(['antragEdit/erstattung']);
    }
    if(this.dataModel.antrag_Art === 'U') {
      this.router.navigate(['antragEdit/school']);
    }
    if (this.dataModel.antrag_Art === 'I') {
      this.router.navigate(['antragEdit/pickup']);
    } 
    if (this.dataModel.antrag_Art === 'G') {
      this.router.navigate(['antragEdit/school']);
    }
    if (this.dataModel.antrag_Art === 'GA') {
      this.router.navigate(['antragEdit/school_ausb']);
    }
    if(this.dataModel.antrag_Art === 'EA') {
      this.router.navigate(['antragEdit/erstattung']);
    }
  }

  public actionStepNext(): void {

      // this.dataSave();

      // if (vsCommon.vsStringIsNotNull(this.editForm.controls['Bes_Erlaeut'].value)) {

      this.saveInDataModel();
      // }

      this.router.navigate(['antragEdit/doc']);

  } // actionStepNext()


  public saveInDataModel(): void {

    console.log('saveInDataModel');

    this.dataModel.antr_Bes_Erlaeut             =  this.editForm.controls['Bes_Erlaeut'].value;

    console.log('datamodel.antr_Bes_Erlaeut', this.dataModel.antr_Bes_Erlaeut);

    var dtStr: string = vsCommon.RetrieveDateStrNow();
    var tmStr: string = vsCommon.RetrieveTimeStrNow();
  
    // console.log('case Message mit Anhang');
    let vorgHint:  vsCommon.TVorgMain           =  new vsCommon.TVorgMain();
    
    vorgHint.crmActivityID                      = null;
  
    vorgHint.crmActivityType                    = vsCommon.TcrmVorgangArt.vaNotiz;
    this.dataModel.KennMessageWithAttachment    = true;
    this.dataModel.KennAttachment               = true;
  
    vorgHint.crmActivityDate                    = vsCommon.DateStrToISO8061(dtStr);
    vorgHint.crmActivityTime                    = vsCommon.TimeStrToISO8061(tmStr);

    vorgHint.crmActivityName                    = "Besondere Umstände"

    vorgHint.crmActivityDesc                    = this.editForm.controls["Bes_Erlaeut"].value;

    vorgHint.crmActivityIsIncoming              = true;

    this.dataModel.vorgHint                     = vorgHint;

  }


  // public dataSave() :void{

    

  //   // this.dataModel.Kenn_Bes_Schulweg_Danger      =  this.editForm.controls['Inpt_Bes_Schulweg_Danger'].value;
  //   // this.dataModel.antr_Bes_Erlaeut              =  this.editForm.controls['Bes_Erlaeut'].value;
  //   // this.dataModel.antrag_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['Antr_Datum'].value);

  // }


} // schoolViewAntragEdit_Student


