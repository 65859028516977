//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Auswahl Schüler (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2023-05-17  MP   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector:       'schoolViewAntragEdit_StudentSelection',
  templateUrl:  './schoolViewAntragEdit_StudentSelection.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Student.component.scss']
  providers: [DialogService]
})
export class TschoolViewAntragEdit_StudentSelection extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public Anrede: any[];
  public image;
  public cropped: boolean = false;

  public LookupAnrede:                  any[];
  public LookupTitel:                   any[];
  public filteredAnrede:                any[];

  public azubiCalculatedAge:            number;
  public KennAzubiGesVertr:             boolean = false;

  public KennOrtsteilRequired:          boolean = false;

  public displayDialogError: boolean    = false;
  public displayDialogAgeError: boolean = false;
  public data;

  public DocType:       any[] =
  [
    {Caption: 'Nutzerfoto laden',    ID: 'Pic'}

  ]

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor


  ref: DynamicDialogRef;

  constructor(protected router:                     Router
    , protected globalResources:            vsGlobalResources.TvsGlobalResources
    , protected HttpServiceComponent:       TvsHttpServiceComponent
    , public    dataModel:                  schoolDataModel.TschoolDataModel
    , public    dialogService:              DialogService
) {
super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schüler';

    this.ViewProperties.ProgFuncID      =  null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.HttpServiceComponent.dataGet('adrDoKatPersAnrede')
    .subscribe(data => {
    // this.LookupAnrede =  data['Dataset'].Data.slice(1).filter((PKValue) => {return PKValue.Caption !== "UNBEK";});
    this.LookupAnrede =  data['Dataset'].Data;

      this.HttpServiceComponent.dataGet('adrDoKatPersTitel')
      .subscribe(data => {
      this.LookupTitel =  data['Dataset'].Data;

      });

    });

      //Füllen aus dem dataModel falls vorhanden.
      if(vsCommon.vsVarAssigend(this.dataModel.KennAzubiGesVertr)) {
        this.KennAzubiGesVertr = this.dataModel.KennAzubiGesVertr;
      }
      //$REV MP 2023-05-05: KennOrtsteilRequired kann in der app.config.json auf true oder false gesetzt werden, durch true wird die Azubi Ortsteil-Angabe verpflichtend.
      if(vsCommon.vsVarAssigend(this.dataModel.KennOrtsteilRequired)) {
        this.KennOrtsteilRequired = this.dataModel.KennOrtsteilRequired;
      }

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    //$REV MP 2023-05-24: Für Mouseover des Icons notwendig
    const imgElement = document.getElementById('student-select-img') as HTMLImageElement | null;
    if (imgElement) {
      imgElement.addEventListener('mouseover', function() {
        imgElement.src = '/assets/img/Icons/account-outline.svg';
      });

      imgElement.addEventListener('mouseout', function() {
        imgElement.src = '/assets/img/Icons/account-outline-grey.svg';
      });
    }

    // Load profile data
    this.doLoadProfileData();
  }

  ngAfterViewInit(): void {

    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }
  }

  //Laden der Schüler mit dem gesetzlichen Vertreter der dem eingeloggten User entspricht
  doLoadProfileData():void{

    //  let item = this.DataFilter.ItemAdd('Filter Kontakt_ID_Gesetzl_Vertret');

    //  item.CriteriaAdd('Kontakt_ID_Gesetzl_Vertret', this.globalResources.WebAccProfile.KontaktID);
//GDS-Aufruf 9 [OK] Azubi
// console.log('GDS_Azubi Gesetzl_Vertet');

    // this.HttpServiceComponent.dataGet('ausbDOStammAzubi', false, [], this.DataFilter)
    this.HttpServiceComponent.HttpServiceGetRequest('school/data/azubi', '')
      .subscribe((data: any) => {
        // console.log('GDS_Azubi 9 [OK] Gesetzl_Vertet');
        this.data = data.Dataset.Data;
    });

  }

  public handleButtonClick(item) {
    this.studentSelect(item);
    this.actionStepNext();
  }

  public studentSelect(data) {

    //Füllen der Daten im DataModel:

    if(vsCommon.vsStringHasValue(data) && data !== "new") {
      this.dataModel.azubi_ID = data.Azubi_ID;

      //$REV MP 2024-05-27: Adressdaten müssen aus Datenschutzgründen verborgen werden. Müssen später neu eingegeben werden.
      // this.dataModel.azubi_PLZ          = data.Adr_PLZ;
      // this.dataModel.azubi_Ortsteil     = data.Adr_Ortsteil;
      // this.dataModel.azubi_Ort          = data.Adr_Ort;
      // this.dataModel.azubi_Strasse      = data.Adr_Strasse;
      // this.dataModel.azubi_HausNr       = data.Adr_HausNr;
      this.dataModel.azubi_Anrede       = data.Kontakt_ID_Anrede;
      this.dataModel.azubi_Vorname      = data.Kontakt_Name2;
      this.dataModel.azubi_Nachname     = data.Kontakt_Name1;
      this.dataModel.azubi_Geb_Datum    = vsCommon.ISO8061ToDateStr(data.Kontakt_Geb_Datum);
      // this.dataModel.azubi_Phone        = data.Phone;
      // this.dataModel.azubi_eMail        = data.eMail;
      // this.dataModel.azubi_Mobile       = data.Mobile;
    }
    //Leeren des dataModels falls vor Neuanlage ein Schüler ausgewählt war
    if(data === "new") {
      this.dataModel.azubi_ID = null;

      this.dataModel.azubi_PLZ          = null;
      this.dataModel.azubi_Ortsteil     = null;
      this.dataModel.azubi_Ort          = null;
      this.dataModel.azubi_Strasse      = null;
      this.dataModel.azubi_HausNr       = null;
      this.dataModel.azubi_Anrede       = null;
      this.dataModel.azubi_Vorname      = null;
      this.dataModel.azubi_Nachname     = null;
      this.dataModel.azubi_Geb_Datum    = null;
      this.dataModel.azubi_Phone        = null;
      this.dataModel.azubi_eMail        = null;
      this.dataModel.azubi_Mobile       = null;
    }

  }


  public actionStepPrev(): void {

    this.router.navigate(['antragEdit/antrag']);
  }

  public actionStepNext(): void {


      this.router.navigate(['antragEdit/student']);

  } // actionStepNext()


} // schoolViewAntragEdit_Student


