//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputButtonEdit
//  Notes:  Input mit angefügtem Button
//
//
//  H I S T O R Y
//
//  2021-10-13  MP   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                                from '@vsLib/vsCommon';
import * as vsInput                                 from '@vsLib/Components/vsInput/vsInput.component';
import { asLiteral }                                from '@angular/compiler/src/render3/view/util';
import { connectableObservableDescriptor }          from 'rxjs/internal/observable/ConnectableObservable';
import { getLyThemeStyleUndefinedError } from '@alyle/ui';



// TvsInputButtonEdit

@Component({
  selector:     'vsInputButtonEdit',
  templateUrl:  './vsInputButtonEdit.component.html',
  styleUrls:   ['./vsInputButtonEdit.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputButtonEdit extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  @Output()   onButtonClick:    EventEmitter<string>    = new EventEmitter<string>();


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)


  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args

  public doButtonClick(event: any) {
    this.onButtonClick.emit(this.FrmCtlName);
  }
}


