<!-- Header: Standard ----------------------------------------------------------------------------->


<appHeader></appHeader>

<!-- Main Content --------------------------------------------------------------------------------->

<div class="container-xxl vsMainContentContainer">

  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

        <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

        <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" Orientation="horizontal">
          <!--Gesetzliche Vertretung-->

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.legal_rep.heading'| translate}}">

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">

                <vsInputAutoComplete FrmCtlName="AntrStell_ID_Anrede" label="{{'application.legal_rep.form_of_address'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" [suggestions]="LookupAnrede" FieldName="Anrede_Name" MinLength=1 Dropdown=true></vsInputAutoComplete>

              </vsLayoutGroup>

              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputAutoComplete FrmCtlName="AntrStell_ID_Titel" label="{{'application.legal_rep.title'| translate}}" labelWidth="110px" [suggestions]="LookupTitel" FieldName="Titel_Kuerzel" MinLength=1 Dropdown=true></vsInputAutoComplete>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_Vorname" label="{{'application.legal_rep.first_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_Nachname" label="{{'application.legal_rep.last_name'| translate}}"  labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputDateTime FrmCtlName="AntrStell_Geb_Datum" label="{{'application.legal_rep.date_birth'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" >
                </vsInputDateTime>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_Strasse" label="{{'general.address.address_street'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_HausNr" label="{{'general.address.address_house_number'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_PLZ" label="{{'general.address.address_zip_code'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_Ort" label="{{'general.address.address_city'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsInputText FrmCtlName="AntrStell_eMail" label="{{'general.contact.email'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_Phone" label="{{'general.contact.phone'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
                <vsInputText FrmCtlName="AntrStell_Mobile" label="{{'general.contact.mobile'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>

        <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

      </vsLayoutGroup>


    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <button pButton label="{{'change.button_cancel'| translate}}" (click)="actionStepPrev()" class="vsElternButton"
      style="margin-right: 10px;"></button>
    <button pButton label="{{'change.button_send'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button>
  </div>

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>



</div>
<!-- Ende container-xxl -->


<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->

