//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Schülerbeförderung Landing Antragsart
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2022-03-10  MP   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';
import { routes }             from '../app-routing.module';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';

// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

import { TschoolViewAntragList }                      from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragList.component';
import { TschoolViewAntragEdit }                      from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit.component';
import { TschoolViewAntragEdit_Student }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Student.component';
import { TschoolViewAntragEdit_School }               from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_School.component';
import { TschoolViewAntragEdit_SEPA }                 from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_SEPA.component';
import { TschoolViewAntragEdit_Doc }                  from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Doc.component';
import { TschoolViewAntragEdit_Commit }               from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Commit.component';
import { TschoolViewAntragEdit_Antrag }               from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag.component';
import { TschoolViewAntragEdit_Besondere }            from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Besondere.component';
import { TschoolViewAntragEdit_PickDate }             from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_PickDate.component';
import { TschoolViewAntragEdit_Antrag_Erstattung }    from './schoolViewAntragEdit_Antrag_Erstattung.component';
import { TschoolViewAntragEdit_Antrag_Uebernahme }    from './schoolViewAntragEdit_Antrag_Uebernahme.component';
import { TschoolViewAntragEdit_Antrag_Individual }    from './schoolViewAntragEdit_Antrag_Individual.component';
import { TschoolViewAntragEdit_ErstattungList }       from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_ErstattungList.component';
import { TschoolViewAntragEdit_Antrag_Grundantrag }   from './schoolViewAntragEdit_Antrag_Grundantrag.component';
import { TschoolViewNachrichtEdit }                   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewNachrichtEdit.component';
import { TschoolViewNachrichtenList }                 from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewNachrichtenList.component';
import { TschoolViewAntragEdit_StudentSelection }     from './schoolViewAntragEdit_StudentSelection.component';
import { TschoolViewAntragEdit_Schule_Ausb }          from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Schule_Ausb.component';
import { TschoolViewAntragEdit_School_Attendance }    from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_School_Attendance.component';

// $Rev TC 2021-08-05 [MOCKUP] Eltern-Portal
interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}

@Component({
  selector:       'schoolViewAntragLanding',
  templateUrl:  './schoolViewAntragLanding.component.html',
  styleUrls:   ['./schoolViewAntragLanding.component.scss']
})
export class TschoolViewAntragLanding extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  // $Rev TC 2021-08-05 [MOCKUP] Eltern-Portal ---------- BEG ----------

  // public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  // public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse


  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antrag';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

  } // ngOnInit


  public actionStepPrev(): void {
    this.router.navigate(['main']);
  }

  public actionGrundantrag(): void { 

    this.dataModel.antrag_Art = 'G';
    
    // Überschreiben der Router-Pfade
    this.router.config[6] = { path: 'antragEdit',   component: TschoolViewAntragEdit,
    children: [
      { path: '',               redirectTo: 'antrag',    pathMatch: 'full'},
      { path: 'antrag',             component: TschoolViewAntragEdit_Antrag_Grundantrag},
      { path: 'studentSelection',   component: TschoolViewAntragEdit_StudentSelection},
      { path: 'student',            component: TschoolViewAntragEdit_Student},
      { path: 'school',             component: TschoolViewAntragEdit_School},
      { path: 'doc',                component: TschoolViewAntragEdit_Doc},
      { path: 'besondere',          component: TschoolViewAntragEdit_Besondere},
      // { path: 'sepa',               component: TschoolViewAntragEdit_SEPA},
      { path: 'commit',             component: TschoolViewAntragEdit_Commit},
      ]
    };
    
    this.router.navigateByUrl('antragEdit');
  }

//$REV MP 2023-08-09: Routerpfade für Azubi-Grundantrag hinzugefügt
  public actionGrundantragAzubi(): void { 

    this.dataModel.antrag_Art = 'GA';
    
    // Überschreiben der Router-Pfade
    this.router.config[6] = { path: 'antragEdit',   component: TschoolViewAntragEdit,
    children: [
      { path: '',               redirectTo: 'antrag',    pathMatch: 'full'},
      { path: 'antrag',             component: TschoolViewAntragEdit_Antrag_Grundantrag},
      { path: 'studentSelection',   component: TschoolViewAntragEdit_StudentSelection},
      { path: 'student',            component: TschoolViewAntragEdit_Student},
      { path: 'school_ausb',        component: TschoolViewAntragEdit_Schule_Ausb},
      { path: 'doc',                component: TschoolViewAntragEdit_Doc},
      { path: 'besondere',          component: TschoolViewAntragEdit_Besondere},
      // { path: 'sepa',               component: TschoolViewAntragEdit_SEPA},
      { path: 'commit',             component: TschoolViewAntragEdit_Commit},
      ]
    };
    
    this.router.navigateByUrl('antragEdit');
  }

  //$REV MP 2023-08-09: Routerpfade für Azubi-Erstattungsantrag hinzugefügt
  public actionErstattungAzubi(): void {

    this.dataModel.antrag_Art = 'EA';

    // Überschreiben der Router-Pfade
    this.router.config[6] = { path: 'antragEdit',   component: TschoolViewAntragEdit,
    children: [
      { path: '',               redirectTo: 'antrag',    pathMatch: 'full'},
      { path: 'antrag',             component: TschoolViewAntragEdit_Antrag_Erstattung},
      { path: 'school_attendance',  component: TschoolViewAntragEdit_School_Attendance},
      { path: 'erstattung',         component: TschoolViewAntragEdit_ErstattungList},
      { path: 'doc',                component: TschoolViewAntragEdit_Doc},
      //$REV MP 2024-05-24: Für Erstattungsanträge nicht relevant. Betrifft nur Grundanträge.
      // { path: 'besondere',          component: TschoolViewAntragEdit_Besondere},
      { path: 'sepa',               component: TschoolViewAntragEdit_SEPA},
      { path: 'commit',             component: TschoolViewAntragEdit_Commit},
      ]
    };       

    this.router.navigateByUrl('antragEdit');
  }

  public actionErstattung(): void {

    this.dataModel.antrag_Art = 'E';

    // Überschreiben der Router-Pfade
    this.router.config[6] = { path: 'antragEdit',   component: TschoolViewAntragEdit,
    children: [
      { path: '',               redirectTo: 'antrag',    pathMatch: 'full'},
      { path: 'antrag',         component: TschoolViewAntragEdit_Antrag_Erstattung},
      // { path: 'student',        component: TschoolViewAntragEdit_Student},
      // { path: 'school',         component: TschoolViewAntragEdit_School},
      { path: 'erstattung',     component: TschoolViewAntragEdit_ErstattungList},
      { path: 'doc',            component: TschoolViewAntragEdit_Doc},
      //$REV MP 2024-05-24: Für Erstattungsanträge nicht relevant. Betrifft nur Grundanträge.
      // { path: 'besondere',          component: TschoolViewAntragEdit_Besondere},
      //$REV MP 2022-02-03: Nach Kundenrücksprache Bankdaten für Erstattungen wieder hinzugefügt.
      { path: 'sepa',           component: TschoolViewAntragEdit_SEPA},
      { path: 'commit',         component: TschoolViewAntragEdit_Commit},
      ]
    };       

    this.router.navigateByUrl('antragEdit');
  }

  public actionUebernahme(): void {

    this.dataModel.antrag_Art = 'U';

    // Überschreiben der Router-Pfade
    this.router.config[6] = { path: 'antragEdit',   component: TschoolViewAntragEdit,
    children: [
      { path: '',               redirectTo: 'antrag',    pathMatch: 'full'},
      { path: 'antrag',         component: TschoolViewAntragEdit_Antrag_Uebernahme},
      { path: 'student',        component: TschoolViewAntragEdit_Student},
      { path: 'school',         component: TschoolViewAntragEdit_School},
      { path: 'sepa',           component: TschoolViewAntragEdit_SEPA},
      { path: 'besondere',      component: TschoolViewAntragEdit_Besondere},
      { path: 'doc',            component: TschoolViewAntragEdit_Doc},
      { path: 'commit',         component: TschoolViewAntragEdit_Commit},
      ]
    };       
    
    this.router.navigateByUrl('antragEdit');
  }

  public actionIndividual(): void {

    this.dataModel.antrag_Art = 'I';

    // Überschreiben der Router-Pfade
    this.router.config[6] = { path: 'antragEdit',   component: TschoolViewAntragEdit,
    children: [
      { path: '',               redirectTo: 'antrag',    pathMatch: 'full'},
      { path: 'antrag',         component: TschoolViewAntragEdit_Antrag_Individual},
      { path: 'student',        component: TschoolViewAntragEdit_Student},
      { path: 'school',         component: TschoolViewAntragEdit_School},
      // { path: 'besondere',      component: TschoolViewAntragEdit_Besondere},
      { path: 'pickup',         component: TschoolViewAntragEdit_PickDate},
      { path: 'sepa',           component: TschoolViewAntragEdit_SEPA},
      { path: 'doc',            component: TschoolViewAntragEdit_Doc},
      { path: 'commit',         component: TschoolViewAntragEdit_Commit},
      ]
    };     
    
    this.router.navigateByUrl('antragEdit');
  }

} // schoolViewAntragLanding


