//=== Imports
import { Injectable }                         from "@angular/core";
import { TFileData }                          from "AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component";
import * as vsCommon                          from '@vsLib/vsCommon';
import { TvsGlobalResources }                 from '@vsLib/Services/vsGlobalResources.service';


@Injectable({
    providedIn: 'root'
  })

  

export class TschoolDataModel {

  constructor(
    private globalResources: TvsGlobalResources) {
  } // constructor

  //$REV MP 2023-01-31: Konfigurationsdatei app.config.json über globalResources angeschlossen
  //Userbild-Upload auf der schoolViewAntragEditStudent
  // public KennUserImage = this.globalResources.AppConfigData.KennUserImage;

  // //Antragsarten
  public KennAntragIndividual = this.globalResources.AppConfigData.KennAntragIndividual;

  public KennGrundantragErstattung = this.globalResources.AppConfigData.KennGrundantragErstattung;

  public KennAntragErstattung = this.globalResources.AppConfigData.KennAntragErstattung;

  public KennAntragTicket = this.globalResources.AppConfigData.KennAntragTicket;

  public KennOrtsteilRequired = this.globalResources.AppConfigData.KennOrtsteilRequired;

  public KennAntragAzubi = this.globalResources.AppConfigData.KennAntragAzubi;

  public KennStrassenVerz = this.globalResources.AppConfigData.KennStrassenVerz;

  public KennTransportOptions = this.globalResources.AppConfigData.KennTransportOptions;

  //$REV MP 2024-05-16: Transportgrund als Option hinzugefügt.
  public KennApplicationReason = this.globalResources.AppConfigData.KennApplicationReason;

  //$REV MP 2023-09-25: Ausbildungsstättenangabe ist bei einigen Kunden nicht notwendig (VBK), bei anderen schon (SAMR)
  public KennAusbStaettRequired = this.globalResources.AppConfigData.KennAusbStaettRequired;
  //$REV MP 2023-09-25: Bei SAMR soll das aktuell laufende Halbjahr mit angezeigt werden, bei anderen Kunden nicht (RTK)
  public KennErstattungRunningHalfYear = this.globalResources.AppConfigData.KennErstattungRunningHalfYear;

  public kennDisplayPersData = false;


  // // Links im Footer
  public footerLinkFrequentQuestions  = this.globalResources.AppConfigData.footerLinkFrequentQuestions;

  public footerLinkKontakt            = this.globalResources.AppConfigData.footerLinkKontakt;

  public footerLinkImprint            = this.globalResources.AppConfigData.footerLinkImprint;

  public footerLinkDataSecurity       = this.globalResources.AppConfigData.footerLinkDataSecurity;

  public footerLinkImageCreator       = this.globalResources.AppConfigData.footerLinkImageCreator;

  public footerLinkImageCreatorText   = this.globalResources.AppConfigData.footerLinkImageCreatorText;


  //Userbild-Upload auf der schoolViewAntragEditStudent
  public KennUserImage = false;

  // //Antragsarten
  // public KennAntragIndividual = false;

  // public KennGrundantragErstattung = true;

  // public KennAntragErstattung = true;

  // public KennAntragTicket = false;


  // // Links im Footer
  // public footerLinkFrequentQuestions  = "#";

  // public footerLinkKontakt            = "#";

  // public footerLinkImprint            = "#";

  // public footerLinkDataSecurity       = "#";

  // public footerLinkImageCreator       = "https://www.freepik.com/";

  // public footerLinkImageCreatorText   = "Images designed by Freepik";


  //Für Anhänge und Vorgänge
  public files:                         any;

  public vorgHint:                      any;

  public vorgMain:                      any;

  public KennMessageWithAttachment:     boolean;

  public KennAttachment:                boolean;

  public antrID_Grundantrag:            string;

  //$REV MP 2023-10-18: Transportart hinzugefügt.

  public transp_Art:                     string;

  public transp_Art_Rec:                 any;

  public transp_Reason:                  string;

  public transp_Reason_Rec:              any;

  public startDate_Grundantrag?:         string;
  public endDate_Grundantrag?:           string;

  //$REV MP 2023-09-25: Sind nicht immer befüllt, müssen aber beachtet werden wenn Sie gefüllt sind.
  public kostenUebernahme_ab?:           string;
  public kostenUebernahme_bis?:          string;

  //Zum befüllen des AntragsViews. Wird mit dem content von table.dataSelected gefüllt.
  public  antrView: any;

  //wird verwendet wenn ein Antrag abgeschickt wurde
  public  isSubmitted:              boolean = false;

  //=== Properties

  //ID des eingeloggten Users

  public userID?:                       string;


  //Auslösen der Toast-Success-Message nach abschicken der Meldung. Initial auf false, wird nach abschicken auf true gesetzt.
  public KennMessageSent?:              boolean = false;

  public KennMessageEdit?:              boolean;

  //Boolean um zu entscheiden ob man das MessageEdit von der Liste "Meine Nachrichten" aufgerufen hat.
  public KennMessageList?:              boolean = false;

  public KennAdrNotFoundAzubi?:         boolean = false;

  //ID des Antrages (Ticket-ID)
  public antragCRMTicketID?:            string;

  public vorgID?:                        string;

  //--- Antragsart

  public antrag_Art?:                   any;
  public antrag_Datum?:                 any;


  public antr_Datum_Guelt_Beg?:          any;
  public antr_Datum_Guelt_End?:          any;

  //Komplette Antragsdaten z.B. für die Anzeige auf der Nachrichtenliste
  public antrag?:                        any;

  //Schuljahr

  public antrag_Schuljahr?:             any;

  // public get AntrDatum(): string { return vsCommon.ISO8061ToDateStr(this.antrag_Datum) }

  // Azubi & Gesetzlicher Vertreter
  // KennAzubiGesVertr wird auf true gesetzt wenn Schüler keinen gesetzlichen Vertreter benötigt.
  // Standardwert ist false
  public KennAzubiGesVertr:             boolean = false;

  public azubi_ID?:                     any;

  public azubi_Anrede_Rec?:             any;
  public azubi_Anrede?:                 any;
  public azubi_Vorname?:                any;
  public azubi_Nachname?:               any;
  public azubi_Geb_Datum?:              any;
  public azubi_Strasse?:                any;
  public azubi_HausNr?:                 any;
  public azubi_Adr_Zusatz?:             any;
  public azubi_PLZ?:                    any;
  public azubi_Ort?:                    any;
  public azubi_Ortsteil?:               any;
  public azubi_Phone?:                  any;
  public azubi_eMail?:                  any;
  public azubi_Mobile?:                 any;
  public azubi_Nr?:                     any;

  //$REV MP 2023-08-23: Straßenverzeichnis Azubi
  public azubi_StrVerz?:                any;

  public antrStell_Anrede_Rec?:         any;
  public antrStell_Anrede?:             any;
  public antrStell_Titel_Rec?:          any;
  public antrStell_Titel?:              any;
  public antrStell_Vorname?:            any;
  public antrStell_Nachname?:           any;
  public antrStell_Geb_Datum?:          any;
  public antrStell_Strasse?:            any;
  public antrStell_HausNr?:             any;
  public antrStell_Adr_Zusatz?:         any;
  public antrStell_PLZ?:                any;
  public antrStell_Ort?:                any;
  public antrStell_Ortsteil?:           any;
  public antrStell_Phone?:              any;
  public antrStell_eMail?:              any;
  public antrStell_Mobile?:             any;
  public antr_ID_Kontakt_AntrStell?:    any;

  //$REV MP 2023-08-23: Straßenverzeichnis GesVertreter
  public antrStell_StrVerz?:                any;

  //REV MP 2023-08-15: Ausbildungsstätte Daten

  public antrStell_AusbBetr_Name?:       any;
  public antrStell_AusbBetr_Ort?:        any;
  public antrStell_AusbBetr_Ortsteil?:   any;
  public antrStell_AusbBetr_PLZ?:        any;
  public antrStell_AusbBetr_Strasse?:    any;
  public antrStell_AusbBetr_HausNr?:     any;
  public antrStell_AusbBetr_Adr_Zusatz?: any;
  public antrStell_Ausb_School_Text?:    any;

  //$REV MP 2023-08-23: Straßenverzeichnis Ausbildungsbetrieb
  public antrStell_AusbBetr_StrVerz?:                any;


  //Schule
  // Gesamter Schul-Datensatz
  public antr_Ausb_Staett_Rec?:         any;
  public antr_ID_Ausb_Staett_Name?:     any;

  public antr_Klasse_Beg_Nr?:           any;
  public antr_Klasse_Beg_Zusatz?:       any;


  // Erstattung
  public antr_Data_Erstattung?:         any = { // wird beim Speichern zu string umgebaut
      items: []
    , itemcount: 0
  };

  // Besondere Umstände
  public Kenn_Bes_Schulweg_Danger?:       boolean = false;

  public antr_Bes_Erlaeut?:             any;


  // Individual Zeiten
  public antr_Data_Individual?:         any = { // wird beim Speichern zu string umgebaut
      pickupHome:   {
        Time_PickupHome_Monday:         null
      , Time_PickupHome_Tuesday:        null
      , Time_PickupHome_Wednesday:      null
      , Time_PickupHome_Thursday:       null
      , Time_PickupHome_Friday:         null
      , Time_PickupHome_Saturday:       null
      , Time_PickupHome_Sunday:         null
      }
    , pickupSchool: {
        Time_PickupSchool_Monday:       null
      , Time_PickupSchool_Tuesday:      null
      , Time_PickupSchool_Wednesday:    null
      , Time_PickupSchool_Thursday:     null
      , Time_PickupSchool_Friday:       null
      , Time_PickupSchool_Saturday:     null
      , Time_PickupSchool_Sunday:       null
    }
    , additInfo: {
        Wheelchair:                     false
      , Assistant:                      false
      , AdditInfoMsg:                   null
    }
  };


  //Bank

  public antrStell_Bank_ID?:            any;
  public antrStell_Kto_Inh?:            any;
  public antrStell_IBAN?:               any;
  public antrStell_Bank_Name?:          any;
  public antrStell_Bank_BIC?:           any;

  // Eltern Portal


  public antr_ID_Change_Reason?:        any;
  public PKValue?:                      any;
  public article_Data:                  any;
  public articles = [];

  public workInProgressMessage:         boolean = false;

  public azubiNutzungsDaten:  {
    browser: {"id": number};
    device:  {"id": number};
    onlineService:  {"id": number};
    processBegin: string;
    processEnd?: string;
    language: string;
    transmissionType:  {"id": number};
    dataSource: string;
    processStatus:  {"id": number};
  }


  public EmptyData() :void{

    this.antrag_Art                   = null;
    this.antrag_Datum                 = null;

    this.antr_Datum_Guelt_Beg         = null;
    this.antr_Datum_Guelt_End         = null;

    this.startDate_Grundantrag        = null;
    this.endDate_Grundantrag          = null;

    this.kostenUebernahme_ab          = null;
    this.kostenUebernahme_bis         = null;

    this.files                        = null;
    this.vorgHint                     = null;
    this.vorgMain                     = null;

    this.transp_Art                   = null;

    this.transp_Art_Rec               = null;

    this.transp_Reason                = null;

    this.transp_Reason_Rec            = null;

    //Schuljahr

    this.antrag_Schuljahr             = null;

    this.KennAzubiGesVertr            = false;

    this.KennAdrNotFoundAzubi         = false;

    this.kennDisplayPersData          = false;
    // Azubi & Gesetzlicher Vertreter

    this.azubi_ID                     = null;

    this.azubi_Anrede_Rec             = null;
    this.azubi_Anrede                 = null;
    this.azubi_Vorname                = null;
    this.azubi_Nachname               = null;
    this.azubi_Geb_Datum              = null;
    this.azubi_Strasse                = null;
    this.azubi_HausNr                 = null;
    this.azubi_Adr_Zusatz             = null;
    this.azubi_PLZ                    = null;
    this.azubi_Ort                    = null;
    this.azubi_Ortsteil               = null;
    this.azubi_Phone                  = null;
    this.azubi_eMail                  = null;
    this.azubi_Mobile                 = null;
    this.azubi_Nr                     = null;

    this.azubi_StrVerz                = null;

    this.antrStell_Anrede_Rec         = null;
    this.antrStell_Anrede             = null;
    this.antrStell_Titel_Rec          = null;
    this.antrStell_Titel              = null;
    this.antrStell_Vorname            = null;
    this.antrStell_Nachname           = null;
    this.antrStell_Geb_Datum          = null;

    this.antrStell_Strasse            = null;
    this.antrStell_HausNr             = null;
    this.antrStell_Adr_Zusatz         = null;
    this.antrStell_PLZ                = null;
    this.antrStell_Ort                = null;
    this.antrStell_Ortsteil           = null;
    this.antrStell_Phone              = null;
    this.antrStell_eMail              = null;
    this.antrStell_Mobile             = null;

    this.antrStell_StrVerz            = null;

    this.antr_ID_Kontakt_AntrStell    = null;


    //Schule

    this.antr_Ausb_Staett_Rec         = null;

    this.antr_ID_Ausb_Staett_Name     = null;
    this.antr_Klasse_Beg_Nr           = null;
    this.antr_Klasse_Beg_Zusatz       = null;

    this.article_Data                 = null;

    this.articles                     = [];


    //Ausbildungsstätte

    this.antrStell_AusbBetr_Name         = null;
    this.antrStell_AusbBetr_Ort          = null;
    this.antrStell_AusbBetr_Ortsteil     = null;
    this.antrStell_AusbBetr_PLZ          = null;
    this.antrStell_AusbBetr_Strasse      = null;
    this.antrStell_AusbBetr_HausNr       = null;
    this.antrStell_AusbBetr_Adr_Zusatz   = null;
    this.antrStell_Ausb_School_Text      = null;

    this.antrStell_AusbBetr_StrVerz      = null;
  

    //Bank

    this.antrStell_Kto_Inh            = null;
    this.antrStell_IBAN               = null;
    this.antrStell_Bank_Name          = null;
    this.antrStell_Bank_BIC           = null;
    this.antrStell_Bank_ID            = null;

    // Besondere Umstände

    this.Kenn_Bes_Schulweg_Danger     = false;
    this.antr_Bes_Erlaeut             = null;

    this.antr_Data_Erstattung         = null;
    this.antr_Data_Individual         = null;


  // Individual Zeiten leeren
  this.antr_Data_Individual = {
    pickupHome:   {
        Time_PickupHome_Monday:         null
      , Time_PickupHome_Tuesday:        null
      , Time_PickupHome_Wednesday:      null
      , Time_PickupHome_Thursday:       null
      , Time_PickupHome_Friday:         null
      , Time_PickupHome_Saturday:       null
      , Time_PickupHome_Sunday:         null
    }
    , pickupSchool: {
        Time_PickupSchool_Monday:       null
      , Time_PickupSchool_Tuesday:      null
      , Time_PickupSchool_Wednesday:    null
      , Time_PickupSchool_Thursday:     null
      , Time_PickupSchool_Friday:       null
      , Time_PickupSchool_Saturday:     null
      , Time_PickupSchool_Sunday:       null
    }
    , additInfo: {
        Wheelchair:                     false
      , Assistant:                      false
      , AdditInfoMsg:                   null
    }
  };


    this.isSubmitted                  = false;
  }

} // schoolDataModel

