//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputText
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-06-29  TC   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';
import { asLiteral } from '@angular/compiler/src/render3/view/util';



//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputText',
  templateUrl:  './vsInputText.component.html',
  styleUrls:   ['./vsInputText.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputText extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input()    placeholder:            string            = '';

  @Input()    NoLabel:                boolean           = false;

  @Input()    valid:                  boolean           = true;

  @Output()   onBlur:                 EventEmitter<any>   = new EventEmitter<any>();

  @Output()   input:                  EventEmitter<any>   = new EventEmitter<any>();


  // @Input()    pTooltip:               string            = '';
  // @Input()    tooltipPosition:        string            = '';
  // @Input()    tooltipEvent:           string            = '';
  // @Input()    tooltipStyleClass:      string            = '';

  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.

  doOnBlur(event) {
    this.onBlur.emit(event);
  }

  doOnInput(event) {
    this.input.emit(event);
  }

  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
  } // args



} // TvsInputText


