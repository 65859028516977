//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';


import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';



// $Rev TC 2021-08-05 [MOCKUP] Eltern-Portal
interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}

type TranslationItemsType = {
  translationLabel: string;
  routerLink: string;
  wizardAactiveIndex: number;
}



@Component({
  selector:       'schoolViewAntragEdit',
  templateUrl:  './schoolViewAntragEdit.component.html',
  styleUrls:   ['./schoolViewAntragEdit.component.scss']
})
export class TschoolViewAntragEdit extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  // $Rev TC 2021-08-05 [MOCKUP] Eltern-Portal ---------- BEG ----------

  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  public      MenuUser:                       MenuItem[];           // PrimeNG

  // $Rev TC 2021-08-05 [MOCKUP] Eltern-Portal ---------- END ----------


  public      wizardItems:                    MenuItem[]                        = [];
  public      wizardAactiveIndex:             number                            = 0;

  public      translationItems:               TranslationItemsType[]            = [];

  @ViewChild('tabsMain')                      tabsMain:                         vsTabControl.TvsTabControl;



  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antrag';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];


    // [LABOR]
    // GUI Language
    let sURLBaseIconsFlags:   string  = '/assets/img/CountryFlags/';
    this.GUILanguageList = [
        {ID: 'de-DE',  Caption: 'German',   IconURL: sURLBaseIconsFlags + 'Germany.png'}
      , {ID: 'en-GB',  Caption: 'English',  IconURL: sURLBaseIconsFlags + 'UnitedKingdom.png'}
      , {ID: 'it-IT',  Caption: 'Italian',  IconURL: sURLBaseIconsFlags + 'Italy.png'}
    ];

    this.GUILanguageSelected = this.GUILanguageList[0];   // [LABOR] TODO: Kann vermutlich beim Laden des UserProfiles erfolgen

    //$REV TE 2022-07-27: Initiale Erstellung der zu übersetzenden Items
    this.translationItems = [
      {translationLabel:'wizard.application',         routerLink: 'antrag',           wizardAactiveIndex: 0},
      {translationLabel:'wizard.student',             routerLink: 'studentSelection', wizardAactiveIndex: 1},
      {translationLabel:'wizard.student',             routerLink: 'student',          wizardAactiveIndex: 2},
      {translationLabel:'wizard.school',              routerLink: 'school',           wizardAactiveIndex: 3},
      {translationLabel:'wizard.reimbursement',       routerLink: 'erstattung',       wizardAactiveIndex: 4},
      {translationLabel:'wizard.spec_circumstances',  routerLink: 'besondere',        wizardAactiveIndex: 5},
      {translationLabel:'wizard.documents',           routerLink: 'doc',              wizardAactiveIndex: 6},
      {translationLabel:'wizard.bank',                routerLink: 'sepa',             wizardAactiveIndex: 7},
      {translationLabel:'wizard.commit',              routerLink: 'commit',           wizardAactiveIndex: 8}
    ];
  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    this.MenuUser = [
        {label: 'Mein Profil',    icon: 'pi pi-fw pi-user',       command: event => {this.mnuUserMyProfile_OnClick()}}
      , {label: 'Einstellungen',  icon: 'pi pi-fw pi-cog',        command: event => {this.mnuUserConfig_OnClick()}}
      , {separator: true}
      , {label: 'Abmelden',       icon: 'pi pi-fw pi-power-off',  command: event => {this.actionLogout()}}
    ] // User Menu

    //$REV TE 2022-07-27: Initiale Erstellung des Wizard Menüs 
    this.createWizardMenuCases(false);

    //$REV TE 2022-07-27: Neuerstellung des Wizard Menüs pro Sprachauswahl, indem auf das Event bei Auswahl reagiert wird
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.createWizardMenuCases(true);
    });

   

  } // ngOnInit

  
  //------------------------------------------------------------------------------------------------
  // Method:  mnuUserMyProfile_OnClick
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: User Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuUserMyProfile_OnClick(): void {
    alert('Mein Benutzerprofil');
  } // mnuUserMyProfile_OnClick


  public mnuUserConfig_OnClick(): void {
    alert('Einstellungen');
  } // mnuUserConfig_OnClick


  public mnuUserLogout_OnClick(): void {
    this.globalResources.UserProfile.Logout();
  } // mnuUserLogout_OnClick


  // $Rev TC 2021-07-30
  //
  // Das wird von dem vorübergehenden zusätzlichem Button "Abmelden" aufgerufen.
  // Dieser Button ist momentan erforderlich, da das User Menu aktuell nicht funktioniert (wegen a href)
  public actionLogout(): void {
    this.globalResources.UserProfile.Logout();
    this.router.navigateByUrl('/home');
  } // actionLogout

  //$REV TE 2022-07-27: Methode zur Erstellung der Wizard Items
  public createWizardItems (item, res) {
    this.wizardItems.push(
      {label: res,    
       routerLink: item.routerLink,      
       command: (event: any) => {
         this.wizardAactiveIndex = item.wizardAactiveIndex;
       }
     });
  }

  //$REV TE 2022-07-27: Methode zur Erstellung des Wizard Menüs
  public createWizardMenu(translationItems, translated?) {
    if(translated) {
      this.wizardItems = [];
    }
    translationItems.map((item) => {
        this.translate.get(item.translationLabel).subscribe((res)=>{
          this.createWizardItems(item, res)
      });
    });
  }

  //$REV TE 2022-07-27: Methode zur Erstellung des Wizard Menüs pro Case
  public createWizardMenuCases(translated) {

    if(this.dataModel.antrag_Art === 'G') {   // Erstattungs-Grundantrag
      //$REV TE 2022-07-27: Erstellung des Wizard Menüs pro Case (G)
      this.translationItems = [
        {translationLabel:'wizard.application',         routerLink: 'antrag',           wizardAactiveIndex: 0},
        {translationLabel:'wizard.student_selection',   routerLink: 'studentSelection', wizardAactiveIndex: 1},
        {translationLabel:'wizard.student',             routerLink: 'student',          wizardAactiveIndex: 2},
        {translationLabel:'wizard.school',              routerLink: 'school',           wizardAactiveIndex: 3},
        // {translationLabel:'wizard.reimbursement',    routerLink: 'erstattung',    wizardAactiveIndex: 3},
        {translationLabel:'wizard.spec_circumstances',  routerLink: 'besondere',        wizardAactiveIndex: 4},
        {translationLabel:'wizard.documents',           routerLink: 'doc',              wizardAactiveIndex: 5},
        // {translationLabel:'wizard.bank',             routerLink: 'sepa',             wizardAactiveIndex: 5},
        {translationLabel:'wizard.commit',              routerLink: 'commit',           wizardAactiveIndex: 6}
      ];
      // this.translationItems[3] = {translationLabel: this.translate.instant('wizard.pickup'), routerLink: 'pickup', wizardAactiveIndex: 3};
      this.createWizardMenu(this.translationItems, translated ? true : false);
    }

    if(this.dataModel.antrag_Art === 'GA') {   // Erstattungs-Grundantrag für Berufsschüler
      //$REV MP 2023-08-15: Erstellung des Wizard Menüs pro Case (GA)
      this.translationItems = [
        {translationLabel:'wizard.application',         routerLink: 'antrag',           wizardAactiveIndex: 0},
        {translationLabel:'wizard.student_selection',   routerLink: 'studentSelection', wizardAactiveIndex: 1},
        {translationLabel:'wizard.student',             routerLink: 'student',          wizardAactiveIndex: 2},
        {translationLabel:'wizard.school_ausb',         routerLink: 'school_ausb',      wizardAactiveIndex: 3},
        // {translationLabel:'wizard.reimbursement',    routerLink: 'erstattung',    wizardAactiveIndex: 3},
        {translationLabel:'wizard.spec_circumstances',  routerLink: 'besondere',        wizardAactiveIndex: 4},
        {translationLabel:'wizard.documents',           routerLink: 'doc',              wizardAactiveIndex: 5},
        // {translationLabel:'wizard.bank',             routerLink: 'sepa',             wizardAactiveIndex: 5},
        {translationLabel:'wizard.commit',              routerLink: 'commit',           wizardAactiveIndex: 6}
      ];
      // this.translationItems[3] = {translationLabel: this.translate.instant('wizard.pickup'), routerLink: 'pickup', wizardAactiveIndex: 3};
      this.createWizardMenu(this.translationItems, translated ? true : false);
    }

    if(this.dataModel.antrag_Art === 'E') {   // Erstattung
      //$REV TE 2022-07-27: Erstellung des Wizard Menüs pro Case (E)
      this.translationItems = [
        {translationLabel:'wizard.application',        routerLink: 'antrag',       wizardAactiveIndex: 0},
        // {translationLabel:'wizard.student',         routerLink: 'student',      wizardAactiveIndex: 1},
        // {translationLabel:'wizard.school',          routerLink: 'school',       wizardAactiveIndex: 2},
        {translationLabel:'wizard.reimbursement',      routerLink: 'erstattung',   wizardAactiveIndex: 1},
        //$REV MP 2024-05-24: Für Erstattungsanträge nicht relevant. Betrifft nur Grundanträge.
        // {translationLabel:'wizard.spec_circumstances', routerLink: 'besondere',    wizardAactiveIndex: 2},
        {translationLabel:'wizard.documents',          routerLink: 'doc',          wizardAactiveIndex: 2},
        {translationLabel:'wizard.bank',               routerLink: 'sepa',         wizardAactiveIndex: 3},
        {translationLabel:'wizard.commit',             routerLink: 'commit',       wizardAactiveIndex: 4}
      ];
      // this.translationItems[3] = {translationLabel: this.translate.instant('wizard.reimbursement'), routerLink: 'erstattung', wizardAactiveIndex: 3};
      this.createWizardMenu(this.translationItems, translated ? true : false);
    }

    if(this.dataModel.antrag_Art === 'EA') {   // Erstattung für Berufsschüler
      //$REV MP 2023-08-16: Erstellung des Wizard Menüs pro Case (E)
      this.translationItems = [
        {translationLabel:'wizard.application',        routerLink: 'antrag',                 wizardAactiveIndex: 0},
        {translationLabel:'wizard.school_attendance',  routerLink: 'school_attendance',      wizardAactiveIndex: 1},
        {translationLabel:'wizard.reimbursement',      routerLink: 'erstattung',             wizardAactiveIndex: 2},
        //$REV MP 2024-05-24: Für Erstattungsanträge nicht relevant. Betrifft nur Grundanträge.
        // {translationLabel:'wizard.spec_circumstances', routerLink: 'besondere',              wizardAactiveIndex: 3},
        {translationLabel:'wizard.documents',          routerLink: 'doc',                    wizardAactiveIndex: 3},
        {translationLabel:'wizard.bank',               routerLink: 'sepa',                   wizardAactiveIndex: 4},
        {translationLabel:'wizard.commit',             routerLink: 'commit',                 wizardAactiveIndex: 5}
      ];
      // this.translationItems[3] = {translationLabel: this.translate.instant('wizard.reimbursement'), routerLink: 'erstattung', wizardAactiveIndex: 3};
      this.createWizardMenu(this.translationItems, translated ? true : false);
    }

    if(this.dataModel.antrag_Art === 'U') {   // Übernahme Fahrtkosten
      //$REV TE 2022-07-27: Erstellung des Wizard Menüs pro Case (U)
      this.translationItems[3] = {translationLabel: this.translate.instant('wizard.spec_circumstances'), routerLink: 'besondere', wizardAactiveIndex: 3};
      this.createWizardMenu(this.translationItems, translated ? true : false);
    }

    if(this.dataModel.antrag_Art === 'I') {   // Individualverkehr
      //$REV TE 2022-07-27: Erstellung des Wizard Menüs pro Case (I)
      this.translationItems[3] = {translationLabel: this.translate.instant('wizard.pickup'), routerLink: 'pickup', wizardAactiveIndex: 3};
      this.createWizardMenu(this.translationItems, translated ? true : false);
    }
  }


  public doDropdownGUILanguageSetAsStandard() {
    alert('Ausgewählte Sprache als Standard einstellen:  ' + this.GUILanguageSelected.Caption);
  }


} // schoolViewAntragEdit


