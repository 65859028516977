//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Dialog Doc-Upload
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-13  MH   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        Input}         from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl }           from '@angular/forms';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';

//Alyle Image Cropper
import { ImgCropperConfig, ImgCropperEvent, LyImageCropper, ImgCropperErrorEvent, ImgCropperLoaderConfig } from '@alyle/ui/image-cropper';

// PrimeNG
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';
import { DynamicDialogConfig }              from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { SafeUrl } from '@angular/platform-browser';


export class TFileData  {
  public      UploadFile:              File                  = null;
  public      UploadFileName:          string                = null;
  public      UploadFileDataURL:       string                = null;
  public      UploadFileDataBinary?:    string                = null;
  public      UploadFileDataBase64:    string                = null;
  public      croppedImage?:            any                   = null;
  public      src?:                     SafeUrl                = null;
  public      type?:                    string                = null;
}



@Component({
  selector:       'dlgDoc',
  templateUrl:  './dlgDoc.component.html',
  // styleUrls:   ['./dlgDoc.component.scss']
})


export class TdlgDoc {

  // Alyle Image Cropper Anfang
  //classes = this.sRenderer.renderSheet(STYLES);
  croppedImage?: string;
  scale: number;
  ready: boolean;
  minScale: number;
  @ViewChild(LyImageCropper) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    // autoCrop: true,
    width: 200, // Default `250` doppelte größe des Standard-Passfoto-Formates (100 x 128)
    height: 256, // Default `200`
    fill: '#ff2997', // Default transparent if type = png else #000
    type: 'image/png', // Or you can also use `image/jpeg`
    responsiveArea: true}


    //====== Properties (PRIVATE)



  //====== Properties (PUBLIC)
  public      bKennUpload:              boolean               = false;

  public      FormGroupFileUpload:      FormGroup             = new FormGroup({});
  public      DocType:                  any[]                 = [];
  
  //Maximale Dateigröße in Byte TODO MP, sollte ausgelagert werden, damit es pro App konfigurierbar bleibt
  // public      fileMaxUploadSize                               = 3145728;
  public      fileMaxUploadSize                               = 10485760;
  public      customText:               string                = this.config['data'] ? this.config['data'].customText : null;

  //Anzeige der maximalen Dateigröße im HTML in Megabyte
  public get  fileMaxUploadSizeDisplay (): number {
   
    let fileMaxUploadSizeDisplay
    
    fileMaxUploadSizeDisplay = this.fileMaxUploadSize / 1048576;

      return fileMaxUploadSizeDisplay;
  }


  public      displayDialogErrorFileSize:          boolean    = false;
  public      displayDialogErrorFileType:          boolean    = false;

  //---- File Upload
  // public      FUploadFile:              File                  = null;
  // public      FUploadFileName:          string                = null;
  // public      FUploadFileDataURL:       string                = null;
  // public      FUploadFileDataBinary:    string                = null;
  // private     FUploadFileDataBase64:    string                = null;

  public      FileData:                 TFileData             = new TFileData();

  //====== Constructor

  constructor(public    ref:                        DynamicDialogRef,
              public    config:                     DynamicDialogConfig) {

    this.FormGroupFileUpload.addControl('Doc_Name',           new FormControl(null));
    this.FormGroupFileUpload.addControl('scale',         new FormControl(0));

    console.log('Ref:', ref);
    console.log('Config:', config);

    // this.FormGroupFileUpload.addControl('Doc_Type',           new FormControl(null));
    // this.FormGroupFileUpload.controls['Doc_Type'].setValue(null);

    // this.DocType.push(
    //    {Art_ID: 1, Name: "Antrag auf Schülerbeförderung"}
    //   ,{Art_ID: 2, Name: "Foto für Fahrausweis"}
    //   ,{Art_ID: 3, Name: "Sonstiges"}

    //  );


  } // constructor

  // ngOnInit(): void {


   

  // } // ngOnInit


  onUpload(){
    this.ref.close(this.FileData);
  }

  onAbbruch(){
    this.FileData = null;
    this.ref.close(this.FileData);
  }

  doOnFileSelected(event: any) {

    let bOK = true;
    this.FileData.UploadFile        = event.target.files[0];
    this.FileData.UploadFileName    = this.FileData.UploadFile.name;
    const reader                    = new FileReader();

    // this.FormGroupFileUpload.controls['Doc_Name'].setValue(this.FileData.UploadFileName);

    // console.log('#### File Upload: doOnFileSelected():  event.target = ', event.target);

    //------ Einlesen der Datei

    //$REV MP 2022-02-04: Prüfung auf eine maximale Dateigröße und eine Liste von Dateitypen
    //Check maximal zulässige file size
    if (bOK) {
  
      bOK = event.target.files[0].size <= this.fileMaxUploadSize;

      // console.log('file-size kleiner 3MB' , event.target.files[0].size)
      this.displayDialogErrorFileSize = !bOK;

      this.bKennUpload = false;

        //bei false input leeren (bei mehrfachem ausführen notwendig)
        if (!bOK) {
          this.FormGroupFileUpload.controls['Doc_Name'].setValue('');
        }

      // console.log('bOK file size', bOK)
    }

    //Check zulässige file types
    if (bOK) {

      bOK = false;

      bOK = bOK || (event.target.files[0].type == 'image/jpg');
      bOK = bOK || (event.target.files[0].type == 'image/jpeg');
      bOK = bOK || (event.target.files[0].type == 'image/gif');
      bOK = bOK || (event.target.files[0].type == 'image/bmp');
      bOK = bOK || (event.target.files[0].type == 'image/tif');
      bOK = bOK || (event.target.files[0].type == 'image/png');

      bOK = bOK || (event.target.files[0].type == 'application/pdf');

      this.displayDialogErrorFileType = !bOK;

      this.bKennUpload = false;
      
      //bei false input leeren (bei mehrfachem ausführen notwendig)
      if (!bOK) {
        this.FormGroupFileUpload.controls['Doc_Name'].setValue('');
      }

      // console.log('bOK file types', bOK)
    }

    // Filename erst in Input schreiben wenn file-size und file-type check durchlaufen wurden
    if(bOK) {
      //bei mehrfachem ausführen inital leeren
      this.FormGroupFileUpload.controls['Doc_Name'].setValue('');
      this.FormGroupFileUpload.controls['Doc_Name'].setValue(this.FileData.UploadFileName);
    }

    if(bOK && event.target.files && event.target.files.length) {

      reader.readAsDataURL(this.FileData.UploadFile);
      reader.onload = () => {
        this.FileData.UploadFileDataURL     = (reader.result as string);
        // console.log('#### File Upload: doOnFileSelected(): this.FUploadFileDataURL      = ', this.FileData.UploadFileDataURL);

        // read: Binary für eigene Base64-Codierung
        reader.readAsArrayBuffer(this.FileData.UploadFile);
        reader.onload = () => {
          this.FileData.UploadFileDataBinary  = (reader.result as string);

          let   TYPED_ARRAY   = new Uint8Array(reader.result as ArrayBuffer);

          const STRING_CHAR           = TYPED_ARRAY.reduce(
                                            (data, byte)=> {
                                              return data + String.fromCharCode(byte);
                                            }
                                            , '');

          let base64String            = btoa(STRING_CHAR);
          this.FileData.UploadFileDataBase64  = base64String;
          // console.log('#### File Upload: doOnFileSelected(): this.FUploadFileDataBase64   = ', this.FileData.UploadFileDataBase64);
          // console.log('event.target.files' , event.target.files)

        } // reader.onLoad
      } // reader.onLoad
    } // if

    if (bOK && this.config.data == 'Pic')
    {
     this.cropper.selectInputEvent(event);
    }
    

    if(bOK) { 
      this.bKennUpload = true;
    }

  } // doOnFileSelected

  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
    this.bKennUpload = true;
    this.FileData.croppedImage = e.dataURL;
    console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }
   // Alyle Image Cropper Ende
}
