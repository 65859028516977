//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsDataSet.ts
//  Notes:  Container class for datasets
//
//
//  Eine Datenmenge dient als Container für Datensätze mit identischer Struktur. Datenmengen werden
//  vor allem als Bestandteile eines Datenobjektes verwendet und bestehen aus den eigentlichen Nutz-
//  daten (Datensätze) und zusätzlichen Metadaten.
//
//  Das Austauschformat ist in allen Fällen JSON - daher können sich Datenmengen in das JSON-Format
//  serialisieren und aus dem JSON-Format deserialisieren.
//
//
//  == Nutzdaten
//
//  Als Nutzdaten enthalten Datenmengen eine beliebige Anzahl (0..n) von Datensätzen mit identischer
//  Struktur. Die Elemente eines Datensatzes sind Name-Wert-Paare, welche die Datesatzfelder reprä-
//  sentieren.
//
//
//  == Metadaten
//
//  # Strukturelle Metadaten (Schema)
//
//  Strukturelle Metadaten dienen zur Beschreibung der Struktur von übertragenen Datenmengen. Diese
//  werden bei der Abfrage von Daten automatisch vom Backend hinzugefügt. Bei der umgekehrten Richtung
//  (Übertragung von Daten an das Backend (Insert, Update)) sind diese strukturellen Metadaten nicht
//  erforderlich.
//
//      -- FieldDefs    (OPTIONAL)
//      -- PKName
//
//  Die Übertragung der FieldDefs kann optional auch verhindert werden, um das Datenvolumen einzusparen.
//  Dies könnte sinnvoll sein, bei weiderholtem Anfragen von gleichartigen Daten.
//
//  # Sonstige Metadaten
//
//  Weitere Metadaten dienen zur Identifikation einer Datenmenge (um was handelt es sich hier?) und
//  gegebenenfalls beschreibende Texte, die beispielsweise in der Benutzeroberfläche angezeigt oder
//  für Protokollzwecke verwendet werden können.
//
//      -- DataSourceID
//      -- Caption
//      -- RecordCount
//
//
//  == Dataset-Auflistung
//
//  Bei Bedarf können mehrere Datenmengen in einer Auflistunge zusammengefasst werden. Auf diese Weise
//  können auch hierarchische Strukturen aufgebaut werden.
//
//  # Beispiel: parallele Datenmengen
//
//  {
//     DatasetList: [
//         Dataset: {  DataSourceID:  "A"
//                   , ...
//         }
//       , Dataset: {  DataSourceID:  "B"
//                   , ...
//         }
//       , Dataset: {  DataSourceID:  "C"
//                   , ...
//         }
//     ]
//  }
//
//
//  # Beispiel: hierarchische Struktur
//
//  {
//     DatasetList: [
//         Dataset: {  DataSourceID:  "A"
//                   , ...
//                   , DatasetList: [
//                         Dataset: {  DataSourceID:  "A.1"
//                                   , ...
//                         }
//                       , Dataset: {  DataSourceID:  "A.2"
//                                   , ...
//                         }
//                     ]
//         }
//     ]
//  }
//
//
//  == [ENTWURF] / Beispiel:  Kundendaten
//
//  {
//     Dataset: {  DataSourceID: "Customer"
//               , Caption:      "Customer"
//               , PKName:       "Cust_ID"
//               , RecordCount:  42
//               , FieldDefs: [
//                     FieldDef: {Name: "Cust_ID",      Type: "string",  Caption: "Customer: ID",      Size: 35,  IsRequired: true,   ...}
//                   , FieldDef: {Name: "Cust_ID_Art",  Type: "string",  Caption: "Customer: Art-ID",  Size: 35,  IsRequired: true,   ...}
//                   , FieldDef: {Name: "Cust_Name1",   Type: "string",  Caption: "Customer: Name 1",  Size: 50,  IsRequired: true,   ...}
//                   , FieldDef: {Name: "Cust_Name2",   Type: "string",  Caption: "Customer: Name 2",  Size: 50,  IsRequired: false,  ...}
//                   , ...
//                 ]
//               , Data: [  {Record: {Cust_ID: "xxxx", Cust_ID_Art: "K_Std", Cust_Name1: "vedisys AG", Cust_Name2: null, ...}}
//                        , {Record: {Cust_ID: "xxxx", Cust_ID_Art: "K_Std", Cust_Name1: "Test GmbH",  Cust_Name2: null, ...}}
//                        , ...
//                 ]
//     }
//  }
//
//
//  == [ENTWURF] / Beispiel:  Kundendaten - Variante OHNE Feldnamen
//
//  Um Datenvolumen einzusparen, könnte man vermutlich bei den Datensätzen auf den Feldnamen verzichten.
//  Dadurch würde aus dem Objekt "Record" ein Array, das lediglich die Feldwerte enthält. Insbesondere bei
//  sehr vielen Datensätzen sollte der Unterschied erheblich sein.
//
//  Beispiel zum Datenvolumen: TausbDoKatStaettArt, 22 Felder, 14 Datensätze
//  # Mit Feldnamen:    9.463 Bytes
//  # Ohne Feldnamen:   4.492 Bytes
//  --> Ersparnis von >50%
//
//
//  {
//     Dataset: {  DataSourceID:    "Customer"
//               , Caption: "Customer"
//               , PKName:  "Cust_ID"
//               , RecordCount:  42
//               , FieldDefs: [
//                     FieldDef: {Name: "Cust_ID",      Type: "string",  Caption: "Customer: ID",      Size: 35,  IsRequired: true,   ...}
//                   , FieldDef: {Name: "Cust_ID_Art",  Type: "string",  Caption: "Customer: Art-ID",  Size: 35,  IsRequired: true,   ...}
//                   , FieldDef: {Name: "Cust_Name1",   Type: "string",  Caption: "Customer: Name 1",  Size: 50,  IsRequired: true,   ...}
//                   , FieldDef: {Name: "Cust_Name2",   Type: "string",  Caption: "Customer: Name 2",  Size: 50,  IsRequired: false,  ...}
//                   , ...
//                 ]
//               , Data: [  Record: ["xxxx", "K_Std", "vedisys AG", null, ...]
//                        , Record: {"xxxx", "K_Std", "Test GmbH",  null, ...}
//                        , ...
//                 ]
//     }
//  }
//
//
//  H I S T O R Y
//
//  2021-03-24  TC   Genesis
//--------------------------------------------------------------------------------------------------

//
// TvsDataSet
//
// Common Dateset
//

export class TvsDataSet {

    //====== Properties (PRIVATE)
    //private             m_data:                 JSON;


    //====== Properties (PUBLIC)
    public  readonly    DataSourceID:           string;
    public  readonly    Caption:                string;
    public  readonly    PKName:                 string;
    public  readonly    RecordCount:            number;
    // public  readonly    FieldDefs:              TvsDataSetFieldDefinitions;       vorerst deaktiviert um keine Konflikte mit den Daten zu haben.
    public             FieldDefs:              any[] = [];
    // public  readonly    Data:                   any[] = [];
    public      Data:                   any[] = [];

    // public  PKValue() {
    //   get:
    //   {
    //     console.log('this.Data.length: ', this.Data.length)
    //       if (this.Data.length = 1){
    //         console.log('this.Data.length: ', this.Data.length)
    //         let i = this.Data.indexOf(this.PKName);
    //         console.log('i: ', i)
    //         if (i > -1) return this.Data[0][i];
    //       }
    //       else return null;
    //   }
  // }

//     Dataset: {  DataSourceID:    "Customer"
//               , Caption: "Customer"
//               , PKName:  "Cust_ID"
//               , RecordCount:  42

// constructor(  a_sDataSourceID:      string
//   , a_sCaption:           string
//   , a_sPKName:            string
//   , a_fdefs:              TvsDataSetFieldDefinitions
//   , a_data:               any[]
// ) {
// this.DataSourceID   = a_sDataSourceID;
// this.Caption        = a_sCaption;
// this.PKName         = a_sPKName;
// this.FieldDefs      = a_fdefs;
// this.Data           = a_data;
// } // constructor

    constructor() {
    } // constructor


    //====== Methods (PUBLIC)


    public clear(): void {
        this.Data.length = 0;
    } // clear


    //--------------------------------------------------------------------------
    // Method:  DeserializeFromJSON
    // Notes:   Converts a JSON to the FeldDefs object
    //--------------------------------------------------------------------------

    // public DeserializeFromJSON(a_data: JSON) {
        // ...
    // } DeserializeFromJSON

} // TvsDataSet



//
// TvsDataSetFieldDefinition
//
// Common Dateset: Field definition
//
export class TvsDataSetFieldDefinition {

    public      FieldCaption:           String      = '';
    public      FieldName:              String      = '';

    constructor() { }

} // TvsDataSetFieldDefinition



//
// TvsDataSetFieldDefinitions
//
// Common Dateset: Container for field definitions
//
export class TvsDataSetFieldDefinitions {

    private     m_data:                 TvsDataSetFieldDefinition[];


    constructor() { }


    //------ Methods (PUBLIC)


    //--------------------------------------------------------------------------
    // Method:  Add
    // Notes:   Add a FieldDef item
    //--------------------------------------------------------------------------

    public Add(a_fd: TvsDataSetFieldDefinition) {
        // ... a_fd hinzufügen zu dem Array m_data
    } // Add


    //--------------------------------------------------------------------------
    // Method:  Clear
    // Notes:   Delete all FieldDef items
    //--------------------------------------------------------------------------

    public Clear() {
        this.m_data  = null;
    } // Clear

} // TvsDataSetFieldDefinitions


