//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}             from '@angular/core';

import { Router }                               from '@angular/router';
import { FormGroup, FormControl, Validators }   from '@angular/forms';

// [ngx-translate]
import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import  * as schoolDataModel                    from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                             from 'primeng/api';
import { MessageService }                       from 'primeng/api';
import {  DialogService
        , DynamicDialogRef
        , DynamicDialogConfig }                 from 'primeng/dynamicdialog';

// vsLib
import * as vsCommon                            from '@vsLib/vsCommon';
import * as vsViewData                          from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources                   from '@vsLib/Services/vsGlobalResources.service';
// import * as vsTabControl                        from '@vsLib/Components/vsTab/vsTabControl.component';
import { TSearchDlgData, TvsSearchDlg }         from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';



import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { HttpClient }                       from '@angular/common/http';
import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}


type TranslationHeadingType = {
  name: string;
  type: string;
  translationCaption: string;
  size: string;
  sort: boolean;
  alignment: any;
}


@Component({
  selector:       'schoolViewAntragEdit_Schule_Ausb',
  templateUrl:  './schoolViewAntragEdit_Schule_Ausb.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_School.component.scss']
  providers:  [DialogService, DynamicDialogRef, DynamicDialogConfig]
})
export class TschoolViewAntragEdit_Schule_Ausb extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  // ./.
  public      editForm:                     FormGroup               = new FormGroup({});
  public      lookupSchulen:                any[];
  public      LookupSchule:                 any[];
  public      Schularten:                   any[];

  public      schulID:                      any;

  public      refSearchDlg:                 DynamicDialogRef;

  public      schoolData:                   any[];

  public      displayDialogError:           boolean   = false;

  public      translationDialogItems:       TranslationDialogItemsType[]                          = [];

  public      translationHeadingItems:      TranslationHeadingType[]                              = [];

  //$REV MP 2023-09-25: Ausbildungsstättenangabe ist bei einigen Kunden nicht notwendig (VBK), bei anderen schon (SAMR), standardmäßig auf true = required
  public      KennAusbStaettRequired:       boolean = true;

  public      searchDlgData: TSearchDlgData = new TSearchDlgData(
    'ausbDOStammStaettLinkArt',
    [
        { Name: "AusbStaett_Name",      Type: "ftString",   Caption: "Name der Schule", Size: "380",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft }
      , { Name: "Art_Name",             Type: "ftString",   Caption: "Schulform",       Size: "380",     Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
      , { Name: "Adr_Ort",              Type: "ftString",   Caption: "Ort",             Size: "200",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
      , { Name: "Adr_PLZ",              Type: "ftString",   Caption: "PLZ",             Size: "60",     Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
    ], false, null, this.DataFilter, false, true
  );

  //====== Constructor

  constructor(        protected router:                     Router,
                      public    globalResources:            vsGlobalResources.TvsGlobalResources
                    , public    dataModel:                  schoolDataModel.TschoolDataModel
                    , protected messageService:             MessageService
                    , protected HttpServiceComponent:       TvsHttpServiceComponent
                    , protected httpClient:                 HttpClient
                    , public    dialogService:              DialogService
                    , public    translate:                  TranslateService     // [ngx-translate]
) {
super(router, globalResources, HttpServiceComponent);

    console.log('#### TschoolViewAntragEdit_School: constructor');

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schule';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.translationHeadingItems = [
        { name: 'AusbStaett_Name',      type: 'ftString',   translationCaption: 'dialog.text_name_school',     size: '380',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft }
      , { name: 'Art_Name',             type: 'ftString',   translationCaption: 'dialog.text_type_school',     size: '380',     sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
      , { name: 'Adr_Ort',              type: 'ftString',   translationCaption: 'dialog.text_city_school',     size: '200',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
      , { name: 'Adr_PLZ',              type: 'ftString',   translationCaption: 'dialog.text_zip_code_school', size: '60',     sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
    ];

  } // constructor


  //====== Methods: NG event handler


  ngOnInit(): void {

    this.editForm.addControl('AusbStaett_Name',                new FormControl(this.dataModel.antr_Ausb_Staett_Rec?.AusbStaett_Name));
    this.editForm.addControl('Antr_ID_Ausb_Staett_Art',        new FormControl(this.dataModel.antr_Ausb_Staett_Rec?.Art_Name));

    this.editForm.controls['Antr_ID_Ausb_Staett_Art'].disable();

   this.editForm.addControl('Antr_Klasse_Beg_Nr',             new FormControl(this.dataModel.antr_Klasse_Beg_Nr));
   this.editForm.addControl('Antr_Klasse_Beg_Zusatz',         new FormControl(this.dataModel.antr_Klasse_Beg_Zusatz));

  //REV MP 2023-08-15 Felder für den Ausbildungsbetrieb hinzugefügt
   this.editForm.addControl('AusbBetr_Name',             new FormControl(this.dataModel.antrStell_AusbBetr_Name));
   this.editForm.addControl('AusbBetr_Ort',              new FormControl(this.dataModel.antrStell_AusbBetr_Ort));
   this.editForm.addControl('AusbBetr_Ortsteil',         new FormControl(this.dataModel.antrStell_AusbBetr_Ortsteil));
   this.editForm.addControl('AusbBetr_PLZ',              new FormControl(this.dataModel.antrStell_AusbBetr_PLZ));
   this.editForm.addControl('AusbBetr_Strasse',          new FormControl(this.dataModel.antrStell_AusbBetr_Strasse));
   this.editForm.addControl('AusbBetr_HausNr',           new FormControl(this.dataModel.antrStell_AusbBetr_HausNr));
   this.editForm.addControl('AusbBetr_Adr_Zusatz',       new FormControl(this.dataModel.antrStell_AusbBetr_Adr_Zusatz));
   this.editForm.addControl('Ausb_School_Text',          new FormControl(this.dataModel.antrStell_Ausb_School_Text));

  //$REV MP 2023-09-25: Laden aus der app.config.json: Ausbildungsstättenangabe benötigt oder nicht.
  if(vsCommon.vsVarAssigend(this.dataModel.KennAusbStaettRequired)) {
    this.KennAusbStaettRequired = this.dataModel.KennAusbStaettRequired;
  }


  } // ngOnInit


  doSchoolSearch(event:  any) {

    this.DataFilter.ItemsClear();
    
  //Übersetzungen in TSearchDlgData pushen
  //Items initial leeren damit die übersetzten Items nicht dazu kommen.
  this.searchDlgData.FieldDefs = [];
  this.translationHeadingItems.map((item) => {
    this.translate.get(item.translationCaption).subscribe((res)=>{
      this.searchDlgData.FieldDefs.push(
            { Name:       item.name,    
              Type:       item.type,      
              Caption:    res,
              Size:       item.size,
              Sort:       item.sort,
              Alignment:  item.alignment,
            }
        );
    });
  });

  this.translationDialogItems = [
    { data:  this.searchDlgData, 
      header: 'dialog.title_search_school', 
      styleClass: 'vsDialog vsFullscreenDialog',
      baseZIndex: 10000}
  ]

  this.translationDialogItems.map((item) => {
    this.translate.get(item.header).subscribe((res)=>{

        this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
          data:         item.data,
          header:       res,
          width:        item.width,
          styleClass:   item.styleClass,
          style:        item.style,
          baseZIndex:   item.baseZIndex

        });
      });
    });
  
    this.refSearchDlg.onClose.subscribe((Data: any) => {
      if (vsCommon.vsVarAssigend(Data)) {
        this.schoolData = Data;
        this.editForm.controls['AusbStaett_Name'].setValue(Data.AusbStaett_Name);
        this.editForm.controls['Antr_ID_Ausb_Staett_Art'].setValue(Data.Art_Name);
        }
    });
  
  }


  public actionStepPrev(): void {
    this.dataSave();
    this.router.navigate(['antragEdit/student']);
  } // actionStepPrev()

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK) {

      this.editForm.get('AusbStaett_Name').setValidators(Validators.required);
      this.editForm.get('AusbStaett_Name').updateValueAndValidity();

      this.editForm.get('Antr_Klasse_Beg_Nr').setValidators(Validators.required);
      this.editForm.get('Antr_Klasse_Beg_Nr').updateValueAndValidity();

      if(this.KennAusbStaettRequired) {
        this.editForm.get('AusbBetr_Name').setValidators(Validators.required);
        this.editForm.get('AusbBetr_Name').updateValueAndValidity();
  
        this.editForm.get('AusbBetr_Ort').setValidators(Validators.required);
        this.editForm.get('AusbBetr_Ort').updateValueAndValidity();
  
        this.editForm.get('AusbBetr_PLZ').setValidators(Validators.required);
        this.editForm.get('AusbBetr_PLZ').updateValueAndValidity();
  
        this.editForm.get('AusbBetr_Strasse').setValidators(Validators.required);
        this.editForm.get('AusbBetr_Strasse').updateValueAndValidity();
  
        this.editForm.get('AusbBetr_HausNr').setValidators(Validators.required);
        this.editForm.get('AusbBetr_HausNr').updateValueAndValidity();  
      }
     
      this.editForm.get('Ausb_School_Text').setValidators(Validators.required);
      this.editForm.get('Ausb_School_Text').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

      if (bOK) {
        this.dataSave();
        this.router.navigate(['antragEdit/besondere']);
      }
    }
  } // actionStepNext()


public dataSave() :void{

  if (vsCommon.vsVarAssigend(this.editForm.controls['AusbStaett_Name'].value)) {
    //Wenn das Feld schon gefüllt ist, wird der Datensatz hier sonst durch einen leeren Datensatz überschrieben
    //Wenn schoolData leer ist, bleibt dataModel.antr_Ausb_Staett_Rec unverändert
    if(vsCommon.vsVarAssigend(this.schoolData)) {
      this.dataModel.antr_Ausb_Staett_Rec  =  this.schoolData;
    }

  }

  this.dataModel.antr_ID_Ausb_Staett_Name      = this.editForm.controls['AusbStaett_Name'].value;

  this.dataModel.antr_Klasse_Beg_Nr            = this.editForm.controls['Antr_Klasse_Beg_Nr'].value;
  this.dataModel.antr_Klasse_Beg_Zusatz        = this.editForm.controls['Antr_Klasse_Beg_Zusatz'].value;

  this.dataModel.antrStell_AusbBetr_Name     = this.editForm.controls['AusbBetr_Name'].value;
  this.dataModel.antrStell_AusbBetr_Ort      = this.editForm.controls['AusbBetr_Ort'].value;
  this.dataModel.antrStell_AusbBetr_Ortsteil = this.editForm.controls['AusbBetr_Ortsteil'].value;
  this.dataModel.antrStell_AusbBetr_PLZ      = this.editForm.controls['AusbBetr_PLZ'].value;
  this.dataModel.antrStell_AusbBetr_Strasse  = this.editForm.controls['AusbBetr_Strasse'].value;
  this.dataModel.antrStell_AusbBetr_HausNr   = this.editForm.controls['AusbBetr_HausNr'].value;
  this.dataModel.antrStell_AusbBetr_Adr_Zusatz   = this.editForm.controls['AusbBetr_Adr_Zusatz'].value;
  this.dataModel.antrStell_Ausb_School_Text  = this.editForm.controls['Ausb_School_Text'].value;

}


} // schoolViewAntragEdit_School



