
  <form [formGroup]="this.editForm">

      <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>


      <vsLayoutGroup class="col-3" Orientation="horizontal">
      </vsLayoutGroup>  

      <vsLayoutGroup class="col-6" isPanel=true panelTitleVisible=true panelTitleText="{{'application.heading_application_data'| translate}}" Orientation="horizontal">

          <vsLayoutGroup class="col-12">
            <vsInputAutoComplete FrmCtlName="Art_Name" label="{{'application.application_kind'| translate}}" [suggestions]="lookupArtName" placeholder="{{'application.input_required'| translate}}" FieldName="Art_Name" MinLength=1 Dropdown=true ></vsInputAutoComplete>
          </vsLayoutGroup>

          <vsLayoutGroup class="col-12">
            <vsInputDateTime FrmCtlName="Antr_Datum"  label="{{'application.school_start'| translate}}" placeholder="{{'application.input_required'| translate}}"></vsInputDateTime>
          </vsLayoutGroup>

      </vsLayoutGroup>  

      <vsLayoutGroup class="col-3" Orientation="horizontal">
      </vsLayoutGroup>  

    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
      <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button>
  </div>
  
  

  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>
  
  


 