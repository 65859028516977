////--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   System
//  Title:    User Account: Registration
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-08-12  TC   Initial Version
//--------------------------------------------------------------------------------------------------


import {  Component, OnInit, AfterViewInit, ComponentFactoryResolver
        , ViewChild, ElementRef
       }                                    from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators,
         ValidatorFn, ValidationErrors, AbstractControl }    from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MessageService }                   from 'primeng/api';
import { ProgressSpinnerModule }            from 'primeng/progressspinner';
// import { TsysViewUserRegLinkAccount }       from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserRegLinkAccount.component';



// vsLib
import * as vsCommon                          from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources                 from '@vsLib/Services/vsGlobalResources.service';
import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent } from '@vsLib/Services/vsHttp.service.component';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}



@Component({
  selector:       'sysViewUserReg',
  templateUrl:  './sysViewUserReg.component.html',
  styleUrls:   ['./sysViewUserReg.component.scss'],
})
export class TsysViewUserReg extends vsViewData.TvsViewData implements OnInit, AfterViewInit {



  //====== Properties (PRIVATE)

  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public      editForm:                       FormGroup               = new FormGroup({});
  public      Anrede:                         any[];
  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  public      displayDialogError:             boolean                 = false;
  public      displayDialogSameEmail:         boolean                 = false;
  public      displayDialogEmailDifferent:    boolean                 = false;
  public      displayDialogPasswordDifferent: boolean                 = false;
  public      displayDialogAgeLowError:       boolean                 = false;
  public      displayDialogRegisterLink:      boolean                 = false;
  public      displaySpinner:                 boolean                 = false;

  public      KennStrassenVerz:               boolean                 = false;

  public      KennAdrNotFoundReg:             boolean                 = false;

  public      KennForwardRegLink:             boolean                 = true;

  public      lookupAdresse:                  any[];

  public      lookupAnrede:                   any[];

  public      ageValid:                       boolean = true;

  public      antrStellerCalculatedAge:       number;


  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected componentFactoryResolver: ComponentFactoryResolver
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService

) {
  super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'User Account: Registration';

    this.ViewProperties.ProgFuncID    = null;
    this.ViewProperties.DataSourceID  = 'webDoStammUserAccountNoPWD';
    this.ViewProperties.QueryFields   = [];


    //$REV MP 2023-08-23: KennStrassenVerz kann in der app.config.json auf true oder false gesetzt werden
    if(vsCommon.vsVarAssigend(this.dataModel.KennStrassenVerz)) {
      this.KennStrassenVerz = this.dataModel.KennStrassenVerz;
    }


    this.HttpServiceComponent.dataGet('adrDoKatPersAnrede')
    .subscribe(data => {
      console.log('Persanrede: ', data);
    // this.lookupAnrede =  data['Dataset'].Data.slice(1).filter((PKValue) => {return PKValue.Caption !== "UNBEK";});
    this.lookupAnrede =  data['Dataset'].Data;
    });


    this.HttpServiceComponent.dataGet('comDoStammStrVerzKomplett')
    .subscribe(data => {
    this.lookupAdresse =  data['Dataset'].Data;

    });


  } // constructor



  //====== NG event handler


  ngOnInit(): void {

    this.editForm.addControl('edtUserEMail',                 new FormControl(null));
    //REV MP 2023-11-09: Email-Wiederholung eingebaut
    this.editForm.addControl('edtUserEMail_Wdh',             new FormControl(null));

    this.editForm.addControl('User_User_Pwd',                new FormControl(null));
    this.editForm.addControl('User_User_Pwd_Wdh',            new FormControl(null));
    this.editForm.addControl('Kontakt_ID_Anrede',            new FormControl(null));
    this.editForm.addControl('Kontakt_Name1',                new FormControl(null));
    this.editForm.addControl('Kontakt_Name2',                new FormControl(null));
    this.editForm.addControl('Kontakt_Geb_Datum',            new FormControl(null));
    this.editForm.addControl('Adr_Strasse',                  new FormControl(null));
    this.editForm.addControl('Adr_HausNr',                   new FormControl(null));
    this.editForm.addControl('Adr_PLZ',                      new FormControl(null));
    this.editForm.addControl('Adr_Ort',                      new FormControl(null));
    this.editForm.addControl('Adr_Ortsteil',                 new FormControl(null));
    this.editForm.addControl('Adr_Zusatz',                   new FormControl(null));
    this.editForm.addControl('checkbox1',                    new FormControl(null));
    // this.editForm.addControl('checkbox2',                    new FormControl(null));
    this.HttpServiceComponent.dataGet(this.ViewProperties.DataSourceID
      , false
      , this.ViewProperties.QueryFields
      , null
      , TvsGetType.gtNewRecord)
      .subscribe(
        data => {
          this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);
        });

    if(this.KennStrassenVerz) {
      this.editForm.addControl('AntrStell_StrassenVerz',       new FormControl(null));
      this.editForm.addControl('KennAdrNotFoundReg',       new FormControl(this.KennAdrNotFoundReg));
    }

  } // ngOnInit


  ngAfterViewInit(): void {

    if(this.KennStrassenVerz) {
      this.editForm.controls['Adr_Ort'].disable();
      this.editForm.controls['Adr_PLZ'].disable();
      this.editForm.controls['Adr_Ortsteil'].disable();
      this.editForm.controls['Adr_Strasse'].disable();
    }

  } // ngAfterViewInit

  //$REV MP 2022-11-10: Navigation zurück zur Login-Seite
  public actionStepPrev(): void {
    // this.router.navigate(['login']);
    this.router.navigate(['regSelection']);
  }

  //Falls der User zustimmt auf die andere Registrierung weitergeleitet zu werden.
  public actionRouteRegLink(): void {
    if(this.KennForwardRegLink) {
      this.router.navigate(['regLinkAcc']);
    }
  }

  // Custom validator function for checking if two fields are equal
  public matchFieldsValidator(field1: string, field2: string): ValidatorFn {

    return () => {
      const control1 = this.editForm.controls[field1].value;
      const control2 = this.editForm.controls[field2].value;

      if (!control1 || !control2) {
        return null;
      }

      const areEqual = control1 === control2;
      return areEqual ? null : {fieldsNotMatch: false};
    };
  }

  public register() {
    let bOK: boolean;

    if(vsCommon.vsVarAssigend(this.editForm.get('Kontakt_Geb_Datum').value)) {
      this.validateAge();
    }

    // Add required validator to email controls
    this.editForm.get('edtUserEMail').setValidators(Validators.required);
    this.editForm.get('edtUserEMail').updateValueAndValidity();

    // Add custom validator to check if email fields match
    this.editForm.get('edtUserEMail_Wdh').setValidators([Validators.required, this.matchFieldsValidator('edtUserEMail', 'edtUserEMail_Wdh')]);
    this.editForm.get('edtUserEMail_Wdh').updateValueAndValidity();

    // Add required validator to password controls
    this.editForm.get('User_User_Pwd').setValidators(Validators.required);
    this.editForm.get('User_User_Pwd').updateValueAndValidity();

    // Add custom validator to check if password fields match
    this.editForm.get('User_User_Pwd_Wdh').setValidators([Validators.required, this.matchFieldsValidator('User_User_Pwd', 'User_User_Pwd_Wdh')]);
    this.editForm.get('User_User_Pwd_Wdh').updateValueAndValidity();

    this.editForm.get('Kontakt_ID_Anrede').setValidators(Validators.required);
    this.editForm.get('Kontakt_ID_Anrede').updateValueAndValidity();

    this.editForm.get('Kontakt_Geb_Datum').setValidators(Validators.required);
    this.editForm.get('Kontakt_Geb_Datum').updateValueAndValidity();

    this.editForm.get('Kontakt_Name1').setValidators(Validators.required);
    this.editForm.get('Kontakt_Name1').updateValueAndValidity();
    this.editForm.get('Kontakt_Name2').setValidators(Validators.required);
    this.editForm.get('Kontakt_Name2').updateValueAndValidity();
    this.editForm.get('Adr_Strasse').setValidators(Validators.required);
    this.editForm.get('Adr_Strasse').updateValueAndValidity();
    this.editForm.get('Adr_HausNr').setValidators(Validators.required);
    this.editForm.get('Adr_HausNr').updateValueAndValidity();
    this.editForm.get('Adr_PLZ').setValidators(Validators.required);
    this.editForm.get('Adr_PLZ').updateValueAndValidity();
    this.editForm.get('Adr_Ort').setValidators(Validators.required);
    this.editForm.get('Adr_Ort').updateValueAndValidity();
    this.editForm.get('checkbox1').setValidators(Validators.requiredTrue);
    this.editForm.get('checkbox1').updateValueAndValidity();
    // this.editForm.get('checkbox2').setValidators(Validators.requiredTrue);
    // this.editForm.get('checkbox2').updateValueAndValidity();




    // Trigger Fehlermeldung
    if (this.editForm.controls['edtUserEMail'].value !== this.editForm.controls['edtUserEMail_Wdh'].value) {
      this.displayDialogEmailDifferent = true;
    }

    // Trigger Fehlermeldung
    if (this.editForm.controls['User_User_Pwd'].value !== this.editForm.controls['User_User_Pwd_Wdh'].value) {
      this.displayDialogPasswordDifferent = true;
    }
    // Trigger Fehlermeldung
    if(!this.ageValid) {
      this.displayDialogAgeLowError = true;
    }

    bOK = this.editForm.valid && this.ageValid;
    this.displayDialogError = !bOK;


    if (bOK) {

      // this.DOMain.Dataset.Data[0].Acc_Login_ID                = this.editForm.controls['edtUserEMail'].value;
      // this.DOMain.Dataset.Data[0].Acc_Login_Pwd               = this.editForm.controls['User_User_Pwd'].value;
      // this.DOMain.Dataset.Data[0].eMail                       = this.editForm.controls['edtUserEMail'].value;

      // this.DOMain.Dataset.Data[0].Kontakt_ID_Anrede           = this.editForm.controls['Kontakt_ID_Anrede'].value.Anrede_ID;
      // this.DOMain.Dataset.Data[0].Kontakt_Name1               = this.editForm.controls['Kontakt_Name1'].value;
      // this.DOMain.Dataset.Data[0].Kontakt_Name2               = this.editForm.controls['Kontakt_Name2'].value;
      // if (vsCommon.vsVarAssigend(this.editForm.controls['Kontakt_Geb_Datum'].value)) {
      //   this.DOMain.Dataset.Data[0].Kontakt_Geb_Datum           = vsCommon.DateStrToISO8061(this.editForm.controls['Kontakt_Geb_Datum'].value);
      // }
      // this.DOMain.Dataset.Data[0].Adr_Strasse                 = this.editForm.controls['Adr_Strasse'].value;
      // this.DOMain.Dataset.Data[0].Adr_HausNr                  = this.editForm.controls['Adr_HausNr'].value;
      // this.DOMain.Dataset.Data[0].Adr_PLZ                     = this.editForm.controls['Adr_PLZ'].value;
      // this.DOMain.Dataset.Data[0].Adr_Ort                     = this.editForm.controls['Adr_Ort'].value;
      // this.DOMain.Dataset.Data[0].Adr_Ortsteil                = this.editForm.controls['Adr_Ortsteil'].value;
      // this.DOMain.Dataset.Data[0].Adr_Name_Zusatz_1           = this.editForm.controls['Adr_Zusatz'].value;

      //$REV MP 2023-04-03: Da die Registrierungsseiten als Ausnahmen im Routing zugelassen werden können, wird die location falsch gespeichert.
      // und muss hier korrigiert werden, damit die Accountaktivierung korrekt funktionieren kann.
      let sLocation =  window.location.href.replace('/userAccReg', '');
      localStorage.setItem('location', sLocation);

      // this.DOMain.Dataset.Data[0].xURL_Confirm                = localStorage.getItem('location');

      let acc: vsCommon.TWebRegister = new vsCommon.TWebRegister();

      acc.kontaktIDAnrede     = this.editForm.controls['Kontakt_ID_Anrede'].value.Anrede_ID;
      acc.kontaktName1        = this.editForm.controls['Kontakt_Name1'].value;
      acc.kontaktName2        = this.editForm.controls['Kontakt_Name2'].value;
      if (vsCommon.vsVarAssigend(this.editForm.controls['Kontakt_Geb_Datum'].value)) {
        acc.kontaktGebDatum   = vsCommon.DateStrToISO8061(this.editForm.controls['Kontakt_Geb_Datum'].value);
      }
      acc.eMail               = this.editForm.controls['edtUserEMail'].value;
      acc.accLoginID          = this.editForm.controls['edtUserEMail'].value;
      acc.accLoginPWD         = this.editForm.controls['User_User_Pwd'].value;
      acc.adrPLZ              = this.editForm.controls['Adr_PLZ'].value;
      acc.adrOrt              = this.editForm.controls['Adr_Ort'].value;
      acc.adrOrtsteil         = this.editForm.controls['Adr_Ortsteil'].value;
      acc.adrStrasse          = this.editForm.controls['Adr_Strasse'].value;
      acc.adrHausNr           = this.editForm.controls['Adr_HausNr'].value;
      acc.adrNameZusatz1      = this.editForm.controls['Adr_Zusatz'].value;

      acc.xURLConfirm = localStorage.getItem('location');
      acc.forceRegister       = !this.KennForwardRegLink;  // Damit wird wird die Registrierung nur zum Testen des Vorhandenseins des Users als gesetzlicher Vertreter geprüft

      let sBody = JSON.stringify(acc);
      let bRegistered: boolean = false;
      let bResultOK:  boolean = false;
      let bGesVertrFound: boolean = false;

      this.displaySpinner = true;

      this.HttpServiceComponent.HttpServicePostRequest('school/portal/regStd', '', sBody)
      .subscribe((response: any) => {

          console.log('Response: ', response);
          bRegistered     = response.kennRegistered;
          bResultOK       = response.result;
          bGesVertrFound  = response.kennGesVertrFound;

          // console.log('response.resultOK: ', response.resultOK);
          // console.log('bResultOK: ', bResultOK);
          // console.log('bRegistered: ', bRegistered);
          if (!bRegistered && bResultOK)
          {
            console.log('!KennRegisterd');
            if (response.kennLoginIDFound) {
              console.log('Emial');
              this.displayDialogSameEmail = true;
            }
            if (response.kennGesVertrFound) {
              console.log('Link');
              this.displayDialogRegisterLink = true;
            }
          }
        }
          ,error => {
                //TODO MP Alert durch Message ersetzen.
                alert(  'Bei der Registrierung ist ein Fehler aufgetreten:\n'
                + error.error.Error.ErrCode + '\n'
                + error.error.Error.ErrMsg + '\n\n'
                + error.message );
                this.DOMain.IsRefreshing = false;
                this.displaySpinner = false;
              }
          ,() => {
              if (bRegistered && bResultOK)
              {
                //$REV MP 2023-04-03: Beim abschließen localStorage leeren, kann sonst mehrfach befüllt werden
                localStorage.removeItem('accConfirm');
                localStorage.removeItem('location');

                //$REV MP 2023-11-15: Resetten des KennForwardRegLink
                this.KennForwardRegLink = true;

                // Forwarding zur Confirmation-Page
                this.router.navigate(['regConfirmation']);
              }
              else if (!bRegistered && bResultOK && !bGesVertrFound)
              {
                this.KennForwardRegLink = false;
                this.register();
              }
              this.displaySpinner = false;
            }
          );
    } // if bOK
  } // register


  doSelectAdresse(data) {

    // this.dataModel.antrStell_StrVerz = data;

    if (vsCommon.vsStringHasValue(data.OrtPLZ_PLZ)) {
      this.editForm.controls['Adr_PLZ'].setValue(data.OrtPLZ_PLZ);
    }
    if (vsCommon.vsStringHasValue(data.Ortsteil_Name)) {
      this.editForm.controls['Adr_Ortsteil'].setValue(data.Ortsteil_Name);
    }
    if (vsCommon.vsStringHasValue(data.Ort_Name)) {
      this.editForm.controls['Adr_Ort'].setValue(data.Ort_Name);
    }
    if (vsCommon.vsStringHasValue(data.Str_Name)) {
      this.editForm.controls['Adr_Strasse'].setValue(data.Str_Name);
    }

  }

  doActivateManualInput() {

    //Freischalten der Input-Felder für die manuelle Eingabe, Wechsel von html-hidden zu visible
    if(this.editForm.controls['KennAdrNotFoundReg'].value) {
      this.KennAdrNotFoundReg = true;

      this.editForm.controls['Adr_Ort'].enable();
      this.editForm.controls['Adr_PLZ'].enable();
      this.editForm.controls['Adr_Ortsteil'].enable();
      this.editForm.controls['Adr_Strasse'].enable();
    }

    if(!this.editForm.controls['KennAdrNotFoundReg'].value) {
      this.KennAdrNotFoundReg = false;

      this.editForm.controls['Adr_Ort'].disable();
      this.editForm.controls['Adr_PLZ'].disable();
      this.editForm.controls['Adr_Ortsteil'].disable();
      this.editForm.controls['Adr_Strasse'].disable();
    }

  }

    //Funktion zur Berechnung des aktuellen Alters
    getAge(dateString) {

      var today = new Date();
      var birthDate = new Date(vsCommon.DateStrToISO8061(dateString));

      var age = today.getFullYear() - birthDate.getFullYear();

      //getMonth() + 1 weil die Monate in Zahlen von 0-11 ausgegeben werden
      var m = (today.getMonth() + 1) - (birthDate.getMonth() + 1);

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
        {
          age = age - 1;
          if (age < 0) {
            age = 0;
          }
        }

      this.antrStellerCalculatedAge = age;

      return age;
    }

    validateAge() {

      this.getAge(this.editForm.get('Kontakt_Geb_Datum').value);

      if (this.antrStellerCalculatedAge < 18) {
        this.ageValid = false;

      }

      if (this.antrStellerCalculatedAge >= 18) {
        this.ageValid = true;
      }

    }


//Delay-Funktion da das Weiterleiten ansonsten evtl. zu früh kommt
// wait() {

//   function delay(ms: number) {
//     return new Promise( resolve => setTimeout(resolve, ms) );
//   }

//   (async () => {

//     // Do something before delay

//     // 5 Sek warten
//     await delay(5000);

//     // Do something after
//     this.displaySpinner = false;

//     //Routing zu Bestätigungsseite
//     this.router.navigate(['regConfirmation']);
//   })();

// }


} // TsysViewUserReg


