<vsView>
  [ViewProperties] = "ViewProperties"
</vsView>


<div class="vsWrapper">

<!-- Header: Standard ----------------------------------------------------------------------------->
  
<appHeader></appHeader>


<!-- Main Content --------------------------------------------------------------------------------->
  
<div class="container-xxl akMainContent">
  
    <vsLayoutGroup class="col" Orientation="horizontal" MediumGutterWidth=true>

        <vsLayoutGroup class="col-xl-2 col-lg-1 col-md-0">
        </vsLayoutGroup>

        <vsLayoutGroup class="col-xl-8 col-lg-10 col-md-12" Orientation="vertical">

          <vsLayoutGroup class="col">
            <div class="col" style="margin-top: 30px; margin-bottom: 30px;">
              <h4 class="vsElternLoginTitle" style="justify-content: center;">{{'page.imprint.page_header_1'| translate}}</h4>
              <p [innerHtml]="'page.imprint.page_header_2'| translate"></p>
              <p [innerHtml]="'page.imprint.text_1'| translate"></p>
            </div>
          </vsLayoutGroup>

        </vsLayoutGroup>

        <vsLayoutGroup class="col-xl-2 col-lg-1 col-md-0">
        </vsLayoutGroup>

      </vsLayoutGroup>

</div>



<!-- Footer --------------------------------------------------------------------------------------->

<appFooter></appFooter>

</div> <!-- vsWrapper Ende-->


<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->
