
<!-- <div>
  Tabs.length = {{Tabs.length}}
</div> -->


<!-- Tabs ----------------------------------------------------------------------------------------->

<div class="vsTabContainerMain">

  <!-- Tab Control: Pinned Tabs -->

  <!-- ... TODO ... -->


  <!-- Tab Control: Normal Tabs -->

  <div *ngIf = "this.Kind != 'kindWizard' && Tabs.length > 0" [class]="CSSClassCtrl">

    <a *ngFor = "let tab of Tabs" [class]="getCSSClassItem(tab)" (click)="doOnTabClick(tab)" href='#' pTooltip="{{ tab.CaptionTooltip }}" tooltipPosition="top"  tooltipStyleClass="vsTooltip">
      <!-- <span class="pi pi-home"></span> -->
      <span *ngIf = "tab.IconURL" class="pi {{tab.IconURL}}"></span>
      <span>{{ tab.Caption }}</span>
      <div *ngIf = "tab.badgeValue > 0" class="vsTabBadgeContainer"><span class="vsTabBadge">{{ tab.badgeValue }}</span></div>
      <span *ngIf = "tab.Closeable" class="pi pi-times vsTabItem_IconClose" (click)="doOnTabCloseClick(tab)"></span>
    </a>

  </div>


  <!-- Tab Control: Wizard Steps -->

  <div *ngIf = "this.Kind === 'kindWizard'" style="width: 98vw; margin: 3vh 0;">

    <ul class="col-12  wizContent">
      <div *ngFor = "let tab of Tabs" style="display: inline-flex; align-items: center;">
        <li class="col-1 wizStep">
          <div [class]="getCSSClassItem(tab)" pTooltip="{{tab.Caption}}" tooltipPosition="top" (click)='itemActivateVis(tab)'>
            <span class="wizNum">{{ (tab.PosIdx +1) }}</span>
          </div>
        </li>
        <li *ngIf = "(tab.PosIdx +1) < Tabs.length" class="col-1 wizContentLine"></li>
      </div>
    </ul>

    <ul class="col-12  wizContent">
      <div *ngFor = "let tab of Tabs" style="display: inline-flex; align-items: center;">
        <li class="col-1 wizStep wizStepCap" >{{tab.Caption}}</li>
        <li *ngIf = "(tab.PosIdx +1) < Tabs.length" class="col-1 wizContentSpace"></li>
      </div>
    </ul>
  </div>


  <!-- Scroll/Select Control -->

  <!-- ... TODO ... -->

</div>  <!-- vsTabContainerMain -->


<!-- Tab Page ------------------------------------------------------------------------------------->

<!-- $Rev MP 2021-06-22: vsCardBody div ausgeblendet, nicht notwendig da vsTabPage den innenabstand vorgibt -->

<!-- <div *ngIf = "(Tabs.length > 0) && (TemplateTabContent)" class="vsCard"> -->
  <!-- <div *ngIf = "(this.Kind != 'kindMain') && (Tabs.length > 0)" class="vsTabPage"> -->
  <div *ngIf = "(this.Kind != 'kindMain')" class="vsTabPage">   <!-- MH Bedingung (Tabs.length >0) ausgebaut. Grund: crmTicketEdit funktioniert nicht  -->
      <!--<div class="vsCardBody">-->
    <!-- Dies ist der Inhalt einer Tab Page -->
    <ng-template [ngTemplateOutlet]="TemplateTabContent"></ng-template>

    <ng-template #ViewContainer></ng-template>
    <!-- <div *ngIf="this.Kind === 'kindWizard'" style="margin-top: 3rem; margin-bottom: 1.5rem; float:right;">
      <button pButton label="Zurück" (click)="actionStepPrev()" class="vsElternButton btnWiz" style="margin-right: 10px;"></button>
      <button pButton label="Weiter" (click)="actionStepNext()" class="vsElternButton btnWizCon"></button>
  </div> -->
  <!--</div>-->
</div>  <!-- vsTabPage -->


