

<!-- Header: Standard ----------------------------------------------------------------------------->


<appHeader></appHeader>



<!-- Main Content --------------------------------------------------------------------------------->


<div class="container-xxl vsMainContentContainer">



  <h4 class="mb-4 vsElternLoginTitle">
    {{'messages.messages_header'| translate}}
  </h4>


  <div style="display: inline-flex;">
<!-- $REV MP 2023-03-10: Neu erst einmal auskommentiert, Neu funktioniert jetzt erst einmal nur über die Antragsansicht. -->
    <!-- <button pButton style="margin-right: 10px;" label="{{'messages.button_new'| translate}}" (click)="actionNewMessage()" class="vsElternButton" icon="pi pi-plus"></button> -->
    <button pButton style="margin-right: 10px;" label="{{'messages.button_read'| translate}}" (click)="actionReadMessage()" class="vsElternButton" icon="pi pi-comment"></button>
    <button pButton style="margin-right: 10px;" label="Antworten" (click)="actionAnswerMessage()" class="vsElternButton" [disabled]="answerDisabled" icon="pi pi-comments"></button>
    <!-- <button pButton style="margin-right: 10px;" label="Antworten"  class="vsElternButton" icon="pi pi-comments"></button>
    <button pButton style="margin-right: 10px;" label="Löschen"  class="vsElternButton" icon="pi pi-trash"></button> -->

  </div>


<div class="vsElternPortalAntrList">
  <vsLayoutGroup class="col">
    <vsTable #table [DOMain]="dataObject" (onRowSelect)="doTableRowSelect($event)"></vsTable>
  </vsLayoutGroup>
</div>

<!-- 
<vsViewDataList
  [ViewProperties]            = "ViewProperties"
></vsViewDataList> -->

<div style="margin-top: 1rem; margin-bottom: 1rem;">
  <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
  <!-- <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button> -->
</div>

</div> <!-- Ende container-xxl -->




<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>

