<div class="vsInputText">
  <div class="row vsMb-2">
    <label *ngIf="!NoLabel" class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col">
      <div *ngIf="ReadOnly=='true'">
        <div *ngIf="Disabled=='true'">
          <input  class="vsFormControl"
          [style.border]="!valid ? '1px solid red' : ''"
                  formControlName="{{FrmCtlName}}"
                  id="input-{{FrmCtlName}}"
                  type="text"
                  pInputText
                  placeholder="{{placeholder}}"
                  attr.aria-label={{vsAriaLabel}}
                  readonly
                  disabled
                  #charCounter
                  (blur)="doOnBlur($event)"
                  (input)="doOnInput($event)"
          />
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>
        <div *ngIf="Disabled=='false'">
          <input  class="vsFormControl"
          [style.border]="!valid ? '1px solid red' : ''"
                  formControlName="{{FrmCtlName}}"
                  id="input-{{FrmCtlName}}"
                  type="text"
                  placeholder="{{placeholder}}"
                  attr.aria-label={{vsAriaLabel}}
                  pInputText
                  readonly
                  #charCounter
                  (blur)="doOnBlur($event)"
                  (input)="doOnInput($event)"
          />
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>
      </div>
      <div *ngIf="ReadOnly=='false'">
        <div *ngIf="Disabled=='true'">
          <input  class="vsFormControl"
          [style.border]="!valid ? '1px solid red' : ''"
                  formControlName="{{FrmCtlName}}"
                  id="input-{{FrmCtlName}}"
                  type="text"
                  placeholder="{{placeholder}}"
                  attr.aria-label={{vsAriaLabel}}
                  pInputText
                  disabled
                  #charCounter
                  (blur)="doOnBlur($event)"
                  (input)="doOnInput($event)"
          />
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>
        <div *ngIf="Disabled=='false'">
          <input  class="vsFormControl"
          [style.border]="!valid ? '1px solid red' : ''"
                  formControlName="{{FrmCtlName}}"
                  id="input-{{FrmCtlName}}"
                  type="text"
                  pInputText
                  placeholder="{{placeholder}}"
                  [attr.aria-label]=vsAriaLabel
                  maxLength="{{maxLength}}"
                  #charCounter
                  (blur)="doOnBlur($event)"
                  (input)="doOnInput($event)"
          />
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
