//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputText
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-06-29  TC   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
import {  FormBuilder
        , FormControl
        , ControlContainer
        , FormGroupDirective
        , Validators
        , AbstractControl }                 from '@angular/forms';
import {BACKSPACE, DELETE} from '@angular/cdk/keycodes';
// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';
import { ChipsModule }                      from 'primeng/chips';



// export class TvsChipsList extends Array {
// }




//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//

@Component({
  selector:     'vsInputChips',
  templateUrl:  './vsInputChips.component.html',
  styleUrls:   ['./vsInputChips.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputChips extends vsInput.TvsInput implements OnInit {

  @Output()   onChipClick:          EventEmitter<any>   = new EventEmitter<any>();
  @Output()   onAdd:                EventEmitter<any>   = new EventEmitter<any>();
  @Output()   onRemove:             EventEmitter<any>   = new EventEmitter<any>();

  @Input()    Values:               any[]               = [];
  @Input()    FieldName:            string              = '';
  @Input()    KennInput:            boolean             = true;

  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)



  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.


  //====== Methods: NG event handler


  ngOnInit(): void {
  } // ngOnInit

  //====== Methods: Common

  // ./.

  //====== Methods: Event Handler
  public doOnChipClick(event: any) {
    this.onChipClick.emit(event);
  } // doOnChipClick


  public doOnAdd(event: any) {
    let info = [this.FrmCtlName, event];
    // console.log('ADD:', info);
    // console.log('Event:', event);
    this.onAdd.emit(info);
  } // doOnChipClick


  public doOnRemove(event: any) {
    let info = [this.FrmCtlName, event];
    this.onRemove.emit(info);

  } // doOnChipClick

  // public validateChips(arr){
  //   arr.forEach(chip => {
  //     chip.setValidators(Validators.email);
  //     chip.updateValueAndValidity();
  //   });
  // }
  onKeyDown(event: any) {
    // console.log('Code: ', event.code);
    // console.log('TEST1: ', (event.code == 'Backspace') || (event.code == 'Delete'))
    // console.log('TEST2: ', this.KennInput || (event.code == 'Backspace') || (event.code == 'Delete'))
    // let bOK: boolean = this.KennInput || (event.code == 'Backspace') || (event.code == 'Delete');
    // console.log('bOK: ', bOK);
    // return  bOK;  // Keine Tastatureingaben möglich!
    return true;
  }

} // TvsInputChips


