<!-- Main Content --------------------------------------------------------------------------------->

<div class="container vsMainContentContainer">

<form [formGroup]="this.editForm">

  <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

    <vsLayoutGroup class="col-12">
      <div #explanation_header class="vsFormTextDiv">
        <b>{{'explanation.school_company_heading'| translate}}</b>
      </div>
      <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
        {{'explanation.school_company_text'| translate}}
      </div>
    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-7 col-lg-12 col-md-12" Orientation="horizontal">

      <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.school_company.heading_school_block'| translate}}" Orientation="horizontal">
        <vsLayoutGroup class="col-12" Orientation="horizontal">
          <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12">
            <vsInputButtonEdit FrmCtlName="AusbStaett_Name" label="{{'application.school_company.school_name'| translate}}" labelWidth="110px" (onButtonClick)="doSchoolSearch($event)" placeholder="{{'application.input_required'| translate}}"></vsInputButtonEdit>
          </vsLayoutGroup>
          
          <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12">
            <vsInputText FrmCtlName="Antr_ID_Ausb_Staett_Art" label="{{'application.school_company.school_kind'| translate}}" labelWidth="110px" MinLength=1 Dropdown=true placeholder="{{'application.input_required'| translate}}"></vsInputText>
          </vsLayoutGroup>

        </vsLayoutGroup>

        <vsLayoutGroup class="col-12" Orientation="horizontal">
          <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
            <vsInputNumber FrmCtlName="Antr_Klasse_Beg_Nr" label="{{'application.school_company.class'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputNumber>
          </vsLayoutGroup>
          <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
            <vsInputText FrmCtlName="Antr_Klasse_Beg_Zusatz" label="{{'application.school_company.class_add'| translate}}" labelWidth="110px"></vsInputText>
          </vsLayoutGroup>
        </vsLayoutGroup>
      </vsLayoutGroup>


      <vsLayoutGroup class="col-12 vsMt-2" isPanel=true panelTitleVisible=true panelTitleText="{{'application.school_company.training_company'| translate}}" Orientation="horizontal">

        <ng-template [ngIf]="KennAusbStaettRequired" [ngIfElse]="elseBlockAusbStaettRequired">
          <vsLayoutGroup class="col-12" Orientation="horizontal">

            <vsLayoutGroup class="col-lg-12 col-md-12 col-sm-12">
              <vsInputText FrmCtlName="AusbBetr_Name" label="{{'application.school_company.training_company_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
              <vsInputText FrmCtlName="AusbBetr_Strasse" label="{{'general.address.address_street'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
              <vsInputText FrmCtlName="AusbBetr_HausNr" label="{{'general.address.address_house_number'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-12 col-md-12 col-12">
              <vsInputText FrmCtlName="AusbBetr_Adr_Zusatz" label="{{'general.address.address_addition'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
              <vsInputText FrmCtlName="AusbBetr_PLZ" label="{{'general.address.address_zip_code'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12">
              <vsInputText FrmCtlName="AusbBetr_Ort" label="{{'general.address.address_city'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-12 col-md-12 col-12">
              <vsInputText FrmCtlName="AusbBetr_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
          </vsLayoutGroup>
        </ng-template>
        <ng-template #elseBlockAusbStaettRequired>
          <vsLayoutGroup class="col-12" Orientation="horizontal">

            <vsLayoutGroup class="col-lg-12 col-md-12 col-sm-12">
              <vsInputText FrmCtlName="AusbBetr_Name" label="{{'application.school_company.training_company_name'| translate}}" labelWidth="110px" ></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
              <vsInputText FrmCtlName="AusbBetr_Strasse" label="{{'general.address.address_street'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
              <vsInputText FrmCtlName="AusbBetr_HausNr" label="{{'general.address.address_house_number'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-12 col-md-12 col-12">
              <vsInputText FrmCtlName="AusbBetr_Adr_Zusatz" label="{{'general.address.address_addition'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
              <vsInputText FrmCtlName="AusbBetr_PLZ" label="{{'general.address.address_zip_code'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12">
              <vsInputText FrmCtlName="AusbBetr_Ort" label="{{'general.address.address_city'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
            <vsLayoutGroup class="col-lg-12 col-md-12 col-12">
              <vsInputText FrmCtlName="AusbBetr_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>
    
          </vsLayoutGroup>
        </ng-template>
      
      </vsLayoutGroup>

    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-5 col-lg-12 col-md-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.school_company.heading_vocational_school'| translate}}" Orientation="horizontal">
      <vsInputMemo FrmCtlName="Ausb_School_Text" NoLabel=true labelWidth="110px" Rows="8" maxLength=4000 counter=true placeholder="{{'application.input_required'| translate}}">
      </vsInputMemo>
    </vsLayoutGroup>

  </vsLayoutGroup>



</form> <!-- Form Ende -->


<div style="margin-top: 3rem; margin-bottom: 1.5rem;">
  <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
  <button pButton label="{{'application.button_forward'| translate}}"  (click)="actionStepNext()" class="vsElternButton"                            ></button>
</div>

</div>  <!-- Ende container -->


<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->