//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputPwd
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-07-14  TC   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';
import { asLiteral } from '@angular/compiler/src/render3/view/util';



//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputPwd',
  templateUrl:  './vsInputPwd.component.html',
  styleUrls:   ['./vsInputPwd.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputPwd extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input() KennFeedback:      boolean = false;

  @Input() KennToggleMask:    boolean = false;

  @Input() NoLabel:           boolean     = false;


  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args



} // TvsInputPwd


