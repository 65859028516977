//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInput
//  Notes:  Input control zur Eingabe von Daten durch den Anwender.
//
//  Kapselt alle unterschieldichen Arten von Eingabeelementen, wie Textfeld, CheckBox, ComboBox,
//  DropDownSelect, DatePicker etc. Bei Bedarf werden für spezialisierte Eingabeelemente Ableitungen
//  von dieser Klasse erstellt.
//
//  Üblicherweise werden vsInput-Komponenten im Zusammenhang mit vsLayoutGroup-Komponenten verwendet,
//  um möglichst effizient konsistente Kachellayouts zu erzeugen.
//
//
//  H I S T O R Y
//
//  2021-07-02  TC   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild
        , TemplateRef
        , AfterContentChecked
        , ElementRef
       }                                  from '@angular/core';

import {  FormBuilder
        , FormControl
        , FormGroup
        , ControlContainer
        , FormGroupDirective
        , Validators
        , AbstractControl               } from '@angular/forms';

        
// vsLib
import * as vsCommon                      from '@vsLib/vsCommon';


//
// TvsInput
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInput',
  templateUrl:  './vsInput.component.html',
  styleUrls:   ['./vsInput.component.scss'],
  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]
})
export class TvsInput implements OnInit, AfterContentChecked {


  //====== Properties (PRIVATE)

  // ./.
  


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public get FormGroup(): FormGroup { return this.FrmGrpDirective.form }
  public getFormControl(a_sControlName: string): AbstractControl { return this.FrmGrpDirective.form.controls[a_sControlName]}


  // @Input()    FormControlName:    string              = '';
  // @Input()    LabelCaption:       string              = '';
  // @Input()    placeholder:        string              = '';
  // @Input()    Tooltip:            string              = '';
  // ... usw ...

  @Input()    label:              string              = '';
  @Input()    labelWidth:         string              = '';
  @Input()    vsAriaLabel:        string              = '';
  @Input()    labelPlain:         boolean             = false;

  @Input()    Tooltip:            string              = '';

  @Input()    FrmCtlName:         string              = '';

  @Input()    ReadOnly:           string              = "false";

  @Input()    KennInvalid:        boolean             = false;

  @Input()    Disabled:           string              = "false";

  @Input()    placeholder:        string              = '';

  @Input()    maxLength:          number              = 4000;

  @Input()    counter:            boolean             = false;

  @Input()    inputCharsLeft:     string              = 'Zeichen verfügbar'

  @Input()    valid:              boolean             = true;

  @ViewChild('charCounter') element: ElementRef = null;

  // private Variables of handleCharCount
  private writtenChars = 0;
  public availChars = this.maxLength;


  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
  } // constructor

  
  handleCharCount = (e) => {
    this.availChars = this.maxLength;

    if (typeof e === "string") {
      this.writtenChars = e.length;
    }

    if (e && e.value) {
      this.writtenChars = e.value.length
    }

    this.availChars -= this.writtenChars;
  }


  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInput.doOnXYZ(): args = ', record);
  } // args


  public get labelContent(): string {

    if ((!this.label.includes(':')) && (this.label.length > 0) && (this.labelPlain === false) ) {
       return this.label + ':'
    }

    else {
      return this.label
    }
  } // get labelContent

  public get  InvalidCSSClass(): string {
    if (this.KennInvalid || !this.valid) {
      return "ng-invalid ng-dirty"
    }
    else {
      return ""
    }
  } // get InvalidCSSClass

  ngAfterContentChecked() {
    if (this.element) {
      this.handleCharCount(this.element.nativeElement);
    }
  }

} // TvsInput


