//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   Application
//  Title:    Component: Application Footer
//  Notes:    
//
//
//  H I S T O R Y
//
//  2021-08-12  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
       }                                    from '@angular/core';

import { Router }                           from '@angular/router';


// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';



// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}




@Component({
  selector:       'appFooter',
  templateUrl:  './appFooter.component.html',
  // styleUrls:   ['./appFooter.component.scss']
})
export class TappFooter implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  // ./.

  public      KennImprint:                      boolean = false;
  public      KennDataProtection:               boolean = false;


  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService     // [ngx-translate]
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {

    //$REV MP 2024-03-08: KennImprint kann in der app.config.json auf true oder false gesetzt werden
    if(vsCommon.vsVarAssigend(this.globalResources.AppConfigData.KennImprint)) {
      this.KennImprint = this.globalResources.AppConfigData.KennImprint;
    }
    
    //$REV MP 2024-03-08: KennDataProtection kann in der app.config.json auf true oder false gesetzt werden
    if(vsCommon.vsVarAssigend(this.globalResources.AppConfigData.KennDataProtection)) {
      this.KennDataProtection = this.globalResources.AppConfigData.KennDataProtection;
    }

  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

  } // ngOnInit



  //====== Methods: Actions

  //$REV MP 2023-05-05: Actions auskommentiert, Linkaufrufe per href direkt im HTML.

  // public actionContactForm(): void {
  //   this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Kontaktformular'});
  // } // actionInfoPerso


  // public actionInfoAntrag(): void {
  //   this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Antrag'});
  // } // actionInfoAntrag


  // public actionInfoDatenschutz(): void {
  //   this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Datenschutz'});
  // } // actionInfoDatenschutz


  // public actionInfoFAQ(): void {
  //   this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: FAQ'});
  // } // actionInfoFAQ


  // public actionInfoImpressum(): void {
  //   this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Impressum'});
  // } // actionInfoImpressum


  // public actionInfoLeistungen(): void {
  //   this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Leistungen'});
  // } // actionInfoLeistungen



  //====== Methods: Navigation

  public navImprint(): void {
    this.router.navigateByUrl('/imprint')
  }

  public navDataProtection(): void {
    this.router.navigateByUrl('/dataprotection')
  }


  public navHome(): void {
    this.router.navigateByUrl('/home');
  } // navHome


  public navVedisys(): void {
    window.open('https://www.vedisys.de', '_blank');
  } // navVedisys


} // TappFooter


