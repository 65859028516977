
  <form [formGroup]="this.editForm">

      <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>


      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal">
      </vsLayoutGroup>  

      <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" Orientation="horizontal">

        <vsLayoutGroup class="col-12">
          <div #explanation_header class="vsFormTextDiv">
            <b>{{'explanation.spec_circumstances'| translate}}</b>
          </div>
          <div #explanation_text class="vsFormTextDivParagraph vsMb-2" [innerHTML]="'explanation.spec_circumstances_text' | translate">
          </div>
          <div *ngIf="this.dataModel.antrag_Art==='G' || this.dataModel.antrag_Art==='GA'" #explanation_text_2 class="vsFormTextDivParagraph vsMb-2" [innerHTML]="'explanation.spec_circumstances_text_2' | translate">
          </div>
        </vsLayoutGroup>

        <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.spec_circumstances.heading'| translate}}" Orientation="horizontal">

            <!-- <vsLayoutGroup class="col-xl-1 col-lg-2 col-md-2 col-sm-2">
                <p-inputSwitch formControlName="Inpt_Bes_Schulweg_Danger" ariaLabelledBy="checkbox_danger_label"></p-inputSwitch>
            </vsLayoutGroup> -->

            <!-- <vsLayoutGroup class="col-xl-11 col-lg-10 col-md-10 col-sm-10">
              <p id="checkbox_danger_label">{{'application.spec_circumstances.school_route_dangerous'| translate}}</p>
            </vsLayoutGroup> -->

            <!-- <vsLayoutGroup class="col-12">
              <div class="vsFormTextDivParagraph vsMb-2 vsMt-2">{{'application.spec_circumstances.school_route_dangerous_text'| translate}}</div>
            </vsLayoutGroup> -->

            <vsLayoutGroup class="col-12">
              <vsInputMemo FrmCtlName="Bes_Erlaeut" label="{{'application.spec_circumstances.explanation'| translate}}" 
                           labelWidth="110px" Rows="8" maxLength=4000 counter=true
              ></vsInputMemo>
              <!-- <vsInputMemo FrmCtlName="Bes_Erlaeut" label="{{'application.spec_circumstances.explanation'| translate}}" 
              placeholder="{{'application.input_required'| translate}}" labelWidth="110px" Rows="8" maxLength=4000 
              counter=true Disabled="{{Kenn_Bes_Schulweg_Danger}}" 
              ></vsInputMemo> -->
            </vsLayoutGroup>

        </vsLayoutGroup>  

      </vsLayoutGroup>

      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal">
      </vsLayoutGroup>  

    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
      <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button>
  </div>
  
  

  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>


 