import { ElementRef } from "@angular/core";

let calculatedTableHeight: string;
let availableSpace: number;
let settedFilterHeight: number;

export const setAvailableSpace = (newAvailableSpace) => {
  availableSpace = newAvailableSpace;
}

export const getAvailableSpace = () => {
  return availableSpace;
}

export const setFilterHeight = (newSettedFilterHeight) => {
  settedFilterHeight = newSettedFilterHeight;
}

const getFilterHeight = () => {
  return settedFilterHeight;
}

const substractElementsHeightFromViewheight = (toolbar: number | undefined, filter: number | undefined, availableSpace: number | undefined, details: ElementRef | number | undefined) => {
  let newCalculatedTableHeight: number = Number(calculatedTableHeight);
  newCalculatedTableHeight = window.innerHeight;

  if (newCalculatedTableHeight < 450) {
    newCalculatedTableHeight = 350;
    return newCalculatedTableHeight;
  }
  if (newCalculatedTableHeight < 820 && details) {
    newCalculatedTableHeight = 350;
    return newCalculatedTableHeight;
  }

  if (availableSpace) {
    newCalculatedTableHeight = getAvailableSpace();
    setAvailableSpace(undefined);
  }

  if (typeof toolbar === 'number') {
    newCalculatedTableHeight -= toolbar;
  }

  //Falls irgendwann die Toolbar auch als Element übergeben werden können, wäre diese if Bedingung nützlich
  // if (toolbar && toolbar.nativeElement) {
  //   let toolbarHeight = toolbar.nativeElement.scrollHeight;
  //   if(toolbar.nativeElement.scrollHeight === 0) {
  //     toolbarHeight = 138;
  //   }
  //   newCalculatedTableHeight -= toolbarHeight;
  // }

  if (typeof filter === 'number') {
    newCalculatedTableHeight -= filter;
  }

  //Falls irgendwann die Filter auch als Element übergeben werden können, wäre diese if Bedingung nützlich
  // if (filter && filter.nativeElement) {
  //   let filterHeight = filter.nativeElement.scrollHeight;
  //   setFilterHeight(filterHeight);
  //   if(filter.nativeElement.scrollHeight === 0) {
  //     setFilterHeight(138);
  //     filterHeight = getFilterHeight();
  //   }

  //   newCalculatedTableHeight -= getFilterHeight();
  //   // console.log("filter", filter.nativeElement.scrollHeight);
  // }

  if (typeof details === 'number') {
    newCalculatedTableHeight -= details;
  }

  if (typeof details === "object" && details && details.nativeElement) {
    newCalculatedTableHeight -= details.nativeElement.scrollHeight;
  }

  return newCalculatedTableHeight;

}

export const calcTableHeight = (toolbar: number | undefined, filter: number | undefined, availableSpace?: number, details?: ElementRef | number): string => {
  let headingHeight = 81.36;
  let paddings = 18;
  calculatedTableHeight = substractElementsHeightFromViewheight(toolbar, filter, availableSpace, details) - headingHeight - paddings + 'px';

  return calculatedTableHeight;

}
