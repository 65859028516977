//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   System
//  Title:    User Account: Registration
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-08-12  TC   Initial Version
//--------------------------------------------------------------------------------------------------


import {  Component, OnInit, AfterViewInit, ComponentFactoryResolver
        , ViewChild, ElementRef
       }                                    from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators }           from '@angular/forms';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MessageService }                   from 'primeng/api';
import { ProgressSpinnerModule }            from 'primeng/progressspinner';
// import { TsysViewUserRegLinkAccount }       from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserRegLinkAccount.component';



// vsLib
import * as vsCommon                          from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources                 from '@vsLib/Services/vsGlobalResources.service';
import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent } from '@vsLib/Services/vsHttp.service.component';


// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}



@Component({
  selector:       'sysViewUserRegSelection',
  templateUrl:  './sysViewUserRegSelection.component.html',
  // styleUrls:   ['./sysViewUserReg.component.scss'],
})
export class TsysViewUserRegSelection extends vsViewData.TvsViewData implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  FRegistrationInProgess:               boolean             = false;


  //====== Properties (PROTECTED)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                       FormGroup               = new FormGroup({});
  public      Anrede:                         any[];
  // public  get registrationInProgess():        boolean             {return this.FRegistrationInProgess};
  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  public      displayDialogError:             boolean                 = false;

  // public      displayDialogRegInProgress:     boolean                 = false;

  public      displayDialogSameEmail:         boolean                 = false;

  public      displaySpinner:                 boolean               = false;



  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected componentFactoryResolver: ComponentFactoryResolver
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService

) {
  super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Registrierungsauswahl';

    this.ViewProperties.ProgFuncID    = null;
    this.ViewProperties.DataSourceID  = 'webDoStammUserAccountNoPWD';
    this.ViewProperties.QueryFields   = [];


  } // constructor



  //====== NG event handler


  ngOnInit(): void {

    this.editForm.addControl('radioButton',                  new FormControl('A'));

  } // ngOnInit


  ngAfterViewInit(): void {
  } // ngAfterViewInit

  //$REV MP 2022-11-10: Navigation zurück zur Login-Seite
  public actionStepPrev(): void {
    this.router.navigate(['login']);
  }

  public actionStepRegister(): void {
    this.router.navigate(['userAccReg']);
  }

  public actionStepLinkAccount(): void {
    this.router.navigate(['regLinkAcc']);
  }


} // TsysViewUserReg


