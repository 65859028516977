

<!-- Header: Standard ----------------------------------------------------------------------------->


<appHeader></appHeader>



<!-- Main Content --------------------------------------------------------------------------------->


<div class="container-xxl vsMainContentContainer">
  
  <form [formGroup]="this.editForm">


    <div class="row mt-2 mb-4 vsGutterWidth-2 vsPortalList">

      <div class="col-lg-5 col-sm-12" style="line-height:1.5;"><b>{{'messages.application_kind'| translate}}:</b> {{dataModel.antrag?.Art_Name}}</div>  
  
      <div class="col-lg-4 col-sm-12" style="line-height:1.5;"><b>{{'messages.student'| translate}}:</b> {{dataModel.antrag?.xAzubi_Name}}</div>  
  
      <div class="col-lg-3 col-sm-12" style="line-height:1.5;"><b>{{'messages.application_date'| translate}}:</b> {{antrDatum}}</div> 
  
    </div>

    

      <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  

      <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" isPanel=true panelTitleVisible=true panelTitleText="{{'messages.message'| translate}}" Orientation="horizontal">

        <vsLayoutGroup class="col-12" Orientation="horizontal">
          <vsLayoutGroup class="col-lg-4 col-md-4 col-sm-12">
            <vsInputRadioButton FrmCtlName="radioButton" label="{{'messages.reason_change_request'| translate}}" Value="A" inputId="opt1" (onButtonClick)="doOnRadioButtonSelect()"></vsInputRadioButton>
          </vsLayoutGroup>
          <vsLayoutGroup class="col-lg-4 col-md-4 col-sm-12">
            <vsInputRadioButton FrmCtlName="radioButton" label="{{'messages.reason_cancellation'| translate}}" Value="K" inputId="opt1" (onButtonClick)="doOnRadioButtonSelect()"></vsInputRadioButton>
          </vsLayoutGroup>
          <vsLayoutGroup class="col-lg-4 col-md-4 col-sm-12">
            <vsInputRadioButton FrmCtlName="radioButton" label="{{'messages.reason_other'| translate}}" Value="S" inputId="opt1" (onButtonClick)="doOnRadioButtonSelect()"></vsInputRadioButton>
          </vsLayoutGroup>
        </vsLayoutGroup>

          <vsLayoutGroup class="col-12">
            <vsInputText FrmCtlName="Title" label="{{'messages.title'| translate}}"  labelWidth="125px"></vsInputText>
          </vsLayoutGroup>

          <vsLayoutGroup class="col-12">
            <vsInputMemo *ngIf="!KennEdit" FrmCtlName="MessageText" label="{{'messages.your_message'| translate}}" 
                         placeholder="{{'application.input_required'| translate}}" labelWidth="125px" Rows="8" maxLength=4000 
                         counter=true></vsInputMemo>

            <vsInputMemo *ngIf="KennEdit" FrmCtlName="MessageText" label="{{'messages.your_message'| translate}}" 
                         placeholder="{{'application.input_required'| translate}}" labelWidth="125px" Rows="8" maxLength=4000 
                         counter=false></vsInputMemo>
          </vsLayoutGroup>

      </vsLayoutGroup>  

      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  

    </vsLayoutGroup>

    <div class="vsMb-2"></div>

    
    <vsLayoutGroup *ngIf="KennDocExist || KennInsert" class="col-12" Orientation="horizontal" MediumGutterWidth=true>
    
      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  
    
        <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" isPanel=true panelTitleVisible=true panelTitleText="{{'messages.attachments'| translate}}" Orientation="horizontal">

          <vsLayoutGroup *ngIf="KennInsert" class="col-3" Orientation="vertical">

              <div *ngFor="let item of DocType">
                <button pButton label="{{item.Caption | translate}}" (click)="showDlgDoc(item.ID)" class="vsElternButton vsMb-2"></button>
              </div>

            <div class="vsMb-2"></div>
          </vsLayoutGroup>
    
          <vsLayoutGroup class="col" Orientation="vertical">

                <div *ngFor="let item of Files">
        
                  <vsLayoutGroup class="col" HasPaddingBottom=true>

                        <div style="display: flex; justify-content : space-between">
                          
                          <button pButton label="Download: 	&quot;{{item.UploadFileName}}&quot;" (click)="docDownload(item)" class="vsElternButton vsMb-2"></button>
                          
                          <div *ngIf="KennInsert">
                            <button pButton label="Löschen" (click)="docDelete(item)" class="vsElternButton vsMb-2"></button>
                          </div>

                        </div>
                  </vsLayoutGroup>
    
                </div>

          </vsLayoutGroup>
    
        </vsLayoutGroup>  
    
        <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  
    
    </vsLayoutGroup>




  </form>


  <div style="margin-top: 1rem; margin-bottom: 1rem; margin-right: 0; display: flex;">
    <button pButton label="{{'dialog.button_back'| translate}}" (click)="actionBack()" class="vsElternButton" style="margin-left: 0;"></button>
    <button *ngIf="!KennEdit" pButton label="{{'dialog.button_cancel'| translate}}" (click)="actionCancel()" class="vsElternButton" style="margin-left: auto; margin-right: 10px;"></button>
    <button *ngIf="!KennEdit" pButton label="{{'messages.button_send'| translate}}" (click)="actionSend()" class="vsElternButton"></button>
   
  </div>


</div> <!-- Ende container-xxl -->




<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>

  
  

  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>


 