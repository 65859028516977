//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputMemo
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-07-09  MH   Genesis
//--------------------------------------------------------------------------------------------------

import {
  Component
  , OnInit
  , Input
 
} from '@angular/core';

import {
  FormBuilder
  , FormControl
  , ControlContainer
  , FormGroupDirective
  , Validators
  , AbstractControl
} from '@angular/forms';


// vsLib
import * as vsCommon from '@vsLib/vsCommon';
import * as vsInput from '@vsLib/Components/vsInput/vsInput.component';



//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector: 'vsInputMemo',
  templateUrl: './vsInputMemo.component.html',
  styleUrls: ['./vsInputMemo.component.scss'],

  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]

})
export class TvsInputMemo extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input() Rows: string = '';

  @Input() NoLabel: boolean = false;

  @Input() Class: string = '';

  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
  } // constructor


  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit


  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args



} // TvsInputMemo


