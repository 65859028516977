
<div class="vsInputAutoComplete">
  <div class="row vsMb-2">
    <label class="vsInputLabel" [style.width]="labelWidth" *ngIf="!NoLabel" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col">
      <div *ngIf="Disabled=='true' && !isDisabled">
        <div *ngIf="ReadOnly=='true'">
          <p-autoComplete formControlName = "{{FrmCtlName}}"
                        [suggestions]     = "filteredField"
                        [minLength]       = MinLength
                        field             = "{{FieldName}}"
                        (completeMethod)  = "doOnSearch($event)"
                        [dropdown]        = Dropdown
                        [forceSelection]  = "true"
                        inputId           = "input-{{FrmCtlName}}"
                        inputStyleClass   = "vsFormControl"
                        class             = {{InvalidCSSClass}}
                        placeholder       = "{{placeholder}}"
                        readonly
                        disabled=true
          ></p-autoComplete>
        </div>
        <div *ngIf="ReadOnly=='false'">
          <p-autoComplete formControlName = "{{FrmCtlName}}"
                        [suggestions]     = "filteredField"
                        [minLength]       = MinLength
                        field             = "{{FieldName}}"
                        (completeMethod)  = "doOnSearch($event)"
                        (onSelect)        = "doOnSelect($event)"
                        [dropdown]        = Dropdown
                        [forceSelection]  = "true"
                        inputId           = "input-{{FrmCtlName}}"
                        inputStyleClass   = "vsFormControl"
                        class             = {{InvalidCSSClass}}
                        placeholder       = "{{placeholder}}"
                        disabled=true
          ></p-autoComplete>
        </div>
      </div>
      <div *ngIf="Disabled=='false' && !isDisabled">
        <div *ngIf="ReadOnly=='true'">
          <p-autoComplete formControlName = "{{FrmCtlName}}"
                        [suggestions]     = "filteredField"
                        [minLength]       = MinLength
                        field             = "{{FieldName}}"
                        (completeMethod)  = "doOnSearch($event)"
                        [dropdown]        = Dropdown
                        [forceSelection]  = "true"
                        inputId           = "input-{{FrmCtlName}}"
                        inputStyleClass   = "vsFormControl"
                        class             = {{InvalidCSSClass}}
                        placeholder       = "{{placeholder}}"
                        readonly
          ></p-autoComplete>
        </div>
        <div *ngIf="ReadOnly=='false'">
         <p-autoComplete formControlName  = "{{FrmCtlName}}"
                        [suggestions]     = "filteredField"
                        [minLength]       = MinLength
                        field             = "{{FieldName}}"
                        (completeMethod)  = "doOnSearch($event)"
                        (onSelect)        = "doOnSelect($event)"
                        [dropdown]        = Dropdown
                        [forceSelection]  = "true"
                        inputId           = "input-{{FrmCtlName}}"
                        inputStyleClass   = "vsFormControl"
                        class             = {{InvalidCSSClass}}
                        placeholder       = "{{placeholder}}"
          ></p-autoComplete>
        </div>
      </div>
      <div *ngIf="isDisabled">
        <p-autoComplete formControlName  = "{{FrmCtlName}}"
                       [suggestions]     = "filteredField"
                       [minLength]       = MinLength
                       field             = "{{FieldName}}"
                       (completeMethod)  = "doOnSearch($event)"
                       (onSelect)        = "doOnSelect($event)"
                       (onchange)        = "doOnChange($event)"
                       [dropdown]        = Dropdown
                       [forceSelection]  = "true"
                       inputId           = "input-{{FrmCtlName}}"
                       inputStyleClass   = "vsFormControl"
                       class             = {{InvalidCSSClass}}
                       placeholder       = "{{placeholder}}"
                       [(ngModel)]         = "selected"
                       [disabled]="isDisabled"
         ></p-autoComplete>
       </div>


    <!-- </div> -->

  </div>
</div>


