<vsViewData>
  [ViewProperties]="ViewProperties"
</vsViewData>

<!-- Header --------------------------------------------------------------------------------------->

<appHeader></appHeader>


<div *ngIf = "true && !globalResources.UserProfile.IsLoggedIn">


<!-- Main Content --------------------------------------------------------------------------------->


  <div class="container-xxl akMainContent vsMainContentContainer">

  <form [formGroup]="this.editForm">

        <vsLayoutGroup class="col" Orientation="horizontal">

          <!-- Platzhalter links-->
          <vsLayoutGroup class="col-xl-2 col-lg-1 col-md-0">
          </vsLayoutGroup>

          <vsLayoutGroup class="col-xl-8 col-lg-10 col-md-12" Orientation="vertical">

            <vsLayoutGroup class="col">
              <div class="col" style="margin-top: 30px; display:flex; justify-content: center;">
                <h4 class="vsElternLoginTitle">{{'register.heading'| translate}}</h4>
              </div>
            </vsLayoutGroup>
            <vsLayoutGroup class="col">
              <div class="col" style="margin-bottom: 30px; display:flex; justify-content: center;">
                <p class="vsElternLoginText" style="text-align: center;"  [innerHtml]="'register.text_register'| translate"></p>
              </div>
            </vsLayoutGroup>

            <vsLayoutGroup class="col">
              <vsInputText label="{{'register.email'| translate}}" FrmCtlName="edtUserEMail" labelWidth="170px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col">
              <vsInputText label="{{'register.email_repeat'| translate}}" FrmCtlName="edtUserEMail_Wdh" labelWidth="170px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">

              <vsLayoutGroup class="col-xl-6 col-sm-12 mb-3">
                <vsInputPwd label="{{'register.password'| translate}}" FrmCtlName="User_User_Pwd" [KennFeedback]="true" [KennToggleMask]="true" labelWidth="170px" placeholder="{{'application.input_required'| translate}}"></vsInputPwd>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-xl-6 col-sm-12 mb-3">
                <vsInputPwd label="{{'register.password_repeat'| translate}}"  FrmCtlName="User_User_Pwd_Wdh" [KennToggleMask]="true" labelWidth="170px" placeholder="{{'application.input_required'| translate}}"></vsInputPwd>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">

              <vsLayoutGroup class="col-xl-6 col-sm-12">
                <vsInputAutoComplete FrmCtlName="Kontakt_ID_Anrede" label="{{'register.form_of_address'| translate}}" labelWidth="170px" [suggestions]="lookupAnrede" FieldName="Anrede_Name" MinLength=1 Dropdown=true placeholder="{{'application.input_required'| translate}}"></vsInputAutoComplete>
              </vsLayoutGroup>
              <!-- Platzhalter rechts-->
              <vsLayoutGroup class="col-xl-6 col-sm-0">
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">

              <vsLayoutGroup class="col-xl-6 col-sm-12">
                <vsInputText label="{{'register.first_name'| translate}}"  FrmCtlName="Kontakt_Name2" placeholder="{{'application.input_required'| translate}}"  labelWidth="170px"></vsInputText>
              </vsLayoutGroup>
              <!-- Platzhalter rechts-->
              <vsLayoutGroup class="col-xl-6 col-sm-12">
                <vsInputText label="{{'register.last_name'| translate}}" FrmCtlName="Kontakt_Name1" placeholder="{{'application.input_required'| translate}}"  labelWidth="170px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-xl-6 col-sm-12 mb-3">
                <vsInputDateTime [valid]="ageValid" label="{{'register.date_birth'| translate}}" FrmCtlName="Kontakt_Geb_Datum" labelWidth="170px" placeholder="{{'application.input_required'| translate}}"></vsInputDateTime>
              </vsLayoutGroup>
              <!-- Platzhalter rechts-->
              <vsLayoutGroup class="col-xl-6 col-sm-0 mb-3">
              </vsLayoutGroup>
            </vsLayoutGroup>


            <ng-template [ngIf]="KennStrassenVerz">
              <vsLayoutGroup class="col-12" Orientation="horizontal">
                <vsInputAutoComplete FrmCtlName="AntrStell_StrassenVerz" label="{{'general.address.address'| translate}}" labelWidth="170px" [suggestions]="lookupAdresse" FieldName="xAnzeige" MinLength=1
                Dropdown=true (onSelect)="doSelectAdresse($event)"></vsInputAutoComplete>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-12 col-md-12" Orientation="vertical">
                <vsInputCheckBox label="{{'general.address.address_not_existant'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="380px" FrmCtlName="KennAdrNotFoundReg" (change)="doActivateManualInput()"></vsInputCheckBox>
              </vsLayoutGroup>
            </ng-template>

            <vsLayoutGroup class="col-lg-12 col-md-12">
              <vsInputText FrmCtlName="Adr_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="170px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-xl-6 col-sm-12">
                <vsInputText label="{{'general.address.address_street'| translate}}" FrmCtlName="Adr_Strasse" placeholder="{{'application.input_required'| translate}}"  labelWidth="170px"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-xl-6 col-sm-12">
                <vsInputText label="{{'general.address.address_house_number'| translate}}" FrmCtlName="Adr_HausNr" placeholder="{{'application.input_required'| translate}}"  labelWidth="170px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12">
              <vsInputText FrmCtlName="Adr_Zusatz" label="{{'general.address.address_addition'| translate}}" labelWidth="170px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup class="col-xl-6 col-sm-12 mb-3">
                <vsInputText label="{{'general.address.address_zip_code'| translate}}" FrmCtlName="Adr_PLZ" placeholder="{{'application.input_required'| translate}}"  labelWidth="170px"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-xl-6 col-sm-12 mb-3">
                <vsInputText label="{{'general.address.address_city'| translate}}" FrmCtlName="Adr_Ort" placeholder="{{'application.input_required'| translate}}"  labelWidth="170px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup style="width: 170px;">
                <vsInputCheckBox FrmCtlName="checkbox1" NoLabel="true"></vsInputCheckBox>
              </vsLayoutGroup>
              <vsLayoutGroup class="col">
                <p style="font-size: 0.9rem; color: #000;" [innerHtml]="'register.text_checkbox_data_privacy'| translate"></p>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <!-- <vsLayoutGroup class="col" Orientation="horizontal">
              <vsLayoutGroup  style="width: 170px;">
                <vsInputCheckBox FrmCtlName="checkbox2" NoLabel="true"></vsInputCheckBox>
              </vsLayoutGroup>
              <vsLayoutGroup class="col">
                <p style="font-size: 0.9rem; color: #000;" [innerHtml]="'register.text_checkbox_terms_of_use'| translate"></p>
              </vsLayoutGroup>
            </vsLayoutGroup> -->

          </vsLayoutGroup>

          <!-- Platzhalter rechts-->
          <vsLayoutGroup class="col-xl-2 col-lg-1 col-md-0">
          </vsLayoutGroup>

        </vsLayoutGroup>

        <div style="margin-bottom: 30px; margin-top:30px; display: flex; justify-content: center;">

          <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>

          <button pButton type="button"
                  label="{{'register.button_register'| translate}}"
                  (click)="register()"
                  class="vsElternLoginButton"
                  style="width:276px;"
            >
          </button>

        </div>	<!-- Ende col-sm-12 -->

      </form>

  </div><!-- Ende container-xxl -->

</div>  <!-- Ende ngif UserProfile.IsLoggedIn -->

<!-- Dialoge--------------------------------------------------------------------------------------->

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>

  <p *ngIf="displayDialogEmailDifferent" [innerHtml]="'dialog.error_email_not_same_text'| translate"></p>
  <p *ngIf="displayDialogPasswordDifferent" [innerHtml]="'dialog.error_password_not_same_text'| translate"></p>
  <p *ngIf="displayDialogAgeLowError" [innerHtml]="'dialog.error_not_of_age_text'| translate"></p>

  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false; displayDialogEmailDifferent=false; displayDialogPasswordDifferent=false; displayDialogAgeLowError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogSameEmail" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'register.text_same_email'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogSameEmail=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'dialog.error_register_link_heading'| translate}}" [(visible)]="displayDialogRegisterLink" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_register_link_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogRegisterLink=false; actionRouteRegLink()" label="Weiterleiten" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
      <button pButton pRipple type="button" (click)="displayDialogRegisterLink=false; KennForwardRegLink=false; register()" label="Trotzdem registrieren" class="vsToolbarItem vsToolbarItemCancel vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- <p-dialog header="{{'dialog.error_email_not_same_heading'| translate}}" [(visible)]="displayDialogEmailDifferent" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'register.error_email_not_same_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogEmailDifferent=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog> -->

<!-- <p-dialog header="{{'dialog.error_password_not_same_heading'| translate}}" [(visible)]="displayDialogPasswordDifferent" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'register.error_password_not_same_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogPasswordDifferent=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog> -->


  <!-- Spinner bei längerer Dauer -->
  <p-dialog [(visible)]="displaySpinner" [modal]="true" cloeseable="false" [closeOnEscape]="false" [baseZIndex]="10000" class="vsDialogSpinnerContainer" [style]="{width:'150px', height:'250px'}">
    <p-progressSpinner
    [style]             = "{width: '100px', height: '100px'}"
    styleClass          = "vsDialogSpinner"
    ></p-progressSpinner>
  </p-dialog>

<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


