//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsAppViewList.ts
//  Notes:  Verwaltung von AppView Items
//
//
//  H I S T O R Y
//
//  2021-06-20  TC   Genesis
//--------------------------------------------------------------------------------------------------

 import { ComponentRef }                    from '@angular/core';

// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';


//
// TvsAppViewItem
//
// Repräsentiert eine instanzierte View-Komponente der Applikation
//
export class TvsAppViewItem {

  public  ProgFuncID:           string                      = null;
  public  AppViewType:          vsCommon.TvsAppViewType     = null;
  public  PKValue:              string                      = null;
  public  ViewClass:            any                         = null;
  public  ParamList?:           any;
  public  Active:               boolean                     = false;
  public  TabIndex:             number                      = -1;       // [DEPRECATED] use TabItem instead
  public  TabItem:              vsTabControl.TvsTabItem     = null;
  public  ViewComponentRef:     any                         = null;

  public  get View(): vsView.TvsView {
    return  (this.ViewComponentRef) ? (this.ViewComponentRef.instance as vsView.TvsView) : null;
  }


  //====== Constructor

  constructor() {
  };

} // TvsAppViewItem



//
// TvsAppViewList
//
// Container für AppViewItems
//
export class TvsAppViewList extends Array {


  //====== Properties (PUBLIC)

  // ./.


  //====== Properties (PROTECTED)

  // ./.


  //====== Properties (PRIVATE)

  // ./.


  //====== Methods (PUBLIC)


  //------------------------------------------------------------------------------------------------
  // Method:  itemAdd
  //
  // Args:    ./.
  //
  // Result:  Neu erzeugtes AppView-Item
  //
  // Notes:   Fügt ein neues AppView-Item der Liste hinzu
  //------------------------------------------------------------------------------------------------

  public itemAdd(): TvsAppViewItem {

    let item: TvsAppViewItem      = new TvsAppViewItem();
    this.push(item);

    return item;

  } // itemAdd


  //------------------------------------------------------------------------------------------------
  // Method:  itemDelete
  //
  // Args:    a_item:               TvsAppViewItem
  //
  // Result:  ./.
  //
  // Notes:   Löscht das angegebene Item aus der Liste
  //------------------------------------------------------------------------------------------------

  public itemDelete(a_item: TvsAppViewItem): void {

    let bFound:           boolean               = false;


    for(let i = 0; ((i < this.length) && !bFound); i++) {
      bFound      = (this[i] === a_item);

      if (bFound) {
        this.splice(i, 1);
      }
    } // for

  } // itemDelete


  //------------------------------------------------------------------------------------------------
  // Method:  itemFind
  //
  // Args:    a_sProgFuncID                 Programmfunktions-ID
  //          a_avt                         TvsAppViewType (List, Edit)
  //          a_sPKValue                    Primary Key Value (ID) - nur für Edit Views
  //
  // Result:  Gefundenes AppView-Item oder null (wenn nicht gefunden)
  //
  // Notes:   Sucht ein AppView-Item in der Liste
  //------------------------------------------------------------------------------------------------

  public itemFind(a_sProgFuncID:                string
                , a_avt:                        vsCommon.TvsAppViewType
                , a_sPKValue:                   string): TvsAppViewItem
  {

    let itemResult:       TvsAppViewItem        = null;
    let itemSearch:       TvsAppViewItem        = null;
    let bFound:           boolean               = false;


    for(let i = 0; ((i < this.length) && !bFound); i++) {
      itemSearch  = this[i];

      bFound      = true;
      bFound      = bFound && itemSearch.ProgFuncID   === a_sProgFuncID;
      bFound      = bFound && itemSearch.AppViewType  === a_avt;

      // if (a_avt === vsCommon.TvsAppViewType.avtEdit) {
        bFound    = bFound && itemSearch.PKValue      === a_sPKValue;
      // }
    } // for

    if (bFound) {
      itemResult  = itemSearch;
    }

    return itemResult;

  } // itemFind


  //------------------------------------------------------------------------------------------------
  // Method:  itemFindByTabIndex
  //
  // Args:    a_iTabIndex                   TabIndex im TabControl
  //
  // Result:  Gefundenes AppView-Item oder null (wenn nicht gefunden)
  //
  // Notes:   Sucht ein AppView-Item in der Liste über dessen TabIndex
  //------------------------------------------------------------------------------------------------

  public itemFindByTabIndex(a_iTabIndex: number) {

    let itemResult:       TvsAppViewItem        = null;
    let itemSearch:       TvsAppViewItem        = null;
    let bFound:           boolean               = false;


    for(let i = 0; ((i < this.length) && !bFound); i++) {
      itemSearch  = this[i];

      bFound      = true;
      bFound      = bFound && itemSearch.TabIndex     === a_iTabIndex;
    } // for

    if (bFound) {
      itemResult  = itemSearch;
    }

    return itemResult;

  } // itemFindByTabIndex


  //------------------------------------------------------------------------------------------------
  // Method:  itemFindByTabItem
  //
  // Args:    a_tabItem                     TabItem im TabControl
  //
  // Result:  Gefundenes AppView-Item oder null (wenn nicht gefunden)
  //
  // Notes:   Sucht ein AppView-Item in der Liste über dessen Zuordnung zu einem TabItem
  //------------------------------------------------------------------------------------------------

  public itemFindByTabItem(a_tabItem: vsTabControl.TvsTabItem) {

    let itemResult:       TvsAppViewItem        = null;
    let itemSearch:       TvsAppViewItem        = null;
    let bFound:           boolean               = false;


    for(let i = 0; ((i < this.length) && !bFound); i++) {
      itemSearch  = this[i];

      bFound      = true;
      bFound      = bFound && itemSearch.TabItem  === a_tabItem;
    } // for

    if (bFound) {
      itemResult  = itemSearch;
    }

    return itemResult;

  } // itemFindByTabItem


  //------------------------------------------------------------------------------------------------
  // Method:  itemsClear
  //
  // Args:    ./.
  //
  // Result:  void
  //
  // Notes:   Löscht alle Items aus der Liste
  //------------------------------------------------------------------------------------------------

  public itemsClear(): void {
    this.length = 0;
  } // itemsClear


} // TvsAppViewList


