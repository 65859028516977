
<div class="vsSearchDlg">

<vsViewDataList
  [ViewProperties]="ViewProperties"
  [TemplateSectionFilter]=templateSectionFilter
></vsViewDataList>


<ng-template #templateSectionFilter>

  <form [formGroup]="this.listForm">

    <div class="row">
      <vsLayoutGroup class="col" Orientation="horizontal">
        <vsLayoutGroup class="col">
          <vsLayoutGroup class="col">
            <vsInputSearch FrmCtlName="FilterStdMulti" (keydown)='FilterStdMultiOnKeyDown($event)'></vsInputSearch>
          </vsLayoutGroup>
        </vsLayoutGroup>
      </vsLayoutGroup>
    </div>
  </form>
</ng-template>


<div *ngIf="bKennOKEnabled" style="float:right;">

  <!-- <p-button (click)="onOK()" class="me-2">OK</p-button>
  <p-button (click)="onAbbruch()">Abbruch</p-button> -->

  <button pButton pRipple type="button" (click)="onOK()" label="OK" class="vsToolbarItem vsToolbarItemOK">
    <span>
      <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
    </span>
  </button>

  <button pButton pRipple type="button" (click)="onAbbruch()" label="Abbruch" class="vsToolbarItem vsToolbarItemCancel">
    <span>
      <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
    </span>
  </button>

</div>
<div class="vsMb-2"></div>
<div *ngIf="!bKennOKEnabled" style="float:right;">

  <!-- <p-button disabled="true" (click)="onOK()" class="me-2">OK</p-button>
  <p-button (click)="onAbbruch()" >Abbruch</p-button> -->

  <button pButton pRipple type="button" (click)="onOK()" label="OK" class="vsToolbarItem vsToolbarItemOK" disabled="true">
    <span>
      <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
    </span>
  </button>

  <button pButton pRipple type="button" (click)="onAbbruch()" label="Abbruch" class="vsToolbarItem vsToolbarItemCancel">
    <span>
      <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
    </span>
  </button>

</div>


</div><!--Ende vsSearchDlg-->

<!-- {{dataSelected | json}} -->

