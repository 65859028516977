<!-- $Rev MP 2021-09-06: Ein div mit class"row" aus der Struktur entfernt, war hier zuviel-->
<div class="vsInputSearch">
  <div class="row vsMb-2">
      <div class="col">
        <span class="p-input-icon-left" style="width:100%">
          <i class="pi pi-search"></i>
          <input class="vsFormControl" formControlName="{{FrmCtlName}}" type="text" pInputText aria-label="Search"
            placeholder={{placeHolder}} (keypress)="keyPress($event)" spellcheck="false" autofocus/>
        </span>
      </div>
  </div>
</div>
