//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';
import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { TSearchDlgData, TvsSearchDlg } from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}


type TranslationHeadingType = {
  name: string;
  type: string;
  translationCaption: string;
  size: string;
  sort: boolean;
  alignment: any;
}



@Component({
  selector:       'schoolViewAntragEdit_Bankverb',
  templateUrl:  './schoolViewAntragEdit_Bankverb.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_SEPA.component.scss']
})
export class TschoolViewAntragEdit_Bankverb extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  // ./.
  public      editForm:                     FormGroup               = new FormGroup({});

  public      data;

  public      refSearchDlg:                 DynamicDialogRef;

  public      translationDialogItems:       TranslationDialogItemsType[]    = [];

  public      translationHeadingItems:      TranslationHeadingType[]        = [];

  public      searchDlgData: TSearchDlgData = new TSearchDlgData(
    'adrDoKatBank',
    [
        { Name: "xBank_Info",      Type: "ftString", Caption: "Name der Bank",      Size: "600",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft }
    ], true, null, this.DataFilter, false, true
  );

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    ref:                        DynamicDialogRef
            , public    dialogService:              DialogService
            , public    translate:                  TranslateService     // [ngx-translate]

  ) {
    super(router, globalResources, HttpServiceComponent);

    console.log('#### TschoolViewAntragEdit_SEPA: constructor');

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Bankverbindung';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = 'schoolDOStammAntrag';
    this.ViewProperties.QueryFields     = [];

    this.translationHeadingItems = [
      { name: 'xBank_Info',      type: 'ftString',   translationCaption: 'dialog.text_name_bank',     size: 'width:600px',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft }
    ];

  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    // Bankdaten
    this.editForm.addControl('AntrStell_ID_Bank',   new FormControl(null));
    this.editForm.addControl('AntrStell_Kto_Inh',   new FormControl(null));
    this.editForm.addControl('AntrStell_IBAN',      new FormControl(null));
    this.editForm.addControl('AntrStell_Bank_Name', new FormControl(null));

    let item = this.DataFilter.ItemAdd('Filter Antrag PKValue');
    item.CriteriaAdd('Antr_ID', this.dataModel.PKValue);
    // this.HttpServiceComponent.dataGet(
    //     'schoolDOStammAntrag'
    //   , false
    //   , []
    //   , this.DataFilter
    //   , TvsGetType.gtNormal


    //$REV MP 2024-05-28: Abfrage der Kontodaten entfernt. Muss aus Datenschutzgründen jeweils Neu eingegeben werden.
    // this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrag', '&Antr_ID='+this.dataModel.PKValue)
    //   .subscribe(
    //     data => {
    //       this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);

    //       // this.DataFilter.ItemsClear();

    //       console.log('this.DOMain: ', this.DOMain.Dataset.Data[0]);

    //       // this.editForm.controls['Azubi_ID_Anrede'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.LookupAnrede, this.DOMain.Dataset.Data[0]?.Azubi_ID_Anrede));
    //       this.editForm.controls['AntrStell_Kto_Inh'].setValue(this.DOMain.Dataset.Data[0]?.BankVerb_Inhaber_Name);
    //       this.editForm.controls['AntrStell_IBAN'].setValue(this.DOMain.Dataset.Data[0]?.BankVerb_IBAN);
    //       this.editForm.controls['AntrStell_Bank_Name'].setValue(this.DOMain.Dataset.Data[0]?.BankVerb_Institut_Name);

    //     });

  } // ngOnInit



  public actionStepPrev(): void {
    this.dataSave();
    this.router.navigate(['main']);
  } // actionStepPrev()


  public actionStepNext(): void {

    this.dataSave();
    this.dataModel.workInProgressMessage = true;
    this.router.navigate(['main']);
  } // actionStepNext()

  public dataSave() :void{

    this.dataModel.antrStell_Kto_Inh       = this.editForm.controls['AntrStell_Kto_Inh'].value;
    this.dataModel.antrStell_IBAN          = this.editForm.controls['AntrStell_IBAN'].value;

    this.dataModel.antrStell_Bank_Name     = this.editForm.controls['AntrStell_Bank_Name'].value;
    this.dataModel.antrStell_Bank_ID       = this.editForm.controls['AntrStell_ID_Bank'].value;

  }

  doBankSearch(event:  any) {

    this.DataFilter.ItemsClear();

    //Übersetzungen in TSearchDlgData pushen
    //Items initial leeren damit die übersetzten Items nicht dazu kommen.
    this.searchDlgData.FieldDefs = [];

    this.translationHeadingItems.map((item) => {
      this.translate.get(item.translationCaption).subscribe((res)=>{
        this.searchDlgData.FieldDefs.push(
              { Name:       item.name,
                Type:       item.type,
                Caption:    res,
                Size:       item.size,
                Sort:       item.sort,
                Alignment:  item.alignment,
              }
          );
      });
    });

    this.translationDialogItems = [
      { data:  this.searchDlgData,
        header: 'dialog.title_search_bank',
        styleClass: 'vsDialog vsFullscreenDialog',
        baseZIndex: 10000}
    ]

    this.translationDialogItems.map((item) => {
      this.translate.get(item.header).subscribe((res)=>{

          this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
            data:         item.data,
            header:       res,
            width:        item.width,
            styleClass:   item.styleClass,
            style:        item.style,
            baseZIndex:   item.baseZIndex

          });
        });
      });

      // this.refSearchDlg.onClose.subscribe((Data: any) => {
      //   if (vsCommon.vsVarAssigend(Data)) {
      //     this.editForm.controls['AntrStell_ID_Bank'].setValue(Data.Bank_ID);
      //     // this.editForm.controls['Bank_BIC'].setValue(Data.Bank_BIC);
      //     this.editForm.controls['AntrStell_Bank_Name'].setValue(Data.Bank_Name);
      //     }
      // });

    }

} // schoolViewAntragEdit_SEPA


