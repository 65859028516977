//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsChart
//  Notes:  vsChart: Component
//
//
//  H I S T O R Y
//
//  2022-02-14  TE   Initial Version
//--------------------------------------------------------------------------------------------------


import { Component, OnInit, Inject } from "@angular/core";
import { TvsDataObject }             from "@vsLib/vsDataObject";
import * as vsCommon                 from '@vsLib/vsCommon';
import * as vsViewData               from '@vsLib/View/components/vsViewData/vsViewData.component';
import { Router }                    from "@angular/router";
import { TvsHttpServiceComponent }   from "@vsLib/Services/vsHttp.service.component";
import * as vsGlobalResources        from '@vsLib/Services/vsGlobalResources.service';

@Component({
  selector: 'vsChartBase',
  templateUrl: './vsExport.component.html'
})

export class TvsExport {

  //====== Properties (PUBLIC)

  public a_caller: TvsDataObject;
  public csv: string;
  public name: string;

  //====== Constructor

  constructor(@Inject(TvsDataObject) public DOMain) {
    this.a_caller = DOMain;
  } // constructor

  public generateCSV(){
    let items;
    let columns;
    let items_clone = [];

    if(this.a_caller && this.a_caller.Dataset) {
      items = this.a_caller.Dataset.Data;
      items_clone = items.map(object => ({ ...object }));
    }
    //$REV MP 2023-06-23: Ausfiltern der Spalten mit Typ "ftCustomButton" im Exportergebnis eingefügt
    if(this.a_caller.Dataset.FieldDefs) {
      columns = this.a_caller.Dataset.FieldDefs;
      columns = columns.filter((column) =>
        {
          let bExport: boolean = column.Name !== "Filler" && column.Type !== "ftCustomButton" && column.Type !== "ftEditButton" && column.Type !== "ftDeleteButton";

          // 1.) columns.visible existiert nicht (Standard-Liste - weil die Fielddefs überschrieben werden)
          if (column.hasOwnProperty('Visible'))
          {
             bExport = bExport && column.Visible;
          };
          
          return bExport;
        }
      );
    }

    console.log('Item.Columns: ',columns);

    for(let key in columns){
      this.csv += '"' + columns[key].Caption + '"' + ';';
    }
    this.csv += '\r';

    columns.map((column) => {
      items_clone.map((item) => {
        if (item.hasOwnProperty(column.Name) && column.Type === "ftDate") {
          if(item[column.Name]) {
            item[column.Name] = vsCommon.ISO8061ToDateStr(item[column.Name]);
          }
        }
        if (item.hasOwnProperty(column.Name) && column.Type === "ftTime") {
          if(item[column.Name]) {
            item[column.Name] = vsCommon.ISO8061ToTimeStr(item[column.Name]);
          }
        }
      })
    });

    items_clone.map((item) => {
      columns.map((column) => {
          if(column.Type === 'ftInteger') {
            this.csv += item[column.Name]+ ';';
          } else {
            this.csv += '"' + item[column.Name] + '"' + ';';
          }
      });
      this.csv += '\r';
    });
  }

  // Es wurde noch kein besseres Konzept gefunden um einen Download clientseitig zu triggern. Vom Browser selbst können nur spezifische Elemente
  // verwendet werden, um einen Download auszulösen

  public downloadCSV(viewMainContext) {
    if(this.a_caller.Dataset.Data.length > 0) {
      let link = document.createElement('a');
      link.id = 'download-csv';
      link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.csv));
      // Hier wird ein Name für die CSV File übergeben, welcher bei Bedarf angepasst werden kann
      link.setAttribute('download', `Export_${new Date().toISOString().split('T')[0] + '_' + this.name + '.csv'}`);
      document.body.appendChild(link)
      document.getElementById('download-csv').click();
      document.getElementById('download-csv').remove();
    } else {
      viewMainContext.msgShowToastWarning('Keine Daten zum Export verfügbar!')
    }
  }
}
