//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   app
//  Title:    app component
//  Notes:    Controls the display of landing page, login, main view etc.
//
//
//  H I S T O R Y
//
//  2021-07-30  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component
        , ViewChild, ElementRef
       }                                    from '@angular/core';

import { Router }                           from '@angular/router';

// [ngx-translate]
import { TranslateService }                   from '@ngx-translate/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Subscription }                       from 'rxjs';


// vsLib
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { TvsHttpServiceComponent } from '@vsLib/Services/vsHttp.service.component';
import { SlowBuffer } from 'buffer';



@Component({
  selector:     'app-root',
  templateUrl:  './app.component.html',
  styleUrls:   ['./app.component.scss'],
  providers: [ConfirmationService]
})
export class AppComponent {


  //------ Properties (PRIVATE)

  // ./.


  //------ Properties (PUBLIC)

  //-- OBSOLETE -> verschoben nach MainView
  // public    appTitle:               String      = 'AMIS®7 goes WEB';
  // public    appDescriptionShort:    String      = 'MyPlayground (TC) | Spielwiese für Experimente';

  public      AppInitializing:        boolean     = true;

  // [ngx-translate]
  lang: string = "de_DE";
  subscription: Subscription;

  //-- Login Credentials (wird später noch in einer eigene Logion View verlagert)
  @ViewChild('edtUserID',   {read: ElementRef})     edtUserID:          ElementRef;
  @ViewChild('edtUserPwd',  {read: ElementRef})     edtUserPwd:         ElementRef;



  //====== Constructor

  constructor(  public        router:       Router
              , public        globalResources:      vsGlobalResources.TvsGlobalResources
              , public        translate:            TranslateService      // [ngx-translate]
              , public        primeNGConfig:        PrimeNGConfig
              , protected     HttpServiceComponent: TvsHttpServiceComponent
              , private       confirmationService:  ConfirmationService
  ) {

    // Programm-URL merken

    let sLocation =  window.location.href.replace('/home', '');
    sLocation =  sLocation.replace('/login', '');
    localStorage.setItem('location', sLocation);
    //alert(localStorage.getItem('location'));
    // Confirm-Link-Parameter (User-ID) auslesen
    // let iParamBeg   = window.location.href.indexOf('?');
    // if (iParamBeg > -1 ) {
    //   let sToken = window.location.href.substring(iParamBeg + 1);
    //   localStorage.setItem('token',sToken);
    // }
    let sToken: string = '';
    let sPwdReset: string = '';
    let iParamTokenBeg = window.location.href.indexOf('?User=');
    let iParamPwdReset = window.location.href.indexOf('&PwdReset=');

    if (iParamTokenBeg > -1 ) {
      sPwdReset = window.location.href.substring(iParamPwdReset + 10);
      sToken    = window.location.href.substring(iParamTokenBeg + 1 + 5, iParamTokenBeg + 1 + 5 + 32);

       console.log('sToken1', sToken);
       console.log('sPwdreset', sPwdReset);
       console.log('sToken2', iParamTokenBeg);
       console.log('sToken2', window.location.href.toString());
      localStorage.setItem('accConfirm', sToken);
      localStorage.setItem('accPwdReset', sPwdReset);

    }

    // [ngx-translate] ---------- BEG ----------
    translate.addLangs(['de_DE']);
    translate.addLangs(['en-GB']);
    //$REV MP 2023-05-16: Russisch auskommentiert
    // translate.addLangs(['ru-RU']);
    translate.setDefaultLang('de-DE'); // $Rev TC 2021-02-19: languages should be added dynamically at run time (available languages get by a REST service)

    // translate.use('de-DE');                      // $Rev TC 2021-02-19: should be read from user profile
    // translate.use('en-GB');                      // $Rev TC 2021-02-19: should be read from user profile
    // translate.use(globalResources.UserProfile.UserAccDefaultLangID);

    this.subscription = this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });

    // [ngx-translate] ---------- END ----------


    this.AppInitializing    = false;

  } // constructor

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnDestroy() {
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
  }

  //====== Methods: Event Handler


  // private btnLogin_OnClick() {
  //   let sUserID:      string    = this.edtUserID.nativeElement.value;
  //   let sUserPwd:     string    = this.edtUserPwd.nativeElement.value;

  //   this.globalResources.UserProfile.Login(sUserID, sUserPwd);

  //   if (this.globalResources.UserProfile.IsLoggedIn) {
  //     // alert('Login erfolgreich');
  //   } else {
  //     alert('Login fehlgeschlagen - bitte überprüfen Sie Ihre Eingabe von Benutzer-ID und Kennwort.');
  //   }

  // } // btnLogin_OnClick


} // AppComponent


