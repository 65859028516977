//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { HttpClient }                       from '@angular/common/http';
import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


@Component({
  selector:       'schoolViewAntragEdit_StudentAddress',
  templateUrl:  './schoolViewAntragEdit_StudentAddress.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_StudentAddress.component.scss']
  providers: [DialogService]
})
export class TschoolViewAntragEdit_StudentAddress extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public displayDialogError: boolean   = false;
  public data;



  //====== Constructor


  ref: DynamicDialogRef;

  constructor(protected router:                     Router,
    public    globalResources:            vsGlobalResources.TvsGlobalResources
  , public    dataModel:                  schoolDataModel.TschoolDataModel
  , protected messageService:             MessageService
  , protected HttpServiceComponent:       TvsHttpServiceComponent
  , protected httpClient:                 HttpClient
  , public    dialogService:              DialogService
) {
super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schüler';
    this.ViewProperties.DataSourceID    = 'schoolDOStammAntrag';
    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    //Schüler

    this.editForm.addControl('Azubi_PLZ',           new FormControl(null));
    this.editForm.addControl('Azubi_Ort',           new FormControl(null));
    this.editForm.addControl('Azubi_Strasse',       new FormControl(null));
    this.editForm.addControl('Azubi_HausNr',        new FormControl(null));
    this.editForm.addControl('Azubi_Phone',         new FormControl(null));
    this.editForm.addControl('Azubi_eMail',         new FormControl(null));
    this.editForm.addControl('Azubi_Mobile',        new FormControl(null));


    let item = this.DataFilter.ItemAdd('Filter Antrag PKValue');
    item.CriteriaAdd('Antr_ID', this.dataModel.PKValue);
    this.HttpServiceComponent.dataGet(
      // Aus irgendeinem Grund ist die this.ViewProperties.DataSourceID an dieser Stelle null 
      // this.ViewProperties.DataSourceID
        'schoolDOStammAntrag'
      , false
      // , this.ViewProperties.QueryFields
      , []
      , this.DataFilter
      , TvsGetType.gtNormal
    )
      .subscribe(
        data => {
          this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);

          // this.DataFilter.ItemsClear();

          this.editForm.controls['Azubi_PLZ'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_PLZ);
          this.editForm.controls['Azubi_Ort'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_Ort);
          this.editForm.controls['Azubi_Strasse'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_Strasse);
          this.editForm.controls['Azubi_HausNr'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_HausNr);
          this.editForm.controls['Azubi_Phone'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_Phone);
          this.editForm.controls['Azubi_eMail'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_eMail);
          this.editForm.controls['Azubi_Mobile'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_Mobile);

        });

  
  } // ngOnInit


  public actionStepPrev(): void {
    this.router.navigate(['main']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;


    if (bOK) {

      // Validierung Schülerdaten

      this.editForm.get('Azubi_PLZ').setValidators(Validators.required);
      this.editForm.get('Azubi_PLZ').updateValueAndValidity();

      this.editForm.get('Azubi_Ort').setValidators(Validators.required);
      this.editForm.get('Azubi_Ort').updateValueAndValidity();

      this.editForm.get('Azubi_Strasse').setValidators(Validators.required);
      this.editForm.get('Azubi_Strasse').updateValueAndValidity();

      this.editForm.get('Azubi_HausNr').setValidators(Validators.required);
      this.editForm.get('Azubi_HausNr').updateValueAndValidity();


      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      // this.dataSave();
      this.dataModel.workInProgressMessage = true;
      this.router.navigate(['main']);
    }

  } // actionStepNext()



  public dataSave() :void{


    //Schüler
    this.DOMain.Dataset.Data[0].Azubi_Strasse             = this.editForm.controls['Azubi_Strasse'].value;
    this.DOMain.Dataset.Data[0].Azubi_HausNr              = this.editForm.controls['Azubi_HausNr'].value;
    this.DOMain.Dataset.Data[0].Azubi_PLZ                 = this.editForm.controls['Azubi_PLZ'].value;
    this.DOMain.Dataset.Data[0].Azubi_Ort                 = this.editForm.controls['Azubi_Ort'].value;
    this.DOMain.Dataset.Data[0].Azubi_Phone               = this.editForm.controls['Azubi_Phone'].value;
    this.DOMain.Dataset.Data[0].Azubi_Mobile              = this.editForm.controls['Azubi_Mobile'].value;
    

    this.HttpServiceComponent.dataUpdate(
      // this.ViewProperties.DataSourceID
      'schoolDOStammAntrag'
      , this.ViewProperties.DOMain
      , this.dataModel.PKValue)
      .subscribe(
      dataPost => {

        }
      );


  }


} // schoolViewAntragEdit_Student


