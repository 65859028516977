<vsView>
  [ViewProperties] = "ViewProperties"
</vsView>


<div class="vsWrapper">



  <!-- Header: Standard ----------------------------------------------------------------------------->

  <appHeader></appHeader>


  <!-- Main Content --------------------------------------------------------------------------------->

  <div class="vsMainContent">
    <div class="container-xxl akMainContent vsMainContentContainer">

      <form [formGroup]="this.editForm">
        <div class="row">

           <!--TODO Platzhalter auf 2 Ändern bei aktivierter Login-Auswahl-->
          <div class="col-lg-4 col-md-4 col-sm-0 col-xs-0">
          </div>

          <!--TODO Platzhalter auf 8 Ändern bei aktivierter Login-Auswahl-->
          <div class="col-lg-4 col-md-4">
            <div class="row">

              <!-- Auswahl der Login-Methode -->
              <!-- <div class="col-lg-6 vsLoginSelect">
                <fieldset>
                  <legend>
                    <div class="col-12" style="margin-bottom: 30px; justify-content: center;">
                      <h4 class="vsElternLoginTitle">{{'login.heading_choose_login_method'| translate}}</h4>
                    </div>
                  </legend>

                  <div class="vsLoginRadioButtonGroup">
                    <vsInputRadioButton FrmCtlName="radioButton" label="{{'login.username_password'| translate}}" Value="N" inputId="opt1">
                    </vsInputRadioButton>
                    <div [innerHtml]="'login.text_checkbox_username_password'| translate"></div>
                  </div>

                  <div class="vsLoginRadioButtonGroup">
                    <vsInputRadioButton FrmCtlName="radioButton" label="{{'login.digital_passport'| translate}}" Value="O" inputId="opt2"></vsInputRadioButton>
                    <div [innerHtml]="'login.text_checkbox_digital_passport'| translate"></div>
                  </div>
                </fieldset>
              </div> -->

              <!-- <div class="col-lg-6"> TODO Wenn Login-Auswahl aktiviert wird, col-lg-12 ersetzen -->
              <div class="col-lg-12">

                <!--TODO Login mit Nutzername und Passwort -->
              <!-- <div *ngIf="this.editForm.controls['radioButton'].value === 'N'"> -->
              <div>
                <fieldset>
                  <legend>
                    <div class="col-12" style="margin-bottom: 30px; justify-content: center;">
                      <h4 class="vsElternLoginTitle">{{'login.heading_existing_account'| translate}}</h4>
                    </div>
                  </legend>

                  <div class="col-sm-12" style="margin-bottom: 30px;">
                    <div class="row">
                      <label for="edtUserID" class="sr-only">{{'login.label_user_ID'| translate}}</label>
                      <div class="col">
                        <vsInputText #edtUserID id="edtUserID" FrmCtlName="user_ID" [NoLabel]="true" placeholder="{{'login.placeholder_user_ID'| translate}}"
                          pTooltip="{{'login.tooltip_user_ID'| translate}}" tooltipPosition="right" tooltipEvent="focus"
                          tooltipStyleClass="vsTooltip"></vsInputText>
                      </div>
                    </div><!-- row -->
                  </div> <!-- Ende col-sm-12 -->

                  <div class="col-sm-12" style="margin-bottom: 30px;">
                    <div class="row">
                      <label for="edtUserPwd" class="sr-only">{{'login.label_password'| translate}}</label>
                      <div class="col">
                        <!-- <input #edtUserPwd id="edtUserPwd" FrmCtlName="user_PWD" type="password" class="vsFormControl"
                          placeholder="{{'login.placeholder_password'| translate}}" pTooltip="{{'login.tooltip_password'| translate}}" tooltipPosition="right"
                          tooltipStyleClass="vsTooltip"> -->

                          <vsInputPwd #edtUserPwd id="edtUserPwd" [NoLabel]="true" FrmCtlName="user_PWD" [KennToggleMask]="true" 
                          placeholder="{{'login.placeholder_password'| translate}}" pTooltip="{{'login.tooltip_password'| translate}}" tooltipPosition="right"
                          tooltipStyleClass="vsTooltip" (keydown.enter)="btnLogin_OnClick()"></vsInputPwd>
                      </div>
                    </div><!-- row -->
                  </div> <!-- Ende col-sm-12 -->
                </fieldset>


                <div class="col-sm-12" style="margin-bottom: 30px;">
                  <button pButton type="button" FrmCtlName="loginButton" label="{{'login.button_login'| translate}}"  (click)="btnLogin_OnClick()"
                    class="vsElternLoginButton"></button>
                </div> <!-- Ende col-sm-12 -->

                <!-- <span *ngIf="!globalResources.UserProfile.IsLoggedIn ? '' : redirect()"></span> -->
                <span *ngIf="!globalResources.WebAccProfile.IsLoggedIn ? '' : redirect()"></span>

                <div class="col-sm-12 vsMb-2">
                  <div style="line-height: 1.4rem;">
                    {{'login.text_no_account'| translate}}<a style="cursor: pointer" (click)="actionRegister()">{{'login.link_text_no_account'| translate}}</a>.
                  </div>
                </div> <!-- Ende col-sm-12 -->

                <div class="col-sm-12" style="margin-bottom: 30px;">
                  <div style="line-height: 1.4rem;">
                    {{'login.text_forgot_password_1'| translate}}<a style="cursor: pointer" (click)="btnPwdLost_OnClick()">{{'login.link_forgot_password'| translate}}</a> {{'login.text_forgot_password_2'| translate}}.
                  </div>
                </div> <!-- Ende col-sm-12 -->

              </div>

            <!-- Login mit Online Ausweis -->
            <!-- <div *ngIf="this.editForm.controls['radioButton'].value === 'O'">
  
                <div class="col-12" style="margin-bottom: 30px; justify-content: center;">
                  <h4 class="vsElternLoginTitle" [innerHtml]="'login.heading_digital_passport'| translate"></h4>
                </div>

                <div class="col-sm-12">
                  <p [innerHtml]="'login.text_digital_passport'| translate"></p>
                </div>

                <div class="col-sm-12" style="margin-bottom: 30px;">
                  <button pButton type="button" FrmCtlName="passportLoginButton" label="{{'login.button_proceed_login'| translate}}"
                    (click)="btnLogin_OnClick()" class="vsBundLoginButton">
                    <span>
                      <img alt="" src="../../assets/img/Icons/npa.svg" />
                    </span>
                  </button>
                </div> 

                <div class="col-sm-12">
                  <p style="line-height: 1.4rem;" [innerHtml]="'login.text_no_account_digital_passport'| translate"></p>
                </div>
            </div> -->

              </div> <!-- Ende col-lg-12 -->
            </div> <!-- Ende row -->
          </div> <!-- col-8  -->

          <!--TODO Platzhalter auf 2 Ändern bei aktivierter Login-Auswahl-->
          <div class="col-lg-4 col-md-4 col-sm-0 col-xs-0">
          </div>
          <!--Ende col-lg-3 col-md-3 col-sm-0 col-xs-0-->


        </div> <!-- Ende row -->

      </form> <!-- Ende form -->

    </div>
    <!--Ende container-xxl-->
  </div> <!-- vsMainContainer Ende-->

  <!-- <pre>{{ this.editForm.value | json}}</pre> -->




  <div *ngIf="globalResources.UserProfile.IsLoggedIn" class="vsMainContent">
    <div class="container-xxl">
      <h4 style="margin-top: 2rem; display: flex; justify-content: center;" class="vsElternLoginTitle" [innerHtml]="'login.text_already_signed_in'| translate"></h4>
    </div>
    <!--container-xxl Ende-->
  </div> <!-- vsMainContainer und ngif Ende-->

  <!-- Erfolgsmeldung bei Passwort-Zurücksetzung -->
  <p-dialog header="{{'login.dialog_password_reset_header'| translate}}" [(visible)]="displayPwdResetSuccessMessage" [baseZIndex]="10000" class="vsDialog vsSuccess vsRequired">
    <p [innerHtml]="'login.text_password_reset'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayPwdResetSuccessMessage=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>

    <!-- Fehlermeldung bei Fehlender Benutzer-ID - Password-Zurücksetzung -->
    <p-dialog header="{{'login.dialog_password_reset_error_header'| translate}}" [(visible)]="displayPwdResetErrorMessage" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
      <p [innerHtml]="'login.text_password_reset_error'| translate"></p>
      <ng-template pTemplate="footer">
          <button pButton pRipple type="button" (click)="displayPwdResetErrorMessage=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
            <span>
              <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
            </span>
          </button>
      </ng-template>
    </p-dialog>

  <!-- Meldung bei Passwort-Zurücksetzung -->
  <p-dialog header="{{'login.dialog_password_reset_confirm_header'| translate}}" [(visible)]="displayPwdResetMessage" [style]="{width: '17vw'}" [baseZIndex]="10000" class="vsDialog vsWarning">

    <p [innerHtml]="'login.text_password_reset_confirm'| translate"></p>
  
    <ng-template pTemplate="footer">
      <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
        <button pButton pRipple type="button" (click)="displayPwdResetMessage=false; actionPwdLost()" label="OK" class="vsToolbarItem vsToolbarItemOK">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
  
        <button pButton pRipple type="button" (click)="displayPwdResetMessage=false" label="Abbruch" class="vsToolbarItem vsToolbarItemCancel">
          <span>
            <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
          </span>
        </button>
      </div>
    </ng-template>
  </p-dialog>


  <!-- Footer --------------------------------------------------------------------------------------->

  <appFooter></appFooter>




</div> <!-- vsWrapper Ende-->



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>
