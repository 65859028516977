//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate }                       from '@angular/common';
import { asLiteral }                        from '@angular/compiler/src/render3/view/util';
import { HttpClient }                       from '@angular/common/http';
import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


@Component({
  selector:       'schoolViewAntragEdit_VertretungAddress',
  templateUrl:  './schoolViewAntragEdit_VertretungAddress.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_VertretungAddress.component.scss']
  providers: [DialogService]
})
export class TschoolViewAntragEdit_VertretungAddress extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public Anrede: any[];

  public LookupAnrede:                any[];
  public LookupTitel:                 any[];

  public displayDialogError: boolean   = false;
  public data;

  public DocType:       any[] =
  [
    {Caption: 'Nutzerfoto laden',    ID: 'Pic'}
  ]

  //====== Constructor


  ref: DynamicDialogRef;

  constructor(protected router:             Router
    , protected globalResources:            vsGlobalResources.TvsGlobalResources
    , protected HttpServiceComponent:       TvsHttpServiceComponent
    , public    dataModel:                  schoolDataModel.TschoolDataModel
    , public    dialogService:              DialogService
) {
super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schüler';

    this.ViewProperties.ProgFuncID      =  null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.HttpServiceComponent.dataGet('adrDoKatPersAnrede')
    .subscribe(data => {
    this.LookupAnrede =  data['Dataset'].Data;

      this.HttpServiceComponent.dataGet('adrDoKatPersTitel')
      .subscribe(data => {
      this.LookupTitel =  data['Dataset'].Data;

      });


    });

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    // Erziehungsberechtigte/r

    this.editForm.addControl('AntrStell_ID_Anrede',     new FormControl(null));

    this.editForm.addControl('AntrStell_ID_Titel',      new FormControl(null));

    this.editForm.addControl('AntrStell_Vorname',       new FormControl(null));
    this.editForm.addControl('AntrStell_Nachname',      new FormControl(null));
    this.editForm.addControl('AntrStell_Geb_Datum',     new FormControl(null));
    this.editForm.addControl('AntrStell_PLZ',           new FormControl(null));
    this.editForm.addControl('AntrStell_Ort',           new FormControl(null));
    this.editForm.addControl('AntrStell_Strasse',       new FormControl(null));
    this.editForm.addControl('AntrStell_HausNr',        new FormControl(null));
    this.editForm.addControl('AntrStell_Phone',         new FormControl(null));
    this.editForm.addControl('AntrStell_eMail',         new FormControl(null));
    this.editForm.addControl('AntrStell_Mobile',        new FormControl(null));


    // let item = this.DataFilter.ItemAdd('Filter Antrag PKValue');
    // item.CriteriaAdd('Antr_ID', this.dataModel.PKValue);
    // //GDS-Aufruf 10 [OK] Antrag
// this.HttpServiceComponent.dataGet(
    //     'schoolDOStammAntrag'
    //   , false
    //   , []
    //   , this.DataFilter
    //   , TvsGetType.gtNormal
    this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrag', '&Antr_ID='+this.dataModel.PKValue)
      .subscribe(
        data => {
          this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);

          this.editForm.controls['AntrStell_ID_Anrede'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.LookupAnrede, this.DOMain.Dataset.Data[0].AntrStell_ID_Anrede));

          if (vsCommon.vsStringHasValue(this.DOMain.Dataset.Data[0].AntrStell_ID_Titel)) {
            this.editForm.controls['AntrStell_ID_Titel'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.LookupTitel, this.DOMain.Dataset.Data[0].AntrStell_ID_Titel));
          }

          this.editForm.controls['AntrStell_ID_Titel'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_ID_Titel);
          this.editForm.controls['AntrStell_Vorname'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_Nachname);
          this.editForm.controls['AntrStell_Nachname'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_Vorname);
          this.editForm.controls['AntrStell_Geb_Datum'].setValue(vsCommon.ISO8061ToDateStr(this.DOMain.Dataset.Data[0]?.AntrStell_Geb_Datum));
          this.editForm.controls['AntrStell_PLZ'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_PLZ);
          this.editForm.controls['AntrStell_Ort'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_Ort);
          this.editForm.controls['AntrStell_Strasse'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_Strasse);
          this.editForm.controls['AntrStell_HausNr'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_HausNr);
          this.editForm.controls['AntrStell_Phone'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_Phone);
          this.editForm.controls['AntrStell_eMail'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_eMail);
          this.editForm.controls['AntrStell_Mobile'].setValue(this.DOMain.Dataset.Data[0]?.AntrStell_Mobile);


          this.DataFilter.ItemsClear();

        });


  } // ngOnInit


  ngAfterViewInit(): void {


  }

  public actionStepPrev(): void {
    this.router.navigate(['main']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;


    if (bOK) {

      // Validierung Gesetzl. Vertretung

      this.editForm.get('AntrStell_ID_Anrede').setValidators(Validators.required);
      this.editForm.get('AntrStell_ID_Anrede').updateValueAndValidity();

      this.editForm.get('AntrStell_Vorname').setValidators(Validators.required);
      this.editForm.get('AntrStell_Vorname').updateValueAndValidity();

      this.editForm.get('AntrStell_Nachname').setValidators(Validators.required);
      this.editForm.get('AntrStell_Nachname').updateValueAndValidity();

      this.editForm.get('AntrStell_Geb_Datum').setValidators(Validators.required);
      this.editForm.get('AntrStell_Geb_Datum').updateValueAndValidity();

      this.editForm.get('AntrStell_PLZ').setValidators(Validators.required);
      this.editForm.get('AntrStell_PLZ').updateValueAndValidity();

      this.editForm.get('AntrStell_Ort').setValidators(Validators.required);
      this.editForm.get('AntrStell_Ort').updateValueAndValidity();

      this.editForm.get('AntrStell_Strasse').setValidators(Validators.required);
      this.editForm.get('AntrStell_Strasse').updateValueAndValidity();

      this.editForm.get('AntrStell_HausNr').setValidators(Validators.required);
      this.editForm.get('AntrStell_HausNr').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      // this.dataSave();
      this.dataModel.workInProgressMessage = true;
      this.router.navigate(['main']);
    }

  } // actionStepNext()



  public dataSave() :void{

    // this.dataModel.antr_ID_Kontakt_AntrStell = 'E3DB624A83AA4E278A11428F71AB32F3';

    this.DOMain.Dataset.Data[0].AntrStell_ID_Anrede              = this.editForm.controls['AntrStell_ID_Anrede'].value;
    this.DOMain.Dataset.Data[0].AntrStell_ID_Titel              = this.editForm.controls['AntrStell_ID_Titel'].value;
    this.DOMain.Dataset.Data[0].AntrStell_Vorname               = this.editForm.controls['AntrStell_Vorname'].value;
    this.DOMain.Dataset.Data[0].AntrStell_Nachname                = this.editForm.controls['AntrStell_Nachname'].value;

    this.DOMain.Dataset.Data[0].AntrStell_Geb_Datum              = vsCommon.DateStrToISO8061(this.editForm.controls['AntrStell_Geb_Datum'].value);

    this.DOMain.Dataset.Data[0].AntrStell_Strasse              = this.editForm.controls['AntrStell_Strasse'].value;
    this.DOMain.Dataset.Data[0].AntrStell_HausNr              = this.editForm.controls['AntrStell_HausNr'].value;
    this.DOMain.Dataset.Data[0].AntrStell_PLZ               = this.editForm.controls['AntrStell_PLZ'].value;
    this.DOMain.Dataset.Data[0].AntrStell_Ort                = this.editForm.controls['AntrStell_Ort'].value;

    this.DOMain.Dataset.Data[0].AntrStell_Phone              = this.editForm.controls['AntrStell_Phone'].value;
    this.DOMain.Dataset.Data[0].AntrStell_eMail               = this.editForm.controls['AntrStell_eMail'].value;
    this.DOMain.Dataset.Data[0].AntrStell_Mobile                = this.editForm.controls['AntrStell_Mobile'].value;

    this.HttpServiceComponent.dataUpdate(
      // this.ViewProperties.DataSourceID
        'schoolDOStammAntrag'
      , this.ViewProperties.DOMain
      , this.dataModel.PKValue)
      .subscribe(
        dataPost => {

          //   //Schüler
          //   // this.DOMain.Dataset.Data[0].Azubi_ID_Anrede              = this.editForm.controls['Azubi_ID_Anrede'].value;
          //   this.DOMain.Dataset.Data[0].Azubi_Nachname               = this.editForm.controls['Azubi_Nachname'].value;
          //   this.DOMain.Dataset.Data[0].Azubi_Vorname                = this.editForm.controls['Azubi_Vorname'].value;
          //   this.DOMain.Dataset.Data[0].Azubi_Geb_Datum              = vsCommon.DateStrToISO8061(this.editForm.controls['Azubi_Geb_Datum'].value);

        }
      );


  }

  showDlgDoc(a_Value: any) {

    let x    = this.DocType.map(el => el.ID)
    let i    = x.indexOf(a_Value);

    this.ref = this.dialogService.open(TdlgDoc, {
        data:         a_Value,
        header:       this.DocType[i].Caption,
        width:        '50%',
//        contentStyle: {"max-height": "500px", "overflow": "auto"},
        baseZIndex:   10000
    });

    this.ref.onClose.subscribe((fileData: any) =>{

      //  this.crmVorgData.push({Datum: "13.08.2021", Uhrzeit: "12:45", Name: fileData.FileName});
    });
}


} // schoolViewAntragEdit_Student


