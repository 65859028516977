

<div #akVsView id="akVsView" *ngIf="!ViewProperties.TestModeLayout" class="vsView">


<!-- <div #akVsView id="akVsView" *ngIf="!ViewProperties.TestModeLayout" class="vsView"> -->


  <!--
    Section: Local Toolbar

    Toolbar items are configured via proerty ToolbarItems
  -->

  <!-- <div *ngIf="SectionIsVisible_Toolbar && (!ToolbarItems.IsEmpty || TemplateSectionDetail)" class="vsViewSectionToolbar">
    <button *ngFor = "let item of ToolbarItems; let i=index"
            pButton type="button"
            class="p-button-sm vsToolbarItem"
            label="Test i"
            (click)="btnToolbarOnClick(i)"
    ></button>
  </div> -->

  <!-- [LABOR] -->
  <!-- Teilung der Mainsection in zwei Sektionen um Content links und rechts in der Mainsection anzeigen zu können. Breitenangabe sollte auch über Property einstellbar sein.-->
  <div class="row vsGutterWidth-2">
    <!--Anfang Section left-->

    <!--$REV MP 2022-07-20: Wenn TemplateSectionRight eingeblendet wird, ist erst einmal fest col-8 als Breite festelegt, sollte später per Property veränderbar sein.-->
    <div [ngClass]="{
      'col-6' :(ViewProperties.SectionIsVisible_SectionRight && TemplateSectionRight && ViewProperties.SectionRightMainColWidth === 6),
      'col-7' :(ViewProperties.SectionIsVisible_SectionRight && TemplateSectionRight && ViewProperties.SectionRightMainColWidth === 7),
      'col-8' :(ViewProperties.SectionIsVisible_SectionRight && TemplateSectionRight && ViewProperties.SectionRightMainColWidth === 8),
      'col-12':!(ViewProperties.SectionIsVisible_SectionRight && TemplateSectionRight)}">

    <!--Section: Title -->

    <div *ngIf="ViewProperties.SectionIsVisible_Title" class="vsViewSectionTitle">
      {{ ViewProperties.ViewTitle }}
    </div>


  <div #akToolbar id="akToolbar" *ngIf="ViewProperties.SectionIsVisible_Toolbar && ((ViewProperties.ToolbarItems.length > 0) || TemplateSectionDetail)" class="vsViewSectionToolbar">

    <div class="vsToolbar">
      <div class="vsToolbarLeft">

        <!-- <button *ngFor = "let item of ViewProperties.ToolbarItems; let i=index"

          pButton
          type      = "button"
          class     = "p-button-sm vsToolbarItem"
          label     = "{{item.Caption}}"
          (click)   = "btnToolbar_OnClick(i)"
        ></button> -->

        <p-tieredMenu #ActionMenu [model]=MenuItemsAction [popup]="true" class="vsTieredMenu"></p-tieredMenu>
        <p-tieredMenu #NewMenu    [model]=MenuItemsNew    [popup]="true" class="vsTieredMenu"></p-tieredMenu>

        <div *ngFor="let item of ViewProperties.ToolbarItems; let i=index">
          <div *ngIf="item.Visible">
            <div *ngIf="!item.Enabled">

              <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}" disabled  class      ="vsToolbarItem">
                <span>
                  <img alt="logo" src="{{item.src}}" />
                </span> </button>
            </div>

            <div *ngIf="item.Enabled">
              <div *ngIf="item.ID == 'tbActions'">
                <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
                  (click)="ActionMenu.toggle($event)" class="vsToolbarItem">
                  <span>
                    <img alt="logo" src="{{item.src}}" />
                  </span> </button>
              </div>
              <div *ngIf="item.ID == 'tbNew' && this.MenuItemsNew.length > 0">
                <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
                  (click)="NewMenu.toggle($event)" class="vsToolbarItem">
                  <span>
                    <img alt="logo" src="{{item.src}}" />
                  </span> </button>
              </div>
              <div *ngIf="((item.ID != 'tbActions') && (item.ID != 'tbNew'))  || (item.ID == 'tbNew' && this.MenuItemsNew.length == 0)">
                <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
                  (click)="btnToolbar_OnClick(i)" class="vsToolbarItem">
                  <span>
                    <img alt="logo" src="{{item.src}}" />
                  </span> </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Toolbar: rechter Bereich -->
      <div class="vsToolbarRight">

        <!-- View Configuration -->
        <!-- <div>
          <button type      = "button"
                  pButton
                  class     = "p-button-sm vsToolbarItem vsToolbarItemViewConfig"
                  label     = "<Standardansicht>"
          ></button>
        </div> -->

        <!-- Details Toggle -->
        <!--
          $Rev TC 2021-07-26
          Aktuell noch deaktiviert, da nach Aus- und wieder Einblenden des Detailbereiches dessen Inhalt
          verschwunden ist.
          ==> Mal testen mit "display: none" beim Detailbereich, das sollte helfen
        -->
        <!-- <div *ngIf="TemplateSectionDetail">
          <button type      = "button"
                  pButton
                  [class]   = "CSSClassDetailsSwitch"
                  label     = "Details"
                  (click)   = " setElementSize()"
          ></button> -->
          <!-- $rev AK 2021-08-18: habe folgendes aus (click) des obigen Buttons zum Testen von setElementSize() entfernt:
                                    ViewProperties.SectionIsVisible_Detail = !ViewProperties.SectionIsVisible_Detail;
          -->
        <!-- </div> -->

      </div>  <!-- Toolbar: rechter Bereich -->

    </div>  <!-- Toolbar -->

  </div>  <!-- Ende Section Toolbar -->

  <!--
    Section: Filter

    Anmerkung:
    Es ist wichtig, das komplette div auszublenden, wenn die TemplateRef nicht gesetzt ist.
    Je nach Definition der CSS-Klasse  könnte sonst vorkommen, dass z.B. Borders und ein Teil des
    Hintergrundes angezeigt werden, obwohl der eigentliche Content leer ist.
  -->
  <!-- [LABOR] -->
  <!-- <div *ngIf="!templateSectionFilter"  class="vsViewSectionFilter"> -->

  <!-- [RICHTIG] -->
  <!-- <div #filter id="filter"> -->
    <div #akFilter id="akFilter" *ngIf="ViewProperties.SectionIsVisible_Filter && TemplateSectionFilter"  class="vsViewSectionFilter">

      <div class="vsFilterContainer">
        <ng-template [ngTemplateOutlet]="TemplateSectionFilter"></ng-template>
      </div>
      <!-- [LABOR] nur zum experimentieren und damit man mal was sieht -->
      <!-- <div style="display: flex">
        <span style="padding-right: 0.6rem;">Filterwert:</span>
        <input type="text">
      </div> -->

    </div>
  <!-- </div> -->

  <!--
    Section: Main
  -->


  <div class="row vsGutterWidth-2">
      <!-- <div id="akSectionMain" [class]="CSSClassSectionMain" [style.height.px]="iContentHeight" class="col"> -->
      <div class="col">

        <!-- no multi -->
        <!-- <div class="vsViewSectionMainTable" [style.height]="calculatedTableHeight"> -->
          <vsTable #TableList
            [DOMain]="DOMain"
            (onRowSelect)="doTableRowSelect($event)"
            (onCustomButtonClick)="doCustomButtonClick($event, name)"
            (onActionEditClick)="doActionEditClick($event)"
            (onActionDeleteClick)="doActionDeleteClick($event)"
            [templateTitle]="templateTableTitle"
            [MenuItemsContext]="MenuItemsContext"
            [dataSelected]="fDataSelected"
            [HintVisibleFilterRequired]=HintVisibleFilterRequired
            [reorderableColumns]="false"
            [resizableColumns]="false"
            [calculatedTableHeight]="calculatedTableHeight"
            >
          </vsTable>
          <!-- <ng-template #templateTableTitle>
            <h2>Das ist ein zusätzlicher Title - nur zum Testen der Content Projection</h2>
          </ng-template> -->
        <!-- </div> -->

      </div><!--Ende SectionMain-->

      <!--TemplateSectionMainRight ist obsolet, wird durch SectionRight ersetzt-->
      <!-- <div *ngIf="TemplateSectionMainRight" class="vsViewSectionRight col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-md-12">
        <ng-template [ngTemplateOutlet]="TemplateSectionRight"></ng-template>
      </div> -->

  </div><!--Ende row Mainsection unter Toolbar und Filter-->

    </div><!--Ende col Section left-->

      <!--$REV MP 2022-07-20: TemplateSectionRight hinzugefügt-->
   <!--Anfang Section right-->
    <div *ngIf="ViewProperties.SectionIsVisible_SectionRight && TemplateSectionRight" class="col vsViewSectionRight">
      <ng-template [ngTemplateOutlet]="TemplateSectionRight"></ng-template>
    </div><!--Ende Section right-->



</div><!--Ende row gesamte Mainsection-->

  <!--
    Section: Detail
  -->
  <div #akDetails id="akDetails" *ngIf="ViewProperties.SectionIsVisible_Detail && TemplateSectionDetail" style="width: 100%"  class="vsViewSectionDetail vsMt-2">
     <ng-template [ngTemplateOutlet]="TemplateSectionDetail"></ng-template>
  </div>


  <!--
    Section: Footer
  -->

  <!-- <div class="vsViewSectionFooter">
      Anzahl Datensätze: {{DOMain.Dataset.RecordCount}}
  </div> -->

  <div *ngIf="ViewProperties.SectionIsVisible_Footer && TemplateSectionFooter"  class="vsViewSectionFooter">
    <ng-template [ngTemplateOutlet]="TemplateSectionFooter"></ng-template>
  </div>

</div>



<!-- TestModeLayout ------------------------------------------------------------------------------->


<div *ngIf="ViewProperties.TestModeLayout" class="vsView">
  <div class="vsViewSectionPlaceholderTitle">Ansicht: {{ ViewProperties.ViewTitle }} <span class="vsViewSectionPlaceholder_DebugText">(SectionIsVisible = {{ViewProperties.SectionIsVisible_Title}})</span></div>

  <div class="vsViewSectionPlaceholderMenu">Lokales Menü / Toolbar  <span class="vsViewSectionPlaceholder_DebugText">(SectionIsVisible = {{ViewProperties.SectionIsVisible_Menu}})</span></div>
  <div class="vsViewSectionPlaceholderExtern">Filter Controls  <span class="vsViewSectionPlaceholder_DebugText">(SectionIsVisible = {{ViewProperties.SectionIsVisible_Filter}})</span></div>

  <div class="vsViewSectionPlaceholderMain">
    <div #sectionMainTable class="vsViewSectionPlaceholderMainTable">Table Component</div>
    <div #sectionMainRight class="vsViewSectionPlaceholderMainRight">Details/Buttons  <span class="vsViewSectionPlaceholder_DebugText">(SectionIsVisible = {{ViewProperties.SectionIsVisible_MainRight}})</span></div>
  </div>

  <div class="vsViewSectionPlaceholderFooter">Lokaler Footer  <span class="vsViewSectionPlaceholder_DebugText">(SectionIsVisible = {{ViewProperties.SectionIsVisible_Footer}})</span></div>

  <!--
  <b>Filter Items ({{ ViewProperties.FilterMain.length }}):</b>
  <p *ngFor = "let item of ViewProperties.FilterMain">{{ item.Caption }} = "{{ item.Value }}" ({{ item.ControlType }})</p>

  <hr>
  -->

</div>


<!--
<p-dialog header="Löschen?" [(visible)]="displayDialogDelete" [style]="{width: '40vw'}" [baseZIndex]="10000" class="vsDialog">
  <p>Wollen Sie den Datensatz wirklich löschen?</p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogDelete=false; this.internalActionDelete()" label="OK" class="p-button-raised p-button-success"></button>
      <button pButton pRipple type="button" (click)="displayDialogDelete=false" label="Abbrechen" class="p-button-secondary"></button>
  </ng-template>
</p-dialog>-->

<!-- Confirm-Dialog -->

<p-dialog header="Datensatz löschen?" [(visible)]="displayDialogDeleteConfirm" [style]="{width: '17vw'}" [baseZIndex]="10000" class="vsDialog vsWarning">
  <p class="vsDialogText">Wollen Sie den Datensatz wirklich löschen?</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="displayDialogDeleteConfirm=false; this.internalActionDelete()" label="OK" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>

      <button pButton pRipple type="button" (click)="displayDialogDeleteConfirm=false" label="Abbrechen" class="vsToolbarItem vsToolbarItemCancel">
        <span>
          <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Edit-Multiple-Validate-Error - Dialog -->

<p-dialog header="Abbruch?" [(visible)]="displayDialogEditMultipleError" [style]="{width: '17vw'}" [baseZIndex]="10000" class="vsDialog vsWarning">
  <p class="vsDialogText">{{displayDialogEditMultipleErrorMsg}}</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="displayDialogEditMultipleError=false" label="Abbrechen" class="vsToolbarItem vsToolbarItemCancel">
        <span>
          <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Delete-Multiple-Validate-Error - Dialog -->

<p-dialog header="Abbruch?" [(visible)]="displayDialogDeleteMultipleError" [style]="{width: '17vw'}" [baseZIndex]="10000" class="vsDialog vsWarning">
  <p class="vsDialogText">{{displayDialogDeleteMultipleErrorMsg}}</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="displayDialogDeleteMultipleError=false" label="Abbrechen" class="vsToolbarItem vsToolbarItemCancel">
        <span>
          <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Delete-Validate-Error - Dialog -->

<p-dialog header="Löschen nicht möglich!" [(visible)]="displayDialogDeleteError" [style]="{width: '24vw'}" [baseZIndex]="10000" class="vsDialog vsDanger">
  <p class="vsDialogText">{{displayDialogDeleteMsgText}}</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="this.displayDialogDeleteError=false;" label="OK" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>



<!-- Delete-Validate-Error - Dialog -->

<p-dialog header="Löschen nicht möglich!" [(visible)]="displayDialogFlagSystemDeleteError" [style]="{width: '24vw'}" [baseZIndex]="10000" class="vsDialog vsDanger">
  <p class="vsDialogText">{{displayDialogFlagSystemDeleteErrorMsgText}}</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="this.displayDialogFlagSystemDeleteError=false;" label="OK" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>



<!-- <p-dialog [(visible)]="DOMain.IsRefreshing" [style]="{width: '25vw'}" [baseZIndex]="10000"
          [modal]=true [maximizable]=false [closable]=false [closeOnEscape]=false [showHeader]=false
>
  <div style="color: dimgray; text-align: center; font-size: 1rem; font-weight: bolder; margin-top: 2rem">... Bitte warten ...</div>

  <div *ngIf="DOMain.IsRefreshing" class="vsProgressSpinner">
    <p-progressSpinner
        [style]             = "{width: '50px', height: '50px'}"
        styleClass          = "custom-spinner"
        strokeWidth         = "5"
        animationDuration   = "1s"
    ></p-progressSpinner>
  </div>

</p-dialog> -->


