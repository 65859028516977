//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';
import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';


import { Router }                           from '@angular/router';
import { FormGroup
       , FormControl
       , Validators }                       from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { TSearchDlgData, TvsSearchDlg } from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}


type TranslationHeadingType = {
  name: string;
  type: string;
  translationCaption: string;
  size: string;
  sort: boolean;
  alignment: any;
}

@Component({
  selector:       'schoolViewAntragEdit_SEPA',
  templateUrl:  './schoolViewAntragEdit_SEPA.component.html',
  providers:  [DialogService, DynamicDialogRef, DynamicDialogConfig]
  // styleUrls:   ['./schoolViewAntragEdit_SEPA.component.scss']
})
export class TschoolViewAntragEdit_SEPA extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  // ./.
  public      editForm:                     FormGroup               = new FormGroup({});

  public      data;

  public      displayDialogError:           boolean                 = false;

  public      KennErstattung:               boolean                 = false;

  public      refSearchDlg:                 DynamicDialogRef;

  public      translationDialogItems:       TranslationDialogItemsType[]    = [];

  public      translationHeadingItems:      TranslationHeadingType[]        = [];

  public      ibanValid:                    boolean                         = true;

  public      searchDlgData: TSearchDlgData = new TSearchDlgData(
    'adrDoKatBank',
    [
        { Name: "xBank_Info",      Type: "ftString", Caption: "Name der Bank",      Size: "600",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft }
    ], true, null, this.DataFilter, false, true
  );

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;



  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    ref:                        DynamicDialogRef
            , public    dialogService:              DialogService
            , public    translate:                  TranslateService     // [ngx-translate]

  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Bankverbindung';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.translationHeadingItems = [
      { name: 'xBank_Info',      type: 'ftString',   translationCaption: 'dialog.text_name_bank',     size: 'width:600px',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft }
    ];


  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    // Bankdaten
    this.editForm.addControl('AntrStell_ID_Bank',   new FormControl(this.dataModel.antrStell_Bank_ID));
    this.editForm.addControl('AntrStell_Kto_Inh',   new FormControl(this.dataModel.antrStell_Kto_Inh));
    this.editForm.addControl('AntrStell_IBAN',      new FormControl(this.dataModel.antrStell_IBAN));
    this.editForm.addControl('AntrStell_Bank_Name', new FormControl(this.dataModel.antrStell_Bank_Name));
    this.editForm.addControl('AntrStell_Bank_BIC', new FormControl(this.dataModel.antrStell_Bank_BIC));

    if(this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA') {
      this.KennErstattung = true;
    }

    this.doLoadProfileData();

  } // ngOnInit


  ngAfterViewInit(): void {

    //$REV MP 2023-03-20: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }

  }


  doLoadProfileData():void{


  this.HttpServiceComponent.HttpServiceGetRequest('school/data/kontakt', '')
  .subscribe((data: any) => {


      this.data = data.Dataset.Data[0];

      this.dataModel.antrStell_Bank_ID   = this.data.Bank_ID_Bank;
      this.dataModel.antrStell_IBAN      = this.data.Bank_IBAN;
      this.dataModel.antrStell_Bank_Name = this.data.Bank_Name;
      this.dataModel.antrStell_Kto_Inh   = this.data.Bank_Inhaber_Name;
      this.dataModel.antrStell_Bank_BIC  = this.data.Bank_BIC;

      //Prüfung auf Kontodaten in den Profildaten, wenn diese gefüllt sind Füllen aus den Profildaten und Sperren der editForms, wenn nicht Eingabe erlauben

      if(this.data.Bank_IBAN && this.data.Bank_Inhaber_Name) {
        this.editForm.controls['AntrStell_Kto_Inh'].setValue(this.data.Bank_Inhaber_Name);
        this.editForm.controls['AntrStell_ID_Bank'].setValue(this.data.Bank_ID_Bank);
        this.editForm.controls['AntrStell_IBAN'].setValue(this.data.Bank_IBAN);
        this.editForm.controls['AntrStell_Bank_Name'].setValue(this.data.Bank_Name);
        this.editForm.controls['AntrStell_Bank_BIC'].setValue(this.data.Bank_BIC);

        this.dataModel.antrStell_Bank_ID   = this.data.Bank_ID_Bank;
        this.dataModel.antrStell_IBAN      = this.data.Bank_IBAN;
        this.dataModel.antrStell_Bank_Name = this.data.Bank_Name;
        this.dataModel.antrStell_Kto_Inh   = this.data.Bank_Inhaber_Name;
        this.dataModel.antrStell_Bank_BIC  = this.data.Bank_BIC;

        this.editForm.controls['AntrStell_Kto_Inh'].disable();
        this.editForm.controls['AntrStell_IBAN'].disable();
        // this.editForm.controls['AntrStell_Bank_Name'].disable();
        // this.editForm.controls['AntrStell_Bank_BIC'].disable();
      }
      else {
        this.editForm.controls['AntrStell_Kto_Inh'].enable();
        this.editForm.controls['AntrStell_IBAN'].enable();
        // this.editForm.controls['AntrStell_Bank_Name'].enable();
        // this.editForm.controls['AntrStell_Bank_BIC'].enable();
      }

    });

  }


  validateIBAN = () => {
    //TEST für IBAN-Check
    if(this.editForm.controls['AntrStell_IBAN'].value){
      this.HttpServiceComponent.HttpServiceGetRequest('invActionCheckIBAN', '&IBAN='+this.editForm.controls['AntrStell_IBAN'].value)
        .subscribe((response: any) => {

          this.editForm.controls['AntrStell_Bank_Name'].setValue(response.bankName);
          this.editForm.controls['AntrStell_ID_Bank'].setValue(response.bankID);
          this.editForm.controls['AntrStell_Bank_BIC'].setValue(response.bic);

          if(response.error.errCode) {
            this.ibanValid = false;
          }
          if(response.error.errCode === "") {
            this.ibanValid = true;
          }
        });//Ende HttpService
     }
     if(this.editForm.controls['AntrStell_IBAN'].value === '') {
      this.editForm.controls['AntrStell_Bank_Name'].setValue('');
      this.editForm.controls['AntrStell_ID_Bank'].setValue('');
      this.editForm.controls['AntrStell_Bank_BIC'].setValue('');
    }
    if(this.editForm.controls['AntrStell_Bank_Name'].value === "" && this.editForm.controls['AntrStell_IBAN'].value === "") {
      this.ibanValid = false;
    }
  }


  public actionStepPrev(): void {
    this.dataSave();
    this.router.navigate(['antragEdit/doc']);
  } // actionStepPrev()


  public actionStepNext(): void {

    let bOK:        boolean       = true;

    //$REV MP 2022-12-22: Kontodaten verpflichten bei Grundantrag Erstattung und Erstattungsantrag
    if (bOK && this.dataModel.antrag_Art === 'E') {

      this.editForm.get('AntrStell_Kto_Inh').setValidators(Validators.required);
      this.editForm.get('AntrStell_Kto_Inh').updateValueAndValidity();

      this.editForm.get('AntrStell_IBAN').setValidators(Validators.required);
      this.editForm.get('AntrStell_IBAN').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;
    }

    if (bOK) {
      this.dataSave();
      this.dataModel.workInProgressMessage = true;
      this.router.navigate(['antragEdit/commit']);
    }

  } // actionStepNext()

  public dataSave() :void{
      if(!this.ibanValid) {
        this.messageService.add({severity: 'success',  summary: 'Info',  detail: 'IBAN ist nicht gültig. Bitte geben Sie eine gütlige IBAN an.'});
      }

      if(this.ibanValid) {
        this.dataModel.antrStell_Kto_Inh       = this.editForm.controls['AntrStell_Kto_Inh'].value;
        this.dataModel.antrStell_IBAN          = this.editForm.controls['AntrStell_IBAN'].value;
        this.dataModel.antrStell_Bank_Name     = this.editForm.controls['AntrStell_Bank_Name'].value;
        this.dataModel.antrStell_Bank_ID       = this.editForm.controls['AntrStell_ID_Bank'].value;
        this.dataModel.antrStell_Bank_BIC       = this.editForm.controls['AntrStell_Bank_BIC'].value;
      }
  }


  doBankSearch(event:  any) {

    this.DataFilter.ItemsClear();

    //Übersetzungen in TSearchDlgData pushen
    //Items initial leeren damit die übersetzten Items nicht dazu kommen.
    this.searchDlgData.FieldDefs = [];

    this.translationHeadingItems.map((item) => {
      this.translate.get(item.translationCaption).subscribe((res)=>{
        this.searchDlgData.FieldDefs.push(
              { Name:       item.name,
                Type:       item.type,
                Caption:    res,
                Size:       item.size,
                Sort:       item.sort,
                Alignment:  item.alignment,
              }
          );
      });
    });

    this.translationDialogItems = [
      { data:  this.searchDlgData,
        header: 'dialog.title_search_bank',
        styleClass: 'vsDialog vsFullscreenDialog',
        baseZIndex: 10000}
    ]

    this.translationDialogItems.map((item) => {
      this.translate.get(item.header).subscribe((res)=>{

          this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
            data:         item.data,
            header:       res,
            width:        item.width,
            styleClass:   item.styleClass,
            style:        item.style,
            baseZIndex:   item.baseZIndex

          });
        });
      });

      this.refSearchDlg.onClose.subscribe((Data: any) => {
        if (vsCommon.vsVarAssigend(Data)) {
          this.editForm.controls['AntrStell_ID_Bank'].setValue(Data.Bank_ID);
          // this.editForm.controls['Bank_BIC'].setValue(Data.Bank_BIC);
          this.editForm.controls['AntrStell_Bank_Name'].setValue(Data.Bank_Name);
          }
      });

    }



} // schoolViewAntragEdit_SEPA


