//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputDropDown
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-07-06  MH   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';


//
// TvsInputDropDown
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputDropDown',
  templateUrl:  './vsInputDropDown.component.html',
  styleUrls:   ['./vsInputDropDown.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputDropDown extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input()    LookupSrc:          any[]               = [];
  @Input()    LookupKey:          string              = '';
  @Input()    LookupDisplay:      string              = '';
  @Input()    allowClear:         boolean             = true;
  @Input()    EmptyMessage:       string              = null; // $rev AK 2023-02-14: von '' zu null geändert, sonst springt der FormControl required validator nicht an



//  @Output()   onChange:    EventEmitter<TvsInputDropDownEventArgs>    = new EventEmitter<TvsInputDropDownEventArgs>();
  @Output()   onChange:    EventEmitter<any>    = new EventEmitter<any>();

  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args


  doOnChange(event: any) {
    this.onChange.emit(event);
  } // doOnChange



} // TvsInputDropDown


