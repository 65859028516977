//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputAutoComplete
//  Notes:  Input control zur Eingabe von Text mit Nachschlagen der Ergebnismenge (wie GoogleSuche)
//
//
//  H I S T O R Y
//
//  2021-08-31  MH   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';



//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputAutoComplete',
  templateUrl:  './vsInputAutoComplete.component.html',
  styleUrls:   ['./vsInputAutoComplete.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputAutoComplete extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)
  public filteredField: any[] = [];
  // ./.


  //====== Properties (PROTECTED)
  // ./.

  //====== Properties (PUBLIC)
  @Input()    suggestions:          any[]       = [];
  @Input()    FieldName:            string      = '';
  @Input()    MinLength:            number      = 1;
  @Input()    Dropdown:             boolean     = false;
  @Input()    NoLabel:              boolean     = false;
  @Input()    inputId:              string      = 'input-'+this.FrmCtlName;
  @Input()    isDisabled:           boolean     = false;
  @Input()    selected:             any         = "";


  @Output()   onSearch:    EventEmitter<any>    = new EventEmitter<any>();
  @Output()   onSelect:    EventEmitter<any>    = new EventEmitter<any>();



  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.

  //====== Methods: Event Handler

  doOnSearch(event: any) {
    let filtered: any[] = [];
    let query = event.query.toLowerCase();

    // console.log('query: ', query.toLowerCase());

    const regex = new RegExp(`${query}`, 'g');
    let matchedField = this.suggestions.filter((val) => val[this.FieldName].toLowerCase().match(regex));

    if(event.query === "") {
      for (let i = 0; i < this.suggestions.length; i++) {
        let status = this.suggestions[i];
        if (status[this.FieldName].toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(status);
        }
      }
      matchedField = filtered;
    }
    this.filteredField = matchedField;
    this.onSearch.emit(event);
  } // doOnSearch

  doOnSelect(event: any) {
    this.onSelect.emit(event);
  } // doOnSelect

  //public RetrieveValues(event) {

      //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side

    //   let filtered: any[] = [];
    //   let query = event.query;
    //   for (let i = 0; i < this.countries.length; i++) {
    //     let country = this.countries[i];
    //     if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //       filtered.push(country);
    //     }
    //   }

    //   this.filteredCountries = filtered;
    // }



  //} // RetrieveValues



} // TvsInputAutoComplete


