//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   Main
//  Title:    Main View der Applikation
//  Notes:
//
//
//  Die Verwendung einer eigenen MainView für die Gestaltung des "Anwendungsrahmens", anstatt
//  hierfür direkt die AppComponent zu verwenden, bietet folgende Vorteile:
//
//    # Man kann die MainView von eigenen vsLib-Basisklassen ableiten.
//
//    # Über verschiedene Basisklassen kann man verschiedene Layouts und Funktionalitäten für
//      unterschiedliche Arten von Applikationen zur Verfügung stellen.
//      Beispielsweise speziell optimierte Versionen für mobile Geräte unter Verwendung von
//      IONIC etc.
//
//  Aktuell könnte man zwar auch die AppComponent von einer vsLib-Klasse ableiten, da diese noch
//  nicht von irgendeiner Angular-Klasse abgeleitet ist. Jedoch könnte sich das in zukünftigen
//  Versionen des Angular-Frameworks theoretisch mal ändern. Durch die Verwendung einer eigenen
//  MainView sind wir von Anfang an unabhängig von derartigen Änderungen.
//
//
//  H I S T O R Y
//
//  2021-08-12  TC, MP   Initial Version
//--------------------------------------------------------------------------------------------------

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router }                           from '@angular/router';

import {  Directive
        , ViewChild
        , ViewContainerRef, TemplateRef
        , ComponentFactoryResolver}         from '@angular/core';

// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';

// PrimeNG
import { MegaMenuItem }                     from 'primeng/api';
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';

// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsDataObject                    from '@vsLib/vsDataObject';

//import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';            // $Rev TC 2021-06-06: zumindest vorläufig mal hinzugefügt, damit der Dispatcher PKValue setzen kann
import * as vsViewDataEdit                  from '@vsLib/View/components/vsViewDataEdit/vsViewDataEdit.component';    // $Rev TC 2021-06-06: zumindest vorläufig mal hinzugefügt, damit der Dispatcher PKValue setzen kann
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsAppViewList                   from '@vsLib/vsAppViewList';
import * as vsAppDispatcher                 from '@vsLib/Services/vsAppDispatcher.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { TschoolViewAntragList }            from '../school/schoolViewAntragList.component';
import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';





@Component({
    selector:       'mainView',
    templateUrl:  './mainView.component.html',
    styleUrls:   ['./mainView.component.scss']
})
export class MainView extends vsView.TvsView implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  // $rev AK 2021-08-05: Labor Mockup ----------------- BEG
  private     FDataSelected:                any                     = [];
  // $rev AK 2021-08-05: Labor Mockup ----------------- END



  //====== Properties (PROTECTED)

  // ./.


  //====== Properties (PUBLIC)

  public      MenuMain:                       MegaMenuItem[];       // PrimeNG: MegaMenu


  //====== ViewChilds
  @ViewChild('antrList') antrList: TschoolViewAntragList;
  // ./.



  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , protected componentFactoryResolver:   ComponentFactoryResolver
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {
    super(router, globalResources);

    globalResources.ViewMain  = this;

  

  } // constructor


  //====== NG event handler


  ngOnInit(): void {


  } // ngOnInit


  ngAfterViewInit(): void {

    //Toast-Message für die Änderungs-Edits, da dataSave dort noch nicht aktiviert ist.
    //Wahrscheinlich obsolet, da die Änderungsedits erst einmal nicht verwendet werden.
    // if (this.dataModel.workInProgressMessage) {
    //   // this.actionAntragEdit();
    //   this.dataModel.workInProgressMessage = false;
    // }

  } // ngAfterViewInit


  

  //------------------------------------------------------------------------------------------------
  // Method:  msgShowToast...
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Displays a toast message
  //------------------------------------------------------------------------------------------------

  msgShowToastError(a_sMsg: string) {
    this.messageService.add({severity: 'error',     summary: 'Fehler',  detail: a_sMsg});
  } // msgShowToastError

  msgShowToastInfo(a_sMsg: string) {
    this.messageService.add({severity: 'info',      summary: 'Info',  detail: a_sMsg});
  } // msgShowToastInfo

  msgShowToastSuccess(a_sMsg: string) {
    this.messageService.add({severity: 'success',   summary: 'Erfolg',  detail: a_sMsg});
  } // msgShowToastSuccess

  msgShowToastWarning(a_sMsg: string) {
    this.messageService.add({severity: 'warn',      summary: 'Warnung',  detail: a_sMsg});
  } // msgShowToastWarning



  //------------------------------------------------------------------------------------------------
  // Method:  mnuMain...
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlungen: Main Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuMainHelp_OnClick(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Hilfe'});
  } // doMenuMainItemClick







} // MainView


