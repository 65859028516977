//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputDateTime
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-07-06  MH   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';
import { formatDate, getLocaleDateFormat } from '@angular/common';



//
// TvsInputDateTime
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputDateTime',
  templateUrl:  './vsInputDateTime.component.html',
  styleUrls:   ['./vsInputDateTime.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputDateTime extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public  de: any;

  @Input()    KennTimeOnly:   boolean       = false;
  @Input()    YearRange:      string        = '1920:2026';
  @Input()    placeholder:    string        = '';
  @Input()    NoLabel:        boolean       = false;

  @Output()   input:          EventEmitter<any>   = new EventEmitter<any>();

  public      customDateFormat: string      = 'dd.mm.yy';
  // ... usw ...



  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
  } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {

  } // ngOnInit

  onDateSelect(event: any): void {
    const selectedDate: Date = event.value;
    const formattedDate: string = this.formatDate(selectedDate);
    this.FormGroup.controls[this.FrmCtlName].setValue(formattedDate);
  }

  private formatDate(date: Date): string {
    const day: string = this.padZero(date.getDate());
    const month: string = this.padZero(date.getMonth() + 1); // Note: Months are 0-indexed
    const year: string = date.getFullYear().toString();

    return `${day}.${month}.${year}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  public handleInputChange(value: string): void {
    const dateRegex = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;

    console.log('HANDLEINPUTCHANGE')

    if (value.length === 8 && /^\d+$/.test(value) && !dateRegex.test(value)) {
        // If the value has 8 digits and no separators, extract day, month, and year
        const day: string = value.slice(0, 2);
        const month: string = value.slice(2, 4);
        const year: string = value.slice(4);
        const formattedDate: string = `${day}.${month}.${year}`;

        // Check if the formatted date is different from the current value before setting it
        if (this.FormGroup.controls[this.FrmCtlName].value !== formattedDate) {
            this.FormGroup.controls[this.FrmCtlName].setValue(formattedDate);
        }
    } else if (dateRegex.test(value)) {
        // If the value matches the date regex, extract day, month, and year
        const match = value.match(dateRegex);
        const day: string = match[1].padStart(2, '0');
        const month: string = match[2].padStart(2, '0');
        const year: string = match[3];
        const formattedDate: string = `${day}.${month}.${year}`;

        // Check if the formatted date is different from the current value before setting it
        if (this.FormGroup.controls[this.FrmCtlName].value !== formattedDate) {
            this.FormGroup.controls[this.FrmCtlName].setValue(formattedDate);
        }
    } 

}

  // Neue Wrapper-Methode
  handleMultipleInputEvents(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.handleInputChange(value);
    this.doOnInput(event);
  }


  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler

  doOnInput(event) {
    this.input.emit(event);
  }

  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args



} // TvsInputDateTime


