
<div class="vsLayoutGroup" [style]="{'background-color': BGColor, 'margin-top': marginTop}" [class]=CSSClassContent>
  <!-- <div *ngIf="Caption" style="font-weight: bold; color: gray;">{{Caption}} | {{Orientation}}</div> -->

  <!-- <div [class]=CSSClassContent> -->
    
    <div [class]=CSSClassPanel>

      <div *ngIf="isPanel && panelTitleVisible" class="vsCardTitle">
        {{panelTitleText}}
      </div>

      <div [class]=CSSClassBody>
        <div [class]="CSSClassHorizontal">
          <ng-content></ng-content>
        </div>
      </div>

      <div *ngIf="isPanel && panelFooterVisible" class="vsCardFooter">
        {{panelFooterText}}
      </div>

    </div>
  
  <!-- </div>  CSSClassContent -->

</div>


