//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


@Component({
  selector:       'schoolViewAntragEdit_StudentName',
  templateUrl:  './schoolViewAntragEdit_StudentName.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Student.component.scss']
  providers: [DialogService]
})
export class TschoolViewAntragEdit_StudentName extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public editForm:                     FormGroup               = new FormGroup({});

  public Anrede: any[];

  public LookupAnrede:                any[];
  // public LookupTitel:                 any[];
  public filteredAnrede:              any[];

  public displayDialogError: boolean   = false;
  public data;

  public DocType:       any[] =
  [
    {Caption: 'Nutzerfoto laden',    ID: 'Pic'}

  ];

  //====== Constructor


  ref: DynamicDialogRef;

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected httpClient:                 HttpClient
            , public    dialogService:              DialogService
) {
super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schüler';
    this.ViewProperties.DataSourceID    = 'schoolDOStammAntrag';
    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.HttpServiceComponent.dataGet('adrDoKatPersAnrede')
    .subscribe(data => {
    this.LookupAnrede =  data['Dataset'].Data;

    });

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {
alert('Studen_Name');
        //Schüler

    this.editForm.addControl('Azubi_ID_Anrede',     new FormControl(null));
    this.editForm.addControl('Azubi_Vorname',       new FormControl(null));
    this.editForm.addControl('Azubi_Nachname',      new FormControl(null));
    this.editForm.addControl('Azubi_Geb_Datum',     new FormControl(null));

    this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrag', '&Antr_ID='+this.dataModel.PKValue)
      .subscribe(
        data => {

          this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);

          // this.DataFilter.ItemsClear();

          this.editForm.controls['Azubi_ID_Anrede'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.LookupAnrede, this.DOMain.Dataset.Data[0]?.Azubi_ID_Anrede));
          this.editForm.controls['Azubi_Vorname'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_Vorname);
          this.editForm.controls['Azubi_Nachname'].setValue(this.DOMain.Dataset.Data[0]?.Azubi_Nachname);
          this.editForm.controls['Azubi_Geb_Datum'].setValue(vsCommon.ISO8061ToDateStr(this.DOMain.Dataset.Data[0]?.Azubi_Geb_Datum));

        });


  } // ngOnInit


  filterAnrede(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.LookupAnrede.length; i++) {
      let anrede = this.LookupAnrede[i];
      if (anrede.Anrede_Name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(anrede);
      }
    }
    this.filteredAnrede = filtered;

  }


  public actionStepPrev(): void {

    this.router.navigate(['main']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK) {

      // Validierung Schülerdaten

      this.editForm.get('Azubi_ID_Anrede').setValidators(Validators.required);
      this.editForm.get('Azubi_ID_Anrede').updateValueAndValidity();

      this.editForm.get('Azubi_Vorname').setValidators(Validators.required);
      this.editForm.get('Azubi_Vorname').updateValueAndValidity();

      this.editForm.get('Azubi_Nachname').setValidators(Validators.required);
      this.editForm.get('Azubi_Nachname').updateValueAndValidity();

      this.editForm.get('Azubi_Geb_Datum').setValidators(Validators.required);
      this.editForm.get('Azubi_Geb_Datum').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      // this.dataSave();
      this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Änderung: Schüler Name'});
      this.dataModel.workInProgressMessage = true;
      this.router.navigate(['main']);
    }

  } // actionStepNext()



  public dataSave() :void{

    //Schüler
    this.DOMain.Dataset.Data[0].Azubi_ID_Anrede              = this.editForm.controls['Azubi_ID_Anrede'].value;
    this.DOMain.Dataset.Data[0].Azubi_Nachname               = this.editForm.controls['Azubi_Nachname'].value;
    this.DOMain.Dataset.Data[0].Azubi_Vorname                = this.editForm.controls['Azubi_Vorname'].value;
    this.DOMain.Dataset.Data[0].Azubi_Geb_Datum              = vsCommon.DateStrToISO8061(this.editForm.controls['Azubi_Geb_Datum'].value);

    this.HttpServiceComponent.dataUpdate(
      // this.ViewProperties.DataSourceID
        'schoolDOStammAntrag'
      , this.ViewProperties.DOMain
      , this.dataModel.PKValue)
      .subscribe(
        dataPost => {

          //   //Schüler
          //   // this.DOMain.Dataset.Data[0].Azubi_ID_Anrede              = this.editForm.controls['Azubi_ID_Anrede'].value;
          //   this.DOMain.Dataset.Data[0].Azubi_Nachname               = this.editForm.controls['Azubi_Nachname'].value;
          //   this.DOMain.Dataset.Data[0].Azubi_Vorname                = this.editForm.controls['Azubi_Vorname'].value;
          //   this.DOMain.Dataset.Data[0].Azubi_Geb_Datum              = vsCommon.DateStrToISO8061(this.editForm.controls['Azubi_Geb_Datum'].value);

        }
      );

  }

  showDlgDoc(a_Value: any) {

    let x    = this.DocType.map(el => el.ID)
    let i    = x.indexOf(a_Value);

    this.ref = this.dialogService.open(TdlgDoc, {
        data:         a_Value,
        header:       this.DocType[i].Caption,
        width:        '50%',
//        contentStyle: {"max-height": "500px", "overflow": "auto"},
        baseZIndex:   10000
    });

    this.ref.onClose.subscribe((fileData: any) =>{

    });
}


} // schoolViewAntragEdit_Student


