//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputNumber
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-07-06  MH   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';



//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputNumber',
  templateUrl:  './vsInputNumber.component.html',
  styleUrls:   ['./vsInputNumber.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputNumber extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input()    KennCurrency:           boolean           = false; // Währungsformat oder einfache Zahl
  @Input()    KennGrouping:           boolean           = false; // Tausender-Punkt-Formattierung

  @Input()    KennFractionDigits:     boolean           = false; // Nachkommastellen, z.B. für Kilometerangaben
  @Input()    NoLabel:                boolean           = false;
  @Input()    inputId:                string            = 'input-'+this.FrmCtlName;

  @Output() onBlur: EventEmitter<string> = new EventEmitter();




  // ... usw ...



  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor




  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.




  handleBlurEvent(event): void {
    this.onBlur.emit(event);
  }
  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args



} // TvsInputNumber


