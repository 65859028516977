
  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

        <vsLayoutGroup class="col-12">
          <div #explanation_header class="vsFormTextDiv">
            <b>{{'explanation.student_data_heading'| translate}}</b>
          </div>
          <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
            {{'explanation.student_data_text'| translate}}
          </div>
        </vsLayoutGroup>

        <vsLayoutGroup class="col-xl-6 col-lg-12 col-md-12 vsMb-2" Orientation="horizontal" MediumGutterWidth=true>

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.student.heading'| translate}}" Orientation="horizontal">

            <vsLayoutGroup class="col-lg-12 col-md-12" Orientation="vertical">
              <vsInputCheckBox label="{{'application.student.student_of_age'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="360px" FrmCtlName="KennAzubiGesVertr" (change)="doSetGesetzVertret()" class="vsInHeader"></vsInputCheckBox>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-6 col-md-12" Orientation="vertical">
              <vsLayoutGroup class="col-12">
                <vsInputAutoComplete FrmCtlName="Azubi_ID_Anrede" label="{{'application.student.form_of_address'| translate}}" labelWidth="110px"
                  placeholder="{{'application.input_required'| translate}}" [suggestions]="lookupAnrede" FieldName="Anrede_Name" MinLength=1
                  Dropdown=true ></vsInputAutoComplete>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-12">
                <vsInputText FrmCtlName="Azubi_Vorname" label="{{'application.student.first_name'| translate}}" placeholder="{{'application.input_required'| translate}}"
                  labelWidth="110px" ></vsInputText>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-12">
                <vsInputText FrmCtlName="Azubi_Nachname" label="{{'application.student.last_name'| translate}}" placeholder="{{'application.input_required'| translate}}"
                  labelWidth="110px" ></vsInputText>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-12">
                <vsInputDateTime FrmCtlName="Azubi_Geb_Datum" label="{{'application.student.date_birth'| translate}}" labelWidth="110px"
                  placeholder="{{'application.input_required'| translate}}" ></vsInputDateTime>
              </vsLayoutGroup>

            </vsLayoutGroup>

           
            <vsLayoutGroup class="col-lg-6 col-md-12" Orientation="vertical">
  
              <div *ngIf="dataModel.KennUserImage" class="row">
                <label for="uploadPhoto" class="vsInputLabel" style="width: 110px;">{{'general.contact.image'| translate}}:</label>
                
                <div class="col vsMb-2">
                
                  <button *ngIf="!cropped" pButton type="button" id="uploadPhoto" label="{{'application.student.button_img_upload'| translate}}" class="vsUploadContactImage"  
                          (click)="showDlgDoc(DocType[0].ID)" [attr.aria-label]="'application.student.button_img_upload'| translate">
                    <div style="margin-bottom: 0.425rem;">
                      <img alt="" src="/assets/img/Icons/vs-plus-circle-outline-grey.svg" />
                    </div>
                  </button>

                  <div *ngIf="cropped" id="image" style="max-width:110px; max-height:130px;" (click)="showDlgDoc(DocType[0].ID)"></div>
                  <div id="image"></div>               
                </div>
              </div>

            </vsLayoutGroup>

            <ng-template [ngIf]="KennStrassenVerz">
              <vsLayoutGroup class="col-12" Orientation="horizontal">

                <vsInputAutoComplete FrmCtlName="Azubi_StrassenVerz" label="{{'general.address.address'| translate}}" labelWidth="110px" [suggestions]="lookupAdresse" FieldName="xAnzeige" MinLength=1
                Dropdown=true (onSelect)="doSelectAdresse($event)"></vsInputAutoComplete>

                <vsLayoutGroup class="col-lg-12 col-md-12" Orientation="vertical">
                  <vsInputCheckBox label="{{'general.address.address_not_existant'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="380px" FrmCtlName="KennAdrNotFoundAzubi" (change)="doActivateManualInput()"></vsInputCheckBox>
                </vsLayoutGroup>

              </vsLayoutGroup>
            </ng-template>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12" [class.hidden]="KennStrassenVerz && !KennAdrNotFoundAzubi">
                <vsInputText FrmCtlName="Azubi_Strasse" label="{{'general.address.address_street'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Azubi_HausNr" label="{{'general.address.address_house_number'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal" [class.hidden]="KennStrassenVerz && !KennAdrNotFoundAzubi">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Azubi_PLZ" label="{{'general.address.address_zip_code'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Azubi_Ort" label="{{'general.address.address_city'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12">
              <vsInputText FrmCtlName="Azubi_Adr_Zusatz" label="{{'general.address.address_addition'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12" [class.hidden]="KennStrassenVerz && !KennAdrNotFoundAzubi">

              <ng-template [ngIf]="KennOrtsteilRequired" [ngIfElse]="elseBlockOrtsteil">
                <vsInputText FrmCtlName="Azubi_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputText>
              </ng-template>

              <ng-template #elseBlockOrtsteil>
                <vsInputText FrmCtlName="Azubi_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="110px"></vsInputText>
              </ng-template>
              
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsInputText FrmCtlName="Azubi_eMail" label="{{'general.contact.email'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Azubi_Phone" label="{{'general.contact.phone'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="Azubi_Mobile" label="{{'general.contact.mobile'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

          </vsLayoutGroup>


        </vsLayoutGroup>

        <vsLayoutGroup *ngIf="KennAzubiGesVertr===false" class="col-xl-6 col-lg-12 col-md-12" Orientation="horizontal">
          <!--Gesetzliche Vertretung-->

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.legal_rep.heading'| translate}}" pTooltip="Die Daten des gessetzlichen Vertreters werden aus ihren Profildaten geladen." tooltipPosition="top" tooltipEvent="hover" tooltipStyleClass="vsTooltip">

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputAutoComplete FrmCtlName="AntrStell_ID_Anrede" label="{{'application.legal_rep.form_of_address'| translate}}" labelWidth="110px" [suggestions]="lookupAnrede" FieldName="Anrede_Name" MinLength=1 Dropdown=true></vsInputAutoComplete>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputAutoComplete FrmCtlName="AntrStell_ID_Titel" label="{{'application.legal_rep.title'| translate}}" labelWidth="110px" [suggestions]="lookupTitel" FieldName="Titel_Kuerzel" MinLength=1 Dropdown=true></vsInputAutoComplete>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_Vorname" label="{{'application.legal_rep.first_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_Nachname" label="{{'application.legal_rep.last_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputDateTime FrmCtlName="AntrStell_Geb_Datum" label="{{'application.legal_rep.date_birth'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" >
                </vsInputDateTime>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-0">
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_Strasse" label="{{'general.address.address_street'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_HausNr" label="{{'general.address.address_house_number'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_PLZ" label="{{'general.address.address_zip_code'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" [attr.aria-label]="'address_zip_code_full' | translate"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_Ort" label="{{'general.address.address_city'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12">
              <vsInputText FrmCtlName="AntrStell_Adr_Zusatz" label="{{'general.address.address_addition'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-12 col-md-12">
              <vsInputText FrmCtlName="AntrStell_Ortsteil" label="{{'general.address.address_city_district'| translate}}" labelWidth="110px"></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsInputText FrmCtlName="AntrStell_eMail" label="{{'general.contact.email'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" ></vsInputText>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_Phone" label="{{'general.contact.phone'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputText FrmCtlName="AntrStell_Mobile" label="{{'general.contact.mobile'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>

      </vsLayoutGroup>


    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
    <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button>
  </div>

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'dialog.error_age_heading'| translate}}" [(visible)]="displayDialogAgeError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_age_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogAgeError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- <pre>{{ this.editForm.value | json}}</pre> -->
