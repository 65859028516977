//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Nachricht verfassen (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-09-16  MP   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService
       , DynamicDialogRef
       , DynamicDialogConfig }              from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType
        ,TvsHTTPMethod
        ,TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import * as vsViewData                  from '@vsLib/View/components/vsViewData/vsViewData.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { TvsDataFilter } from '@vsLib/vsDataFilter';
import { TvsDataObject } from '@vsLib/vsDataObject';

import { TdlgDoc, TFileData }               from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}

@Component({
  selector:       'schoolViewNachrichtEdit',
  templateUrl:  './schoolViewNachrichtEdit.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig]
})
// export class TschoolViewNachrichtEdit extends vsViewData.TvsViewData implements OnInit {

  export class TschoolViewNachrichtEdit implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public      KennEdit:                     boolean;

  public      vorfID:                       string;

  public      vorgID:                       string;

  public      lookupVorgang:                any;

  public      displayDialogError:           boolean   = false;

  public      DOMain:                       TvsDataObject = new TvsDataObject();

  public      Files:                        TFileData[] = [];

  public      translationDialogItems:       TranslationDialogItemsType[]                          = [];

  public      refOpen:                      DynamicDialogRef;

  public      antrDatum:                    string;

  //Falls Files vorhanden sind
  public get KennDocExist(): boolean {
      return this.Files.length > 0
    }

  //Prüfung muss über die vorhandene oder nicht vorhandene vorgID erfolgen, da man keine Informationen über Edit/Insert von einem vsDataEdit mitbekommt
  public get KennInsert(): boolean {
      return !vsCommon.vsStringHasValue(this.vorgID)
  }

  public DocType:       any[] =
    [
      {Caption: 'application.documents.button_load_document',      ID: 'Doc'}
    // , {Caption: 'application.documents.button_load_user_image',    ID: 'Pic'}

    ]


  //====== Constructor

  constructor(protected router:                     Router
            , protected globalResources:            vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected messageService:             MessageService
            , protected componentFactoryResolver:   ComponentFactoryResolver
            , public    dialogService:              DialogService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , public    translate:                  TranslateService

) {


  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {


    this.editForm.addControl('Title', new FormControl(null));
    this.editForm.addControl('MessageText', new FormControl(null));
    this.editForm.addControl('radioButton',         new FormControl('S'));

    this.KennEdit = this.dataModel.KennMessageEdit;

    this.vorfID = this.dataModel.antragCRMTicketID;
    this.vorgID = this.dataModel.vorgID;

    this.antrDatum = vsCommon.ISO8061ToDateStr(this.dataModel.antrag.Antr_Datum_Antrag);

    //Neue Nachricht, deshalb keine vorgID vorhanden
    if (!vsCommon.vsStringHasValue(this.vorgID)) {
      this.HttpServiceComponent.dataGet('crmDODataVorg'
        , false
        , []
        , null
        , TvsGetType.gtNewRecord)
      // this.HttpServiceComponent.HttpServiceGetRequest('school/data/vorg', '')  // New Record
      .subscribe(
          data => {
             Object.assign(this.DOMain, data);
            this.DOMain.Dataset.Data[0].Vorg_ID_Vorf                     = this.vorfID;

          });
      }
     let DataFilter: TvsDataFilter = new TvsDataFilter();

    //Vorhandene Nachricht, deshalb vorgID vorhanden
    if (vsCommon.vsStringHasValue(this.vorgID) && vsCommon.vsStringIsNotNull(this.vorgID)) {

        // console.log('edit Mode');
        DataFilter.ItemsClear();
        // let item = DataFilter.ItemAdd('Vorg_ID');
        // item.CriteriaAdd('Vorg_ID', this.vorgID);

//GDS-Aufruf 14 [OK] crmDoDataVorg
        // console.log('GDS_Vorg Vorg_ID');
        // this.HttpServiceComponent.dataGet('crmDODataVorg', false, [], DataFilter, TvsGetType.gtNormal, true)
        this.HttpServiceComponent.HttpServiceGetRequest('school/data/vorg', '&Vorg_ID=' + this.vorgID)
        .subscribe(data => {
          // console.log('GDS_Vorg 14 [OK] Vorg_ID');

          Object.assign(this.DOMain, data);

          this.editForm.controls['radioButton'].disable();

          this.editForm.controls['Title'].setValue(data['Dataset'].Data[0].Vorg_Name);
          this.editForm.controls['MessageText'].setValue(data['Dataset'].Data[0].Vorg_Beschreib);

          this.editForm.controls['Title'].disable();
          this.editForm.controls['MessageText'].disable();

          //Falls VorgangsArt = Dokument: Files nachladen
          if (this.DOMain.Dataset.Data[0].Vorg_ID_Art == vsCommon.TcrmVorgangArt.vaDokumentSonstiges) {
            this.HttpServiceComponent.HttpServiceGetRequest('crmDataDocumentGet', '&crmEventID=' + this.DOMain.Dataset.Data[0].PKValue)
              .subscribe(response => {
                this.Files = [];
                for (let i = 0; i < ((response as unknown) as vsCommon.TVorg).document.documents.length; i++) {

                  let FileData: TFileData = new TFileData();
                  FileData.UploadFileName         = ((response as unknown) as vsCommon.TVorg).document.documents[i].docOrigFileName;
                  FileData.UploadFileDataBase64   = ((response as unknown) as vsCommon.TVorg).document.documents[i].docData;
                  this.Files.push(FileData);
                }
                // this.editForm.controls['Vorg_Files'].setValue(this.Files);

                // this.ViewProperties.SubjDisplay.next(null);
                // this.ViewProperties.SubjDisplay.complete();
              });
          }

        },
        error => {
        console.log('Error Get-Request: ', error);
        alert(  'Bei dem Get-Request ist ein Fehler aufgetreten:\n'
        + error.error.Error.ErrCode + '\n'
        + error.error.Error.ErrMsg + '\n\n'
        + error.message );
        this.DOMain.IsRefreshing = false;

        this.messageService.add({severity: 'danger',  summary: 'Info',  detail: 'Es ist ein Fehler aufgetreten'});
        },

        () => {
          console.log('Get Request completed');

          // console.log('this.DOMain.Dataset.Data[0]', this.DOMain.Dataset.Data[0]);

          if (!this.DOMain.Dataset.Data[0].Vorg_Kenn_Komm_Gelesen && !this.DOMain.Dataset.Data[0].Vorg_Kenn_Eingehend) {
            this.saveKennGelesen();
          }

          }

        )
      }

  } // ngOnInit


  ngAfterViewInit(): void {


  } //ngAfterViewInit


  //Wenn Dokument vorhanden ist, laden des Dokumentes auf Button-Click
  docDownload(a_file: TFileData){
    let sExt = vsCommon.vsStringFileExtractExt(a_file.UploadFileName);
    if (vsCommon.vsStringSameText(sExt,'pdf')) {
      var a = document.createElement("a");
      a.href = "data:application/octet-stream;base64," + a_file.UploadFileDataBase64;
    }
    else {
      var a = document.createElement("a");
      a.href = "data:image/" + sExt + ";base64," + a_file.UploadFileDataBase64;
    }
    a.download = a_file.UploadFileName;
    a.click();
  }

  //Upload-Dialog öffnen
  showDlgDoc(a_Value: any) {

    let x    = this.DocType.map(el => el.ID)
    let i    = x.indexOf(a_Value);


    this.translationDialogItems = [
      { data:       a_Value,
        header:     'application.documents.button_load_document',
        styleClass: 'vsDialog vsFullscreenDialog vsUploadDialog vsElternDialog',
        baseZIndex: 10000}
    ]

    this.translationDialogItems.map((item) => {
        this.translate.get(item.header).subscribe((res)=>{

            this.refOpen = this.dialogService.open(TdlgDoc, {
              data:         item.data,
              header:       res,
              styleClass:   item.styleClass,
              baseZIndex:   item.baseZIndex

            });
          });
        });


    this.refOpen.onClose.subscribe((fileData: any) =>{

      if (vsCommon.vsVarAssigend(fileData)) {
        this.Files.push(fileData);
      }

    });
 }

 doOnRadioButtonSelect() {

  if(this.editForm.controls['radioButton'].value === "S") {
    this.editForm.controls['Title'].setValue("");
    this.editForm.controls['Title'].enable();
  }

  if(this.editForm.controls['radioButton'].value === "A") {
    this.editForm.controls['Title'].setValue("Änderungsanfrage");
    this.editForm.controls['Title'].disable();
  }

  if(this.editForm.controls['radioButton'].value === "K") {
    this.editForm.controls['Title'].setValue("Kündigung");
    this.editForm.controls['Title'].disable();
  }

}

 docDelete(a_file: TFileData){
  let search = this.Files.map(el => el.UploadFileName);
  let i      = search.indexOf(a_file.UploadFileName);
  this.Files.splice(i, 1);
}


  public actionSend(): void {
    this.dataSave();
  }

  public actionCancel(): void {
    this.router.navigate(['/messages']);
    this.dataModel.KennMessageEdit = false;

    if (vsCommon.vsStringHasValue(this.vorgID) && vsCommon.vsStringIsNotNull(this.vorgID)) {
      //Resetten der vorgID
      this.vorgID = null;
      this.dataModel.vorgID = null;
    }
  }

  public actionBack(): void {
    this.dataModel.KennMessageEdit = false;

    if (vsCommon.vsStringHasValue(this.vorgID) && vsCommon.vsStringIsNotNull(this.vorgID)) {
      //Resetten der vorgID
      this.vorgID = null;
      this.dataModel.vorgID = null;
    }

    //Routing entweder zur Nachrichtenliste des Antrages oder zur allgemeinen Nachrichtenliste.
    if (this.dataModel.KennMessageList) {
      this.dataModel.KennMessageList = false;

      this.router.navigate(['/mymessagelist']);
    }

    else {
      this.router.navigate(['/messages']);
    }

  }

  //Zur speicherung des 'Gelesen' Status im CRM-Vorgang
  public saveKennGelesen() {

    this.DOMain.Dataset.Data[0].Vorg_Kenn_Komm_Gelesen                  = true;

    this.HttpServiceComponent.dataUpdate('crmDODataVorg',
    this.DOMain,
    this.vorgID)
    .subscribe(
      dataPost => {

      },
        error => {
        console.log('Error Put-Request: ', error);
        alert(  'Bei dem Put-Request ist ein Fehler aufgetreten:\n'
        + error.error.Error.ErrCode + '\n'
        + error.error.Error.ErrMsg + '\n\n'
        + error.message );
        this.DOMain.IsRefreshing = false;

        this.messageService.add({severity: 'danger',  summary: 'Info',  detail: 'Es ist ein Fehler aufgetreten'});
        },

        () => {
          console.log('Put Request completed');

          }
      );

  }



  public dataSave() :void{

    //Validierung einbauen, bOk nur true wenn Validierung OK
    let bOK = true;

    var dtStr: string = vsCommon.RetrieveDateStrNow();
    var tmStr: string = vsCommon.RetrieveTimeStrNow();

    if (bOK) {

      //Falls Anhänge vorhanden sind
      if (this.Files.length > 0) {

        let vorgMain:  vsCommon.TVorgMain  =  new vsCommon.TVorgMain();
        vorgMain.crmTicketID               = this.vorfID;

        //Vorf_ID_Kontakt muss später noch hinzugefügt werden
        // vorgMain.crmContactID           = this.ParamList.get('Vorf_ID_Kontakt');

        vorgMain.crmActivityID             = null;
        vorgMain.crmActivityType           = vsCommon.TcrmVorgangArt.vaDokumentSonstiges;
        vorgMain.crmActivityDate           = vsCommon.DateStrToISO8061(dtStr);
        vorgMain.crmActivityTime           = vsCommon.TimeStrToISO8061(tmStr);
        vorgMain.crmActivityName           = this.editForm.controls["Title"].value;
        vorgMain.crmActivityDesc           = this.editForm.controls["MessageText"].value;
        vorgMain.crmActivityMultiDoc       = false;
        vorgMain.crmActivityIsIncoming     = true;

        let vDoc: vsCommon.TVorg = new vsCommon.TVorg(vorgMain);
        for (let i=0; i < this.Files.length; i++) {
          vDoc.DocAdd(this.Files[i].UploadFileName,
            null,
            vsCommon.vsStringFileExtractExt(this.Files[i].UploadFileName),
            this.Files[i].UploadFileDataBase64);
        }
        //------ HTTP Request

        this.HttpServiceComponent.HttpServicePostRequest('crmDataDocumentAdd', '',JSON.stringify(vDoc))
          .subscribe(response => {
            // console.log('Put-Response:', response )
            // alert('Bild gespeichert: ' + (response as vorgDoc).document.crmActivityID);
            this.globalResources.ViewMain.msgShowToastSuccess('Der Vorgang wurde gespeichert');
            // this.actionEdit(((response as unknown) as vsCommon.TVorg).document.crmActivityID);
            // this.actionClose(null);
            this.router.navigate(['messages']);
          });

      }

      //Falls keine Anhänge vorhanden sind
      else {

      // this.DOMain.Dataset.Data[0].Vorg_ID_Kontakt             = this.dataModel.userID;
      // this.DOMain.Dataset.Data[0].Vorg_ID_Kontakt             = //TvsWebAccProfile.Kontakt_ID
      // this.DOMain.Dataset.Data[0].Vorg_ID_Kontakt             = 'E3DB624A83AA4E278A11428F71AB32F3';

      //Wird im insert-case eingesetzt, deshalb hier auskommentiert.
      // this.DOMain.Dataset.Data[0].Vorg_ID_Vorf                     = this.vorfID;


      this.DOMain.Dataset.Data[0].Vorg_Name                   = this.editForm.controls['Title'].value;
      this.DOMain.Dataset.Data[0].Vorg_Beschreib              = this.editForm.controls['MessageText'].value;

      this.DOMain.Dataset.Data[0].Vorg_Datum                  = vsCommon.DateStrToISO8061(dtStr);
      this.DOMain.Dataset.Data[0].Vorg_Uhrzeit                = vsCommon.TimeStrToISO8061(tmStr);


      if (this.Files.length > 0) {
        this.DOMain.Dataset.Data[0].Vorg_ID_Art               = 'DOK';
      }
      else {
        this.DOMain.Dataset.Data[0].Vorg_ID_Art               = 'KOMM';
        this.DOMain.Dataset.Data[0].Vorg_ID_Komm_Art          = 'WEB';
      }

      this.DOMain.Dataset.Data[0].Vorg_ID_Kat                 = 'SONST';
      this.DOMain.Dataset.Data[0].Vorf_ID_Art                 = 'SCHOOL_ANTR';
      this.DOMain.Dataset.Data[0].Vorg_Kenn_Manuell           = true;
      this.DOMain.Dataset.Data[0].Vorg_Kenn_Eingehend         = true;

            // console.log('DATA SAVE: ', this.DOMain.Dataset.Data[0]);

            this.HttpServiceComponent.dataUpdate('crmDODataVorg',
            this.DOMain,
            null)
            .subscribe(
              dataPost => {
                // Wenn Anhänge vorhanden sind, muss ein Vorgang unterhalb des neuen Vorfalls gespeichert werden
              },
                error => {
                console.log('Error Put-Request: ', error);
                // alert(  'Bei dem Put-Request ist ein Fehler aufgetreten:\n'
                // + error.error.Error.ErrCode + '\n'
                // + error.error.Error.ErrMsg + '\n\n'
                // + error.message );
                this.DOMain.IsRefreshing = false;

                this.messageService.add({severity: 'danger',  summary: 'Info',  detail: 'Es ist ein Fehler aufgetreten'});
                },

                () => {
                  console.log('Put Request completed');

                  this.dataModel.EmptyData();
                  this.dataModel.KennMessageSent = true;

                  if (vsCommon.vsStringHasValue(this.vorgID) && vsCommon.vsStringIsNotNull(this.vorgID)) {
                    //Resetten der vorgID
                    this.vorgID = null;
                    this.dataModel.vorgID = null;
                  }

                  this.router.navigate(['messages']);
                  }
              );

      }




    } // if bOK

  } // dataSave


} // schoolViewAntragEdit_Student


