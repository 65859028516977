//--------------------------------------------------------------------------------------------------
//  AMIS7core
//
//  Domain:   Elternportal
//  Title:    Nachrichtenliste
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-06-15  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit
        , ComponentFactoryResolver
        , ViewChild}                        from '@angular/core';

import { Router }                           from '@angular/router';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';

// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import { TvsDataObject }                    from '@vsLib/vsDataObject';
import { TvsViewData }                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsViewDataList                  from '@vsLib/View/components/vsViewDataList/vsViewDataList.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import { TvsTableComponent }                from '@vsLib/Components/vsTable/vsTable.component';
import * as vsTable                         from '@vsLib/Components/vsTable/vsTable.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';
import { TvsDataFilter } from '@vsLib/vsDataFilter';


type TranslationMessageItemsType = {
  severity: string;
  summary: string;
  detail: string;
}



@Component({
  selector:       'schoolViewMeineNachrichtenList',
  templateUrl:  './schoolViewMeineNachrichtenList.component.html',
  // styleUrls:   ['./schoolViewNachrichtenList.component.scss']
})
export class TschoolViewMeineNachrichtenList extends vsViewDataList.TvsViewDataList implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  public      filterVorfID:                   string;

  public      filterVorgID:                   string;

  public      MenuUser:                       MenuItem[];           // PrimeNG

  public      dataObject:                     TvsDataObject              = new TvsDataObject();

  public      translationMessageItems:        TranslationMessageItemsType[]                          = [];

  public      antrDatum:                      string;

  public      lookupCRM:                      any;

  public      answerDisabled:                 Boolean;

  public      secondDataSet:                  any[];

  @ViewChild('table') table: TvsTableComponent;


  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService     // [ngx-translate]
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected componentFactoryResolver:   ComponentFactoryResolver
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Anträge';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = "crmDODataVorg";
    this.ViewProperties.QueryFields     = [];
    this.ViewProperties.DOMain.KennFieldDefs = false;




  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    this.DataFilter.ItemsClear();
    //$REV MP 2023-04-21: Korrektur auf Azubi_ID_Gesetzl_Vertret
    // let item = this.DataFilter.ItemAdd('Filter Azubi_ID_Gesetzl_Vertret');
    // item.CriteriaAdd('Azubi_ID_Gesetzl_Vertret', this.globalResources.WebAccProfile.KontaktID);

    // let item2 = this.DataFilter.ItemAdd('Filter Visibility = Public');
    // item2.CriteriaAdd('Vorg_ID_Visibility', 'PUBLIC');

    this.initialiseTable('crmDODataVorgPortal', this.DataFilter);

    this.HintVisibleFilterRequired = false;
    this.FilterRequired            = false;

    this.translationMessageItems = [
      {severity:'success',  summary: 'Info', detail: 'messages.message_sent_confirmation'}
    ];



  } // ngOnInit

  ngAfterViewInit() {

    //Da das NachrichtenEdit nach Absenden wieder direkt zur NachrichtenListe weiterleitet soll die Toastmessage zur Bestätigung hier im File verschickt werden.
    if(this.dataModel.KennMessageSent)  {

      // ToDo: MP Messages übersetzen
      // this.messageService.add({severity: 'success',  summary: 'Info',  detail: 'Ihre Nachricht wurde verschickt'});

      this.translationMessageItems.map((item) => {
        //if-Abfrage falls noch andere Message-Items hinzukommen
        if (item.detail === 'messages.message_sent_confirmation') {
          this.translate.get(item.detail).subscribe((res)=>{
            this.messageService.add(
               {severity: item.severity,
                icon: item.summary,
                detail: res
              });
          });
        }
      });


      this.dataModel.KennMessageSent = false;
    }
  }

  public initialiseTable(DO: string, DataFilter: TvsDataFilter): void {
// GDS-Aufruf 12 [OK] crmDODataVorgPortal

    // this.HttpServiceComponent.dataGet(DO, false, [], DataFilter)
    // item.CriteriaAdd('Azubi_ID_Gesetzl_Vertret', this.globalResources.WebAccProfile.KontaktID);

    // let item2 = this.DataFilter.ItemAdd('Filter Visibility = Public');
    // item2.CriteriaAdd('Vorg_ID_Visibility', 'PUBLIC');
    this.HttpServiceComponent.HttpServiceGetRequest('school/data/vorgPortal', '&Azubi_ID_Gesetzl_Vertret='+this.globalResources.WebAccProfile.KontaktID +
                                                                              '&Vorg_ID_Visibility=PUBLIC')
    .subscribe(data => {
        this.dataObject.Dataset.Data =  data['Dataset'].Data;

        //Zweite Anfrage um auch Dokumente anzuzeigen, werden ansonsten durch Filterung nach Kommunikationsart ausgeschlossen.
        // this.DataFilter.ItemsClear();
        // let item = this.DataFilter.ItemAdd('Filter Azubi_ID_Gesetzl_Vertret');
        // item.CriteriaAdd('Azubi_ID_Gesetzl_Vertret', this.globalResources.WebAccProfile.KontaktID);
        // let item2 = this.DataFilter.ItemAdd('Filter Vorg_ID_Art = DOK');
        // item2.CriteriaAdd('Vorg_ID_Art', 'DOK');

        // this.HttpServiceComponent.dataGet('crmDODataVorg', false, [], this.DataFilter).subscribe((data: any) => {
        //   this.secondDataSet = data['Dataset'].Data;

        //   // Combine the first and second data sets
        // this.dataObject.Dataset.Data = [...this.dataObject.Dataset.Data, ...this.secondDataSet];

            this.table.DOMain.Dataset.Data = this.dataObject.Dataset.Data;

            this.table.dataSelected = this.dataObject.Dataset.Data[0];
            //Initiales füllen der Antragsdaten, falls ohne onRowSelect direkt zum NachrichtenEdit gegangen wird.
            //$REV MP 2023-04-21: Prüfung auf vorhandensein der Daten, bei leerer Tabelle führt es sonst zu Fehlern.
            if(vsCommon.vsVarAssigend(this.dataObject.Dataset.Data[0])) {
              this.dataModel.antrag = this.dataObject.Dataset.Data[0];
              this.dataModel.vorgID = this.dataObject.Dataset.Data[0].Vorg_ID;
              this.dataModel.antragCRMTicketID = this.dataObject.Dataset.Data[0].Vorg_ID_Vorf;
            }
            //Für das initiale Laden wenn kein Datensatz vorhanden ist
            if(!vsCommon.vsVarAssigend(this.dataObject.Dataset.Data[0])) {
              this.answerDisabled = false;
            }
            //Für das initiale Laden wenn ein Datensatz vorhanden ist
            if(vsCommon.vsVarAssigend(this.dataObject.Dataset.Data[0])) {

              if (this.dataObject.Dataset.Data[0].Vorg_Kenn_Eingehend === true) {
                this.answerDisabled = true;
              }
              if (this.dataObject.Dataset.Data[0].Vorg_Kenn_Eingehend === false) {
                this.answerDisabled = false;
              }

            }

          this.dataObject.Dataset.FieldDefs = [
              {Name: "Vorg_Kenn_Eingehend",     Type: "ftInOutEP",     Caption: "messages.columns.in-out",              Size: "160",    Sort: false,   Alignment: vsCommon.TvsAlignment.alCenter }
            , {Name: "Vorg_Kenn_Komm_Gelesen",  Type: "ftReadUnread",  Caption: "messages.columns.read",                Size: "100",    Sort: false,   Alignment: vsCommon.TvsAlignment.alCenter }
            , {Name: "Antr_Nr",                 Type: "ftString",      Caption: "messages.columns.application_number",  Size: "120",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            , {Name: "Antr_Art_Name",           Type: "ftString",      Caption: "messages.columns.application_kind",    Size: "140",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            , {Name: "Vorg_Datum",              Type: "ftDate",        Caption: "messages.columns.date",                Size: "100",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            // , {Name: "Vorg_Uhrzeit",            Type: "ftTime",        Caption: "messages.columns.time",       Size: "80",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            , {Name: "xDisplayArt",             Type: "ftString",      Caption: "messages.columns.type",                Size: "120",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            // , {Name: "Vorg_Kenn_Eingehend", Type: "ftBoolean",      Caption: "Eingehend?",    Size: "80",    Sort: false,   Alignment: vsCommon.TvsAlignment.alCenter }
            , {Name: "Vorg_Name",               Type: "ftString",      Caption: "messages.columns.title",               Size: "330",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            , {Name: "xAzubi_Name",             Type: "ftString",      Caption: "messages.columns.name",                Size: "220",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            // , {Name: "Azubi_Vorname",        Type: "ftString",      Caption: "Vorname",      Size: "300",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
          ];
        // });

      });
    }


  public doTableRowSelect(args: vsTable.TvsTableComponentEventArgs) {

    this.dataModel.antrag = args.Data;
    this.dataModel.antragCRMTicketID = args.Data.Vorg_ID_Vorf;

    this.dataModel.vorgID = args.Data.Vorg_ID;

    this.dataModel.antrag.xAzubi_Name = args.Data.Azubi_Nachname +', '+ args.Data.Azubi_Vorname;

    //$REV MP 2023-04-21: Fälle für das aktivieren und deaktivieren des Antworten-Buttons
    if (args.Data.Vorg_Kenn_Eingehend === false) {
      this.answerDisabled = false;
    }

    if (args.Data.Vorg_Kenn_Eingehend === true) {
      this.answerDisabled = true;
    }

    if (!vsCommon.vsVarAssigend(args.Data)) {
      this.answerDisabled = false;
    }

  } // doTableRowSelect


  public actionNewMessage(): void {

    this.dataModel.KennMessageEdit = false;
    //$REV MP 2023-02-20: Für das Routing zurück zu Meine Nachrichten.
    this.dataModel.KennMessageList = true;

    //$REV MP 2023-03-09: Leeren der vorgID, wurde sonst beim anklicken einer Zeile über doTableRowSelect gefüllt.
    this.dataModel.vorgID = null;

    this.router.navigate(['/messageedit']);
  }

  public actionAnswerMessage(): void {

    this.dataModel.KennMessageEdit = false;
    this.dataModel.KennMessageList = true;
    this.dataModel.vorgID = null;

    this.router.navigate(['/messageedit']);
  }


  public actionReadMessage(): void {

    //$REV MP 2023-02-20: Es kam zu Problemen wenn die Daten nur bei doTableRowSelect befüllt wurden.
    Object.assign(this.dataModel.antrag, this.table.dataSelected.Data);

    this.dataModel.vorgID = this.table.dataSelected.Vorg_ID
    this.dataModel.antragCRMTicketID = this.table.dataSelected.Antr_ID_CRM_Vorf;

    this.dataModel.KennMessageEdit = true;

    //$REV MP 2023-02-20: Für das Routing zurück zu Meine Nachrichten.
    this.dataModel.KennMessageList = true;

    this.router.navigate(['/messageedit']);
  }


  public actionStepPrev(): void {

    this.router.navigate(['/main']);

  }


  // protected RetrieveFilter(): string {
  //   return '';
  // } // RetrieveFilter


  //------------------------------------------------------------------------------------------------
  // Method:  mnuUserMyProfile_OnClick
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: User Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuUserMyProfile_OnClick(): void {
    alert('Mein Benutzerprofil');
  } // mnuUserMyProfile_OnClick


  public mnuUserConfig_OnClick(): void {
    alert('Einstellungen');
  } // mnuUserConfig_OnClick


  public mnuUserLogout_OnClick(): void {
    this.globalResources.UserProfile.Logout();
  } // mnuUserLogout_OnClick


  // $Rev TC 2021-07-30
  //
  // Das wird von dem vorübergehenden zusätzlichem Button "Abmelden" aufgerufen.
  // Dieser Button ist momentan erforderlich, da das User Menu aktuell nicht funktioniert (wegen a href)
  public actionLogout(): void {
    this.globalResources.UserProfile.Logout();
    this.router.navigateByUrl('/home');
  } // actionLogout


} // schoolViewAntragList


