<!-- 
<div class="vsInputNumber">
  <div class="row vsMb-2">
    <label *ngIf="NoLabel === false" class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="inputId">{{labelContent}}</label>

    <div *ngIf="ReadOnly=='true'" class="col">

      <div *ngIf="!KennCurrency">
        <p-inputNumber  class="vsForm"
                        inputStyleClass="vsFormControl {{InvalidCSSClass}}"
                        formControlName="{{FrmCtlName}}"
                        mode="decimal"
                        [minFractionDigits]="2" 
                        [maxFractionDigits]="2"
                        locale="de-DE"
                        [useGrouping]='KennGrouping'
                        inputId="input-{{FrmCtlName}}"
                        placeholder="{{placeholder}}"
                        (onBlur)="handleBlurEvent($event)"
                        readonly
        ></p-inputNumber>
      </div>
      <div *ngIf="KennCurrency">
        <p-inputNumber  class="vsForm"
                        inputStyleClass="vsFormControl {{InvalidCSSClass}}"
                        formControlName="{{FrmCtlName}}"
                        mode="currency"
                        locale="de-DE"
                        [useGrouping]='KennGrouping'
                        inputId="input-{{FrmCtlName}}"
                        placeholder="{{placeholder}}"
                        (onBlur)="handleBlurEvent($event)"
                        readonly
        ></p-inputNumber>
      </div>

    </div>
    <div *ngIf="ReadOnly=='false'" class="col">

      <div *ngIf="!KennCurrency">
        <p-inputNumber  class="vsForm"
                        inputStyleClass="vsFormControl {{InvalidCSSClass}}"
                        formControlName="{{FrmCtlName}}"
                        mode="decimal"
                        [minFractionDigits]="2" 
                        [maxFractionDigits]="2"
                        locale="de-DE"
                        [useGrouping]='KennGrouping'
                        inputId="input-{{FrmCtlName}}"
                        placeholder="{{placeholder}}"
                        (onBlur)="handleBlurEvent($event)"
        ></p-inputNumber>
      </div>
      <div *ngIf="KennCurrency">
        <p-inputNumber  class="vsForm"
                        inputStyleClass="vsFormControl {{InvalidCSSClass}}"
                        formControlName="{{FrmCtlName}}"
                        mode="currency" 
                        currency="EUR"
                        locale="de-DE"
                        [minFractionDigits]="2" 
                        [maxFractionDigits]="2"
                        [useGrouping]='KennGrouping'
                        inputId="input-{{FrmCtlName}}"
                        (onBlur)="handleBlurEvent($event)"
        ></p-inputNumber>
      </div>

    </div>

  </div>
</div> -->


<div class="vsInputNumber">
  <div class="row vsMb-2">
    <label *ngIf="!NoLabel" class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="inputId">{{labelContent}}</label>

    <div class="col">
      <div>
        <p-inputNumber class="vsForm"
          inputStyleClass="vsFormControl {{InvalidCSSClass}}"
          formControlName="{{FrmCtlName}}"
          [mode]="KennCurrency ? 'currency' : 'decimal'"
          [currency]="KennCurrency ? 'EUR' : undefined"
          [minFractionDigits]="KennFractionDigits ? 2 : undefined"
          [maxFractionDigits]="KennFractionDigits ? 2 : undefined"
          locale="de-DE"
          [useGrouping]="KennGrouping"
          inputId="input-{{FrmCtlName}}"
          placeholder="{{placeholder}}"
          (onBlur)="handleBlurEvent($event)"
        ></p-inputNumber>
      </div>
    </div>
  </div>
</div>