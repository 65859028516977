//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsTabControl
//  Notes:  vsTab Control to display a number of different tab pages.
//
//  The vsTabControl is a combination of one or more tab components and one card component.
//  The card component is used to display the content of the active tab page.
//
//
//  H I S T O R Y
//
//  2021-09-03  TC   Initial Version
//  2021-09-11  TC   MANTIS #0005096: itemActivate() löst nun die Angular Change Detection manuell aus
//--------------------------------------------------------------------------------------------------

import {  Component
  , OnInit
  , ComponentFactoryResolver
  , ViewContainerRef, TemplateRef
  , ViewChild
  , EventEmitter
  , Type
 }                                    from '@angular/core';

import { ChangeDetectorRef }                from '@angular/core';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsAppViewList                   from '@vsLib/vsAppViewList';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
// import * as vsViewWizard                    from '@vsLib/Components/vsViewWizard/vsViewWizard.component'



// TODO: eigene Komponente in eigener Datei
export class TvsCard {
// ...
} // TvsCard


// TODO: eigene Komponente in eigener Datei
export class TvsTabCard {
// ...
} // TvsTabCard



//
// TvsTabControlKind
//
// Enumeration
//
export enum TvsTabControlKind {
kindStandard       = "kindStandard"           // Standard Tabs
, kindMain           = "kindMain"               // Tabs in der Main View (Programmfunktionen)
, kindCenter         = "kindCenter"             // Tabs in einer Center View
, kindWizard         = "kindWizard"             // $Rev TC 2021-09-03: added
} // TvsTabControlKind



//
// TvsTabControl
//
// Komponente
//
@Component({
selector:     'vsTabControl',
templateUrl:  './vsTabControl.component.html',
styleUrls:   ['./vsTabControl.component.scss']
})
export class TvsTabControl {


//====== Properties (PRIVATE)

private   FKind:                  TvsTabControlKind             = null;

private   FAppViewList:           vsAppViewList.TvsAppViewList  = new vsAppViewList.TvsAppViewList();

// private   sDivId:                 string                        = null; 



//====== Properties (PROTECTED)

protected get AppViewList():      vsAppViewList.TvsAppViewList    {return this.FAppViewList};



//====== Properties (PUBLIC)

public    FCSSClassCtrl:          string                      = '';
public    FCSSClassItem:          string                      = '';

public    Tabs:                   TvsTabItem[]                = [];
public    TemplateTabContent:     TemplateRef<any>            = null;

// Events
public    EventOnTabClose:        EventEmitter<TvsTabItem>    = new EventEmitter();
public    EventOnTabSelect:       EventEmitter<TvsTabItem>    = new EventEmitter();

public    onActiveTabChanged:     EventEmitter<TvsTabItem>    = new EventEmitter(); // $rev AK 2021-09-22: added for wizard

public    testX:                  boolean                     = true;



//====== View Childs

@ViewChild('ViewContainer',  {read: ViewContainerRef, static: false})   ViewContainer:    ViewContainerRef;



//====== Constructor

constructor(protected componentFactoryResolver:   ComponentFactoryResolver,
        private   _cdRef:                     ChangeDetectorRef)
{
this.Kind   = TvsTabControlKind.kindStandard;

// [LABOR] nur zum Testen ---------- BEG ----------
// let item:           TvsTabItem              = null;

// this.itemAdd('Tab A');
// this.itemAdd('Tab B');
// this.itemAdd('Tab C');
// this.itemAdd('Tab D');

// item = this.Tabs[0];
// this.itemMoveToPos(item, 4);
// [LABOR] nur zum Testen ---------- END ----------

} // constructor



//====== Property-Zugriffsmethoden


// CSSClassCtrl
public    get CSSClassCtrl(): string {
return  this.FCSSClassCtrl;
}

public    get CSSClassItem(): string {
return  this.FCSSClassItem;
}

// public    get CSSClassItemView(): string {      
//   return  this.FCSSClassItemView;
// }    // $rev AK 2021-09-17: added for Wizard (Div-Switch)

public getCSSClassItem(a_tab: TvsTabItem): string {
// return this.CSSClassItem + (a_tab.Active ? (this.Kind == TvsTabControlKind.kindMain ? ' vsTabItemMainActive' : ' vsTabItemStandardActive') : '');

let sResult:    string          = '';
// let sResultV:   string          = '';

switch (this.FKind) {

case TvsTabControlKind.kindStandard:
  sResult   = this.CSSClassItem + (a_tab.Active ? ' vsTabItemStandardActive' : '');
break;

case TvsTabControlKind.kindMain:
  sResult   = this.CSSClassItem + (a_tab.Active ? ' vsTabItemMainActive' : '');
break;

case TvsTabControlKind.kindCenter:
  sResult   = this.CSSClassItem + (a_tab.Active ? ' vsTabItemStandardActive' : '');
break;

case TvsTabControlKind.kindWizard:
  switch(true)  {   //$rev AK 2021-09-16: added

    case a_tab.Visited && !a_tab.Active:
      sResult   = this.CSSClassItem + ' wizLinkVisited';
    break;

    case a_tab.Active:
      sResult   = this.CSSClassItem + ' wizLinkActive';
      // sResultV  = this.CSSClassItemView + ' wizViewActive'; // $rev AK 2021-09-17: added for Wizard (Div-Switch)
    break;

    default:
      sResult   = this.CSSClassItem + '';
      // sResultV  = this.CSSClassItemView + ''; // $rev AK 2021-09-17: added for Wizard (Div-Switch)
  }
break;

default:
  sResult   = this.CSSClassItem + (a_tab.Active ? ' vsTabItemStandardActive' : '');

} // switch


return sResult;
//return sResultV; // $rev AK 2021-09-17: added for Wizard (Div-Switch)

} // getCSSClassItem


// ItemCount
public    get ItemCount(): number {
return  this.Tabs.length;
}


// Kind
public    get Kind(): TvsTabControlKind {
return  this.FKind;
}
public    set Kind(a_vValue: TvsTabControlKind) {
this.FKind  = a_vValue;
this.applyKind();
// this.TabControl.itemsSortByPosIdx();
}


// ActiveTab
public  get ActiveTab(): TvsTabItem {
let item:             TvsTabItem            = null;

for(let i = 0; ((i < this.Tabs.length) && !item); i++) {
if (this.Tabs[i].Active) {item = this.Tabs[i]}
} // for

return item;

} // get ActiveTab


// ViewByTab
public getViewByTab(a_tab: TvsTabItem): vsView.TvsView {
if (a_tab) {
return this.AppViewList.itemFindByTabItem(a_tab).View;
} else {
return null;
}
} // getViewByTab



//====== Methods (PROTECTED)


//------------------------------------------------------------------------------------------------
// Method:  applyKind
//
// Args:    ./.
//
// Result:  void
//
// Notes:   Anwenden der Property Kind
//
//          Hier werden automatisch verschiedene Eigenschaften gesetzt in Abhängigkeit von der
//          Property Kind.
//------------------------------------------------------------------------------------------------

protected applyKind(): void {

switch (this.FKind) {

case TvsTabControlKind.kindStandard:
  this.FCSSClassCtrl    = 'vsTabControlStandard';
  this.FCSSClassItem    = 'vsTabItemStandard';
  break;

case TvsTabControlKind.kindMain:
  this.FCSSClassCtrl    = 'vsTabControlMain';
  this.FCSSClassItem    = 'vsTabItemMain';
  break;

  // $rev AK 2021-09-13: kindWizard noch anzupassen / erweitern
  case TvsTabControlKind.kindWizard:
  this.FCSSClassCtrl    = 'wizContent';
  this.FCSSClassItem    = 'wizLink';
  break;

default:
  console.log('ERROR: Unsupported value of property "Kind"');
} // switch

} // applyKind


//------------------------------------------------------------------------------------------------
// Method:  itemsSortByPosIdx
//
// Args:    ./.
//
// Result:  void
//
// Notes:   Sortiert die Items nach ihrem Positionsindex
//------------------------------------------------------------------------------------------------

protected itemsSortByPosIdx(): void {

this.Tabs.sort((a, b) => a.PosIdx - b.PosIdx);

// PosIdx anpassen an tatsächlichen Array-Index
for(let i = 0; (i < this.Tabs.length); i++) {
this.Tabs[i].PosIdx = i;
}

} // itemsSortByPosIdx



//====== Methods (PUBLIC)


//------------------------------------------------------------------------------------------------
// Method:  itemAdd
//
// Args:    a_sCaption                    Beschriftung
//          a_tmplref                     TemplateRef für den Content der Tab Page
//
// Result:  Neu erzeugtes TvsTabItem-Item
//
// Notes:   Fügt ein neues TabItem der Liste hinzu
//------------------------------------------------------------------------------------------------

public itemAdd(a_sCaption: string, a_tmplref: TemplateRef<any>): TvsTabItem {

let bOK:            boolean                         = true;
let item:           TvsTabItem                      = new TvsTabItem(this, a_sCaption, this.Tabs.length);

// this.Tabs.forEach((item) => {item.Active = false});

 //------ Validierungen $REV MP 2022-04-05: führt dazu, dass die Main-Tabs nicht mehr dargestellt werden. itemAdd wird beim Aufruf einer ListView wohl einmal aufgerufen.

//  if (bOK) {
//   bOK = (a_tmplref != null);
//   if (!bOK) {
//     //... Exception ...
//   }
// } // if bOK

//------ Content einblenden

if (bOK) {

  item                         = new TvsTabItem(this, a_sCaption, this.Tabs.length);
  item.TmplRef                 = a_tmplref;
  // $REV MP 2022-04-05: Auskommentiert, da die Angaben fälschlicherweise auch auf die MainTabs angewendet werden.
  // item.Closeable               = false;
  // item.Moveable                = false;
  // item.Pinable                 = false;
  this.Tabs.push(item);

} // if bOK

return item;

} // itemAdd


//------------------------------------------------------------------------------------------------
// Method:  itemAddView
//
// Args:    a_classView                   Klasse der zu erzeugenden View
//
// Result:  Neu erzeugtes TvsTabItem-Item
//
// Notes:   Erzuegt eine neue View der angegebenen Klasse und fügt für diese View ein neues
//          TabItem der Liste hinzu.
//------------------------------------------------------------------------------------------------

public itemAddView(a_classView: Type<vsView.TvsView>, a_sItemCaption: string = ''): TvsTabItem {

let bOK:            boolean                         = true;
let tabItem:        TvsTabItem                      = null;
let appViewItem:    vsAppViewList.TvsAppViewItem    = null;


//
// == PROBLEM
//
// # Wenn der erste Tab hinzugefügt wird, ist ViewContainer noch undefinedm da die Tab Page
//   aufgrund des *ngFor erst dann gerendert wird, wenn Tabs vorhanden sind.
//
//
// == LÖSUNG (hoffentlich)
//
// # itemAddView() erzeugt nur das Tab Item, aber noch nicht die View.
//
// # Wenn ein Tab aktiviert wird, führt dies dazu, dass die View erzeugt wird, falls noch
//   nicht vorhanden.
//
// # Dies führt auch zu besserer Performance, wenn die View erst dann erzeugt wird (und damit
//   vermutlich auch ein Refresh erfolgt), wenn der betreffende Tab aktiviert wird.
//
// # Anmerkung:
//   Hierfür wird natürlich eine lokale AppViewList benötigt.
//


//------ Validierungen

if (bOK) {
bOK = (a_classView != null);
if (!bOK) {
  //... Exception ...
}
} // if bOK


//------ View erzeugen

if (bOK) {


// console.log('TvsTabContol.itemAddView(): ---------- BEG ----------');

// console.log('TvsTabContol.itemAddView(): this.viewContainer  = ', this.ViewContainer);


// let componentFactory            = this.componentFactoryResolver.resolveComponentFactory(a_classView);
// let viewComponentRef:   any     = this.ViewContainer.createComponent(componentFactory);
// tabItem                   = new TvsTabItem(this, (viewComponentRef.instance as vsView.TvsView).ViewProperties.ViewTitle, this.Tabs.length);


tabItem                         = new TvsTabItem(this, ((vsCommon.vsStringHasValue(a_sItemCaption)) ? a_sItemCaption :'<New Tab>'), this.Tabs.length);
tabItem.Closeable               = false;
tabItem.Moveable                = false;
tabItem.Pinable                 = false;
this.Tabs.push(tabItem);

appViewItem                     = this.AppViewList.itemAdd();
appViewItem.ProgFuncID          = null;
appViewItem.AppViewType         = null;
appViewItem.PKValue             = null;
appViewItem.ViewClass           = a_classView;
appViewItem.ViewComponentRef    = null;
appViewItem.TabItem             = tabItem;


// $Rev TC 2021-06-20:
// Auch für den ersten Tab darf hier noch nicht Active auf true gesetzt werden - dies darf
// ausschließlich über die Methode itemActivate() erfolgen.

// if (this.Tabs.length == 1) {
//   tabItem.Active = true
// };


// console.log('TvsTabContol.itemAddView(): tabItem             = ', tabItem);
// console.log('TvsTabContol.itemAddView(): Tabs.length         = ', this.Tabs.length);
// console.log('TvsTabContol.itemAddView(): appViewItem         = ', appViewItem);
// console.log('TvsTabContol.itemAddView(): AppViewList.length  = ', this.AppViewList.length);

// console.log('TvsTabContol.itemAddView(): ---------- END ----------');

} // if bOK



return tabItem;

} // itemAddView


//------------------------------------------------------------------------------------------------
// Method:  itemDelete
//
// Args:    a_item:               TvsTabItem
//
// Result:  ./.
//
// Notes:   Löscht das angegebene Item aus der Liste
//------------------------------------------------------------------------------------------------

public itemDelete(a_item: TvsTabItem): void {

let bFound:           boolean               = false;

for(let i = 0; ((i < this.Tabs.length) && !bFound); i++) {
bFound      = (this.Tabs[i] === a_item);      // $Rev TC 2021-04-08: diese Prüfung ergibt immer false !?
if (bFound) {
  this.itemDeleteByIdx(i);
}
} // for

} // itemDelete


//------------------------------------------------------------------------------------------------
// Method:  itemDeleteByIdx
//
// Args:    a_iIdx                Index des zu löschenden Items
//
// Result:  ./.
//
// Notes:   Löscht das über a_iIdx angegebene Item aus der Liste
//------------------------------------------------------------------------------------------------

public itemDeleteByIdx(a_iIdx: number): void {

let bOK:              boolean               = true;

// Übergebener Index innerhlab des zulässigen Bereiches?
if (bOK) {
bOK = ((a_iIdx >= 0) && (a_iIdx < this.Tabs.length));
if (!bOK) {
  // ... eventuell Exception auslösen ...
}
} // if bOK


// Ggf. weitere Validierungen
if (bOK) {
// ./.
} // if bOK


if (bOK) {
this.Tabs.splice(a_iIdx, 1);
this.itemsSortByPosIdx();
}

} // itemDeleteByIdx


//------------------------------------------------------------------------------------------------
// Method:  itemsClear
//
// Args:    ./.
//
// Result:  void
//
// Notes:   Löscht alle Items aus der Liste
//------------------------------------------------------------------------------------------------

public itemsClear(): void {
this.Tabs.length  = 0;
} // itemsClear


//------------------------------------------------------------------------------------------------
// Method:  itemMoveToPos
//
// Args:    a_item            Zu verschiebendes Item
//          a_iPos            Neue Zielposition (1-basiert)
//
// Result:  void
//
// Notes:   Verschiebt ein Item an eine andere Position
//------------------------------------------------------------------------------------------------

public itemMoveToPos(a_item: TvsTabItem, a_iPos: number): void {

let bOK:            boolean         = null;
let iPosMin:        number          = null;
let iPosOld:        number          = null;
let iPosNew:        number          = null;


// Minimale Position ist die Position nach dem letzten gepinnten Tab
iPosMin       = 0;

iPosOld       = a_item.PosIdx;
iPosNew       = a_iPos - 1;

bOK           = true;
bOK           = bOK && (iPosNew != iPosOld);
bOK           = bOK && (iPosNew >= iPosMin);


//------ Item verschieben

if (bOK) {
if (iPosNew < iPosOld) {    //---- verschieben nach links

  for (let i = iPosNew; i <= iPosOld; i++) {
    this.Tabs[i].PosIdx++;
  } // for

} else {                    //---- verschieben nach rechts

  for (let i = iPosOld + 1; i <= iPosNew; i++) {
    this.Tabs[i].PosIdx--;
  } // for

} // if

a_item.PosIdx   = iPosNew;
} // if bOK


//------ Sortierung, damit PosIdx auch mit dem tatsächlichen Item-Index im Array übereinstimmt

if (bOK) {
this.itemsSortByPosIdx();
}

} // itemMoveToPos


//------------------------------------------------------------------------------------------------
// Method:  itemActivate
//
// Args:    a_tab             Zu aktivierendes TabItem
//
// Result:  void
//
// Notes:   Aktiviert das angegebene TabItem
//------------------------------------------------------------------------------------------------

public itemActivate(a_tab: TvsTabItem): void {
// console.log('TvsTabControl.itemActivate: a_tab          = ', a_tab);
// console.log('TvsTabControl.itemActivate: a_tab.TmplRef  = ', a_tab.TmplRef);

let bOK:                boolean               = true;


if (bOK) {
bOK = vsCommon.vsVarAssigend(a_tab);
} // if bOK


if (bOK) {
this.Tabs.forEach((item)  => {item.Active = false});
this.Tabs.forEach((item)  => {item.Visible = false}); //$rev AK 2021-09-17: hinzugefügt für wizard
a_tab.Active              = true;
a_tab.Visible             = true; //$rev AK 2021-09-17: hinzugefügt für wizard
a_tab.Visited             = true; //$rev AK 2021-09-16: hinzugefügt für wizard
} // if bOK


//------ Variante 1: es wurde eine TemplateRef angegeben (-> keine automatische Erzeugung einer View)

if (bOK && (this.Kind != TvsTabControlKind.kindMain) && a_tab.TmplRef) {
  this.TemplateTabContent   = a_tab.TmplRef;

  //------ Ggf. vorhandene View aus dem View Container entfernen (die View Component wird dabei NICHT zerstört!)
  //$REV MP 2022-04-05: Added
  // if (vsCommon.vsVarAssigend(this.ViewContainer)) {
    if (bOK && this.ViewContainer.length > 0)
    {
      this.ViewContainer.detach(0);
    }
  // }


  // if (this.Kind == TvsTabControlKind.kindWizard) { 
  
  // }

} // if Variante 1: vorgegebene TemplateRef





//------ Variante 2: es wurde kine TemplateRef angegeben und es soll eine View automatisch erzeugt werden

if (bOK && (this.Kind != TvsTabControlKind.kindMain) && !a_tab.TmplRef) {
// console.log('TvsTabControl.itemActivate() kindStandard, Variante 2 ---------- BEG ----------');
// console.log('TvsTabControl.itemActivate():  a_tab          = ', a_tab);

let appViewItem:        vsAppViewList.TvsAppViewItem    = null;
let componentFactory:   any                             = null;
let viewComponentRef:   any                             = null;


appViewItem   = this.AppViewList.itemFindByTabItem(a_tab);
bOK           = (appViewItem) && (appViewItem.ViewClass);

// console.log('TvsTabControl.itemActivate():  appViewItem    = ', appViewItem);


//------ Ggf. vorhandene TabContent entfernen
//$REV MP 2022-04-05: Added
this.TemplateTabContent   = null;

//------ Ggf. vorhandene View aus dem View Container entfernen (die View Component wird dabei NICHT zerstört!)

// if (vsCommon.vsVarAssigend(this.ViewContainer)) {
  if (bOK && this.ViewContainer.length > 0)
  {
    this.ViewContainer.detach(0);
  }
// }

//---- View erzeugen bzw. einblenden

// console.log('TvsTabControl.itemActivate():  (1)');

if (bOK && (!appViewItem.View)) {
  //  console.log('TvsTabControl.itemActivate():  (1.1) View erzeugen');
  componentFactory                = this.componentFactoryResolver.resolveComponentFactory(appViewItem.ViewClass);
  viewComponentRef                = this.ViewContainer.createComponent(componentFactory);
  appViewItem.ViewComponentRef    = viewComponentRef;
} else {
  //  console.log('TvsTabControl.itemActivate():  (1.2) View einblenden');
  this.ViewContainer.insert(appViewItem.ViewComponentRef.hostView);
} // if bOK

// console.log('TvsTabControl.itemActivate() kindStandatd, Variante 2 ---------- END ----------');

} // if Variante 2: View erzeugen bzw. einblenden


//---- Bei allen Varianten Event auslösen

if (bOK) {

// $Rev TC 2021-09-11: added / MANTIS #0005096
this._cdRef.detectChanges();

this.EventOnTabSelect.emit(a_tab);
}


} // itemActivate


// $Rev TE 2022-09-28: added
//------------------------------------------------------------------------------------------------
// Method:    tabSwapByView
//
//
// Args:      oldTabToBeDeleted             Aktuelles TabItem welches ersetzt werden soll und somit gelöscht werden muss
//            newViewAsTab                  Neuer View welcher als Tab erzeugt werden soll
//            newViewAsTabName              Name des neuen Tab als string
//            moveToPos                     Angabe an welche Position der Tab in der Anzeige gesetzt werden soll
//                                        --> leitet davon automatisch den Index für die Angabe im Array ab
//
//
// Result:    TvsTabItem
//
//
// Notes:     Ersetzt den momentanen Tab durch einen neuen Tab, welcher anhand der View-Angabe erzeugt wird.
//            Um in detailDataSynchronize wie gewohnt auf die Instanz des TabItems zugreifen zu können, wird das TabItem als result zurückgegeben.
//            Kann bei Bedarf, um mehr Fallbehandlungen (wie für Templates) funktional erweitert werden.
//
// Thoughts:  Kann vielleicht durch einfaches ein- oder ausblenden zukünftig ersetzt werden
//------------------------------------------------------------------------------------------------

tabSwapByView(oldTabToBeDeleted: TvsTabItem, newViewAsTab: Type<vsView.TvsView>, labelForNewTab: string, moveToPos: number): TvsTabItem {
  let pushToIndex = moveToPos-1;

  this.itemDelete(oldTabToBeDeleted);
  let newTab: TvsTabItem = this.itemAddView(newViewAsTab, labelForNewTab);
  this.itemMoveToPos(newTab, moveToPos);
  this.Tabs[pushToIndex] = newTab;
  if(oldTabToBeDeleted.Active) {
    this.itemActivate(newTab);
  }
  return newTab;
}




//------------------------------------------------------------------------------------------------
// Method:  itemActivateFirst
//
// Args:    ./.
//
// Result:  void
//
// Notes:   Aktiviert das erste TabItem
//------------------------------------------------------------------------------------------------

public itemActivateFirst(): void {
this.itemActivate(this.Tabs[0]);
} // itemActivateFirst


//------------------------------------------------------------------------------------------------
// Method:  itemActivateVis
//
// Args:    a_tab             Zu aktivierendes TabItem
//
// Result:  void
//
// Notes:   Aktiviert das angegebene TabItem,
//            SOFERN dieses TabItem zuvor bereits min 1x aktiviert wurde
//------------------------------------------------------------------------------------------------

public itemActivateVis(a_tab :TvsTabItem) :void {
if (a_tab.Visited) {
this.itemActivate(a_tab);
} else {
console.log(a_tab.Caption, ' ist für Sie noch nicht verfügbar');
}
}


//====== Event Handler


protected doOnTabClick(a_tab: TvsTabItem): void {
if (!a_tab.Active) {
this.itemActivate(a_tab);
}
} // doOnTabClick



protected doOnTabCloseClick(a_tab: TvsTabItem): void {
// console.log('onTabCloseClick: tab = ', a_tab);

if (this.Kind = TvsTabControlKind.kindMain) {
this.EventOnTabClose.emit(a_tab);
}

} // doOnTabCloseClick


} // TvsTabControl



//======[ TvsTabItem ]==============================================================================



export class TvsTabItem {


//====== Properties (PRIVATE)

private               FPosIdx:          number            = null;


//====== Properties (PUBLIC)

public    readonly    TabControl:       TvsTabControl     = null;

public                Caption:          string            = null;
public                CaptionTooltip:   string            = null;
public                IconURL:          string            = null;

public                Active:           boolean           = false;
public                Enabled:          boolean           = true;
public                Closeable:        boolean           = true;
public                Moveable:         boolean           = false;
public                Pinable:          boolean           = false;
public                Visited:          boolean           = false; //$rev AK 2021-09-16: added for wizard
public                Visible:          boolean           = false; //$rev AK 2021-09-16: added for wizard

public                badgeValue:       number            = 0;
public                TmplRef:          TemplateRef<any>  = null;


//====== Constructor

constructor(a_ctrl: TvsTabControl, a_sCaption: string, a_iPosIdx: number) {
this.TabControl   = a_ctrl;
this.Caption      = a_sCaption;
this.PosIdx       = a_iPosIdx;
} // constructor



//====== Zugriffsmethoden


// Pos          Position (1-basiert)
public    get Pos(): number {
return this.FPosIdx + 1;
}

// PosIdx       PositionIndex (0-basiert, entspricht dem Array-Index des Tabs)
public    get PosIdx(): number {
return this.FPosIdx;
}
public    set PosIdx(a_iValue: number) {
this.FPosIdx    = Math.max(0, a_iValue);
// this.TabControl.itemsSortByPosIdx();
}


} // TvsTabItem


