//------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vs-view-data.component.ts
//  Notes:  View component: Basisklasse für Views, die dynamische Daten darstellen
//
//
//  H I S T O R Y
//
//  2021-03-21  TC   Genesis
//------------------------------------------------------------------------------

// tslint:disable-next-line: import-spacing
import { Component, EventEmitter, Input, OnInit, TemplateRef }        from '@angular/core';
import { Router }                                       from '@angular/router';
import { FormGroup, FormControl }                       from '@angular/forms';

// vsLib
import * as vsCommon                                    from '@vsLib/vsCommon';
import * as vsView                                      from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources                           from '@vsLib/Services/vsGlobalResources.service';
import * as vsDataObject                                from '@vsLib/vsDataObject';
import { TvsHTTPMethod, TvsHttpServiceComponent,
         TvsGetType }                                   from '@vsLib/Services/vsHttp.service.component';
import { vsStringIsNotNull }                            from '@vsLib/vsCommon';
import { TvsViewDataList }                              from '../vsViewDataList/vsViewDataList.component';
import { MenuItemContent } from 'primeng/menu';
import { TvsActionList } from '@vsLib/vsActionList';
import * as vsTabControl                                from '@vsLib/Components/vsTab/vsTabControl.component';

// primeNG
import      { MenuItem }                                from 'primeng/api';
import { TvsViewDataEdit } from '../vsViewDataEdit/vsViewDataEdit.component';



//
// TvsViewPropertiesData
//
export class TvsViewPropertiesData extends vsView.TvsViewProperties {

  // Properties
  public                DOMain:           vsDataObject.TvsDataObject    = new vsDataObject.TvsDataObject();

  // Event handler
  // ...

} // TvsViewPropertiesData



//
// TvsViewData
//
// Base class: Data View
//
@Component({
  selector:     'vsViewData',
  templateUrl:  './vsViewData.component.html',
  styleUrls:   ['./vsViewData.component.scss']
})
export class TvsViewData extends vsView.TvsView implements OnInit {

  //====== Properties (PRIVATE)

  private     FPKValue:                     string                        = null;


  //====== Properties (PUBLIC)

  @Input()    ViewProperties:               TvsViewPropertiesData         = new TvsViewPropertiesData();
  public    afterRefresh:        EventEmitter<any>    = new EventEmitter();

  public  get PKValue():                    string                        {return this.FPKValue}
  public  set PKValue(a_sValue: string)                                   {this.FPKValue = a_sValue}

  public  get DOMain():                     vsDataObject.TvsDataObject    {return this.ViewProperties.DOMain}
  public  get MenuItemsContext():           MenuItem[]                    {return this.ViewProperties.MenuItemsContext}
  public  get MenuItemsAction():            MenuItem[]                    {return this.ViewProperties.MenuItemsAction}
  public  get MenuItemsNew():               MenuItem[]                    {return this.ViewProperties.MenuItemsNew}
  public      listForm:                     FormGroup                     = new FormGroup({});

  protected   editObject:                   any                           = null;
  public      avt:                          vsCommon.TvsAppViewType       = vsCommon.TvsAppViewType.avtUndefined;


  public      ActionList:                   TvsActionList                 = new TvsActionList();

  public      TabItem:                      vsTabControl.TvsTabItem       = null;
  public      ReadOnly:                     boolean                       = false;

  public      KennFilterSearchAll:          boolean                       = false;

  public      KennShowDetailTabRecordcount: boolean                 = false;
  public      DetailTabCaption:             string                  = '';

  // Properties: Datenübergabe von Liste nach Edit

  //====== Constructor

  constructor(protected router:             Router
            , protected globalResources:    vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent: TvsHttpServiceComponent
  ) {
    super(router, globalResources);

  } // constructor



  //====== NG event handler

  ngOnInit(): void {
  } // ngOnInit



  //====== Methods: Actions


  //------------------------------------------------------------------------------------------------
  // Method:  actionClose
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Close
  //          Template Method - should never be overridden!
  //------------------------------------------------------------------------------------------------

  protected actionClose(a_Data: any): void {
    // console.log('actionClose(): ',(this.ViewProperties.Instance as TvsViewData).TabItemMainView);
    // this.globalResources.ViewMain.doTabsMainOnTabClose((this.ViewProperties.Instance as TvsViewData).TabItemMainView);
console.log('vsViewDate.ActionClose')
console.log('vsViewDate.DoBeforClose')
this.DoBeforeClose(a_Data);

    this.globalResources.ViewMain.tabItemDelete((this.ViewProperties.Instance as TvsViewData).TabItemMainView);
  } // actionClose


  //------------------------------------------------------------------------------------------------
  // Method:  actionRefresh
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Refresh -> load data via data service and display the data
  //          Template Method - should never be overridden!
  //------------------------------------------------------------------------------------------------

  public actionRefresh(a_getType: TvsGetType = TvsGetType.gtNormal, a_sPKValue: string = null): void {
    let bOK:            boolean       = true;
    let bStaticData:    boolean       = false;      // $Rev TC 2021-08-05: [MOCKUP] [PORTAL_ELTERN]
    let sFilter:        string        = '';
    // let dataFilter:     any           = null; //vsDataFilter.TvsDataFilter = null;


    this.PKValue                      = a_sPKValue;
    this.DOMain.Dataset.Data.length   = 0;


    // $Rev TC 2021-08-05
    // [MOCKUP] [PORTAL_ELTERN] Diese Methode testweise eingebaut, um statische Daten zur Verfügung stellen zu können.
    if (bOK) {
      bStaticData   = (this.ViewProperties.Instance as TvsViewData).retrieveStaticData();
    }

    // Subscribe auf LookupLoad
// console.log('ActionRefresh: ', this.DataFilter);
    // Aufruf LookupLoad

    if (bOK && !bStaticData) {
      this.DOMain.IsRefreshing = true;


      if ((a_getType === TvsGetType.gtNormal) && (a_sPKValue === null)) {
        try {
          // sFilter    = (this.ViewProperties.Instance as TvsViewData).RetrieveFilter();
          // dataFilter = (this.ViewProperties.Instance as TvsViewData).RetrieveDataFilter();

          // console.log('ActionRefresh - FilterApply');

          (this.ViewProperties.Instance as TvsViewDataList).FilterApply();

          // console.log('FilterRequired: ', (this.ViewProperties.Instance as TvsViewDataList).FilterRequired);
          // console.log('sFilter: ', vsCommon.vsStringHasValue(sFilter))


          // Kein Filter gesetzt und FilterRequired = True => Datenmenge leeren

          // console.log('FilterRequired2: ', (this.ViewProperties.Instance as TvsViewDataList).FilterRequired);
          // console.log('DataFilter.Length: ', (this.ViewProperties.Instance as TvsViewData).DataFilter.length);
          //  console.log('Test:',             ((this.ViewProperties.Instance as TvsViewDataList).FilterRequired)
          //                                 && ((this.ViewProperties.Instance as TvsViewData).DataFilter.length === 0));


          if ( ((this.ViewProperties.Instance as TvsViewDataList).FilterRequired)
            // && (!vsCommon.vsStringHasValue(sFilter))
            // && (!vsCommon.vsVarAssigend(dataFilter))
               && ((this.ViewProperties.Instance as TvsViewData).DataFilter.length === 0)
           ) {
            // Datenmenge leeren und keinen Get-Request ausführen;
            this.DOMain.Dataset.Data.length = 0;
            bOK = false;
            this.DOMain.IsRefreshing = false;
           }


        } // try
        catch (e) {
          bOK                       = false;
          alert(e);
          this.DOMain.IsRefreshing  = false;
        } // catch
      }
      else if ((a_getType === TvsGetType.gtNormal) && (a_sPKValue != null)) {
//        sFilter = '&PKValue=' + this.PKValue;
        (this.ViewProperties.Instance as TvsViewData).DataFilter.ItemsClear();

        let item = (this.ViewProperties.Instance as TvsViewData).DataFilter.ItemAdd('PKValue');
        item.CriteriaAdd('PKValue', this.PKValue);

      }
    } // if bOK


    if (bOK && !bStaticData) {
      (this.ViewProperties.Instance as TvsViewData).doBeforeRefresh();

    }

    if (bOK && !bStaticData) {
       console.log('Refresh: ', this.ViewProperties.DataSourceID)
      // console.log('Refresh: ', this.DataFilter)
      // console.log('Refresh: ', (this.ViewProperties.Instance as TvsViewData).DataFilter)
      this.HttpServiceComponent.dataGet(this.ViewProperties.DataSourceID
                                        , this.ViewProperties.DOMain.KennFieldDefs
                                        , this.ViewProperties.QueryFields
                                        , (this.ViewProperties.Instance as TvsViewData).DataFilter
                                        , a_getType
                                        , this.ViewProperties.UseRecordBasedAccessRights
                                        )
        .subscribe(
          data => {
             console.log('get-data: ', data)
            this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, a_getType == TvsGetType.gtInitList);
          },
          error => {
             console.log('Error DataList-Refresh: ', error);

            alert('Bei dem HTTP-Request ist ein Fehler aufgetreten:\n'
            + error.error.Error.ErrCode + '\n'
            + error.error.Error.ErrMsg + '\n\n'
            + error.message );
            this.DOMain.IsRefreshing = false;
          },
          () => {
            // console.log('Refresh completed');
            this.DOMain.IsRefreshing = false;
            // (this.ViewProperties.Instance as TvsViewData).detailDataSynchronize();
            (this.ViewProperties.Instance as TvsViewData).doAfterRefresh();
          }
        );

    } // if bOK

    this.afterRefresh.emit({data: this.DOMain.Dataset.Data, refreshed: true});
  } // actionRefresh

  //------------------------------------------------------------------------------------------------
  // Method:  detailDataSynchronize
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Synchronizes the displayed data in the detail section with the selected main data record
  //------------------------------------------------------------------------------------------------

  protected detailDataSynchronize(): void {

    // if (this.DOMain.Dataset.Data.length > 0) {
    //   this.FDataSelected = [this.DOMain.Dataset.Data[0]];
    // }

  } // detailDataSynchronize

  protected DoBeforeClose(a_Data: any) {

  }

  protected RetrieveFilter(): string {
    return '';
  }

  protected FilterApply(): void {

    //regex wird für das Einsetzen des * benötigt
    let regex = /\s/g;

    if(this.ViewProperties.TableList.selectionMode === "multiple") {
      this.ViewProperties.TableList.filtered = true;
    }
    (this.ViewProperties.Instance as TvsViewData).DataFilter.ItemsClear();

    // Hinzufügen der allgemeinen Textsuche, wenn sie mit mindestens 3 Zeichen gefüllt wurde.

    if (this.listForm) {

     
      let sFilterStdMulti = this.listForm.controls['FilterStdMulti'].value;
       //$REV MP / TE 2024-07-17: die folgende Zeile wurde ersetzt, hat einen Alert-Fehler über den Datentyp des FilterStdMulti, z.B. beim report-Center korrigiert.
      let sFilterMultiType: string | number = '';
  // let sFilterMultiType = this.listForm.controls['FilterMultiTypeCheck'].value ? 1 : '';

      // console.log('KennFilterSearchAll vsViewData' , this.KennFilterSearchAll);
      // Falls die property KennFilterSearchAll von außen gesetzt wird, wird vor jeden Suchparamenter automatisch der Joker * gesetzt
      if (vsStringIsNotNull(this.listForm.controls['FilterStdMulti'].value) && this.KennFilterSearchAll) {
        sFilterStdMulti = '*' + this.listForm.controls['FilterStdMulti'].value.replace(regex, ' *');
      }

      // console.log('FilterStdMulti', sFilterStdMulti);
      if (vsStringIsNotNull(sFilterStdMulti) && (sFilterStdMulti !== '') && (sFilterStdMulti.length > 2)) {

        let item = (this.ViewProperties.Instance as TvsViewData).DataFilter.ItemAdd('FilterStdMulti');
        item.CriteriaAdd('FilterStdMulti', sFilterStdMulti);

      }
      if(sFilterMultiType !== '') {
        let item = (this.ViewProperties.Instance as TvsViewData).DataFilter.ItemAdd('FilterMultiType');
        item.CriteriaAdd('FilterMultiType', sFilterMultiType);
      }
    }

  } // FilterApply



  protected ResponseHandler(a_HTTPMethod: TvsHTTPMethod, a_data: any, a_bClose: boolean, a_bInitList: boolean = false) {

    switch(a_HTTPMethod){

      case TvsHTTPMethod.hmGet : {
          // console.log('HttpRequest-Data:', a_data);
          if (a_bInitList) {
          a_data.Dataset.Data.splice(0,1);
        }
        Object.assign(this.DOMain, a_data);
        this.DOMain.IsRefreshing = false;
        (this.ViewProperties.Instance as TvsViewData).InternalRefresh(a_data);
        break;
      }

      case TvsHTTPMethod.hmPost :  {
        this.ViewProperties.Subj.next(a_data.Dataset.Data[0]);

        break;
      }

      case TvsHTTPMethod.hmDelete : {
        break;
      }

      case TvsHTTPMethod.hmPut : {
        this.ViewProperties.Subj.next(a_data.Dataset.Data[0]);

        break;
      }

    } // switch


    if (a_bClose)
    {
      this.actionClose(a_data.Dataset.Data[0]);
    }


  } // ResponsHandler



  //====== Methods: Miscellaneous

  // ./.
  protected  InternalRefresh(data: any) {
  }



  //====== Methods: Hooks / Event handler


  protected doAfterRefresh() {
    if(vsCommon.vsVarAssigend(this.TabItem)){
      let sTitle = this.RetrieveRecordTitleInfo();
      sTitle = (vsCommon.vsStringHasValue(sTitle)) ? ' [' + sTitle + ']' : '';

      if(this.avt == vsCommon.TvsAppViewType.avtEdit){
        if (!vsCommon.vsStringHasValue(this.PKValue) ) {
          this.TabItem.Caption = this.ViewProperties.ViewTitle + ' - Neu';
        } else {
          this.TabItem.Caption = this.ViewProperties.ViewTitle + sTitle;
        }
      } else {
        this.TabItem.Caption = this.ViewProperties.ViewTitle + sTitle;
      }
    }
    //  console.log('>>>> TvsViewData.doAfterRefresh()');
  } // doAfterRefresh


  protected RetrieveRecordTitleInfo(): string {
    return '';
  }



  protected doBeforeRefresh() {
    // console.log('>>>> TvsViewData.doBeforeRefresh()');
  } // doBeforeRefresh


  // $Rev TC 2021-08-05
  // [MOCKUP] [PORTAL_ELTERN] Diese Methode testweise eingebaut, um statische Daten zur Verfügung stellen zu können.
  protected retrieveStaticData(): boolean {
    return false;
  } // retrieveStaticData

} // TvsViewData


