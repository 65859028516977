//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   Main
//  Title:    Landing Page
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-08-12  TC, MP   Initial Version
//--------------------------------------------------------------------------------------------------


import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router }                           from '@angular/router';

import {  Directive
        , ViewChild
        , ViewContainerRef, TemplateRef
        , ComponentFactoryResolver}         from '@angular/core';


// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}



@Component({
  selector:       'mainViewLanding',
  templateUrl:  './mainViewLanding.component.html',
  styleUrls:   ['./mainViewLanding.component.scss']
})
export class TmainViewLanding extends vsView.TvsView implements OnInit, AfterViewInit {



  //====== Properties (PRIVATE)

  // ./.
  FDeepLink:               boolean             = false;
  FDeepLinkOK:             boolean             = false;
  FDeepLinkMessage:        string;

  FDeepLinkErrorInvalid:   boolean             = false;
  FDeepLinkErrorActivated: boolean             = false;
  FDeepLinkErrorExpired:   boolean             = false;

  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public      MenuUser:                       MenuItem[];           // PrimeNG

  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  static      ProgFuncID:                     string    = 'TEST';   // [LABOR] Test für Zugriff auf class properties



  //====== ViewChilds

  // ./.



  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    dataModel:                  schoolDataModel.TschoolDataModel

  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Landing Page';

    //------ User Menu

    this.MenuUser = [
        {label: 'Mein Profil',    icon: 'vsUserIcon',      command: event => {this.mnuUserMyProfile_OnClick()}}
      , {label: 'Einstellungen',  icon: 'vsCogIcon',       command: event => {this.mnuUserConfig_OnClick()}}
      , {separator: true}
      , {label: 'Abmelden',       icon: 'vsPowerIcon',     command: event => {this.actionLogout()}}
    ] // User Menu


    //------ GUI Language

    let sURLBaseIconsFlags:   string  = '/assets/img/CountryFlags/';
    this.GUILanguageList = [
        {ID: 'de-DE',  Caption: 'German',   IconURL: sURLBaseIconsFlags + 'Germany.png'}
      , {ID: 'en-GB',  Caption: 'English',  IconURL: sURLBaseIconsFlags + 'UnitedKingdom.png'}
      , {ID: 'it-IT',  Caption: 'Italian',  IconURL: sURLBaseIconsFlags + 'Italy.png'}
      , {ID: 'zh-ZH',  Caption: 'Chinese',  IconURL: sURLBaseIconsFlags + 'CountryFlagUnknown.png'}
    ];

    this.GUILanguageSelected = this.GUILanguageList[0];   // [LABOR] TODO: Kann vermutlich beim Laden des UserProfiles erfolgen


//    this.route.queryParams.subscribe(params => { console.log('Parameter in der URL: ', params);  });



  } // constructor



  //====== NG event handler


  ngOnInit(): void {
  } // ngOnInit



  ngAfterViewInit(): void {
    this.checkParams();
  } // ngAfterViewInit



  //====== Methods: Actions


  public actionContactForm(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Kontaktformular'});
  } // actionInfoPerso


  public actionInfoAntrag(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Antrag'});
  } // actionInfoAntrag


  public actionInfoDatenschutz(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Datenschutz'});
  } // actionInfoDatenschutz


  // public actionInfoFAQ(): void {
  //   // this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: FAQ'});

  // } // actionInfoFAQ


  public actionInfoImpressum(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Impressum'});
  } // actionInfoImpressum


  public actionInfoLeistungen(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Leistungen'});
  } // actionInfoLeistungen


  public actionInfoPerso(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Info Page: Login mit Personalausweis'});
  } // actionInfoPerso


  public actionLogin(): void {
    // [LABOR]
    // console.log('>>>> Landing Page: actionLogin()');
    //$REV MP 2022-03-09: Navigation erst einmal nicht zum login für Demo
    this.router.navigateByUrl('/login');
    // this.router.navigateByUrl('/main');
  } // actionLogin


  public actionLogout(): void {
    // [LABOR]
    console.log('>>>> Landing Page: actionLogout()');
    this.globalResources.UserProfile.Logout();
  } // actionLogin


  public checkParams(): void{

    // UserConfirm-DeepLink
    console.log(localStorage.getItem('accConfirm') + ' ' + localStorage.getItem('accPwdReset'));

    if (vsCommon.vsVarAssigend(localStorage.getItem('accConfirm'))) {
      let sToken = localStorage.getItem('accConfirm');
      let sPwdReset = localStorage.getItem('accPwdReset');
      localStorage.removeItem('accConfirm');
      localStorage.removeItem('accPwdReset');

      if (vsCommon.vsStringHasValue(sToken) && sPwdReset == 'F') {
        this.HttpServiceComponent.HttpServicePostRequest(
          'webActionUserAccConfirm',
          '&tokenID=' + sToken,
          ''
        ).subscribe((response) => {
          let resp: any = response;
            this.FDeepLink          = true;
            this.FDeepLinkOK        = resp.result;
            if (!resp.result) {
              switch(resp.error.errCode) {

                case '01':
                // this.FDeepLinkMessage   = 'Der E-Mail-Bestätigungs-Link ist abgelaufen';
                this.FDeepLinkErrorExpired = true;
                break;

                case '02':
                // this.FDeepLinkMessage   = 'Die E-Mail-Adresse wurde bereits bestätigt';
                this.FDeepLinkErrorActivated = true;
                break;

                case '03':
                // this.FDeepLinkMessage   = 'Die E-Mail-Bestätigung ist ungültig! Bitte registrieren sie sich erneut';
                this.FDeepLinkErrorInvalid = true;
                break;
              }
            }
            else {
              // this.FDeepLinkMessage   = 'Die E-Mail-Bestätigung war erfolgreich';
              this.FDeepLinkOK = true;
            }
            // console.log('this.FDeepLinkOK: ', this.FDeepLinkOK);
            // console.log('this.FDeepLinkMessage: ', this.FDeepLinkMessage);
        });
      }
      else
      if (vsCommon.vsStringHasValue(sToken) && sPwdReset == 'T') {

        this.HttpServiceComponent.HttpServicePostRequest(
          'webActionUserAccPwdReset',
          '&AccID=' + sToken,
          ''
        ).subscribe((response) => {
            let resp: any = response;
              if (!resp.result) {
               // switch(resp.error.errCode) {

          //     //   case '01':
          //     //   // this.FDeepLinkMessage   = 'Der E-Mail-Bestätigungs-Link ist abgelaufen';
          //     //   this.FDeepLinkErrorExpired = true;
          //     //   break;

          //     //   case '02':
          //     //   // this.FDeepLinkMessage   = 'Die E-Mail-Adresse wurde bereits bestätigt';
          //     //   this.FDeepLinkErrorActivated = true;
          //     //   break;

          //     //   case '03':
          //     //   // this.FDeepLinkMessage   = 'Die E-Mail-Bestätigung ist ungültig! Bitte registrieren sie sich erneut';
          //     //   this.FDeepLinkErrorInvalid = true;
          //     //   break;
          //     // }
              }
              else {
                this.globalResources.ViewMain.msgShowToastSuccess('Sie haben ihr Passwort erfolgreich zurückgesetzt. Es wurde per Mail an sie versendet');
              }
        });
      }

    }

  }

  //------------------------------------------------------------------------------------------------
  // Method:  mnuUser...
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: User Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuUserMyProfile_OnClick(): void {
    alert('Mein Benutzerprofil');
  } // mnuUserMyProfile_OnClick


  public mnuUserConfig_OnClick(): void {
    alert('Einstellungen');
  } // mnuUserConfig_OnClick


  public mnuUserLogout_OnClick(): void {
    this.globalResources.UserProfile.Logout();
  } // mnuUserLogout_OnClick



  //------------------------------------------------------------------------------------------------
  // Method:  doDropdownGUILanguageOnClick
  //
  // Args:    a_iLangIndex            Index der ausgewählten Sprache bezogen auf GUILanguageList
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: Sprachauswahl
  //------------------------------------------------------------------------------------------------

  public doDropdownGUILanguageOnClick(a_iLangIndex: number) {
    console.log('>>>> Language selected:  ', a_iLangIndex);

    if (this.GUILanguageSelected.ID != this.GUILanguageList[a_iLangIndex].ID) {
      this.GUILanguageSelected = this.GUILanguageList[a_iLangIndex];
      this.translate.use(this.GUILanguageSelected.ID);
    }

  } // doDropdownGUILanguageOnClick



  //------------------------------------------------------------------------------------------------
  // Method:  doDropdownGUILanguageSetAsStandard
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: Ausgwählte Sprache als Standard speichern
  //------------------------------------------------------------------------------------------------

  // [MOCKUP]
  public doDropdownGUILanguageSetAsStandard() {
    alert('Ausgewählte Sprache als Standard einstellen:  ' + this.GUILanguageSelected.Caption);
  }



} // TmainViewLanding

