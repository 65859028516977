<!-- <p-tieredMenu #menu [model]="changeReasonData" [popup]="true" styleClass="vsAntrList"></p-tieredMenu> -->
<div style="display: inline-flex" class="vsMb-2">

  <button pButton style="margin-right: 10px;" label="{{'antrlist.button_new'| translate}}"    (click)="actionAntragNew()"     class="vsElternButton" icon="pi pi-plus"    [attr.aria-label]="'antrlist.button_new'| translate"></button>
  <!-- <button pButton style="margin-right: 10px;" label="{{'antrlist.button_change'| translate}}" (click)="menu.toggle($event);"  class="vsElternButton" icon="pi pi-pencil"  [attr.aria-label]="'antrlist.button_change'| translate"></button> -->
  <!-- <button pButton style="margin-right: 10px;" label="{{'antrlist.button_cancel'| translate}}" (click)="actionAntragStorno()"  class="vsElternButton" icon="pi pi-trash"   [attr.aria-label]="'antrlist.button_cancel'| translate"></button> -->
  <!-- <button pButton style="margin-right: 10px;" label="{{'antrlist.button_view'| translate}}" (click)="actionAntragView()" [disabled]="disabled"  class="vsElternButton" icon="pi pi-file"   [attr.aria-label]="'antrlist.button_view'| translate"></button> -->
</div>
  
  <vsLayoutGroup class="col">
    <vsTable #table [DOMain]="dataObject" (onRowSelect)="doTableRowSelect($event)" (onCustomButtonClick)="doCustomButtonClick($event)"></vsTable>
  </vsLayoutGroup>


<!-- <div *ngFor="let object of data; let i=index " class="row mt-2 mb-2 vsGutterWidth-2 vsPortalList">

  <div class="col-2">{{object.Azubi_Vorname}}</div>

  <div class="col-2">{{object.Azubi_Nachname}}</div>

  <div class="col-2" style="width: 120px;">2022/2023</div>

  <div class="col-2" *ngIf="object.Antr_ID_Art==='U'">Schülerticket</div>
  <div class="col-2" *ngIf="object.Antr_ID_Art==='E'">Erstattung</div>
  <div class="col-2" *ngIf="object.Antr_ID_Art==='I'">Individualbeförderung</div>

  <div class="col-2">{{this.ISOToDateStr(object.Antr_Datum_Antrag)}}</div>

  <div class="col-1">
    <p-progressBar *ngIf="object.Antr_ID_Status==='NEU'" [value]="25" [showValue]="false" class="vsProgressGreen"></p-progressBar>
    <p-progressBar *ngIf="object.Antr_ID_Status==='BEARB'" [value]="75" [showValue]="false" class="vsProgressYellow"></p-progressBar>
    <p-progressBar *ngIf="object.Antr_ID_Status==='GEN'" [value]="100" [showValue]="false" class="vsProgressGreen"></p-progressBar>
    <p-progressBar *ngIf="object.Antr_ID_Status==='ABG'" [value]="100" [showValue]="false" class="vsProgressRed"></p-progressBar>
  </div>

  <div class="col-1">
    <div *ngIf="object.Antr_ID_Status==='NEU'" class="vsGreenText">{{object.Status_Name}}</div>
    <div *ngIf="object.Antr_ID_Status==='BEARB'" class="vsYellowText">{{object.Status_Name}}</div>
    <div *ngIf="object.Antr_ID_Status==='GEN'" class="vsGreenText">{{object.Status_Name}}</div>
    <div *ngIf="object.Antr_ID_Status==='ABG'" class="vsRedText">{{object.Status_Name}}</div>
  </div>

  <div class="col"><i class="pi vsEmailIcon p-mr-4 p-text-secondary" pBadge style="background-size: 1.5rem 1.5rem; width: 1.5rem; height: 1.5rem;" [value]="'2'" severity="danger"></i></div>

</div> -->
