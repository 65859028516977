<div class="row vsMb-2">
  <label class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
  <div class='col vsInputGroup'>
    <p-chips  *ngIf="Disabled=='true'"
              formControlName = '{{FrmCtlName}}'
              inputId         ="input-{{FrmCtlName}}"
              inputStyleClass = 'vsFormControl'
              styleClass      = 'p-inputgroup p-disabled'
              field           = "{{FieldName}}"
              placeholder     = "{{placeholder}}"
              (onChipClick)   = "doOnChipClick($event)"
              (onAdd)         = "doOnAdd($event)"
              (onRemove)      = "doOnRemove($event)"
              [disabled]      = "true"
    ></p-chips>
    <p-chips  *ngIf="Disabled=='false'"
              formControlName = '{{FrmCtlName}}'
              inputId         ="input-{{FrmCtlName}}"
              inputStyleClass = 'vsFormControl'
              styleClass      = 'p-inputgroup {{InvalidCSSClass}}'
              field           = "{{FieldName}}"
              placeholder     = "{{placeholder}}"
              (keydown)       = "onKeyDown($event)"
              (onChipClick)   = "doOnChipClick($event)"
              (onAdd)         = "doOnAdd($event)"
              (onRemove)      = "doOnRemove($event)"
    ></p-chips>
  </div>
</div>


