//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2022-10-05  MP   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';

import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';

type TranslationLookup = {
  Name:         string;
  Value:        string;
  PKValue:      string;
}



@Component({
  selector:       'TchoolViewAntragEdit_Antrag_Grundantrag',
  templateUrl:  './schoolViewAntragEdit_Antrag_Grundantrag.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
})
export class TschoolViewAntragEdit_Antrag_Grundantrag extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});


  // public lookupSchuljahr:                   any[];

  public lookupAntrag:                      any[];

  public translationLookupTranspArt:        TranslationLookup[]        = [];

  public translationLookupTranspArtTranslated: any[] = [];

  public displayDialogError:                boolean = false;

  public KennTransportOptions:              boolean = false;

  public KennApplicationReason:             boolean = false;

  public translationLookupApplicationReason:  TranslationLookup[]    = [];

  public translationLookupApplicationReasonTranslated: TranslationLookup[] = [];

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;
  @ViewChild('explanation_header2')  explanation_header2: ElementRef;
  @ViewChild('explanation_text2')    explanation_text2:   ElementRef;


  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    translate:                  TranslateService     // [ngx-translate]
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antragsauswahl';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.translationLookupTranspArt = [
      {Name: 'application.transp_Option_1', Value: 'Nutzung öffentlicher Verkehrsmittel (Schülerticket Hessen)',  PKValue: 'TICKET'},
      {Name: 'application.transp_Option_2', Value: 'Nutzung privater Verkehrsmittel',  PKValue: 'PKW'},
      {Name: 'application.transp_Option_3', Value: 'Sammelbeförderung (Taxi)',  PKValue: 'TAXI'}
    ];

    this.translationLookupApplicationReason = [
      {Name: 'application.application_Reason_1', Value: 'Einschulung',  PKValue: 'EINSCHUL'},
      {Name: 'application.application_Reason_2', Value: 'Umzug',  PKValue: 'UMZUG'},
      {Name: 'application.application_Reason_3', Value: 'Schulwechsel',  PKValue: 'SCHULWECHS'}
    ];

    if(vsCommon.vsVarAssigend(this.dataModel.KennTransportOptions)) {
      this.KennTransportOptions = this.dataModel.KennTransportOptions;
    }

    if(vsCommon.vsVarAssigend(this.dataModel.KennApplicationReason)) {
      this.KennApplicationReason = this.dataModel.KennApplicationReason;
    }

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    if(!vsCommon.vsStringHasValue(this.dataModel.antrag_Datum)) {
      this.dataModel.antrag_Datum = vsCommon.RetrieveDateStrNow();
    }

    // this.editForm.addControl('Schuljahr', new FormControl(this.dataModel.antrag_Schuljahr));
    this.editForm.addControl('Antr_Datum',          new FormControl(this.dataModel.antrag_Datum));
    this.editForm.addControl('Datum_Guelt_Beg',     new FormControl(this.dataModel.antr_Datum_Guelt_Beg));

    if(this.KennTransportOptions) {
      this.editForm.addControl('Transp_Art',    new FormControl(this.dataModel.transp_Art));
    }

    if(this.KennApplicationReason) {
      this.editForm.addControl('Transp_Reason',    new FormControl(this.dataModel.transp_Reason));
    }

    //Initial Übersetzungen in translationLookupTranspArtTranslated pushen

    this.translationLookupTranspArt.map((item) => {
      this.translate.get(item.Name).subscribe((res)=>{
            this.translationLookupTranspArtTranslated.push(
              {
                Name:       res,
                Value:      item.Value,
                PKValue:    item.PKValue,
              }
            ) 
          });
        });

      this.translationLookupApplicationReason.map((item) => {
          this.translate.get(item.Name).subscribe((res)=>{
              this.translationLookupApplicationReasonTranslated.push(
                 {
                    Name:       res,
                    Value:      item.Value,
                    PKValue:    item.PKValue,
                }
              ) 
             });
          });

    this.translateItems();

  } // ngOnInit

  translateItems() { 

    if(this.KennTransportOptions) {
          //Wird nur bei language-change getriggert
          this.translate.onLangChange.subscribe((event: LangChangeEvent) => {


            //Übersetzungen in translationLookupTranspArtTranslated pushen
            //Items initial leeren damit die übersetzten Items nicht dazu kommen.
            this.translationLookupTranspArtTranslated = []
    
            this.translationLookupTranspArt.map((item) => {
              this.translate.get(item.Name).subscribe((res)=>{
                    this.translationLookupTranspArtTranslated.push(
                      {
                        Name:       res,
                        Value:      item.Value,
                        PKValue:    item.PKValue,
                      }
                    ) 
                  });
                });
            });
    }

    if(this.KennApplicationReason) {
      //Wird nur bei language-change getriggert
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

        //Übersetzungen in translationLookupTranspArtTranslated pushen
        //Items initial leeren damit die übersetzten Items nicht dazu kommen.
        this.translationLookupApplicationReasonTranslated = []

        this.translationLookupApplicationReason.map((item) => {
          this.translate.get(item.Name).subscribe((res)=>{
                this.translationLookupApplicationReasonTranslated.push(
                  {
                    Name:       res,
                    Value:      item.Value,
                    PKValue:    item.PKValue,
                  }
                ) 
              });
            });
        });
      }
    }


  ngAfterViewInit(): void {

    if (vsCommon.vsStringHasValue(this.dataModel.transp_Art)) {
      this.editForm.controls['Transp_Art'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.translationLookupTranspArt, this.dataModel.transp_Art));
    }
    
    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }
    if(this.explanation_header2.nativeElement.innerText === '') {
      this.explanation_header2.nativeElement.remove();
    }
    if(this.explanation_text2.nativeElement.innerText === '') {
      this.explanation_text2.nativeElement.remove();
    }
  }

  public actionStepPrev(): void {
    this.dataSave();
    this.dataModel.EmptyData();
    this.router.navigate(['landing']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    
    if (bOK) {
      this.editForm.get('Datum_Guelt_Beg').setValidators(Validators.required);
      this.editForm.get('Datum_Guelt_Beg').updateValueAndValidity();

      if(this.KennTransportOptions) {
        this.editForm.get('Transp_Art').setValidators(Validators.required);
        this.editForm.get('Transp_Art').updateValueAndValidity();
      }

      if(this.KennApplicationReason) {
        this.editForm.get('Transp_Reason').setValidators(Validators.required);
        this.editForm.get('Transp_Reason').updateValueAndValidity();
      }

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      this.dataSave();
      this.router.navigate(['antragEdit/studentSelection']);
    }

  } // actionStepNext()



  public dataSave() :void{

    this.dataModel.antr_Datum_Guelt_Beg      =  this.editForm.controls['Datum_Guelt_Beg'].value;
    this.dataModel.antrag_Datum              =  this.editForm.controls['Antr_Datum'].value;
    // this.dataModel.antrag_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['Antr_Datum'].value);

    if (this.KennTransportOptions) {
      this.dataModel.transp_Art                =  this.editForm.controls['Transp_Art'].value?.PKValue;
      this.dataModel.transp_Art_Rec            =  this.editForm.controls['Transp_Art']?.value;
    }

    if (this.KennApplicationReason) {
      this.dataModel.transp_Reason                =  this.editForm.controls['Transp_Reason'].value?.PKValue;
      this.dataModel.transp_Reason_Rec            =  this.editForm.controls['Transp_Reason']?.value;
    }

    console.log('this.dataModel.antrag_Art ' , this.dataModel.antrag_Art)
    // console.log('this.editForm.controls_Art_Name' , this.editForm.controls['Art_Name'])

  }


} // schoolViewAntragEdit_Student


