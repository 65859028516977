//--------------------------------------------------------------------------------------------------
//  AMIS7bm
//
//  Domain:   AMIS7bm
//  Title:    Dialog vsSearchDlg
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-09-22  MH   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
  , OnInit
  , ComponentFactoryResolver,
  AfterViewInit,
  ChangeDetectorRef}         from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl }           from '@angular/forms';

import { ImgCropperConfig, ImgCropperEvent, LyImageCropper, ImgCropperErrorEvent, ImgCropperLoaderConfig } from '@alyle/ui/image-cropper';

// PrimeNG
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';
import { DynamicDialogConfig }              from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';
import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsViewDataList                  from '@vsLib/View/components/vsViewDataList/vsViewDataList.component';
import { TvsViewFieldDefinitions }          from '@vsLib/vsViewConfig';
import { TvsDataFilter }                    from '@vsLib/vsDataFilter';
import { TvsDataSet }                       from '@vsLib/vsDataSet';


export class TSearchDlgData  {
  public      FieldDefs:              any[];
  public      DataSourceID:           string;
  public      FilterRequired?:        boolean;
  public      DataSet?:               TvsDataSet;
  public      DataFilter?:            TvsDataFilter;
  public      MultiSelect?:           boolean;
  public      KennFilterSearchAll?:   boolean;

  constructor(a_DataSourceID: string, a_FieldDefs: any[], a_bFilterRequired?: boolean, a_DataSet?: TvsDataSet, a_DataFilter?: TvsDataFilter, a_bMultiSelect?: boolean, a_bFilterSearchAll?: boolean) {
    this.FieldDefs      = a_FieldDefs;
    this.DataSourceID   = a_DataSourceID;
    vsCommon.vsVarAssigend(a_bFilterRequired) ? this.FilterRequired = a_bFilterRequired : this.FilterRequired = true;
    this.DataSet        = a_DataSet;
    this.DataFilter     = a_DataFilter;
    vsCommon.vsVarAssigend(a_bMultiSelect) ? this.MultiSelect = a_bMultiSelect : this.MultiSelect = false;
    vsCommon.vsVarAssigend(a_bFilterSearchAll) ? this.KennFilterSearchAll = a_bFilterSearchAll : this.KennFilterSearchAll = false;

  }
}


@Component({
selector:       'vsSearchDlg',
templateUrl:  './vsSearchDlg.component.html',
// styleUrls:   ['./vsSearchDlg.scss']
})


export class TvsSearchDlg extends vsViewDataList.TvsViewDataList implements OnInit, AfterViewInit {

//====== Properties (PRIVATE)

private DataSet: TvsDataSet = new TvsDataSet();
private configData: TSearchDlgData;

//====== Properties (PUBLIC)

  public get bKennOKEnabled(): boolean { return vsCommon.vsStringHasValue(this.dataSelected) }
  public     FormGroup:        FormGroup        = new FormGroup({});

  //====== Constructor

  constructor(protected router:                 Router
        , protected globalResources:            vsGlobalResources.TvsGlobalResources
        , protected HttpServiceComponent:       TvsHttpServiceComponent
        , protected componentFactoryResolver:   ComponentFactoryResolver
        , public    ref:                        DynamicDialogRef
        , public    config:                     DynamicDialogConfig
        , private     cd:                       ChangeDetectorRef

) {
    super(router, globalResources, HttpServiceComponent);

    this.configData = config.data;


    // console.log('configData: ', config.data);
    this.ViewProperties.Instance                  = this;
    this.ViewProperties.DataSourceID              = (config.data as TSearchDlgData).DataSourceID;
    this.ViewProperties.QueryFields               = [];
    this.ViewProperties.DOMain.KennFieldDefs      = false;
    this.ViewProperties.SectionIsVisible_Toolbar  = false
    this.HintVisibleFilterRequired                = (config.data as TSearchDlgData).FilterRequired;
    this.FilterRequired                           = (config.data as TSearchDlgData).FilterRequired;
    this.DataSet                                  = (config.data as TSearchDlgData).DataSet;
    this.KennFilterSearchAll                      = (config.data as TSearchDlgData).KennFilterSearchAll;

    // if (vsCommon.vsVarAssigend((config.data as TSearchDlgData).DataFilter)) {
    //   this.DataFilter                               = (config.data as TSearchDlgData).DataFilter;
    // }

  } // constructor

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ViewProperties.TableList.multiSelectEnabled = this.configData.MultiSelect;

    if (!this.FilterRequired) {
      if (vsCommon.vsStringHasValue(this.ViewProperties.DataSourceID)) {
        this.actionRefresh();
      }
      else if (vsCommon.vsVarAssigend(this.DataSet)) {
        this.SectionIsVisible_Filter = false;
        this.init();
        Object.assign(this.DOMain.Dataset.Data, this.DataSet.Data);
        (this.ViewProperties.Instance as vsViewDataList.TvsViewDataList).ViewProperties.TableList.doSelect();
      }
    }

    //Falls der Aufruf des Search-Dialoges den KennFilterSearchAll auf true setzt, Übergabe an vsViewData
    if (this.KennFilterSearchAll) {
      (this.ViewProperties.Instance as vsViewData.TvsViewData).KennFilterSearchAll = true;
    }


    this.cd.detectChanges();
  }

  protected doAfterRefresh() {

    this.init();
  }

  init() {
//    this.ActionList = null;
    (this.ViewProperties.Instance as vsViewData.TvsViewData).DOMain.Dataset.FieldDefs =  (this.config.data as TSearchDlgData).FieldDefs;
    this.LoadContextMenu();

  }
  protected LoadContextMenu():  void {}

  onOK(){
    // this.ref.close(this.dataSelected);

    if (!this.configData.MultiSelect) {
      this.ref.close(this.dataSelected)
    }
    else {
      this.ref.close(this.ViewProperties.TableList.dataSelected)
    }
  }

  onAbbruch(){
    this.ref.close(null);
  }

  protected FilterApply(): void {
    super.FilterApply();

  
    if (vsCommon.vsVarAssigend((this.configData as TSearchDlgData).DataFilter)) {
      ((this.configData as TSearchDlgData).DataFilter).forEach(item => this.DataFilter.push(item));
    }
  }

  

}
