//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}             from '@angular/core';

import { Router }                               from '@angular/router';
import { FormGroup, FormControl, Validators }   from '@angular/forms';

// [ngx-translate]
import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import  * as schoolDataModel                    from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                             from 'primeng/api';
import { MessageService }                       from 'primeng/api';
import {  DialogService
        , DynamicDialogRef
        , DynamicDialogConfig }                 from 'primeng/dynamicdialog';

// vsLib
import * as vsCommon                            from '@vsLib/vsCommon';
import * as vsViewData                          from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources                   from '@vsLib/Services/vsGlobalResources.service';
// import * as vsTabControl                        from '@vsLib/Components/vsTab/vsTabControl.component';
import { TSearchDlgData, TvsSearchDlg }         from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';



import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { HttpClient }                       from '@angular/common/http';
import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}


type TranslationHeadingType = {
  name: string;
  type: string;
  translationCaption: string;
  size: string;
  sort: boolean;
  alignment: any;
}


@Component({
  selector:       'schoolViewAntragEdit_Schule',
  templateUrl:  './schoolViewAntragEdit_Schule.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_School.component.scss']
  providers:  [DialogService, DynamicDialogRef, DynamicDialogConfig]
})
export class TschoolViewAntragEdit_Schule extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  // ./.
  public      editForm:                     FormGroup               = new FormGroup({});
  public      lookupSchulen:                any[];
  public      LookupSchule:                 any[];
  public      Schularten:                   any[];

  public      schulID:                      any;

  public      refSearchDlg:                 DynamicDialogRef;

  public      schoolData:                   any[];

  public      displayDialogError:                boolean   = false;

  public      translationDialogItems:       TranslationDialogItemsType[]                          = [];

  public      translationHeadingItems:      TranslationHeadingType[]                              = [];

  public      searchDlgData: TSearchDlgData = new TSearchDlgData(
    'ausbDOStammStaettLinkArt',
    [
        { Name: "AusbStaett_Name",      Type: "ftString",   Caption: "Name der Schule", Size: "380",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft }
      , { Name: "Art_Name",             Type: "ftString",   Caption: "Schulform",       Size: "380",     Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
      , { Name: "Adr_Ort",              Type: "ftString",   Caption: "Ort",             Size: "200",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
      , { Name: "Adr_PLZ",              Type: "ftString",   Caption: "PLZ",             Size: "60",     Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
    ], false, null, this.DataFilter, false, true
  );



  //====== Constructor

  constructor(        protected router:                     Router,
                      public    globalResources:            vsGlobalResources.TvsGlobalResources
                    , public    dataModel:                  schoolDataModel.TschoolDataModel
                    , protected messageService:             MessageService
                    , protected HttpServiceComponent:       TvsHttpServiceComponent
                    , protected httpClient:                 HttpClient
                    , public    dialogService:              DialogService
                    , public    translate:                  TranslateService     // [ngx-translate]
) {
super(router, globalResources, HttpServiceComponent);

    console.log('#### TschoolViewAntragEdit_School: constructor');

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schule';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.translationHeadingItems = [
        { name: 'AusbStaett_Name',      type: 'ftString',   translationCaption: 'dialog.text_name_school',     size: '380',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft }
      , { name: 'Art_Name',             type: 'ftString',   translationCaption: 'dialog.text_type_school',     size: '380',     sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
      , { name: 'Adr_Ort',              type: 'ftString',   translationCaption: 'dialog.text_city_school',     size: '200',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
      , { name: 'Adr_PLZ',              type: 'ftString',   translationCaption: 'dialog.text_zip_code_school', size: '60',     sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
    ];


  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    this.editForm.addControl('AusbStaett_Name',                new FormControl(null));
    this.editForm.addControl('Antr_ID_Ausb_Staett_Art',        new FormControl(null));

    this.editForm.controls['Antr_ID_Ausb_Staett_Art'].disable();

  // console.log('Ausbildungstätten-ID Antrag ' + this.dataModel.antr_ID_Ausb_Staett_Name)

  //  this.editForm.addControl('Antr_ID_Ausb_Staett_Art',        new FormControl(null));

   this.editForm.addControl('Antr_Klasse_Beg_Nr',             new FormControl(null));
   this.editForm.addControl('Antr_Klasse_Beg_Zusatz',         new FormControl(null));

  //  let item = this.DataFilter.ItemAdd('Filter Antrag PKValue');
  //  item.CriteriaAdd('Antr_ID', this.dataModel.PKValue);



   //  this.HttpServiceComponent.dataGet(
  //      'schoolDOStammAntrag'
  //    , false
  //    , []
  //    , this.DataFilter
  //    , TvsGetType.gtNormal
  this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrag', '&Antr_ID='+this.dataModel.PKValue)
     .subscribe(
       data => {
        this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);

         console.log('this.DOMain: ', this.DOMain.Dataset.Data[0]);

         this.editForm.controls['AusbStaett_Name'].setValue(this.DOMain.Dataset.Data[0].SchuleAntr_Name);
         this.editForm.controls['Antr_ID_Ausb_Staett_Art'].setValue(this.DOMain.Dataset.Data[0].SchuleArtAntr_Name);

         this.editForm.controls['Antr_Klasse_Beg_Nr'].setValue(this.DOMain.Dataset.Data[0]?.Antr_Klasse_Beg_Nr);
         this.editForm.controls['Antr_Klasse_Beg_Zusatz'].setValue(this.DOMain.Dataset.Data[0]?.Antr_Klasse_Beg_Zusatz);

         this.DataFilter.ItemsClear();

       });

  } // ngOnInit


  doSchoolSearch(event:  any) {

    this.DataFilter.ItemsClear();

  //Übersetzungen in TSearchDlgData pushen
  //Items initial leeren damit die übersetzten Items nicht dazu kommen.
  this.searchDlgData.FieldDefs = [];
  this.translationHeadingItems.map((item) => {
    this.translate.get(item.translationCaption).subscribe((res)=>{
      this.searchDlgData.FieldDefs.push(
            { Name:       item.name,
              Type:       item.type,
              Caption:    res,
              Size:       item.size,
              Sort:       item.sort,
              Alignment:  item.alignment,
            }
        );
    });
  });

  this.translationDialogItems = [
    { data:  this.searchDlgData,
      header: 'dialog.title_search_school',
      styleClass: 'vsDialog vsFullscreenDialog',
      baseZIndex: 10000}
  ]

  this.translationDialogItems.map((item) => {
    this.translate.get(item.header).subscribe((res)=>{

        this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
          data:         item.data,
          header:       res,
          width:        item.width,
          styleClass:   item.styleClass,
          style:        item.style,
          baseZIndex:   item.baseZIndex

        });
      });
    });

    this.refSearchDlg.onClose.subscribe((Data: any) => {
      if (vsCommon.vsVarAssigend(Data)) {
        this.schoolData = Data;
        this.editForm.controls['AusbStaett_Name'].setValue(Data.AusbStaett_Name);
        this.editForm.controls['Antr_ID_Ausb_Staett_Art'].setValue(Data.Art_Name);
        }
    });

  }






  public actionStepPrev(): void {
    this.router.navigate(['main']);

  } // actionStepPrev()


  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK) {

      this.editForm.get('AusbStaett_Name').setValidators(Validators.required);
      this.editForm.get('AusbStaett_Name').updateValueAndValidity();

      this.editForm.get('Antr_Klasse_Beg_Nr').setValidators(Validators.required);
      this.editForm.get('Antr_Klasse_Beg_Nr').updateValueAndValidity();


      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

      if (bOK) {
        // this.dataSave();
        this.dataModel.workInProgressMessage = true;
        this.router.navigate(['main']);
      }
    }
  } // actionStepNext()


public dataSave() :void{

  // if (vsCommon.vsVarAssigend(this.editForm.controls['AusbStaett_Name'].value)) {
  //   this.dataModel.antr_Ausb_Staett_Rec  =  this.editForm.controls['AusbStaett_Name'].value;
  //   this.schulID = this.editForm.controls['AusbStaett_Name'].value;
  //   console.log('dataModel AusbStaett_Rec' , this.dataModel.antr_Ausb_Staett_Rec)
  // }


  // this.dataModel.antr_ID_Ausb_Staett_Name      = this.editForm.controls['AusbStaett_Name'].value;

  // this.dataModel.antr_ID_Ausb_Staett_Art       = this.editForm.controls['Antr_ID_Ausb_Staett_Art'].value;

  // this.dataModel.antr_ID_Ausb_Staett_Art_Rec   = this.editForm.controls['Antr_ID_Ausb_Staett_Art'].value;
  // this.dataModel.antr_ID_Ausb_Staett_Antr      = this.schulID;

  // this.dataModel.antr_Klasse_Beg_Nr            = this.editForm.controls['Antr_Klasse_Beg_Nr'].value;
  // this.dataModel.antr_Klasse_Beg_Zusatz        = this.editForm.controls['Antr_Klasse_Beg_Zusatz'].value;



}


} // schoolViewAntragEdit_School



