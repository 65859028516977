<div class="vsInputPwd">
  <div class="row mb-3">
    <label class="vsInputLabel" *ngIf="!NoLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col">
      <p-password formControlName="{{FrmCtlName}}" 
      [feedback]="KennFeedback" class={{InvalidCSSClass}} 
      inputStyleClass="vsFormControl" 
      [toggleMask]=KennToggleMask 
      id="input-{{FrmCtlName}}"
      placeholder="{{placeholder}}"
      styleClass="p-password p-component p-inputwrapper p-input-icon-right"
      ></p-password>
     </div>
  </div>
</div>


