//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsDataObject.ts
//  Notes:  Container class for data
//
//
//  Ein Datenobjekt dient als Container für alle Daten, die über die Webservices (Middleware)
//  abgefragt oder an das Backend übertragen werden. Der Inhalt besteht dabei aus den eigentlichen
//  Nutzdaten und diversen zusätzlichen Metadaten.
//
//  Das Austauschformat ist in allen Fällen JSON - daher können sich Datenobjekte in das JSON-Format
//  serialisieren und aus dem JSON-Format deserialisieren.
//
//
//  == Nutzdaten
//
//  Für die reinen Nutzdaten (ohne Metadaten) wird der Begriff Datenmenge (Dataset) verwendet.
//  Datenmengen können aus einem einzelnen Datensatz, einer Liste von gleichartigen Datensätzen
//  oder komplexeren hierarchischen Strukturen bestehen.
//
//  Beispiele:
//
//  # Einzelner Datensatz
//    - Stammdaten des angemeldeten Benutzers
//    - Kundenstammdatensatz
//
//  # Liste von gleichartigen Datensätzen
//    - Alle Login-Vorgänge des angemeldeten Benutzers
//    - Alle Verträge zu einem Kunden
//    - Alle Vorgänge zu einem Support-Ticket
//
//  # Komplexe hierarchische Strukturen
//    - Bestellung
//      -- besteht aus: Bestellkopf (GV-Kopf) & n Bestellpositionen (GV-Positionen)
//      -- Bei der Übertrgaung einer Bestellung (z.B. Warenkorb) an das Backend ist es zwingend
//         erforderlich, dass ALLE zu einer BEstellung gehörenden Daten gemeinsam übertragen
//         werden. Ansonsten könnte es im Backend zu inkonsistenten/unvollständigen Daten kommen,
//         beispielsweise Bestellung ohne Positionen oder nur einem Teil der Positionen.
//      -- Das Beispiel der Übertragung von Bestellungen muss eine "atomare Funktion" sein!
//
//    - Normalerweise werden derartige komplexe Strukturen nur zwingend benötigt bei der Über-
//      tragung vom Client an das Backend.
//
//    - Optional sollte das trotzdem auch im umgekehrten Fall möglich sein, also bei der
//      Abfrage von Daten. Dies könnte eventuell für Performancesteigerungen interessant sein,
//      wenn die benötigte Zeit für mehrere Webserviceaufrufe (HTTP Requests) größer ist,
//      als die Zeit für die Übertragung einer größeren Datenmenge. Dies ist gegebenenfalls
//      im Einzelfall zu prüfen.
//
//      Beispielsweise könnte es sinnvoll sein, sehr häufig benötigte Detaildaten (Anschriften,
//      Kommunikationsdaten, offene Forderungen eines Kunden) immer mitzuliefern, während eher
//      seltener benötigte Detaildaten erst bei Bedarf gesondert nachgeladen werden (Liste von
//      Verträgen etc).
//
//      Anderes Beispiel: Stammdatensatz & Lookup-Datenmengen
//
//
//  == Datenmengen
//
//  Wie oben bereits beschrieben, können Datenmengen eine beliebige Anzahl von Datensätzen
//  enthalten (oder natürlich auch leer sein) und optional auch hierarchisch mit unterschiedlichen
//  Strukturen (Felddefinitionen) aufgebaut sein.
//
//  Daher werden Metadaten benötigt, welche die Strukur einer Datenmenge beschreiben. Um die
//  Verwendung einer Datenmenge innerhalb der App so einfach (und so gewohnt) wie möglich zu
//  gestalten, werden diese Metadaten getrennt von der Datenmenge zur Verfügung gestellt.
//
//  Zu diesen Metadaten gehören
//  # FieldDefs:        Felddefinitionen einer Datenmenge
//  # PKName:           Primärschlüsselfeld einer Datenmenge
//
//
//  == Metadaten
//
//  # Strukturelle Metadaten
//
//  Strukturelle Metadaten dienen zur Beschreibung der Struktur von übertragenen Datenmengen. Diese
//  werden bei der Abfrage von Daten automatisch vom Backend hinzugefügt. Bei der umgekehrten Richtung
//  (Übertragung von Daten an das Backend (Insert, Update)) sind diese strukturellen Metadaten nicht
//  erforderlich.
//
//  -- FieldDefs und PKName pro Datenmenge
//
//
//  # Sonstige Metadaten
//
//  ???
//
//  ANMERKUNG zu ViewConfig:
//  ViewConfig gehört NICHT in ein Datenobjekt.
//  Wir gehen zunächst mal davon aus, dass sich das Datenobjekt einer View niemals verändert - stattdessen
//  müssten bei Bedarf unterschiedliche Varianten einer View zur Verfügung gestellt werden.
//  Falls sich dies in der Praxis als nicht durchsetzbar erweist, muss hier nochmal nachgedacht werden...
//
//
//  == Struktur
//
//  # [ENTWURF] / Beispiel: Bestellung (komplexe Struktur)
//
//  {
//    DataObject: {
//
//        IncludesSchema:               true
//      , IncludesSchemaPerDataset:     false       // false = Schema als getrennte SchemaList / true = Schema innerhalb jedes Datasets
//
//      , QueryFields: ["Order_ID", "Order_Date", "Order_Num", ...]    // Bei Abfragen: Liste der Felder, die zurückgeliefert werden sollen
//                                                                     // $Rev TC 2021-03-22: das muss doch auch pro Dataset angegeben werden... (?)
//
//      , SchemaList: [
//            Schema: {   DatasetName:     "OrderHead"
//                        DatasetCaption:  "Order"
//                      , PKName:          "Order_ID"
//                      , FieldDefs: [
//                            FieldDef: {Name: "Order_ID",    Type: "string", Caption: "inv.bo.Order.Head.OrderID",   Size: 35,  IsRequired: true, ...}
//                          , FieldDef: {Name: "Order_Date",  Type: "date",   Caption: "inv.bo.Order.Head.OrderDate", Size: 10,  IsRequired: true, ...}
//                          , FieldDef: {Name: "Order_Num",   Type: "string", Caption: "inv.bo.Order.Head.OrderNum",  Size: 25,  IsRequired: true, ...}
//                          , ...
//                        ]
//                    } // Schema
//        ] // SchemaList
//
//      , DatasetList: [
//            Dataset: {  Name:     "OrderHead"
//                      , Caption:  "Order"
//                      , PKName:   "Order_ID"
//                      , Schema:   {}                      // enthält nur dann Schema-Daten, wenn IncludesSchemaPerDataset = true
//                      , Data: [{Order_ID: "xxxx", Order_Date: "2021-03-10", Order_Num: "900001", ...}]
//
//                      , DatasetList: [
//                            Dataset: {  Name:     "OrderPos"
//                                      , Caption:  "Order: Position"
//                                      , PKName:   "OrderPos_ID"
//                                      , Data: [
//                                            {OrderPos_ID: "xxxx", OrderPos_Num: 1, Obj_Num: "123456", ...}
//                                          , {OrderPos_ID: "xxxx", OrderPos_Num: 2, Obj_Num: "789012", ...}
//                                          , {OrderPos_ID: "xxxx", OrderPos_Num: 3, Obj_Num: "901234", ...}
//                                        ]
//                            } // Dataset
//                        ] // DatasetList
//            } // Dataset
//        ] // DatasetList
//
//    } // DataObject
//  }
//
//
//  # [ENTWURF] / Beispiel: Einzelner Kundendatensatz
//
//  {
//    DataObject: {
//
//        DataSchema: [
//            {  DatasetName: "Customer"
//             , PKName:      "Cust_ID"
//             , FieldDefs: [
//                   FieldDef: {Name: "Cust_ID",      Type: "string",  Caption: "Customer: ID",      Size: 35,  IsRequired: true,   ...}
//                 , FieldDef: {Name: "Cust_ID_Art",  Type: "string",  Caption: "Customer: Art-ID",  Size: 35,  IsRequired: true,   ...}
//                 , FieldDef: {Name: "Cust_Name1",   Type: "string",  Caption: "Customer: Name 1",  Size: 50,  IsRequired: true,   ...}
//                 , FieldDef: {Name: "Cust_Name2",   Type: "string",  Caption: "Customer: Name 2",  Size: 50,  IsRequired: false,  ...}
//                 , ...
//               ]
//            }
//        ] // DataSchema
//
//      , DatasetList: [
//            Dataset: {  Name:    "Customer"
//                      , Caption: "Customer"
//                      , Data: [{Cust_ID: "xxxx", Cust_ID_Art: "K_Std", Cust_Name1: "vedisys AG", Cust_Name2: null, ...}]
//            } // Dataset
//        ] // DatasetList
//
//    } // DataObject
//  }
//
//
//
//
//  H I S T O R Y
//
//  2021-05-31  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import { Observable }                 from 'rxjs';
import { map }                        from 'rxjs/operators';

import * as vsDataSet                 from './vsDataSet';
import * as vsDataFilter              from './vsDataFilter';
import { TvsHttpServiceComponent }    from '@vsLib/Services/vsHttp.service.component';

//
// TvsDataObject
//
// Data Object (Container for untyped data sets)
//
export class TvsDataObject {


//====== PRIVATE

// private     _DataMain:      JSON;

private     FIsRefreshing:      boolean                     = false;                      // $Rev TC 2021-05-31: added


//====== PUBLIC

// public      DataSourceID:                     string                          = null;
// public      QueryFields:                      string[]                        = [];


public      TestMode:       boolean                         = false;            // $Rev TC 2021-06-13: added
public      PKName:         string                          = null;
// public      FieldDefs:      any                             = [];            // type JSON? Eigene Klasse TvsFieldDefs?
public      SortDefs:       any                             = [];               // type JSON? Eigene Klasse TvsSortDefs?
public      ViewConfig:     any                             = [];               // $rev TC 2021-06-14: vorläufige Labor-Implementierung
//public      DataMain:       any                             = [];
public      Dataset:        vsDataSet.TvsDataSet            = new vsDataSet.TvsDataSet();

public      Filter:         vsDataFilter.TvsDataFilter      = new vsDataFilter.TvsDataFilter();
public      KennFieldDefs:  boolean                         = false;

public      get IsRefreshing():    boolean      {return this.FIsRefreshing};              // $Rev TC 2021-05-31: added
public      set IsRefreshing(a_bValue: boolean) {this.FIsRefreshing = a_bValue;};



//====== Constructor

constructor() {
    // ./.
} // constructor



//====== Methods (PUBLIC)



} // TvsDataObject


