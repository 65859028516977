//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Zusätzliche Informationen zum Schulbesuch von Berufsschülern.
//  Notes:    
//
//
//  H I S T O R Y
//
//  2023-08-16  MP   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import {  TvsHttpServiceComponent
        , TvsGetType }                      from '@vsLib/Services/vsHttp.service.component';
import { TvsDataFilter }                    from '@vsLib/vsDataFilter';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


@Component({
  selector:       'TchoolViewAntragEdit_School_Attendance',
  templateUrl:  './schoolViewAntragEdit_School_Attendance.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
})
export class TschoolViewAntragEdit_School_Attendance extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});


  public displayDialogError: boolean   = false;

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antragsauswahl';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {


    this.editForm.addControl('Ausb_School_Text',     new FormControl(this.dataModel.antrStell_Ausb_School_Text));

  } // ngOnInit

  ngAfterViewInit(): void {
    
    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }

  }


  public actionStepPrev(): void {
    this.dataSave();
    this.router.navigate(['antragEdit/antrag']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    
    if (bOK) {

      if (this.dataModel.antrag_Art === 'EA') {
        this.editForm.get('Ausb_School_Text').setValidators(Validators.required);
        this.editForm.get('Ausb_School_Text').updateValueAndValidity();
      }

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      this.dataSave();
      this.router.navigate(['antragEdit/erstattung']);
    }

  } // actionStepNext()



  public dataSave() :void{

    this.dataModel.antrStell_Ausb_School_Text = this.editForm.controls['Ausb_School_Text'].value;
    
    }


} //schoolViewAntragEdit_School_Attendance


