
  <form [formGroup]="this.editForm">

      <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>


      <vsLayoutGroup class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
      </vsLayoutGroup>

      <vsLayoutGroup class="col-lg-6 col-md-8 col-sm-12" Orientation="horizontal">

        <vsLayoutGroup class="col-12">
          <div #explanation_header class="vsFormTextDiv">
            <b>{{'explanation.reimbursement_heading'| translate}}</b>
          </div>
          <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
            {{'explanation.reimbursement_text'| translate}}
          </div>
        </vsLayoutGroup>

        <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.heading_application_data'| translate}}" Orientation="horizontal">

            <vsLayoutGroup class="col-12">
              <vsInputDateTime FrmCtlName="Antr_Datum"  label="{{'application.application_date'| translate}}" Disabled="true"></vsInputDateTime>
            </vsLayoutGroup>

            <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='E' || this.dataModel.antrag_Art==='EA'" class="col-12">
              <vsInputAutoComplete FrmCtlName="Grundantrag" label="{{'application_kind.heading_4_short'| translate}}" [suggestions]="lookupAntrag" placeholder="{{'application.input_required'| translate}}" FieldName="xAntrag_Info" MinLength=1 Dropdown=true ></vsInputAutoComplete>
            </vsLayoutGroup>

            <!-- <vsLayoutGroup class="col-12">
              <vsInputAutoComplete FrmCtlName="Schuljahr" label="{{'application.school_year'| translate}}" [suggestions]="lookupSchuljahr" placeholder="{{'application.input_required'| translate}}" FieldName="Schuljahr" MinLength=1 Dropdown=true ></vsInputAutoComplete>
            </vsLayoutGroup> -->

        </vsLayoutGroup>

      </vsLayoutGroup>

      <vsLayoutGroup class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
      </vsLayoutGroup>

    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton" style="margin-right: 10px;"></button>
      <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button>
  </div>



  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>



