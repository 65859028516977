 <div class="row vsMb-2">
    <label class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col vsInputGroup">
      <div *ngIf="ReadOnly=='true'">
        <div *ngIf="Disabled=='true'">

          <p-tieredMenu #menu [model]=TieredMenuData [popup]="true" ></p-tieredMenu>
          <div class="p-inputgroup">
            <input  class="vsFormControl"
            formControlName="{{FrmCtlName}}"
            type="text"
            id="input-{{FrmCtlName}}"
            pInputText
            placeholder="{{placeholder}}"
            readonly
            disabled
            />
            <button type="button" pButton pRipple icon="pi pi-chevron-right" styleClass="p-button-warn" (click)="menu.toggle($event)" disabled="true"></button>
          </div>

        </div>
        <div *ngIf="Disabled=='false'">

          <p-tieredMenu #menu [model]=TieredMenuData [popup]="true" ></p-tieredMenu>
          <div class="p-inputgroup">
            <button type="button" pButton pRipple icon="pi pi-chevron-right" styleClass="p-button-warn" (click)="menu.toggle($event)"></button>
            <input  class="vsFormControl"
            formControlName="{{FrmCtlName}}"
            type="text"
            id="input-{{FrmCtlName}}"
            placeholder="{{placeholder}}"
            pInputText
            readonly
            />
          </div>

        </div>
      </div>
      <div *ngIf="ReadOnly=='false'">
        <div *ngIf="Disabled=='true'">

          <p-tieredMenu #menu [model]=TieredMenuData [popup]="true" ></p-tieredMenu>
          <div class="p-inputgroup">
            <input  class="vsFormControl"
            formControlName="{{FrmCtlName}}"
            type="text"
            id="input-{{FrmCtlName}}"
            placeholder="{{placeholder}}"
            pInputText
            disabled
            />
            <button type="button" pButton pRipple icon="pi pi-chevron-right" styleClass="p-button-warn" (click)="menu.toggle($event)" disabled="true"></button>
          </div>

        </div>
        <div *ngIf="Disabled=='false'">

          <p-tieredMenu #menu [model]=TieredMenuData [popup]="true" ></p-tieredMenu>
          <div class="p-inputgroup">
            <input  class="vsFormControl"
            formControlName="{{FrmCtlName}}"
            type="text"
            id="input-{{FrmCtlName}}"
            pInputText
            placeholder="{{placeholder}}"
            />
            <button type="button" pButton pRipple icon="pi pi-chevron-right" styleClass="p-button-warn" (click)="menu.toggle($event)"></button>
          </div>

        </div>
      </div>
    </div>
  </div>



