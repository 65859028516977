//--------------------------------------------------------------------------------------------------
//  AMIS7core
//
//  Domain:   System
//  Title:    AccPasswordChange: (Dialog)
//  Notes:
//
//
//  H I S T O R Y
//
//  2022-09-21  MH   Initial Version
//--------------------------------------------------------------------------------------------------


import {  Component
        , OnInit
        , ComponentFactoryResolver
        , TemplateRef
        , ViewChild
        }                                         from '@angular/core';
import { FormGroup, FormControl, Validators }                 from '@angular/forms';

// vsLib
import * as vsCommon                              from '@vsLib/vsCommon';
import * as vsGlobalResources                     from '@vsLib/Services/vsGlobalResources.service';
import { TvsGetType, TvsHttpServiceComponent }    from '@vsLib/Services/vsHttp.service.component';

// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';


// AMIS7core
// import * as AMIS7coreConst                        from '@AMIS7core/$Common/comConst';
// import { TvsDataObject }                          from '@vsLib/vsDataObject';

//PrimeNG
import { DialogService, DynamicDialogRef }        from 'primeng/dynamicdialog';
import { DynamicDialogConfig }                    from 'primeng/dynamicdialog';
import { TvsViewData }                            from '@vsLib/View/components/vsViewData/vsViewData.component';
import { Router }                                 from '@angular/router';
import { MessageService }                         from 'primeng/api';




type TranslationMessageItemsType = {
  severity: string;
  summary: string;
  detail: string;
}

@Component({
  selector:       'sysViewAccPwdChangeDlg',
  templateUrl:  './sysViewAccPwdChangeDlg.component.html'
})
export class TsysViewAccPwdChangeDlg extends TvsViewData implements OnInit {

    //====== Properties (PRIVATE)


  //====== Properties (PUBLIC)

  public      FormGroup:                FormGroup             = new FormGroup({});
  public      displayDialogError:       boolean               = false;

  public      displayPasswordDifferent: boolean               = false;


  public      translationMessageItems:           TranslationMessageItemsType[]                          = [];


  //====== Constructor

  constructor(protected router:                   Router
            , protected globalResources:          vsGlobalResources.TvsGlobalResources
            , public    translate:                TranslateService     // [ngx-translate]
            , protected HttpServiceComponent:     TvsHttpServiceComponent
            , public    ref:                      DynamicDialogRef
            , public    config:                   DynamicDialogConfig
            , protected messageService:           MessageService
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.FormGroup.addControl('User_Pwd_New',      new FormControl(null));
    this.FormGroup.addControl('User_Pwd_Confirm',  new FormControl(null));


    this.translationMessageItems = [
      {severity:'success',  summary: 'Info', detail: 'password_change.text_new_password_saved'}
    ];

  }

  ngOnInit(): void {
  } // ngOnInit

  onOK(){

    this.FormGroup.get('User_Pwd_New').setValidators(Validators.required);
    this.FormGroup.get('User_Pwd_New').updateValueAndValidity();

    this.FormGroup.get('User_Pwd_Confirm').setValidators(Validators.required);
    this.FormGroup.get('User_Pwd_Confirm').updateValueAndValidity();

    if (this.FormGroup.get('User_Pwd_New').value !== this.FormGroup.get('User_Pwd_Confirm').value) {
      // alert('Beide Einträge müssen gleich sein!');

      this.displayPasswordDifferent = true;
    }
    else if (this.FormGroup.valid) {
      // console.log('GlobalResources2: ', this.globalResources.WebAccProfile.AccLoginID);

      let envelope: vsCommon.TWebParamPwdChange_Envelope = new vsCommon.TWebParamPwdChange_Envelope();
      envelope.data.userID    = this.globalResources.WebAccProfile.AccLoginID;
      envelope.data.password  = this.FormGroup.get('User_Pwd_New').value;


      // this.HttpServiceComponent.HttpServicePostRequest('webActionUserAccPwdChange',
      // '&AccLoginID=' + this.globalResources.WebAccProfile.AccLoginID + '&AccLoginPwdNew=' + this.FormGroup.get('User_Pwd_New').value, '')
      this.HttpServiceComponent.HttpServicePostRequest('webActionUserAccPwdChange',  '', JSON.stringify(envelope))
        .subscribe(response => {
          let resp: any = response;
            if (resp.result) {

              // alert('Das neue Kennwort wurde gespeichert');
              this.translateMessageError();
            }
        });

      this.ref.close();
    }
    else {
      this.displayDialogError = true;
    }
  }

  translateMessageError() {

      this.translationMessageItems.map((item) => {
        //if-Abfrage falls noch andere Message-Items hinzukommen
        if (item.detail === 'password_change.text_new_password_saved') {
          this.translate.get(item.detail).subscribe((res)=>{
            this.messageService.add(
               {severity: item.severity,
                icon: item.summary,
                detail: res
              });
          });
        }
      });

  }

  onAbbruch(){
    this.ref.close(null);
  }

} // TsysViewAccGroupEdit


