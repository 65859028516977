//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Schüler/Eltern
//
//  Domain:   school
//  Title:    Bestätigungsseite nach Registrierung
//  Notes:
//
//
//  H I S T O R Y
//
//  2022-11-14  MP   Initial Version
//--------------------------------------------------------------------------------------------------


import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router }                           from '@angular/router';

import {  Directive
        , ViewChild
        , ViewContainerRef, TemplateRef
        , ComponentFactoryResolver}         from '@angular/core';

import { FormGroup, FormControl }           from '@angular/forms';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as schoolDataModel                 from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';



@Component({
  selector:       'sysViewUserReg_Confirmation',
  templateUrl:  './sysViewUserReg_Confirmation.component.html',
  // styleUrls:   ['./sysViewUserReg_Confirmation.component.scss']
})
export class TsysViewUserReg_Confirmation extends vsView.TvsView implements OnInit, AfterViewInit {



  //PrimeNG InputSwitchModule

  InputSwitch: boolean = false;

  
  //====== Properties (PRIVATE)

  // public      editForm:                     FormGroup               = new FormGroup({});


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public      MenuUser:                       MenuItem[];           // PrimeNG

  static      ProgFuncID:                     string    = 'TEST';   // [LABOR] Test für Zugriff auf class properties

  // public      Anrede:                         any[];


  //====== ViewChilds

  // ./.



  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Landing Page';

  } // constructor


  //====== NG event handler


  ngOnInit(): void {

    //Nach abschicken des Antrages das dataModel leeren
    // this.dataModel.EmptyData();

  } // ngOnInit


  ngAfterViewInit(): void {
  } // ngAfterViewInit

  public actionForward(): void {
    this.router.navigateByUrl('login');
  }

} // TbmViewTicket_Confirmation

