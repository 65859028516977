
<div class="container-xxl vsMainContent"><!--container-xxl Start-->

    <vsLayoutGroup class="col-12">
      <div #explanation_header class="vsFormTextDiv">
        <b>{{'explanation.confirmation_data_heading'| translate}}</b>
      </div>
      <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
        {{'explanation.confirmation_data_text'| translate}}
      </div>
    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-12">
        <h4>{{'application.commit.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-12" Orientation="horizontal" >
      <vsLayoutGroup class="col-xl-6">
        <h4>{{'application.commit.application.heading'| translate}}</h4>
      </vsLayoutGroup>

    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.application.application_kind'| translate}}</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataModel.antrag_Art==='U'" class="vsFormTextDiv">{{'application_kind.heading_1'| translate}}</div>
            <div *ngIf="this.dataModel.antrag_Art==='E'" class="vsFormTextDiv">{{'application_kind.heading_2'| translate}}</div>
            <div *ngIf="this.dataModel.antrag_Art==='I'" class="vsFormTextDiv">{{'application_kind.heading_3'| translate}}</div>
            <div *ngIf="this.dataModel.antrag_Art==='G'" class="vsFormTextDiv">{{'application_kind.heading_4'| translate}}</div>
            <div *ngIf="this.dataModel.antrag_Art==='GA'" class="vsFormTextDiv">{{'application_kind.heading_5'| translate}}</div>
            <div *ngIf="this.dataModel.antrag_Art==='EA'" class="vsFormTextDiv">{{'application_kind.heading_6'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>
      </vsLayoutGroup>

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.application.application_date'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrag_Datum}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup>

      <vsLayoutGroup *ngIf="KennTransportOptions"class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.information_about_transport'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.transp_Art_Rec.Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup>

      <vsLayoutGroup *ngIf="KennApplicationReason"class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.application_reason'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.transp_Reason_Rec.Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Antrag()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_application'| translate"></button>
      </div>

    </vsLayoutGroup><!--Ende Angaben zur Fahrt-->

    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.student.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel" width="180px">{{'application.commit.student.student'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.azubi_Anrede_Rec?.Anrede_Name}} {{this.dataModel.azubi_Nachname}}, {{this.dataModel.azubi_Vorname}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.student.date_birth'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.azubi_Geb_Datum}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

              <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

          <vsLayoutGroup *ngIf="kennDisplayStudentAddress" class="col" Orientation="horizontal">
              <label class="vsInputLabel">{{'general.address.address'| translate}}</label>
            <vsLayoutGroup class="col">
              <div *ngIf="!this.dataModel.azubi_Ortsteil" class="vsFormTextDiv">{{this.dataModel.azubi_Strasse}} {{this.dataModel.azubi_HausNr}}, <span *ngIf="this.dataModel.azubi_Adr_Zusatz">{{this.dataModel.azubi_Adr_Zusatz}},</span></div>
              <div *ngIf="!this.dataModel.azubi_Ortsteil" class="vsFormTextDiv">{{this.dataModel.azubi_PLZ}} {{this.dataModel.azubi_Ort}}</div>
              <div *ngIf="this.dataModel.azubi_Ortsteil" class="vsFormTextDiv">{{this.dataModel.azubi_Strasse}} {{this.dataModel.azubi_HausNr}}, <span *ngIf="this.dataModel.azubi_Adr_Zusatz">{{this.dataModel.azubi_Adr_Zusatz}},</span></div> 
              <div *ngIf="this.dataModel.azubi_Ortsteil" class="vsFormTextDiv">{{this.dataModel.azubi_Ortsteil}}, {{this.dataModel.azubi_PLZ}} {{this.dataModel.azubi_Ort}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup *ngIf="kennDisplayStudentAddress" class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.phone'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{this.dataModel.azubi_Phone}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup *ngIf="kennDisplayStudentAddress" class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.mobile'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{this.dataModel.azubi_Mobile}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup *ngIf="kennDisplayStudentAddress" class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.email'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{this.dataModel.azubi_eMail}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

      </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Student()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_student'| translate"></button>
      </div>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="!this.dataModel.KennAzubiGesVertr" class="col" Orientation="vertical">
      <h4>{{'application.commit.legal_rep.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="!this.dataModel.KennAzubiGesVertr" class="col" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel" width="180px">{{'application.commit.legal_rep.heading'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_Anrede_Rec?.Anrede_Name}} {{this.dataModel.antrStell_Titel_Rec?.Titel_Kuerzel}} {{this.dataModel.antrStell_Nachname}}, {{this.dataModel.antrStell_Vorname}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.legal_rep.date_birth'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_Geb_Datum}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

              <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

          <vsLayoutGroup class="col" Orientation="horizontal">
              <label class="vsInputLabel">{{'general.address.address'| translate}}</label>
            <vsLayoutGroup class="col">
              <div *ngIf="!this.dataModel.antrStell_Ortsteil" class="vsFormTextDiv">{{this.dataModel.antrStell_Strasse}} {{this.dataModel.antrStell_HausNr}},</div> 
              <div *ngIf="!this.dataModel.antrStell_Ortsteil" class="vsFormTextDiv">{{this.dataModel.antrStell_PLZ}} {{this.dataModel.antrStell_Ort}}</div>
              <div *ngIf="this.dataModel.antrStell_Ortsteil" class="vsFormTextDiv">{{this.dataModel.antrStell_Strasse}} {{this.dataModel.antrStell_HausNr}}, {{this.dataModel.antrStell_Ortsteil}},</div>
              <div *ngIf="this.dataModel.antrStell_Ortsteil" class="vsFormTextDiv">{{this.dataModel.antrStell_PLZ}} {{this.dataModel.antrStell_Ort}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.phone'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{this.dataModel.antrStell_Phone}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.mobile'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv ">{{this.dataModel.antrStell_Mobile}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.email'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv ">{{this.dataModel.antrStell_eMail}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

      </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Student()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_legal_rep'| translate"></button>
      </div>
    </vsLayoutGroup>

    <!--Beschreibung -->

    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.school.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-6">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.school.school_name'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antr_Ausb_Staett_Rec?.AusbStaett_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.school.class'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv" style="overflow-wrap: break-word;">{{this.dataModel?.antr_Klasse_Beg_Nr}}{{this.dataModel?.antr_Klasse_Beg_Zusatz}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

      <vsLayoutGroup class="col-xl-6">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.school.school_kind'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antr_Ausb_Staett_Rec?.Art_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='GA' || this.dataModel.antrag_Art==='EA'" class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.school_company.heading_vocational_school'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_Ausb_School_Text}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Schule()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_school'| translate"></button>
      </div>

    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='GA'" class="col" Orientation="vertical">
      <h4>{{'application.school_company.heading_training_company'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='GA'" class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.school_company.training_company'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_AusbBetr_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'general.address.address'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_AusbBetr_Strasse}} {{this.dataModel.antrStell_AusbBetr_HausNr}}, {{this.dataModel.antrStell_AusbBetr_Adr_Zusatz}}, {{this.dataModel.antrStell_AusbBetr_Ortsteil}}, {{this.dataModel.antrStell_AusbBetr_PLZ}} {{this.dataModel.antrStell_AusbBetr_Ort}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!-- Ende col-xl-6 -->

    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='E' || this.dataModel.antrag_Art==='EA'" class="col" Orientation="vertical">
      <h4>{{'application.reimbursement.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='E' || this.dataModel.antrag_Art==='EA'" class="col" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-12">

        <table id="mockTableErstatt">
          <tr>
            <th scope="col-2">{{'application.reimbursement.table_heading_item'| translate}}</th>
            <th style="text-align: center;"   scope="col">{{'application.reimbursement.table_heading_amount'| translate}}</th>
            <th style="text-align: right;"    scope="col">{{'application.reimbursement.table_heading_item_price'| translate}}</th>
            <th style="text-align: right;"    scope="col">{{'application.reimbursement.table_heading_distance'| translate}}</th>
            <th style="text-align: right;"    scope="col">{{'application.reimbursement.table_heading_total_price'| translate}}</th>
          </tr>
          <tr *ngFor="let item of dataModel.articles">
          <td>{{item.bezeichnung}}</td>
          <td style="text-align: center;">{{item.amount}}</td>
          <td style="text-align: right;">{{item.price.toFixed(2)}} €</td>
          <td style="text-align: right;">{{item.streckenlaenge}}</td>
          <td style="text-align: right;">{{item.totalprice.toFixed(2)}} €</td>
        </tr>
        </table>

        <vsLayoutGroup class="col" Orientation="horizontal">
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-12-->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Erstatt()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_reimburse'| translate"></button>
      </div>

    </vsLayoutGroup>


    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='I'" class="col" Orientation="vertical">
      <h4>{{'application.individual.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='I'" class="col" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-6 row">
        <vsLayoutGroup class="col" Orientation="horizontal">
        <table id="mockTableIndiv" style="margin-left: -6px;">
          <tr>
            <th> </th>
            <th>{{'application.individual.table_heading_monday'| translate}}</th>
            <th>{{'application.individual.table_heading_tuesday'| translate}}</th>
            <th>{{'application.individual.table_heading_wednesday'| translate}}</th>
            <th>{{'application.individual.table_heading_thursday'| translate}}</th>
            <th>{{'application.individual.table_heading_friday'| translate}}</th>
            <th>{{'application.individual.table_heading_saturday'| translate}}</th>
            <th>{{'application.individual.table_heading_sunday'| translate}}</th>
          </tr>
          <tr>
            <th>{{'application.individual.table_heading_school_start'| translate}}</th>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Monday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Tuesday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Wednesday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Thursday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Friday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Saturday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupHome.Time_PickupHome_Sunday}}</td>
          </tr>
          <tr>
            <th>{{'application.individual.table_heading_school_end'| translate}}</th>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Monday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Tuesday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Wednesday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Thursday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Friday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Saturday}}</td>
            <td>{{this.dataModel.antr_Data_Individual.pickupSchool.Time_PickupSchool_Sunday}}</td>
          </tr>
        </table>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.individual.wheelchair'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataModel.antr_Data_Individual.additInfo.Wheelchair" class="vsFormTextDiv">{{'application.individual.wheelchair_yes'| translate}}</div>
            <div *ngIf="!this.dataModel.antr_Data_Individual.additInfo.Wheelchair" class="vsFormTextDiv">{{'application.individual.wheelchair_no'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.individual.accomp_person'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataModel.antr_Data_Individual.additInfo.Assistant" class="vsFormTextDiv">{{'application.individual.accomp_person_yes'| translate}}</div>
            <div *ngIf="!this.dataModel.antr_Data_Individual.additInfo.Assistant" class="vsFormTextDiv">{{'application.individual.accomp_person_no'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>
        
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.individual.add_info'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataModel.antr_Data_Individual.additInfo.AdditInfoMsg" class="vsFormTextDiv">{{this.dataModel.antr_Data_Individual.additInfo.AdditInfoMsg}}</div>
            <div *ngIf="!this.dataModel.antr_Data_Individual.additInfo.AdditInfoMsg" class="vsFormTextDiv">{{'application.individual.add_info_no'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Indiv()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_indiv'| translate"></button>
      </div>

    </vsLayoutGroup>


    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='E' || this.dataModel.antrag_Art==='EA'" class="col" Orientation="vertical">
      <h4>{{'application.commit.bank.heading'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='E' || this.dataModel.antrag_Art==='EA'" class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.account_owner'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv" >{{this.dataModel.antrStell_Kto_Inh}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.IBAN'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_IBAN}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.bank_name'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_Bank_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!-- Ende col-xl-6 -->

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
        
        </vsLayoutGroup>
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.BIC'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{this.dataModel.antrStell_Bank_BIC}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
        
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6 -->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!--<p-button (click)="actionStepNext()">Weiter</p-button>-->
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Bank()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_bank'| translate"></button>
      </div>

    </vsLayoutGroup><!--Ende col-xl-12 Panel -->
    
    
    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.attachment.heading'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-12" Orientation="horizontal" isPanel="true">

            <vsLayoutGroup class="col" Orientation="vertical">

              <div *ngFor="let item of dataModel.files">
                <div style="display: flex; justify-content : space-between">
                        
                  <button pButton label="&quot;{{item.UploadFileName}}&quot;" class="vsElternButton vsMb-2"></button>

                </div>
              </div>

            </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Doc()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_attachment'| translate"></button>
      </div>

    </vsLayoutGroup>
    
    
    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='G' || this.dataModel.antrag_Art==='GA'" class="col" Orientation="vertical">
      <h4>{{'application.commit.spec_circumstances.heading'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="this.dataModel.antrag_Art==='G' || this.dataModel.antrag_Art==='GA'" class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-12" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.spec_circumstances.explanation'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDivParagraph">{{this.dataModel.antr_Bes_Erlaeut}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
          <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Besond()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_spec_circumstances'| translate"></button>
        </div>

      </vsLayoutGroup><!-- Ende col-xl-12 -->

    </vsLayoutGroup><!--Ende col-xl-12 Panel -->

    <form [formGroup]="this.editForm">
    <!-- Hinweistext mit Checkbox-->
    <vsLayoutGroup class="col mt-3" Orientation="horizontal">
      <vsLayoutGroup class="col-1" Orientation="vertical">
        <!-- <vsInputCheckBox labelContent=""></vsInputCheckBox> -->
        <vsInputCheckBox noLabel=true FrmCtlName="Zust_Box" inputId="zust_box"></vsInputCheckBox>
      </vsLayoutGroup>
      <vsLayoutGroup class="col-11 mt-3" Orientation="vertical">
        <label id="zust_box_descr_1" for="zust_box" style="font-size: 0.9rem; color: #000; align-self:center; display:block; padding: auto 0;" [innerHtml]="'application.commit.additions.text_checkbox_data_privacy'| translate"></label>
      </vsLayoutGroup>
    </vsLayoutGroup>

  </form><!-- Formulare Ende-->
    <!-- Hinweistext Datenschutz-->
    <vsLayoutGroup class="col mt-3" Orientation="horizontal">
      <vsLayoutGroup class="col-10">
        <p style="font-size: 0.9rem; color: #000;" [innerHtml]="'application.commit.additions.text_data_privacy'| translate"></p>
      </vsLayoutGroup>
    </vsLayoutGroup>


</div><!--container-xxl Ende-->


  <!-- <pre>{{Summary}}</pre> -->
  <div style="margin-top: 3rem; margin-bottom: 1.5rem;">
    
    <button pButton label="{{'application.button_back'| translate}}"              (click)="actionStepPrev()"  class="vsElternButton vsMb-2" style="margin-right: 10px;"></button>
    <button pButton label="{{'application.button_cancel'| translate}}"            (click)="actionDialogCancel()"  class="vsElternButton vsMb-2" style="margin-right: 10px;"></button>
    <button pButton label="{{'application.button_application_send'| translate}}"  (click)="ValidateData()"        class="vsElternButton vsMb-2" [disabled]="this.dataModel.isSubmitted"></button>
  </div>

<p-toast></p-toast>

<p-dialog header="{{'dialog.dialog_cancel_application_heading'| translate}}" [(visible)]="displayCancelDialog" [style]="{width: '25vw'}" [baseZIndex]="10000"
  class="vsDialog vsDanger">
  <p class="vsDialogText">{{'dialog.dialog_cancel_application_text'| translate}}</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="displayCancelDialog=false; actionCancel();" label="OK"
        class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>

    </div>
  </ng-template>
</p-dialog>


