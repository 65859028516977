//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Schüler/Eltern
//
//  Domain:   school
//  Title:    Bestätigungsseite
//  Notes:
//
//
//  H I S T O R Y
//
//  2022-05-11  MP   Initial Version
//--------------------------------------------------------------------------------------------------


import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router }                           from '@angular/router';

import {  Directive
        , ViewChild
        , ViewContainerRef, TemplateRef
        , ComponentFactoryResolver}         from '@angular/core';

import { FormGroup, FormControl }           from '@angular/forms';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as schoolDataModel                 from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';



// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}



@Component({
  selector:       'TschoolViewAntragEdit_Confirmation',
  templateUrl:  './schoolViewAntragEdit_Confirmation.component.html',
  // styleUrls:   ['./TschoolViewAntragEdit_Confirmation.component.scss']
})
export class TschoolViewAntragEdit_Confirmation extends vsView.TvsView implements OnInit, AfterViewInit {



  //PrimeNG InputSwitchModule

  InputSwitch: boolean = false;

  
  //====== Properties (PRIVATE)

  public      editForm:                     FormGroup               = new FormGroup({});


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public      MenuUser:                       MenuItem[];           // PrimeNG

  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  static      ProgFuncID:                     string    = 'TEST';   // [LABOR] Test für Zugriff auf class properties

  // public      Anrede:                         any[];


  //====== ViewChilds

  // ./.



  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Landing Page';

  } // constructor


  //====== NG event handler


  ngOnInit(): void {

    //Nach abschicken des Antrages das dataModel leeren
    this.dataModel.EmptyData();

  } // ngOnInit


  ngAfterViewInit(): void {
  } // ngAfterViewInit

  public actionForward(): void {
    this.router.navigateByUrl('main');
  }

} // TbmViewTicket_Confirmation

