//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsProgFunc.ts
//  Notes:  Verwaltung von Programmfunktionen
//
//
//  == Programmfunktionen
//
//  Eine Programmfunktion repräsentiert eine bestimmte aufrufbare Funktion einer Applikation. Der
//  Aufruf einer Programmfunktion erfolgt über einen Dispatcher (TvsAppDispatcher), der die zuge-
//  hörige View ermittelt und Validierungen durchführt (beispielsweise der Benutzerzugriffsrechte).
//
//  Jede einzelne Programmfunktion einer App wird durch eine eindeutige ID identifiziert. Diese
//  werden in der zentralen Datenbank verwaltet und können über Administrator-Funktionen (beispiels-
//  weise über AMIS7admin) mit rollenbasierten Zugriffsrechten versehen werden.
//
//
//  == Features & Anwendungsmöglichkeiten
//
//  # Basis für den Dispatcher
//
//  # Basis für die dynamische Erzeugung von Menüstrukturen zur Navigation innerhalb einer App.
//    - Die Eigenschaft MenuSectionCaption kann verwendet werden, um
//
//
//  H I S T O R Y
//
//  2021-01-19  TC   Genesis
//--------------------------------------------------------------------------------------------------

// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';



//
// TvsProgFuncItem
//
// Repräsentiert eine Programmfunktion
//
export class TvsProgFuncItem {

  public  ProgFuncID:             string                      = null;
  public  AppViewType:            vsCommon.TvsAppViewType     = null;
  public  ViewClass:              any                         = null;

  public  Active:                 boolean                     = false;    // Programmfunktion ist innerhalb der App aktiv? (abhängig von z.B. Zugriffsrechten, erworbener Lizenz etc.)
  public  AdminOnly:              boolean                     = false;    // Programmfunktion nur für Admins zugänglich?

  public  MenuSectionIdx:         number                      = -1;       // Memü: Position des Menübereiches
  public  MenuSectionCaption:     string                      = null;     // Menü: Anzuzeigender Name des Menübereiches
  public  MenuSectionIconURL:     string                      = null;     // Menü: Anzuzeigendes Icon des Menübereiches

  public  MenuProgFuncIdx:        number                      = -1;       // Memü: Position der Programmfunktion innerhalb eines Menübereiches
  public  MenuProgFuncCaption:    string                      = null;     // Menü: Anzuzeigender Name der Programmfunktion
  public  MenuProgFuncIconURL:    string                      = null;     // Menü: Anzuzeigendes Icon der Programmfunktion


  //====== Constructor

  constructor(a_sProgFuncID: string, a_avt: vsCommon.TvsAppViewType, a_classView: any, a_bAdminOnly: boolean) {
    this.ProgFuncID     = a_sProgFuncID;
    this.AppViewType    = a_avt;
    this.ViewClass      = a_classView;
    this.AdminOnly      = a_bAdminOnly;
  }; // constructor

} // TvsProgFuncItem



//
// TvsProgFuncList
//
// Container für ProgFuncItem
//
export class TvsProgFuncList extends Array {


  //====== Properties (PUBLIC)

  // ./.


  //====== Properties (PROTECTED)

  // ./.


  //====== Properties (PRIVATE)

  // ./.


  //====== Methods (PUBLIC)


  //------------------------------------------------------------------------------------------------
  // Method:  itemAdd
  //
  // Args:    a_sProgFuncID                 Programmfunktions-ID
  //          a_avt                         TvsAppViewType (List, Edit)
  //          a_classView                   Konkrete View-Klasse
  //
  // Result:  Neu erzeugtes ProgFunc-Item
  //
  // Notes:   Fügt ein neues ProgFunc-Item der Liste hinzu
  //------------------------------------------------------------------------------------------------

  public itemAdd(a_sProgFuncID: string, a_avt: vsCommon.TvsAppViewType, a_classView: any, a_bAdminOnly: boolean = false): TvsProgFuncItem {

    let item: TvsProgFuncItem   = new TvsProgFuncItem(a_sProgFuncID, a_avt, a_classView, a_bAdminOnly);
    this.push(item);

    return item;

  } // itemAdd


  //------------------------------------------------------------------------------------------------
  // Method:  itemFind
  //
  // Args:    a_sProgFuncID                 Programmfunktions-ID
  //          a_avt                         TvsAppViewType (List, Edit)
  //
  // Result:  Gefundenes ProgFunc-Item oder null (wenn nicht gefunden)
  //
  // Notes:   Sucht ein ProgFunc-Item in der Liste
  //------------------------------------------------------------------------------------------------

  public itemFind(a_sProgFuncID:                string
                , a_avt:                        vsCommon.TvsAppViewType): TvsProgFuncItem
  {

    let itemResult:       TvsProgFuncItem       = null;
    let itemSearch:       TvsProgFuncItem       = null;
    let bFound:           boolean               = false;


    for(let i = 0; ((i < this.length) && !bFound); i++) {
      itemSearch  = this[i];

      bFound      = true;
      bFound      = bFound && itemSearch.ProgFuncID   === a_sProgFuncID;
      bFound      = bFound && itemSearch.AppViewType  === a_avt;
    } // for

    if (bFound) {
      itemResult  = itemSearch;
    }

    return itemResult;

  } // itemFind


  //------------------------------------------------------------------------------------------------
  // Method:  itemsClear
  //
  // Args:    ./.
  //
  // Result:  void
  //
  // Notes:   Löscht alle Items aus der Liste
  //------------------------------------------------------------------------------------------------

  public itemsClear(): void {
    this.length = 0;
  } // itemsClear

} // TvsProgFuncList


