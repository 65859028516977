


<!-- Header: Standard ----------------------------------------------------------------------------->
  
  <appHeader></appHeader>


<!-- Main Content --------------------------------------------------------------------------------->

  <div class="container-xxl vsMainContentContainer">

    <!--
      Assistent zur Erfassung und Bearbeitung eines neuen Antrages
    -->
    <nav>
      <h4 *ngIf="this.dataModel.antrag_Art==='U'" class="mb-4 vsElternLoginTitle">
        {{'application_kind.heading_1'| translate}}
      </h4>
      <h4 *ngIf="this.dataModel.antrag_Art==='E'" class="mb-4 vsElternLoginTitle">
        {{'application_kind.heading_2'| translate}}
      </h4>
      <h4 *ngIf="this.dataModel.antrag_Art==='I'" class="mb-4 vsElternLoginTitle">
        {{'application_kind.heading_3'| translate}}
      </h4>
      <h4 *ngIf="this.dataModel.antrag_Art==='G'" class="mb-4 vsElternLoginTitle">
        {{'application_kind.heading_4'| translate}}
      </h4>
    </nav>

    <div style="margin-bottom:1.5rem;">
      <p-steps [model]="wizardItems" [(activeIndex)]="wizardAactiveIndex" [readonly]="true"></p-steps>
    </div>

    <router-outlet></router-outlet>

  </div> <!-- Ende container-xxl -->


<!-- Footer --------------------------------------------------------------------------------------->

  <appFooter></appFooter>


<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


