
import { NgModule }                                  from '@angular/core';
import { Routes, RouterModule }                      from '@angular/router';

import { MainView }                                  from 'AMIS7school_Portal_Eltern/src/app/$main/mainView.component';
import { TmainViewLanding }                          from 'AMIS7school_Portal_Eltern/src/app/$main/mainViewLanding.component';
import { TsysViewUserLogin }                         from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserLogin.component';
import { TsysViewUserReg }                           from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserReg.component';
import { TsysViewUserRegLinkAccount }                from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserRegLinkAccount.component';
import { TsysViewUserRegSelection }                  from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserRegSelection.component';
import { TsysViewUserReg_Confirmation }              from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserReg_Confirmation.component';
import { TschoolViewAntragList }                     from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragList.component';
import { TschoolViewAntragEdit }                     from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit.component';
import { TschoolViewAntragEdit_Student }             from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Student.component';
import { TschoolViewAntragEdit_School }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_School.component';
import { TschoolViewAntragEdit_SEPA }                from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_SEPA.component';
import { TschoolViewAntragEdit_Doc }                 from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Doc.component';
import { TschoolViewAntragEdit_Commit }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Commit.component';
import { TschoolViewAntragEdit_Antrag }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag.component';
import { TschoolViewAntragEdit_Antrag_Uebernahme }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Uebernahme.component';
import { TschoolViewAntragEdit_Antrag_Erstattung }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Erstattung.component';
import { TschoolViewAntragEdit_Antrag_Individual }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Individual.component';
import { TschoolViewAntragLanding }                  from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragLanding.component';
import { TschoolViewAntragEdit_Besondere }           from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Besondere.component';
import { TschoolViewNachrichtenList }                from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewNachrichtenList.component';
import { TschoolViewAntragEdit_Confirmation }        from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Confirmation.component';
import { TschoolViewAntragEdit_ErstattungList }      from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_ErstattungList.component';
import { TschoolViewAntragEdit_StudentAddress }      from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_StudentAddress.component';
import { TschoolViewAntragEdit_StudentName }         from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_StudentName.component';
import { TschoolViewAntragEdit_PickDate }            from './school/schoolViewAntragEdit_PickDate.component';
import { TschoolViewAntragEdit_VertretungAddress }   from './school/schoolViewAntragEdit_VertretungAddress.component';
import { TschoolViewAntragEdit_Bankverb }            from './school/schoolViewAntragEdit_Bankverb.component';
import { TschoolViewAntragEdit_Schule }              from './school/schoolViewAntragEdit_Schule.component';
import { TschoolViewAntragEdit_Schule_Ausb }         from './school/schoolViewAntragEdit_Schule_Ausb.component';
import { TschoolViewAntragEdit_School_Attendance }   from './school/schoolViewAntragEdit_School_Attendance.component';
import { TschoolViewNachrichtEdit }                  from './school/schoolViewNachrichtEdit.component';
import { TschoolViewMeineNachrichtenList }           from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewMeineNachrichtenList.component';
import { TschoolViewAntrag }                         from './school/schoolViewAntrag.component';
import { TschoolViewAntragEdit_StudentSelection }    from './school/schoolViewAntragEdit_StudentSelection.component';

import { TsysViewProfileData }                       from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewProfileData.component';

import { TsysViewDataProtection}                     from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewDataProtection.component';
import { TsysViewImprint}                            from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewImprint.component';

import  * as schoolDataModel                         from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


export const routes: Routes = [

    { path: '',                       redirectTo: '/home',    pathMatch: 'full'},

    { path: 'home',                   component: TmainViewLanding},
    { path: 'login',                  component: TsysViewUserLogin},
    { path: 'userAccReg',             component: TsysViewUserReg},
    { path: 'main',                   component: MainView},
    { path: 'landing',                component: TschoolViewAntragLanding},
    //antragEdit sollte an 6ter Stelle bleiben, da an anderen Stellen die Subrouten des 6. Punktes überschrieben werden
    { path: 'antragEdit',   component: TschoolViewAntragEdit,
      children: [
        { path: '',                   redirectTo: 'antrag',    pathMatch: 'full'},
        { path: 'antrag',             component: TschoolViewAntragEdit_Antrag},
        { path: 'student',            component: TschoolViewAntragEdit_Student},
        { path: 'school',             component: TschoolViewAntragEdit_School},
        { path: 'sepa',               component: TschoolViewAntragEdit_SEPA},
        { path: 'doc',                component: TschoolViewAntragEdit_Doc},
        { path: 'besondere',          component: TschoolViewAntragEdit_Besondere},
        { path: 'pickup',             component: TschoolViewAntragEdit_PickDate},
        { path: 'commit',             component: TschoolViewAntragEdit_Commit},
        { path: 'erstattung',         component: TschoolViewAntragEdit_ErstattungList},
        { path: 'studentSelection',   component: TschoolViewAntragEdit_StudentSelection},
        { path: 'school_ausb',        component: TschoolViewAntragEdit_Schule_Ausb},
        { path: 'school_attendance',  component: TschoolViewAntragEdit_School_Attendance},
      ]
    },
    { path: 'messages',               component: TschoolViewNachrichtenList},
    { path: 'messageedit',            component: TschoolViewNachrichtEdit},
    { path: 'editStudentAddress',     component: TschoolViewAntragEdit_StudentAddress},
    { path: 'editStudentName',        component: TschoolViewAntragEdit_StudentName},
    { path: 'editVertretungAddress',  component: TschoolViewAntragEdit_VertretungAddress},
    { path: 'editBankverb',           component: TschoolViewAntragEdit_Bankverb},
    { path: 'editSchule',             component: TschoolViewAntragEdit_Schule},
    { path: 'antrView',               component: TschoolViewAntrag},
    { path: 'Confirmation',           component: TschoolViewAntragEdit_Confirmation},
    { path: 'profile',                component: TsysViewProfileData},
    { path: 'regConfirmation',        component: TsysViewUserReg_Confirmation},
    { path: 'regSelection',           component: TsysViewUserRegSelection},
    { path: 'regLinkAcc',             component: TsysViewUserRegLinkAccount},
    { path: 'mymessagelist',          component: TschoolViewMeineNachrichtenList},
    { path: 'imprint',                component: TsysViewImprint},
    { path: 'dataprotection',         component: TsysViewDataProtection},
    { path: '**',                     redirectTo: '/home'},

]; // routes

// };

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}


