  <form [formGroup]="this.FormGroup">
    <vsLayoutGroup class="col-12" Orientation="horizontal">

      <vsLayoutGroup class="col">
        <vsInputPwd FrmCtlName="User_Pwd_New"     label="{{'password_change.new_password'| translate}}" [KennFeedback]="true" [KennToggleMask]="true" labelWidth="220px"></vsInputPwd>
        <vsInputPwd FrmCtlName="User_Pwd_Confirm" label="{{'password_change.new_password_verification'| translate}}" [KennToggleMask]="true" labelWidth="220px"></vsInputPwd>
      </vsLayoutGroup>

    </vsLayoutGroup>
  </form> <!-- Form Ende -->

  <div style="float:right;">
    <button pButton pRipple type="button" (click)="onOK()" label="{{'dialog.button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK">
      <span>
        <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
      </span>
    </button>
    <button pButton pRipple type="button" (click)="onAbbruch()" label="{{'dialog.button_cancel'| translate}}" class="vsToolbarItem vsToolbarItemCancel">
      <span>
        <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
      </span>
    </button>
  </div>


  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>

  <p-dialog header="{{'password_change.dialog_password_same_header'| translate}}" [(visible)]="displayPasswordDifferent" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'password_change.text_password_same'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayPasswordDifferent=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>

<!-- <pre>{{ this.DOMain.Dataset.Data[0] | json}}</pre> -->
