


<!--
  Section: Title
-->
<div *ngIf="ViewProperties.SectionIsVisible_Title" class="vsViewSectionTitle">
  {{ ViewProperties.ViewTitle }}
</div>

<!--
  Section: Local Toolbar

  Toolbar items are configured via proerty ToolbarItems
-->

<!-- [LABOR] -->
<div *ngIf="ViewProperties.SectionIsVisible_Toolbar && ViewProperties.ToolbarItems.length > 0" class="vsViewSectionToolbar">

  <div class="vsToolbar">

    <div *ngFor="let item of ViewProperties.ToolbarItems; let i=index">
      <div *ngIf="item.Visible">
        <div *ngIf="item.ID == 'tbActions'">
          <div *ngIf="item.Enabled && item.tbLeft">

            <!-- <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
              (click)="ActionMenu.toggle($event)"></button> -->

              <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
              (click)="ActionMenu.toggle($event)" class="vsToolbarItem">
                <span>
                  <img alt="logo" src="{{item.src}}" />
                </span>
              </button>

          </div>
          <div *ngIf="!item.Enabled && item.tbLeft">

            <!-- <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}" disabled></button> -->

            <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}" disabled  class="vsToolbarItem">
              <span>
                <img alt="logo" src="{{item.src}}" />
              </span>
            </button>

          </div>
        </div>
        <div *ngIf="item.ID != 'tbActions'">
          <div *ngIf="item.Enabled && item.tbLeft">

            <!-- <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
              (click)="btnToolbar_OnClick(i)"></button> -->

              <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
              (click)="btnToolbar_OnClick(i)" class="vsToolbarItem">
                <span>
                  <img alt="logo" src="{{item.src}}" />
                </span>
              </button>

          </div>
          <div *ngIf="!item.Enabled && item.tbLeft">

            <!-- <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}" disabled></button> -->

            <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}" disabled  class="vsToolbarItem">
              <span>
                <img alt="logo" src="{{item.src}}" />
              </span>
            </button>

          </div>
        </div>
      </div>
    </div>

    <!-- Toolbar: rechter Bereich -->
    <div class="vsToolbarRight">

      <p-tieredMenu #ActionMenu [model]=MenuItemsAction [popup]="true" class="vsTieredMenu"></p-tieredMenu>
      <div *ngFor = "let item of ViewProperties.ToolbarItems; let i=index">
        <div *ngIf  = "item.Enabled && !item.tbLeft && item.Visible">
          <!-- <button
                pButton
                type      = "button"
                class     = "p-button-sm vsToolbarItem"
                label     = "{{item.Caption}}"
                (click)   = "btnToolbar_OnClick(i)"
          ></button> -->

          <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}"
          (click)="btnToolbar_OnClick(i)" class="vsToolbarItem">
            <span>
              <img alt="logo" src="{{item.src}}" />
            </span>
          </button>

        </div>
        <div *ngIf  = "!item.Enabled && !item.tbLeft && item.Visible">

          <!-- <button
                pButton
                type      = "button"
                class     = "p-button-sm vsToolbarItem"
                label     = "{{item.Caption}}"
                disabled
          ></button> -->

          <button pButton type="button" class="p-button-sm vsToolbarItem" label="{{item.Caption}}" disabled  class="vsToolbarItem">
            <span>
              <img alt="logo" src="{{item.src}}" />
            </span>
          </button>

        </div>
      </div>

    </div>  <!-- Toolbar: rechter Bereich -->

  </div>  <!-- Toolbar -->

</div>  <!-- Section Toolbar -->


<!-- Input Main Bereich -->
<div class="vsViewSectionMain">
  <!-- <div style="background-color: white; font-weight: bold; padding: 0.3rem;">{{ViewEditMode == 0 ? "Edit View: NEW" : "Edit View: EDIT" }} / PKValue: {{PKValue}}</div> -->
  <ng-template [ngTemplateOutlet]="TemplateSectionDataMain"></ng-template>
</div>


<!-- Input Detail / Tab Bereich -->
<!-- vemInsert hat keinen Detailbereich -->

<!-- <div *ngIf="(ViewEditMode === 1 || 2) && TemplateSectionDataDetail" class="vsViewSectionToolbar"> -->
  <!-- <div *ngIf="ShowDetails">
    <ng-template [ngTemplateOutlet]="TemplateSectionDataDetail"></ng-template>
  </div> -->
  <!-- <div *ngIf="!ShowDetails" style="display: none;">
    <ng-template [ngTemplateOutlet]="TemplateSectionDataDetail"></ng-template>
  </div> -->

  <!-- MP Insert Detailbereich ausblenden -->


 <div [style.visibility]="kennDetailSectionVisible ? 'visible' : 'hidden'">
    <ng-template [ngTemplateOutlet]="TemplateSectionDataDetail"></ng-template>
</div>

<!-- Dialogs -------------------------------------------------------------------------------------->

<!--
<p-dialog header="Abbruch?" [(visible)]="displayDialogCancel" [style]="{width: '40vw'}" [baseZIndex]="10000" class="vsDialog">
  <p>Wollen Sie die Eingabe wirklich abbrechen?</p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogCancel=false; this.actionClose()" label="OK" class="p-button-raised p-button-success"></button>
      <button pButton pRipple type="button" (click)="displayDialogCancel=false" label="Abbruch" class="p-button-secondary"></button>
  </ng-template>
</p-dialog>-->



<p-dialog header="Abbruch" [(visible)]="displayDialogCancel" [style]="{width: '17vw'}" [baseZIndex]="10000" class="vsDialog vsWarning">
  <p class="vsDialogText">Wollen Sie die Eingabe wirklich abbrechen?</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="displayDialogCancel=false; this.actionClose()" label="OK" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>

      <button pButton pRipple type="button" (click)="displayDialogCancel=false" label="Abbruch" class="vsToolbarItem vsToolbarItemCancel">
        <span>
          <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog header="Fehler" [(visible)]="displayDialogError" [style]="{width: '17vw'}" [baseZIndex]="10000" class="vsDialog vsDanger">
  <p class="vsDialogText">Es sind noch nicht alle Eingabefelder korrekt gefüllt!</p>
  <ng-template pTemplate="footer">
    <div style="display: inline-flex; margin-left:auto;" class="vsMb-2">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="OK" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
    </div>
  </ng-template>
</p-dialog>


 <!-- kennDetailSectionVisible: {{kennDetailSectionVisible}} -->

