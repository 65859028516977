//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   System
//  Title:    User Account: Login
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-07-30  TC   Initial Version
//--------------------------------------------------------------------------------------------------


import {  Component
        , OnInit
        , AfterViewInit
        , ViewChild
        , ElementRef
        , ComponentFactoryResolver }        from '@angular/core';

import { Router }                           from '@angular/router';

import { DomSanitizer,SafeUrl }             from '@angular/platform-browser';

import { HttpClient, HttpHeaders }          from '@angular/common/http';
import { DatePipe, formatDate }             from '@angular/common';


// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import {  FormControl
        , FormGroup
        , Validators
        , FormGroupDirective }              from '@angular/forms';
import * as vsViewDataEdit                  from '@vsLib/View/components/vsViewDataEdit/vsViewDataEdit.component';
import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

import {  DialogService
        , DynamicDialogRef
        , DynamicDialogConfig }             from 'primeng/dynamicdialog';


// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}



@Component({
  selector:       'sysViewUserLogin',
  templateUrl:  './sysViewUserLogin.component.html',
  styleUrls:   ['./sysViewUserLogin.component.scss'],
  providers: [FormGroupDirective, DialogService, DynamicDialogRef, DynamicDialogConfig]
})
export class TsysViewUserLogin extends vsViewDataEdit.TvsViewDataEdit implements OnInit, AfterViewInit {



  //====== Properties (PRIVATE)

  // ./.


  //====== Properties (PROTECTED)

  // ./.


  //====== Properties (PUBLIC)

  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  public      displayPwdResetMessage:         boolean               = false;

  public      displayPwdResetSuccessMessage:  boolean               = false;

  public      displayPwdResetErrorMessage:    boolean               = false;

  public      displayPwdOrAccountMessage:     boolean               = false;

  public      displayAccountActivateMessage:  boolean               = false;

  //====== ViewChilds


  @ViewChild('edtUserID',   {read: ElementRef})     edtUserID:          ElementRef;
  @ViewChild('edtUserPwd',  {read: ElementRef})     edtUserPwd:         ElementRef;



  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , protected componentFactoryResolver:   ComponentFactoryResolver
            , public    translate:                  TranslateService      // [ngx-translate]
            , public    ref:                        DynamicDialogRef
            , public    config:                     DynamicDialogConfig
            , public    dialogService:              DialogService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources, HttpServiceComponent, ref, config, dialogService);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'User Account: Login';


         // GUI Language
         let sURLBaseIconsFlags:   string  = '/assets/img/CountryFlags/';
         this.GUILanguageList = [
             {ID: 'de-DE',  Caption: 'German',   IconURL: sURLBaseIconsFlags + 'Germany.png'}
           , {ID: 'en-GB',  Caption: 'English',  IconURL: sURLBaseIconsFlags + 'UnitedKingdom.png'}
           , {ID: 'it-IT',  Caption: 'Italian',  IconURL: sURLBaseIconsFlags + 'Italy.png'}
           , {ID: 'zh-ZH',  Caption: 'Chinese',  IconURL: sURLBaseIconsFlags + 'CountryFlagUnknown.png'}
         ];

         this.GUILanguageSelected = this.GUILanguageList[0];   // [LABOR] TODO: Kann vermutlich beim Laden des UserProfiles erfolgen

        } // constructor



  //====== NG event handler


  ngOnInit(): void {

    // Noch keine weiteren Anmeldemöglichkeiten vorhanden, RadioButton erst einmal auskommentiert
    // this.editForm.addControl('radioButton',         new FormControl('N'));

    this.editForm.addControl('user_ID',             new FormControl(null));
    this.editForm.addControl('user_PWD',            new FormControl(null));
    this.editForm.addControl('loginButton',         new FormControl(null));

    this.editForm.addControl('passportLoginButton', new FormControl(null));

  } // ngOnInit


  ngAfterViewInit(): void {

  } // ngAfterViewInit


  //$REV MP 2022-09-13: Navigation zur Registrierungsseite
  public actionRegister(): void {

    this.router.navigateByUrl('/regSelection');

  } // actionRegister


  public btnPwdLost_OnClick() {

    let bOK: boolean = true;

    // $REV MP 2022-11-10: Abfrage auf gefülltes User-Feld für Passwortzurücksetzung
    if (bOK) {

      this.editForm.get('user_ID').setValidators(Validators.required);
      this.editForm.get('user_ID').updateValueAndValidity();

      bOK = this.editForm.valid;

      this.displayPwdResetErrorMessage = !bOK;

    }

    // $REV MP 2022-11-10: Zusätzlichen Bestätigungsdialog eingebaut, vorher wurde neues Passwort ohne weiter Rückfrage versendet
    if (bOK) {
      this.displayPwdResetMessage = bOK;
    }

  } // btnPwdLost_OnClick

  //Wird über Bestätigung im Dialog im HTML aufgerufen
  actionPwdLost() {

    // this.HttpServiceComponent.HttpServicePostRequest(
    //   'webActionUserAccPwdReset',
    //   // '&AccLoginID=' + this.edtUserID.nativeElement.value + '&URL=' + localStorage.getItem('location'),
    //   '&AccLoginID=' + this.editForm.controls['user_ID'].value + '&URL=' + localStorage.getItem('location'),
    //   ''
    // ).subscribe((response) => {
    //   let resp: any = response;
    //     if (resp.result) {
    //       // alert("{{'login.text_password_reset'| translate}}")
    //       this.displayPwdResetSuccessMessage = true;
    //     }
    // });
// alert( localStorage.getItem('location'));
    this.HttpServiceComponent.HttpServicePostRequest(
      'webActionUserAccPwdResetConfirm',
      '&AccLoginID=' + this.editForm.controls['user_ID'].value + '&URL=' + localStorage.getItem('location'),
      ''
    ).subscribe((response) => {
      let resp: any = response;
        if (resp.result) {
          // alert("{{'login.text_password_reset'| translate}}")
          // this.displayPwdResetSuccessMessage = true;
        }
    });

  }

  public btnLogin_OnClick() {

    let sUserID:      string    =  this.editForm.controls['user_ID'].value;
    let sUserPwd:     string    =  this.editForm.controls['user_PWD'].value;

    //TODO: Die Error-Messages in der vsGlobalResources sollten variablen hier im file triggern damit Fehlermessages angezeigt werden können.

    this.globalResources.WebAccProfile.Login(sUserID, sUserPwd);

  } // btnLogin_OnClick


  public redirect() {
      // if (this.globalResources.UserProfile.IsLoggedIn) {
        this.router.navigateByUrl('/main');
    // }
  }

      //------------------------------------------------------------------------------------------------
  // Method:  doDropdownGUILanguageOnClick
  //
  // Args:    a_iLangIndex            Index der ausgewählten Sprache bezogen auf GUILanguageList
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: Sprachauswahl
  //------------------------------------------------------------------------------------------------

  public doDropdownGUILanguageOnClick(a_iLangIndex: number) {
    console.log('>>>> Language selected:  ', a_iLangIndex);

    if (this.GUILanguageSelected.ID != this.GUILanguageList[a_iLangIndex].ID) {
      this.GUILanguageSelected = this.GUILanguageList[a_iLangIndex];
      this.translate.use(this.GUILanguageSelected.ID);
    }

  } // doDropdownGUILanguageOnClick

    //------------------------------------------------------------------------------------------------
  // Method:  doDropdownGUILanguageSetAsStandard
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: Ausgwählte Sprache als Standard speichern
  //------------------------------------------------------------------------------------------------

  // [MOCKUP]
  public doDropdownGUILanguageSetAsStandard() {
    alert('Ausgewählte Sprache als Standard einstellen:  ' + this.GUILanguageSelected.Caption);
  }



} // TsysViewUserLogin


