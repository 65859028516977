//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputCheckBox
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-07-09  MH   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl, FormGroup } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';



//
// vsInputCheckBox
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputCheckBox',
  templateUrl:  './vsInputCheckBox.component.html',
  styleUrls:   ['./vsInputCheckBox.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputCheckBox extends vsInput.TvsInput implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)


  @Input()    Value:              string              = '';

  @Input()    labelPosition:      string              = 'left';

  @Input()    checkBoxWidth:      string              = '40px';

  @Input()    inputId:            string              = 'input-'+this.FrmCtlName;

  @Input()    NoLabel:            boolean             = false;

// ... usw ...



  //====== Constructor

 // constructor
 constructor(protected FrmGrpDirective: FormGroupDirective) {
     super(FrmGrpDirective);
  }


  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
} // ngOnInit


  ngAfterViewInit(): void{
  }
  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args



} // vsInputCheckBox


