
<div class="vsDropdownSelect">

  <p-dropdown
    [(ngModel)]   = "this.SelectedData"
    [options]     = "this.LookupData"
    placeholder   = {{this.placeholder}}
    optionLabel   = {{this.LookupFieldDisplay}}
    [showClear]   = "false"
    (onChange)    = "this.onItemClick()"
  >
    <ng-template let-item pTemplate="item">
      <div class="dropdown-item">
        <div>{{ item[this.LookupFieldDisplay] }}</div>
      </div>
    </ng-template>
  </p-dropdown>

</div>


