//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputSearch
//  Notes:  Input control zur Eingabe von Text
//
//
//  H I S T O R Y
//
//  2021-06-29  TC   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsInput                         from '@vsLib/Components/vsInput/vsInput.component';
import { asLiteral } from '@angular/compiler/src/render3/view/util';



//
// TvsInputText
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsInputSearch',
  templateUrl:  './vsInputSearch.component.html',
  styleUrls:   ['./vsInputSearch.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputSearch extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input() placeHolder:    string = 'Suche (* = Joker)';
  @Input() Value:          string = '';

  //---- Events

  @Output()   onKeyPress:    EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();


  //---- Events


  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
    // console.log('>> TvsInputText.doOnXYZ(): args = ', record);
  } // args


  public keyPress(event: KeyboardEvent) {

    this.onKeyPress.emit(event);
  }

} // TvsInputSearch


