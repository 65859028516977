//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsAppDispatcher
//  Notes:  Service: App Dispatcher (Singleton)
//
//
//  Da der Inhalt der verschiedenen Views einer App über einen eigenen MultiTab- Mechanismus
//  dar gestellt werden muss, kann nicht das standardmäßige Routing des Angular-Frameworks ver-
//  wendet werden. Stattdessen wird ein eigener Dispatcher eingesetzt.
//
//  Der Dispatcher ist damit das zentrale Steuerungselement für jeden Aufruf von Programmfunktionen
//  innerhalb einer App und wird als Singleton über die Global Resources zur Verfügiung gestellt.
//
//
//  == Programmfunktionen
//
//  Jede einzelne Programmfunktion einer App wird durch eine eindeutige ID identifiziert. Diese
//  werden in der zentralen Datenbank verwaltet und können über Administrator-Funktionen (bei-
//  spielsweise über AMIS7admin) mit rollenbasierten Zugriffsrechten versehen werden.
//
//
//  == Features
//
//  # Validierungen:
//    - Standardmäßige Validierung: Prüfung von Benutzerzugriffsrechten
//    - Eventuell können in späteren Ausbaustufen auch beliebige zusätzliche anwendungspezifische
//      Validierungen unterstützt werden.
//
//  # Ermittlung bzw. Erzeugung der zu einer Programmfunktion gehörenden View (List View oder Edit View).
//
//  # Die Darstellung der Inhalte von Programmfunktionen wird vom Dispatcher ausgelöst, indem der
//    Observer (üblicherweise die MainView) über den abgeschlossenen Dispatch-Vorgang benachrichtigt
//    wird.
//
//  # Die eigentliche Darstellung liegt in der Verantwortung der aufrufenden View (beispielsweise
//    der MainView).
//
//  # Mögliche Stellen, an welchen eine View eingeblendet werden kann:
//    - MainView    (die hier eingeblendeten Views werden in die globale AppViewList aufgenommen)
//    - ListView    (zur Anzeige von Detail Views in einer Master ListView)
//    - PopupView   (z.B. zur Anzeige von EditViews oder Assistenten)
//
//
//  == Dispatch-Varianten
//
//  # Hauptprogrammfunktion, die über das Hauptmenü der Applikation (in der MainView) aufgerufen wird.
//    - Führt zu einer neuen View (meistens ListView), die über die MainView dargestellt wird
//      in einem Tab.
//    - Ob hierfür immer neue Tabs (und damit auch neue Views) erstellt werden, liegt in der Verant-
//      wortung der MainView.
//
//  # ActionEdit (z.B. aus einer ListView).
//    - Führt zu einer neuen EditView, die über die MainView dargestellt wird in einem Tab oder
//      alternativ in einer PopupView.
//
//  # Sonstige Actions (z.B. Anzeigen von Reports etc.).
//    - Führt zu einer neuen View, die über die MainView dargestellt wird in einem Tab oder beispiels-
//      weise in einer PopupView.
//
//
//  == [ENTWURF] Observer Pattern
//
//  # Der Aufruf einer Dispatch-Methode führt dazu, dass der Aufrufer als Observer registriert wird.
//    Hierfür übergibt sich der Aufrufer selbst als Argument der Dispatch-Methode:
//        dispatcher.dispatchProgFunc(this, ...)
//
//  # Am Ende der Dispatch-Methode wird kein Event ausgelöst, sondern der Observer wird direkt
//    benachrichtigt über Aufruf dessen Notify-Methode.
//
//  # Die Observer-Registrierung gilt jeweils nur für einen einzigen Aufruf.
//
//  # Dadurch wird sichergestellt, dass nicht mehrere Subscriber fäclschlicherweise über ein Event
//    benachrichtigt werden.
//
//  # FRAGE:
//    Geht das nicht auch einfacher ohne Event/Observer-Gedöns? Warum müssen wir hier von einer
//    asynchronen Verarbeitung ausgehen?
//
//    Sollte doch auch über einen einfachen Aufruf von dispatchProgFunc() funktionieren, wobei
//    dispatchProgFunc() dann die bisherigen EventArgs zurückliefert.
//
//
//  H I S T O R Y
//
//  2021-02-26  TC   Genesis
//--------------------------------------------------------------------------------------------------

import { Injectable, EventEmitter }         from '@angular/core';

// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsProgFunc                      from '@vsLib/vsProgFunc';



//
// TvsAppDispatcherEventArgs
//
// Event arguments for app dispatcher
//
// Diese Klasse dient dazu, die Anzahl der Methodenparameter für die Events zu minimieren.
// Um im Anwendungscode nicht mit mehreren unterschiedlichen Parameterklassen hantieren zu
// müssen, wird diese eine Klasee für alle möglichen Dispachter Events verwendet, auch wenn
// gegebenenfalls nicht alle Properties für alle Events benötigt werden.
//
// export class TvsAppDispatcherEventArgs {
export class TvsAppDispatcherResult {

  public  readonly      ProgFuncID:     string                      = null;
  public  readonly      AppViewType:    vsCommon.TvsAppViewType     = null;
  public  readonly      PKValue:        string                      = null;
  public  readonly      ViewClass:      any                         = null;
  public  readonly      AdminOnly:      boolean                     = false;
  public  readonly      ParamList?:     any;

  constructor(a_sProgFuncID:  string
            , a_avt:          vsCommon.TvsAppViewType
            , a_sPKValue:     string
            , a_classView:    any
            , a_adminOnly:    boolean = false
            , a_ParamList?:   any)
  {
    this.ProgFuncID     = a_sProgFuncID;
    this.AppViewType    = a_avt;
    this.PKValue        = a_sPKValue;
    this.ViewClass      = a_classView;
    this.AdminOnly      = a_adminOnly;
    this.ParamList      = a_ParamList;
  } // constructor

} // TvsAppDispatcherEventArgs



//
// TvsAppDispatcher
//
// Service: App Dispatcher (Singleton)
//
@Injectable({
  providedIn: 'root',
})
export class TvsAppDispatcher {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  // [Labor]
  // TODO:  Ist möglicherweise noch nicht der beste Ort hier
  public    ProgFuncList:           vsProgFunc.TvsProgFuncList                = new vsProgFunc.TvsProgFuncList();

  // Events
  // public    EventAfterDispatch:     EventEmitter<TvsAppDispatcherEventArgs>   = new EventEmitter();



  //====== Constructor

  constructor(private _globalResources: vsGlobalResources.TvsGlobalResources) {
  } // constructor



  //====== Methods (PUBLIC)


  //------------------------------------------------------------------------------------------------
  // Method:  dispatchProgFunc
  //
  // Args:    a_sProgFuncID                 Programmfunktions-ID
  //          a_avt                         TvsAppViewType (List, Edit)
  //          a_sPKValue                    Primary Key Value (ID) - nur für Edit Views
  //
  // Result:  TvsAppDispatcherEventArgs
  //
  // Notes:   Dispatcht auf Basis der Programmfunktions-ID
  //------------------------------------------------------------------------------------------------

  public getProcFuncList(): any[] {
    return this._globalResources.UserProfile.UserRights.progFuncList;
  }


  public dispatchProgFunc(a_sProgFuncID:                string
                        , a_avt:                        vsCommon.TvsAppViewType
                        , a_sPKValue:                   string
                        , a_ParamList?:                 any
                        // ): TvsAppDispatcherEventArgs
                        ): TvsAppDispatcherResult
  {

    let bOK:                boolean     = true;
    let classView:          any         = null;
    let bAdminOnly:         boolean     = false;

    // console.log('#### Dispatcher: ProgFunc-ID: ' + a_sProgFuncID);
    // console.log('####             a_avt:       ' + a_avt);
    // console.log('####             a_ViewDataList:  ' + a_ViewDataList);

    //
    // Ablauf
    //
    // (1) View-Klasse ermitteln (aus ProgFunc-Liste)
    // (2) Validierung: Benutzerzugriffsrechte
    // (3) Validierung: Sonstiges
    // (4) [OBSOLETE] Observer (beispielsweise MainView) benachrichtigen
    //
    // TODO:
    // # Alle alert-Meldungen ersetzen durch Dispatcher-Exceptions
    // # Validierung: Benutzerzugriffsrechte
    //

    // UsrRights: accessible wird benutzt um zu Steuern, ob ein Programmaufruf erlaubt wird
    // 6.
    // let accessible = vsCommon.find(this.getProcFuncList(), a_sProgFuncID).read;
    // //------ (1) View-Klasse ermitteln (aus ProgFunc-Liste)
    // UsrRights
    if (bOK/* && accessible*/) {
      // console.log('[INFO]     Dispatcher: View-Klasse aus ProgFunc-Tabelle ermitteln');
      // console.log('[INFO]     Dispatcher: this.getProcFuncList().length: ' + this.getProcFuncList().length.toString());

      let pf: vsProgFunc.TvsProgFuncItem = this.ProgFuncList.itemFind(a_sProgFuncID, a_avt);
      if (pf != null) {
        classView   = pf.ViewClass;
        bAdminOnly  = pf.AdminOnly;
      }

      bOK = (classView != null);

      if (!bOK) {
        console.log('[ERROR]    Dispatcher: Unknown ProgFunc-ID: "' + a_sProgFuncID + '"');

        // TODO: Exception auslösen statt alert
        alert('[ERROR] Dispatcher: Unknown ProgFunc-ID: ' + a_sProgFuncID);
      }
    } // if bOK


    //------ (2) Validierung: Benutzerzugriffsrechte
    // UsrRights
    // if (!bOK/* && !accessible*/) {
    //   // ... TODO: Validierung ...

    //     console.log('[ERROR]    Dispatcher: Insufficient user rights / ProgFunc-ID: "' + a_sProgFuncID + '"');
    //     alert('Leider besitzen Sie für diese Programmfunktion nicht die erforderlichen Zugriffsrechte. Bitte setzen Sie sich gegebenenfalls mit Ihrem Administrator in Verbindung.');
    // }


    //------ (3) Validierung: Sonstiges

    if (bOK) {
      //
      // An dieser Stelle können zukünftig bei Bedarf noch weitere Validierungen durchgeführt werden
      //

      if (bOK) {
        // ... Validierung ...
      }

      if (!bOK) {
        console.log('[ERROR]    Dispatcher: Validation failed / ProgFunc-ID: "' + a_sProgFuncID + '" / Validation: "' + '"');
        alert('[ERROR] Dispatcher: Validation failed / ProgFunc-ID: "' + a_sProgFuncID + '" / Validation: "' + '"');
      }
    }


    //------ (4) [OBSOLETE] Observer (beispielsweise MainView) benachrichtigen

    // if (bOK) {
    //   this.EventAfterDispatch.emit(new TvsAppDispatcherEventArgs(a_sProgFuncID, a_avt, a_sPKValue, classView));
    // }


    // return new TvsAppDispatcherEventArgs(a_sProgFuncID, a_avt, a_sPKValue, classView);
    return new TvsAppDispatcherResult(a_sProgFuncID, a_avt, a_sPKValue, classView, bAdminOnly, a_ParamList);

  } // dispatchProgFunc


} // TvsAppDispatcher


