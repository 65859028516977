//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Erstattungsantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import {  TvsHttpServiceComponent
        , TvsGetType }                      from '@vsLib/Services/vsHttp.service.component';
import { TvsDataFilter }                    from '@vsLib/vsDataFilter';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


@Component({
  selector:       'TchoolViewAntragEdit_Antrag_Erstattung',
  templateUrl:  './schoolViewAntragEdit_Antrag_Erstattung.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
})
export class TschoolViewAntragEdit_Antrag_Erstattung extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public lookupSchuljahr:               any[];

  public lookupSchulHalbjahr:           any[];

  public filteredLookupSchulHalbjahr:   any[];

  public lookupAntrag:                  any[];

  public lookupStammStaett:             any[];

  public displayDialogError: boolean   = false;

  public      kennDisplayPersData:          boolean = false;

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antragsauswahl';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];


    this.lookupSchuljahr = vsCommon.RetrieveSchoolYearLookup();
    this.lookupSchulHalbjahr = vsCommon.RetrieveSchoolHalfYearLookup();


    this.dataModel.azubi_Anrede = {
      Anrede_ID : ""
    , Anrede_Kuerzel: ""
    }

    this.dataModel.antrStell_Anrede = {
      Anrede_ID : ""
    , Anrede_Kuerzel: ""
  }

  this.dataModel.antrStell_Titel = {
    Titel_Kuerzel : ""
  }

    this.dataModel.antr_Ausb_Staett_Rec = {
      Link_ID_AusbStaett_Art : ""
    , Link_ID_AusbStaett: ""
  }

  this.dataModel.antr_Ausb_Staett_Rec = {
      AusbStaett_Name : ""
    , Art_Name : ""
  }

  this.dataModel.antrStell_Anrede_Rec = {
      Anrede_ID : ""
  }

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {



    if(!vsCommon.vsStringHasValue(this.dataModel.antrag_Datum)) {
      this.dataModel.antrag_Datum = vsCommon.RetrieveDateStrNow();
    }

    // this.editForm.addControl('Schuljahr',      new FormControl(this.dataModel.antrag_Schuljahr));
    this.editForm.addControl('Antr_Datum',     new FormControl(this.dataModel.antrag_Datum));

    if (this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA') {
      this.editForm.addControl('Grundantrag',     new FormControl(null));

      // //Filterung auf vorhandene Grundanträge für den eingeloggten User
      // this.DataFilter.ItemsClear();
      // let item = this.DataFilter.ItemAdd('Filter AntrStell_ID');

      // item.CriteriaAdd('Antr_ID_Kontakt_AntrStell', this.globalResources.WebAccProfile.KontaktID);

      // let item2 = this.DataFilter.ItemAdd('Filter Grundantrag');

      // item2.CriteriaAdd('Antr_ID_Art', 'G');

      // //$REV MP 2022-01-30: Zusätzliche Filterung auf Genehmigt und Antr_Kenn_Genehm_Erstatt hinzugefügt
      // let item3 = this.DataFilter.ItemAdd('Filter Grundantrag genehmigt');

      // item3.CriteriaAdd('Status_ID', 'GEN');

      // let item4 = this.DataFilter.ItemAdd('Filter Grundantrag Erstattung erlaubt');

      // item4.CriteriaAdd('Antr_Kenn_Genehm_Erstatt', true);


      this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrag', '&Antr_ID_Art=G' +
                                                                            '&Status_ID=GEN' +
                                                                            '&Antr_Kenn_Genehm_Erstatt=true')

      .subscribe(data => {

        this.lookupAntrag = data['Dataset'].Data;

      });

    }

  } // ngOnInit

  ngAfterViewInit(): void {
    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }

  }

  public actionStepPrev(): void {
    this.dataSave();
    this.dataModel.EmptyData();
    this.router.navigate(['landing']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK) {
      if (this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA') {
        this.editForm.get('Grundantrag').setValidators(Validators.required);
        this.editForm.get('Grundantrag').updateValueAndValidity();
      }

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;
    }

    if (bOK && this.dataModel.antrag_Art === 'E') {
      this.dataSave();
      this.router.navigate(['antragEdit/erstattung']);
    }

    if (bOK && this.dataModel.antrag_Art === 'EA') {
      this.dataSave();
      this.router.navigate(['antragEdit/school_attendance']);
    }

  } // actionStepNext()


  public dataSave() :void{

    // this.dataModel.antrag_Schuljahr      =  this.editForm.controls['Schuljahr'].value;
    this.dataModel.antrag_Datum          =  this.editForm.controls['Antr_Datum'].value;

    if ((this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA') && this.editForm.controls['Grundantrag'].value) {
      this.dataModel.antrID_Grundantrag        = this.editForm.controls['Grundantrag'].value.Antr_ID;
      this.dataModel.startDate_Grundantrag     = this.editForm.controls['Grundantrag'].value.Antr_Datum_Guelt_Beg;
      this.dataModel.endDate_Grundantrag       = this.editForm.controls['Grundantrag'].value.Antr_Datum_Guelt_End;

    // Antragsdaten

    //REV MP 2023-11-06: Wird vorher schon befüllt, ist hier nicht mehr notwendig.
    // this.dataModel.antrag_Art = "E";

    this.dataModel.kostenUebernahme_ab = this.editForm.controls['Grundantrag'].value.Antr_Kost_Ueber_Datum_Beg;
    this.dataModel.kostenUebernahme_bis = this.editForm.controls['Grundantrag'].value.Antr_Kost_Ueber_Datum_End;

    //Schüler

    //Azubi-ID des Schülers
    this.dataModel.azubi_ID = this.editForm.controls['Grundantrag'].value.Antr_ID_Azubi;

    // if (vsCommon.vsStringHasValue(this.editForm.controls['Grundantrag'].value.Azubi_ID_Anrede)) {
      this.dataModel.azubi_Anrede.Anrede_ID = this.editForm.controls['Grundantrag'].value.Azubi_ID_Anrede;
    // }
    // if (vsCommon.vsStringHasValue(this.editForm.controls['Grundantrag'].value.Azubi_Anrede_Kuerzel)) {
      this.dataModel.azubi_Anrede.Anrede_Kuerzel = this.editForm.controls['Grundantrag'].value.Azubi_Anrede_Kuerzel;
    // }


    this.dataModel.azubi_Nachname = this.editForm.controls['Grundantrag'].value.Azubi_Nachname;
    this.dataModel.azubi_Vorname = this.editForm.controls['Grundantrag'].value.Azubi_Vorname;
    this.dataModel.azubi_Geb_Datum =  vsCommon.ISO8061ToDateStr(this.editForm.controls['Grundantrag'].value.Azubi_Geb_Datum);

    if(this.globalResources.WebAccProfile.accErfassDatum <= this.lookupAntrag[0].Antr_Erfass_Datum) {
      this.kennDisplayPersData = true;
      console.log('kennDisplayPersData', this.kennDisplayPersData);
    }
    
    //$REV MP 2024-05-24: Nichtbefüllen des dataModels im Falle einer nachträglichen Verknüpfung - Datenschutzauflage
    //Befüllung des dataModels kann erhalten bleiben, da die Adressdaten des Schülers in der Zusammenfassung nicht angezeigt werden.
    if(this.globalResources.WebAccProfile.accErfassDatum <= this.editForm.controls['Grundantrag'].value.Antr_Erfass_Datum) {
      //Merkmal im dataModel
      this.dataModel.kennDisplayPersData = true;
      }
      this.dataModel.azubi_Strasse = this.editForm.controls['Grundantrag'].value.Azubi_Strasse;
      this.dataModel.azubi_HausNr = this.editForm.controls['Grundantrag'].value.Azubi_HausNr;
      this.dataModel.azubi_Ortsteil = this.editForm.controls['Grundantrag'].value?.Azubi_Ortsteil;
      this.dataModel.azubi_PLZ = this.editForm.controls['Grundantrag'].value.Azubi_PLZ;
      this.dataModel.azubi_Ort = this.editForm.controls['Grundantrag'].value.Azubi_Ort;
      this.dataModel.azubi_Phone = this.editForm.controls['Grundantrag'].value?.Azubi_Phone;
      this.dataModel.azubi_Mobile = this.editForm.controls['Grundantrag'].value?.Azubi_Mobile;
      this.dataModel.azubi_eMail = this.editForm.controls['Grundantrag'].value?.Azubi_eMail;
    // }


    //Gesetzliche Vertretung

    //Kontakt-ID des Antragsstellers
    this.dataModel.antr_ID_Kontakt_AntrStell = this.editForm.controls['Grundantrag'].value.Antr_ID_Kontakt_AntrStell;

    this.dataModel.antrStell_Anrede.Anrede_Kuerzel = this.editForm.controls['Grundantrag'].value?.GesVertr_Anrede_Kuerzel;
    this.dataModel.antrStell_Anrede_Rec.Anrede_ID = this.editForm.controls['Grundantrag'].value?.GesVertr_ID_Anrede;
    this.dataModel.antrStell_Anrede.Titel_Kuerzel = this.editForm.controls['Grundantrag'].value?.GesVertr_Titel_Kuerzel;

    this.dataModel.antrStell_Nachname = this.editForm.controls['Grundantrag'].value.GesVertr_Nachname;
    this.dataModel.antrStell_Vorname = this.editForm.controls['Grundantrag'].value.GesVertr_Vorname;
    this.dataModel.antrStell_Geb_Datum = vsCommon.ISO8061ToDateStr(this.editForm.controls['Grundantrag'].value.GesVertr_Geb_Datum);
    this.dataModel.antrStell_Strasse = this.editForm.controls['Grundantrag'].value.GesVertr_Strasse;
    this.dataModel.antrStell_HausNr = this.editForm.controls['Grundantrag'].value.GesVertr_HausNr;
    this.dataModel.antrStell_Ortsteil = this.editForm.controls['Grundantrag'].value?.GesVertr_Ortsteil;
    this.dataModel.antrStell_PLZ = this.editForm.controls['Grundantrag'].value.GesVertr_PLZ;
    this.dataModel.antrStell_Ort = this.editForm.controls['Grundantrag'].value.GesVertr_Ort;
    this.dataModel.antrStell_Phone = this.editForm.controls['Grundantrag'].value?.GesVertr_Phone;
    this.dataModel.antrStell_eMail = this.editForm.controls['Grundantrag'].value?.GesVertr_eMail;
    this.dataModel.antrStell_Mobile = this.editForm.controls['Grundantrag'].value?.GesVertr_Mobile;


    //Bankverbindung
    //$REV MP 2024-05-27: Keine Übernahme der Bankdaten aus dem Grundantrag. Müssen im Erstattungsantrag aus Datenschutzgründen neu eingegeben werden.
    // this.dataModel.antrStell_IBAN = this.editForm.controls['Grundantrag'].value?.BankVerb_IBAN;
    // this.dataModel.antrStell_Kto_Inh = this.editForm.controls['Grundantrag'].value?.BankVerb_Inhaber_Name;
    // this.dataModel.antrStell_Bank_Name = this.editForm.controls['Grundantrag'].value?.BankVerb_Institut_Name;

    //Schule

    this.dataModel.antr_ID_Ausb_Staett_Name      = this.editForm.controls['Grundantrag'].value.SchuleAntr_Name;

    this.dataModel.antr_Klasse_Beg_Nr            = this.editForm.controls['Grundantrag'].value.Antr_Klasse_Beg_Nr;
    this.dataModel.antr_Klasse_Beg_Zusatz        = this.editForm.controls['Grundantrag'].value.Antr_Klasse_Beg_Zusatz;

    this.dataModel.antr_Ausb_Staett_Rec.Link_ID_AusbStaett_Art = this.editForm.controls['Grundantrag'].value.Antr_ID_Ausb_Staett_Art;
    this.dataModel.antr_Ausb_Staett_Rec.Link_ID_AusbStaett = this.editForm.controls['Grundantrag'].value.Antr_ID_Ausb_Staett_Antr;

    this.dataModel.antr_Ausb_Staett_Rec.AusbStaett_Name = this.editForm.controls['Grundantrag'].value.SchuleAntr_Name;
    this.dataModel.antr_Ausb_Staett_Rec.Art_Name = this.editForm.controls['Grundantrag'].value.SchuleArtAntr_Name;

    }

    // this.dataModel.antrag_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['Antr_Datum'].value);

  }


} // schoolViewAntragEdit_Student


