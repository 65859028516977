//------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vs-view-data-list.component.ts
//  Notes:  View component: Basisklasse für Views, die dynamische Daten als
//          Listenansicht darstellen
//
//
//  Klassenhierarchie:
//
//  TvsView
//   |
//   +-- TvsViewData
//     |
//     +-- TvsViewDataList
//     |
//     +-- TvsViewDataEdit
//
//
//  H I S T O R Y
//
//  2021-06-20  TC   Initial Version
//------------------------------------------------------------------------------

const   m_sDir_vsLib: String = 'src/$vsLib_Candidates/';

import {  Component, Input
        , OnInit
        , AfterViewInit
        , Directive
        , ViewContainerRef, ViewChild, ContentChild
        , TemplateRef
        , ElementRef
        , EventEmitter
       }                              from '@angular/core';
import { FormGroup, FormControl }     from '@angular/forms';
import { Router }                     from '@angular/router';



// import { AngularCsv }                 from 'angular-csv-ext/dist/Angular-csv';


// [ngx-translate]
import { TranslateService }           from '@ngx-translate/core';   // TODO: Verlagern nach TvsView

// vsLib
import * as vsCommon                  from '@vsLib/vsCommon';
import * as vsGlobalResources         from '@vsLib/Services/vsGlobalResources.service';
import * as vsAppViewList             from '@vsLib/vsAppViewList';
import * as vsViewData                from '@vsLib/View/components/vsViewData/vsViewData.component';

import * as vsDataFilter              from '@vsLib/vsDataFilter';
import * as vsTable                   from '@vsLib/Components/vsTable/vsTable.component';
import { TvsView }                    from '../vsView/vsView.component';
import { TvsHttpServiceComponent, TvsGetType, TvsHTTPMethod }    from '@vsLib/Services/vsHttp.service.component';
import { calcTableHeight, getAvailableSpace, setAvailableSpace } from './utils/calcElementHeight';
import { TvsExport } from '@vsLib/Components/vsExport/vsExport.component';
import * as vsTabControl              from '@vsLib/Components/vsTab/vsTabControl.component';
import { ThisReceiver } from '@angular/compiler';

// TvsViewPropertiesList
//
// View properties: List View
//
export class TvsViewPropertiesList extends vsViewData.TvsViewPropertiesData {

  // Properties
  public      FilterMain:                   vsDataFilter.TvsDataFilter      = new vsDataFilter.TvsDataFilter();

  // Event handler
  // ...

} // TvsViewPropertiesList



//
// TvsViewDataList
//
// Base class: List View
//
@Component({
  selector:       'vsViewDataList',
  templateUrl:  './vsViewDataList.component.html',
  styleUrls:   ['./vsViewDataList.component.scss']
})
export class TvsViewDataList extends vsViewData.TvsViewData implements OnInit, AfterViewInit {
  //----- rev$ AK 2021-08-18: BEG -----

  @ViewChild('TableList') tableList: vsTable.TvsTableComponent;



  // @ViewChild('substractElementHeight',  {read: ElementRef}) substractElementHeight?:  ElementRef;




// iElementSize:any;
// iContentWidth:any;
// iContentHeight:any;
  //----- rev$ AK 2021-08-18: END -----


  //====== Properties (PRIVATE)

  // private     FPKValue:                     string                  = null;      // $Rev TC 2021-06-06: verlagert nach TvsViewData
  private     fDataSelected:                any                     = [];
  private     FFilterRequired:              boolean                 = false;
  private     FHintVisibleFilterRequired:   boolean                 = false;
  private     SelectedPKValue:              string                  = null;
  private     export:                       TvsExport;


  //====== Properties (PUBLIC)


  // Anmerkung zu der nachfolgenden Deklaration von ViewProperties:
  //
  // Die Property ViewProperties wird ja bereits in der obersten View-Basisklasse TvsView eingeführt,
  // also von dort geerbt. In TvsView ist ViewProperties vom Typ TvsViewProperties und diese Klasse
  // besitzt noch nicht die Property FilterMain, welche erst mit TvsViewPropertiesList eingeführt wird.
  // Deshalb würde es beim Compilieren zu einem Fehler kommen, wenn eine Klasse versucht, auf
  // ViewProperties.FilterMain zuzgreifen (was in einer abgeleiteten konkreten View-Klasse erfolgen
  // wird).
  //
  // Aus diesem Grund muss hier in TvsViewDataList die Deklaration von ViewProperties erneut erfolgen,
  // damit diese in allen Instanzen und Ableitungen von TvsViewDataList den passenden Typ hat. Da die
  // Klasse TvsViewPropertiesList abgeleitet ist von TvsViewProperties, sind diese zuweisungskompatibel.

  //---- Events
  // UsrRights: Muss noch um die weiteren Aktionen wie Delete etc ergänzt werden!
  // 3.
  public    ValidateEdit:     boolean = true;
  public    ValidateEditMsg:  string  = 'Sie haben nicht die notwendigen Rechte, um diese Aktion (Neu) auszuführen!';
  public    ValidateNew:      boolean = true;
  public    ValidateNewMsg:   string  = 'Sie haben nicht die notwendigen Rechte, um diese Aktion (Bearbeiten) auszuführen!';


  // Initialisierung eines EventEmitters um von außen auf das Event reagieren zu können
  public    onRowSelect:        EventEmitter<any>    = new EventEmitter();


  @Input()    ViewProperties:               TvsViewPropertiesList   = new TvsViewPropertiesList();

  // TemplateRefs for optional sections
  @Input()    TemplateSectionMainRight:     TemplateRef<any>        = null;
  @Input()    TemplateSectionRight:         TemplateRef<any>        = null;
  @Input()    TemplateSectionDetail:        TemplateRef<any>        = null;
  @Input()    TemplateSectionFooter:        TemplateRef<any>        = null;
  @Input()    TemplateSectionFilter:        TemplateRef<any>        = null;


  public      FilterPKValue:                string                  = null;
  // public   showDialogDetailEditModified: boolean                 = false; //$rev AK 2022-05-05: für dialog warnung anzeige wegen List/Edit Kombo im Schulenportal - ausführlicher im übernächsten Comment mit Kennung

  public  get displayDialogDeleteConfirm():        boolean          {  return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteConfirmShow; }
  public  set displayDialogDeleteConfirm(a_bShow: boolean)          {  (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteConfirmShow = a_bShow; }

  public  get displayDialogDeleteError():        boolean            {  return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteErrorShow; }
  public  set displayDialogDeleteError(a_bShow: boolean)            {  (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteErrorShow = a_bShow; }

  public  get displayDialogDeleteMsgText():        string           {  return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteErrorMsgText; }

  public  get displayDialogFlagSystemDeleteError():        boolean            {  return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgFlagSystemDeleteErrorShow; }
  public  set displayDialogFlagSystemDeleteError(a_bShow: boolean)            {  (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgFlagSystemDeleteErrorShow = a_bShow; }
  public  get displayDialogFlagSystemDeleteErrorMsgText():  string           {  return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgFlagSystemDeleteErrorMsgText; }

  public  get HintVisibleFilterRequired():  boolean                 {  return (this.ViewProperties.Instance as TvsViewDataList).FHintVisibleFilterRequired }
  public  set HintVisibleFilterRequired(a_bVisible: boolean)        { (this.ViewProperties.Instance as TvsViewDataList).FHintVisibleFilterRequired = a_bVisible; }
  public  get FilterRequired():  boolean                            {  return (this.ViewProperties.Instance as TvsViewDataList).FFilterRequired }
  public  set FilterRequired(a_bRequired: boolean)                  { (this.ViewProperties.Instance as TvsViewDataList).FFilterRequired = a_bRequired; }

  public get displayDialogEditMultipleError(): boolean { { return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgEditMultipleErrorShow; } }
  public set displayDialogEditMultipleError(editShow: boolean) { (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgEditMultipleErrorShow = editShow; }
  public get displayDialogEditMultipleErrorMsg(): string { return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgEditMultipleErrorMsgText; }
  public set displayDialogEditMultipleErrorMsg(text) { (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgEditMultipleErrorMsgText = text; }

  public get displayDialogDeleteMultipleError(): boolean { { return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteMultipleErrorShow; } }
  public set displayDialogDeleteMultipleError(editShow: boolean) { (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteMultipleErrorShow = editShow; }
  public get displayDialogDeleteMultipleErrorMsg(): string { return (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteMultipleErrorMsgText; }
  public set displayDialogDeleteMultipleErrorMsg(text) { (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteMultipleErrorMsgText = text; }

  // public  get PKValue():                    string                  {return this.FPKValue}       // $Rev TC 2021-06-06: verlagert nach TvsViewData
  public  get dataSelected():               any                   { return this.fDataSelected }
  public  get CSSClassSectionMain():        string {
    let sValue:     string      = '';

    // return (this.SectionIsVisible_Detail ? "vsViewSectionMain" : "vsViewSectionMain vsViewSectionMain_FullHeight");
    // return ((this.ViewProperties.Instance as TvsViewDataList).SectionIsVisible_Detail ? "vsViewSectionMain" : "vsViewSectionMain vsViewSectionMain_FullHeight");

    // if ((this.ViewProperties.Instance as TvsViewDataList).SectionIsVisible_Detail) {
    //   return "vsViewSectionMain";
    // } else {
    //   return "vsViewSectionMain vsViewSectionMain_FullHeight";
    // }

    // console.log('#################### get CSSClassSectionMain(): Instance Class           = ', this.ViewProperties.Instance.constructor.name);
    // console.log('#################### get CSSClassSectionMain(): SectionIsVisible_Detail  = ', this.ViewProperties.Instance.ViewProperties.SectionIsVisible_Detail);
    // console.log('#################### get CSSClassSectionMain(): TemplateSectionDetail    = ', this.TemplateSectionDetail);

    // if (this.ViewProperties.Instance.constructor.name == 'TausbViewAzubiList') {
    if (this.ViewProperties.SectionIsVisible_Detail && this.TemplateSectionDetail) {
      sValue = 'vsViewSectionMain';
    } else  {
      sValue = 'vsViewSectionMain  vsViewSectionMain_FullHeight';
    }

    return sValue;

  } // get CSSClassDetailsSwitch


  public  get CSSClassDetailsSwitch():      string {
    return (this.SectionIsVisible_Detail ? "p-button-sm vsToolbarItemDetailsSwitchOn" : "p-button-sm vsToolbarItemDetailsSwitchOff");
  } // get CSSClassDetailsSwitch

  public calculatedTableHeight: string;

  public colEditButtonVisible:   boolean = true;
  public colDeleteButtonVisible: boolean = false;

  //====== ViewChilds

  // @ViewChild(TdirectiveSectionDetail,  {static: false})     directiveSectionDetail:   TdirectiveSectionDetail;
  // @ViewChild('contentSectionDetail',  {read: ViewContainerRef, static: false})    contentSectionDetail:   ViewContainerRef;
  @ViewChild('akFilter',   {read: ElementRef})     akFilter:          ElementRef;
  @ViewChild('akDetails', {read: ElementRef}) akDetails: ElementRef;
  @ViewChild('akToolbar', {read: ElementRef}) akToolbar: ElementRef;
  @ViewChild('akVsView', {read: ElementRef}) akVsView: ElementRef;
  //====== Constructor

  constructor(protected router:             Router
            , protected globalResources:    vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent: TvsHttpServiceComponent
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance      = this;
    this.ViewProperties.ViewTitle     = this.constructor.name;


    this.listForm.addControl('FilterStdMulti', new FormControl(null));
    this.listForm.addControl('FilterMultiTypeCheck', new FormControl(null));
    this.avt  = vsCommon.TvsAppViewType.avtList;

  } // constructor


  ngOnInit(): void {

    // ====== Toolbar Items

    // [LABOR] ---------- BEG ----------

    this.ViewProperties.ToolbarItems  = [
        { ID: "tbNew",                     ID_Parent: null,          Caption: "Neu",             Separator: false,       Enabled: true, Visible: true, src: '/assets/img/Icons/vs-plus-circle.svg', command: () => ((this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionNew').Execute(this.ViewProperties.Instance as TvsViewDataList) ) }
      , { ID: "tbEdit",                    ID_Parent: null,          Caption: "Bearbeiten",      Separator: false,       Enabled: false, Visible: true, src: '/assets/img/Icons/vs-edit-circle.svg', command: () => ((this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionEdit').Execute(this.ViewProperties.Instance as TvsViewDataList) )}
      , { ID: "tbDelete",                  ID_Parent: null,          Caption: "Löschen",         Separator: false,       Enabled: false, Visible: true, src: '/assets/img/Icons/vs-delete-circle.svg', command: () => ((this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionDelete').Execute(this.ViewProperties.Instance as TvsViewDataList))  }
      // , {ID: null,                        ID_Parent: null,          Caption: null,              Separator: true,       Enabled: true,    Visible: true}
      , { ID: "tbRefresh",                 ID_Parent: null,          Caption: "Aktualisieren",   Separator: false,       Enabled: true, Visible: true, src: '/assets/img/Icons/vs-refresh-circle.svg', command: () => ( (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionRefresh').Execute(this.ViewProperties.Instance as TvsViewDataList))  }
      , { ID: "tbExport",                  ID_Parent: null,          Caption: "Export",          Separator: false,       Enabled: true, Visible: true, src: '/assets/img/Icons/vs-report-circle.svg', command: () => ((this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionExport').Execute(this.ViewProperties.Instance as TvsViewDataList))  }
      // , {ID: null,                        ID_Parent: null,          Caption: null,              Separator: true,       Enabled: true,    Visible: true}
      , { ID: "tbActions",                 ID_Parent: null,          Caption: "Aktionen",        Separator: false,       Enabled: true, Visible: true, src: '/assets/img/Icons/vs-action-circle.svg'  }
      , { ID: "tbReports",                 ID_Parent: null,          Caption: "Berichte",        Separator: false,       Enabled: true, Visible: false, src: '/assets/img/Icons/vs-report-circle.svg'  }

      // , {ID: "tbActionsActivateOrder",    ID_Parent: "tbActions",   Caption: "Bestellung: Aktivieren",      Separator: false}
      // , {ID: "tbReportsOrderOverview",    ID_Parent: "tbReports",   Caption: "Bestellungen: Übersicht",     Separator: false}
    ];

    // [LABOR] ---------- END ----------



    // Initial wird hier einen Datensatz vom Server geholt, um das Datenobjekt inkl. Spaltenüberschriften zu bekommen
    // Der Datensatz selber wird wieder gelöscht

    // console.log('ngAfterViewInit: Filter Required: ', (this.ViewProperties.Instance as TvsViewDataList).FilterRequired);
    // if ((this.ViewProperties.Instance as TvsViewDataList).FilterRequired) {
    //   this.actionRefresh(TvsGetType.gtInitList);
    // } else {
    //   this.actionRefresh(TvsGetType.gtNormal);
    // }

    (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemAdd(this, 'ActionNew',      (this.ViewProperties.Instance as TvsViewDataList).actionNew);
    (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemAdd(this, 'ActionEdit',     (this.ViewProperties.Instance as TvsViewDataList).actionEdit);
    (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemAdd(this, 'ActionDelete',   (this.ViewProperties.Instance as TvsViewDataList).actionDelete);
    (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemAdd(this, 'ActionRefresh',  (this.ViewProperties.Instance as TvsViewDataList).actionRefreshCall);
    (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemAdd(this, 'ActionExport',   (this.ViewProperties.Instance as TvsViewDataList).actionExport);

    // this.LoadContextMenu();

    (this.ViewProperties.Instance as TvsViewDataList).LoadContextMenu();



  } // ngOnInit


  protected LoadContextMenu(): void {

    //  console.log('ActionList: ', (this.ViewProperties.Instance as TvsViewDataList).MenuItemsAction);

    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext = [];

    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { label: 'Neu',            command:  event => {(this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionNew').Execute(this)},    disabled: (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionNew').disabled }  );
    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { label: 'Bearbeiten',     command:  event => {(this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionEdit').Execute(this)},   disabled: (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionEdit').disabled}  );
    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { label: 'Löschen',        command:  event => {(this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionDelete').Execute(this)}, disabled: (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionDelete').disabled }  );
    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { label: 'Export',         command:  event => {(this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionExport').Execute(this)}, disabled: (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionExport').disabled }  );
    this.tbElemByName('tbActions').Visible = (this.ViewProperties.Instance as TvsViewDataList).MenuItemsAction.length > 0 ;

    if ((this.ViewProperties.Instance as TvsViewDataList).MenuItemsAction.length > 0) {
      (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { separator : true } );
      (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { label: 'Aktionen', items: (this.ViewProperties.Instance as TvsViewDataList).MenuItemsAction }  );
    }

    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { separator : true  } );
    (this.ViewProperties.Instance as TvsView).ViewProperties.MenuItemsContext.push( { label: 'Aktualisieren',  command:  event => {this.ActionList.ItemByName('ActionRefresh').Execute(this)} }  );

  }

    // TE: Neue Funktion zum Überprüfen ob das object gepushed oder geupdated werden soll!
    protected updateOrAddObject(array, newObject, key): void {
      const index = array.findIndex(obj => obj[key] === newObject[key]);
      console.log(index);
      if (index !== -1) {
        array[index] = { ...array[index], ...newObject };
      } else {
        array.push(newObject);
      }
    }

  ngAfterViewInit(): void {
    // Anmerkung:
    // Je nach Situation können auch in ngOnInit() die ViewChilds bereits initialisiert sein. Aber nur
    // in ngAfterViewInit() ist dies wirklich sichergestellt

    (this.ViewProperties.Instance as TvsViewDataList).createChildViews();
    // console.log('vsDataList: After View Init -> createChildViews ');

    if (vsCommon.vsVarAssigend(this.tableList)) {
      (this.ViewProperties.Instance as TvsView).ViewProperties.TableList = this.tableList;
    }

     if(getAvailableSpace()) {
      this.ViewProperties.TableList.setTableHeight = 350+"px";
      } else {
        if(this.akFilter && this.akToolbar){
          this.ViewProperties.TableList.setTableHeight = calcTableHeight(this.akToolbar.nativeElement.scrollHeight, this.akFilter.nativeElement.scrollHeight, getAvailableSpace());
        }
        if(!this.akFilter && this.akToolbar) {
          this.ViewProperties.TableList.setTableHeight = calcTableHeight(this.akToolbar.nativeElement.scrollHeight, undefined, getAvailableSpace());

        }

        setAvailableSpace(undefined);
      }
  } // ngAfterViewInit



  //====== Methods: Actions


  //
  // IMPORTANT:
  // All action methods are template methods and should therefore NEVER be overwritten!
  //


  //------------------------------------------------------------------------------------------------
  // Method:  actionActions
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Zum Test für Präsentation - wird später anders genutzt
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected actionActions(): void {
    // console.log('Basisklasse actionActions');

    // Nur mal zum Testen des CSV-Exportes (TODO: Item-Verwaltung für "Action Menu" wie im Delphi-AMIS7)
    this.doDataExportCSV();

  } // actionActions


  //------------------------------------------------------------------------------------------------
  // Method:  actionNew
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: New -> Opens edit view for new data record
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected actionNew(a_caller: any): void {
    // UsrRights: Aufruf um zu entscheiden, ob jemand die Rechte hat oder einen Alert angezeigt bekommt.
    // 4.
    // a_caller.ValidateNew = vsCommon.findItemInArrayByProgFuncID(a_caller.globalResources.UserProfile.UserRights.progFuncList, a_caller.ViewProperties.ProgFuncID).new;

    if (a_caller.ValidateNew)
    {
      // (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionNew').disabled = false;
      // alert('>>>> ' + this.constructor.name + ':  Action: New');

      // let data =  (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste

      // console.log('ParamList', (this.ViewProperties.Instance as TvsViewDataList).ParamList);

      // let appViewItem:    vsAppViewList.TvsAppViewItem;
      // appViewItem = this.globalResources.ViewMain.dispatchProgFunc( this.ViewProperties.ProgFuncID,
      //                                                               vsCommon.TvsAppViewType.avtEdit,
      //                                                               null,
      //                                                               (this.ViewProperties.Instance as TvsViewDataList).ParamList);

      // if (appViewItem) {
      //   console.log('>>>> TvsViewDataList: OnDataEditSave before subscribe / appViewItem = ', appViewItem);

      //   appViewItem.View.ViewProperties.Subj.subscribe(
      //       (args) => {
      //         // Wenn der neue Datensatz gespeichert wurde...

      //         // Liste auf den PKValue reduzieren und darin den editierten PKValues finden:
      //         let data =  (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste
      //         data.push(args);                          // Neuen Datensatz an bestehende Menge anhängen
      //         Object.assign(this.DOMain.Dataset, data); // Überschreiben der ursprünglichen Datenmenge

      //         // console.log('Subscribe Subj');

      //         }
      //   );
      // } else {
      //   console.log('>>>> TvsViewDataList: OnDataEditSave subscribe not possible / appViewItem = ', appViewItem);
      // } // if
      let data =  (a_caller.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste

      // console.log('ParamList', (a_caller.ViewProperties.Instance as TvsViewDataList).ParamList);

      let appViewItem:    vsAppViewList.TvsAppViewItem;
      appViewItem = a_caller.globalResources.ViewMain.dispatchProgFunc( a_caller.ViewProperties.ProgFuncID,
                                                                    vsCommon.TvsAppViewType.avtEdit,
                                                                    null,
                                                                    (a_caller.ViewProperties.Instance as TvsViewDataList).ParamList);

      if (appViewItem) {
        // console.log('>>>> TvsViewDataList: OnDataEditSave before subscribe / appViewItem = ', appViewItem);

        appViewItem.View.ViewProperties.Subj.subscribe(
          (args) => {
            // Wenn der neue Datensatz gespeichert wurde...

            // ANMERKUNG: Hier wird nie geprüft, ob ein Datensatz bereits vorhanden ist und geupdated werden sollte. args wird immer gepushed.

            // let data =  (a_caller.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste
            // data.push(args);                          // Neuen Datensatz an bestehende Menge anhängen
            // Object.assign(a_caller.DOMain.Dataset, data); // Überschreiben der ursprünglichen Datenmenge

            // TE: Neue Funktion updateOrAddObject prüft, ob "args" schon vorhanden ist, wenn ja, wird es geupdated, ansonsten gepushed.

            let data = (a_caller.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Current list
            const key = "PKValue"; // Replace 'id' with the appropriate unique key for your data

            // Call the updateOrAddObject function with the data, args, and key
            (a_caller.ViewProperties.Instance as TvsViewDataList).updateOrAddObject(data, args, key);

            // Overwrite the original dataset
            Object.assign(a_caller.DOMain.Dataset, data);

            // if (vsCommon.vsVarAssigend((this.ViewProperties.Instance as TvsViewDataList).TabItem) && (this.ViewProperties.Instance as TvsViewDataList).KennShowDetailTabRecordcount) {
            //   (this.ViewProperties.Instance as TvsViewDataList).TabItem.Caption = (this.ViewProperties.Instance as TvsViewDataList).DetailTabCaption + ' [' + this.DOMain.Dataset.Data.length + ']';
            // }

            // console.log('this.TabItem2: ', (this.ViewProperties.Instance as TvsViewDataList).TabItem);
            // console.log('this.KennShowDetailTabRecordcount2: ', (this.ViewProperties.Instance as TvsViewDataList).KennShowDetailTabRecordcount);
            // console.log('this.TabItem1: ', this.TabItem);
            // console.log('this.KennShowDetailTabRecordcount1: ', this.KennShowDetailTabRecordcount);


            if (vsCommon.vsVarAssigend(a_caller.TabItem) && a_caller.KennShowDetailTabRecordcount) {
              a_caller.TabItem.Caption = a_caller.DetailTabCaption + ' [' + a_caller.DOMain.Dataset.Data.length + ']';
            }

            // console.log('Subscribe Subj', args);
          }
        );
      } else {
        // console.log('>>>> TvsViewDataList: OnDataEditSave subscribe not possible / appViewItem = ', appViewItem);
      } // if
    }
    else {
      alert(a_caller.ValidateNewMsg);
    }

  } // actionNew


  //------------------------------------------------------------------------------------------------
  // Method:  actionEdit
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Edit -> Opens edit view for editing selected data record
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected actionEdit(a_caller: any): void {
    // UsrRights: Aufruf um zu entscheiden, ob jemand die Rechte hat oder einen Alert angezeigt bekommt.
    // 5.
    // a_caller.ValidateEdit = vsCommon.findItemInArrayByProgFuncID(a_caller.globalResources.UserProfile.UserRights.progFuncList, a_caller.ViewProperties.ProgFuncID).edit ? true : false;

    if (a_caller.ValidateEdit)
    {
      // console.log('Caller DataList:', a_caller);
      // alert('>>>> ' + this.constructor.name + ':  Action: Edit  /  PKValue: ' + this.PKValue);
      // console.log('>>>> ' + this.constructor.name + '.actionEdit():  PKValue: ' + this.PKValue);

      // let appViewItem:    vsAppViewList.TvsAppViewItem;
      // appViewItem         = this.globalResources.ViewMain.dispatchProgFunc(this.ViewProperties.ProgFuncID, vsCommon.TvsAppViewType.avtEdit, this.PKValue, (this.ViewProperties.Instance as TvsViewDataList).ParamList);


      // if (appViewItem) {
      //   console.log('>>>> TvsViewDataList: OnDataEditSave before subscribe / appViewItem = ', appViewItem);

      //   appViewItem.View.ViewProperties.Subj.subscribe(
      //       (args) => {
      //         // Liste auf den PKValue reduzieren und darin den editierten PKValues finden:

      //         let data =  (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste
      //         console.log('ActionEdit Data:', data);

      //         let x    = data.map(el => el.PKValue)
      //         let i    = x.indexOf(this.PKValue);

      //         // Daten aus Edit in Listen-Zeile übertragen
      //         data[i] = args;

      //         // console.log('Subscribe Subj EDIT');

      //       }
      //   );
      // } else {
      //   console.log('>>>> TvsViewDataList: OnDataEditSave subscribe not possible / appViewItem = ', appViewItem);
      // } // if

      if (a_caller.ViewProperties.TableList.selectionMode === "single" || a_caller.ViewProperties.TableList.dataSelected.length === 1) {
        let appViewItem: vsAppViewList.TvsAppViewItem;

        appViewItem = a_caller.globalResources.ViewMain.dispatchProgFunc(a_caller.ViewProperties.ProgFuncID, vsCommon.TvsAppViewType.avtEdit, a_caller.PKValue, (a_caller.ViewProperties.Instance as TvsViewDataList).ParamList);


        if (appViewItem) {
          // console.log('>>>> TvsViewDataList: OnDataEditSave before subscribe / appViewItem = ', appViewItem);

          appViewItem.View.ViewProperties.Subj.subscribe(
            (args) => {
              // Liste auf den PKValue reduzieren und darin den editierten PKValues finden:

              let data =  (a_caller.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste
              // console.log('ActionEdit Data:', data);

              let x    = data.map(el => el.PKValue)
              let i    = x.indexOf(a_caller.PKValue);

              // Daten aus Edit in Listen-Zeile übertragen
              data[i] = args;


              // console.log('Subscribe Subj EDIT');


            }
          );
        } else {
          // console.log('>>>> TvsViewDataList: OnDataEditSave subscribe not possible / appViewItem = ', appViewItem);
        } // if
      }
      if (a_caller.ViewProperties.TableList.dataSelected.length === 0) {
        a_caller.displayDialogEditMultipleErrorMsg = "Es muss ein Datensatz zum Bearbeiten selektiert sein!"
        a_caller.displayDialogEditMultipleError = true;
      }
      if (a_caller.ViewProperties.TableList.dataSelected.length > 1) {
        a_caller.displayDialogEditMultipleErrorMsg = "Es darf nur ein Datensatz bearbeitet werden!"
        a_caller.displayDialogEditMultipleError = true;
      }
    }
    else {
      alert(a_caller.ValidateEditMsg);
    }
  } // actionEdit


 //------------------------------------------------------------------------------------------------
  // Method:  actionDelete
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Delete -> Deletes selected data record
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected actionDelete(a_caller: any): void {
    let bOK:             boolean = true;
    let flagSystemFieldName:   string;

    // console.log('dataSelected ' , a_caller.ViewProperties.TableList.dataSelected)


    if (a_caller.ViewProperties.fieldNameFlagSystem) {
      flagSystemFieldName = a_caller.ViewProperties.fieldNameFlagSystem;
      // console.log('flagSystemFieldName ' , a_caller.ViewProperties.fieldNameFlagSystem)
    }

    //Check auf einzelnen Datensatz
    if (bOK) {
      bOK = (!a_caller.ViewProperties.TableList.multiCheckEnabled);
    }

    // $Rev MP 2022-01-10: Check auf Systemeintrag eingebaut
    //Delete zur bei System-Eintrag false möglich für typeof dataSelected === 'object'
    if (bOK && vsCommon.vsStringHasValue(a_caller.ViewProperties.fieldNameFlagSystem) && Array.isArray(a_caller.ViewProperties.TableList.dataSelected)) {
      bOK = !a_caller.ViewProperties.TableList.dataSelected[0][flagSystemFieldName];
      // console.log('bOk Art_Kenn_System ', bOK)
      if (!bOK){
        a_caller.displayDialogFlagSystemDeleteError = true;
      }
    }

    //Delete zur bei System-Eintrag false möglich für typeof dataSelected === 'array'
    if (bOK && vsCommon.vsStringHasValue(a_caller.ViewProperties.fieldNameFlagSystem) && a_caller.ViewProperties.TableList.dataSelected[flagSystemFieldName]) {
      bOK = !a_caller.ViewProperties.TableList.dataSelected[flagSystemFieldName];
      if (!bOK){
        a_caller.displayDialogFlagSystemDeleteError = true;
      }
    }

    if (bOK) {
      bOK = (a_caller.ViewProperties.Instance as TvsViewDataList).ValidateDelete();
      if (!bOK) {
        a_caller.displayDialogDeleteError = true;
      }
    }

    if (bOK) {
      if ((a_caller.ViewProperties.Instance as TvsView).ViewProperties.doConfirmation) {

        // Bestätigungsmeldung anzeigen
        // im Html wird dann bei OK: internalActionDelete() aufgerufen
        console.log('selectionMode ' , a_caller.ViewProperties.TableList.selectionMode)
        if(a_caller.ViewProperties.TableList.selectionMode ==="single" || a_caller.ViewProperties.TableList.dataSelected.length === 1){
          a_caller.displayDialogDeleteConfirm = true;
        }

        else {
          a_caller.displayDialogDeleteMultipleError = true;
        }
      }

      // if (!bOK) {
      //   a_caller.displayDialogDeleteError = true;
      // }

       // Ohne Confirmation-Meldung löschen
      // else {
      //   alert('Delete')
      //   a_caller.internalActionDelete();
      // }
    }

    // else {
    //   a_caller.displayDialogDeleteError = true;
    // }

    if (a_caller.ViewProperties.TableList.dataSelected.length === 0) {
      a_caller.displayDialogDeleteMultipleErrorMsg = "Es muss ein Datensatz zum Löschen selektiert sein!"
      a_caller.displayDialogDeleteMultipleError = true;
    }

    console.log('dataSelected length ' , a_caller.ViewProperties.TableList.dataSelected.length)

    if (a_caller.ViewProperties.TableList.dataSelected.length > 1) {
      a_caller.displayDialogDeleteMultipleErrorMsg = "Es darf nur ein Datensatz gelöscht werden!"
      a_caller.displayDialogDeleteMultipleError = true;
    }

  } // actionDelete


  //------------------------------------------------------------------------------------------------
  // Method:  internalActionDelete
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   internal Action: Delete -> Deletes selected data record
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected internalActionDelete(): void {

    console.log('ViewProperties internalActionDelete ', this.ViewProperties.TableList.dataSelected);
    this.ViewProperties.TableList.dataSelected = [];
    // alert('InternalActionDelete')
    this.HttpServiceComponent.dataDelete(this.ViewProperties.DataSourceID, this.PKValue)
      .subscribe(
        data => {
          this.ResponseHandler(TvsHTTPMethod.hmDelete, data, false);

          let liste =  (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data; // Aktuelle Liste

          let x    = liste.map(el => el.PKValue)
          let i    = x.indexOf(this.PKValue);

          // Datensatz entfernen
          liste.splice(i, 1);

          this.globalResources.ViewMain.msgShowToastSuccess('Datensatz wurde gelöscht');

          console.log('this.TabItem', (this.ViewProperties.Instance as TvsViewDataList).TabItem);
           console.log('this.ViewProperties.Instance as TvsViewDataList).KennShowDetailTabRecordcount', (this.ViewProperties.Instance as TvsViewDataList).KennShowDetailTabRecordcount);

          if (vsCommon.vsVarAssigend((this.ViewProperties.Instance as TvsViewDataList).TabItem) && (this.ViewProperties.Instance as TvsViewDataList).KennShowDetailTabRecordcount) {
            (this.ViewProperties.Instance as TvsViewDataList).TabItem.Caption = (this.ViewProperties.Instance as TvsViewDataList).DetailTabCaption + ' [' + liste.length + ']';
          }

        },
        error => {
          console.log('Error Delete-Request: ', error);
          // alert('Bei dem Delete-Request ist ein Fehler aufgetreten:\n'
          // + error.error.Error.ErrCode + '\n'
          // + error.error.Error.ErrMsg + '\n\n'
          // + error.message );

          // $rev AK 2023-03-07: Message-Dialog statt Alert aktiviert - die Fehlermeldung ist für Entwicklerzwecke nach wie vor in der Konsole einsehbar.
          (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteErrorMsgText = '\n' + error.error.Error.ErrMsg;
          (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.DlgDeleteErrorShow = true;

          this.DOMain.IsRefreshing = false;

        },
        () => {
          // console.log('Delete Request completed');
        }
      );
  }  // internalActionDelete


  //====== Methods: Miscellanious

  protected createChildViews(): void {
  } // createChildViews



  //------------------------------------------------------------------------------------------------
  // Method:  doTableRowSelect
  //
  // Args:    args        vsTable.TvsTableComponentEventArgs
  //
  // Result:  ./.
  //
  // Notes:   Event handler:
  //          Selection of the actual data record in the table has changed
  //------------------------------------------------------------------------------------------------

  public doTableRowSelect(args: vsTable.TvsTableComponentEventArgs) {

    if((this.ViewProperties.Instance as TvsViewDataList).checkIfDetailEditModified()){
      // $rev AK 2022-05-05: für dialog warnung anzeige wegen List/Edit Kombo im Schulenportal - ausführlicher in der Funktion kommentiert
      // $ rev AK 2022-05-06: doch geändert, nun kein Dialog mehr, sondern nur Einblendung eines Toast

      this.globalResources.ViewMain.msgShowToastWarning('Änderungen wurden nicht gespeichert');

    }

      //console.log('doTableRowSelect', args.PKValue);

      this.PKValue          = args.PKValue;
      if (this.fDataSelected.length  < 1) {
        this.fDataSelected = [{ ...args.Data }];
      }
      if(this.fDataSelected.length > 1) {
        this.fDataSelected.push({ ...args.Data });
      }

      this.SelectedPKValue  = this.PKValue; // Remember for positionung after ActionRefresh
      // console.log('SelectedValue:', this.SelectedPKValue);

      (this.ViewProperties.Instance as TvsViewDataList).PKValue         = args.PKValue;
      (this.ViewProperties.Instance as TvsViewDataList).fDataSelected   = args.Data;
      (this.ViewProperties.Instance as TvsViewDataList).SelectedPKValue = args.PKValue;

      // console.log('>>>> TvsViewDataList.doTableRowSelect(): PKValue       = ', this.PKValue);

      this.ToolbarConfig();

      (this.ViewProperties.Instance as TvsViewDataList).detailDataSynchronize();

    // } else {
    //   if(vsCommon.vsVarAssigend((this.ViewProperties.Instance as TvsViewDataList).showDialogDetailEditModified)){
    //     (this.ViewProperties.Instance as TvsViewDataList).showDialogDetailEditModified = true;
    //   }
    // $ rev AK 2022-05-06: doch geändert, nun kein Dialog mehr, sondern nur einblendung einer Flag / eines Toast
    // }

    // Event um von außen accessible zu sein
    this.onRowSelect.emit('');
  } // doTableRowSelect
  //$REV MP 2023-06-23: Name zum doCustomButtonClick hinzugefügt, damit man per Name in den Fielddefs mehrere Events unterscheiden kann.
  protected doCustomButtonClick(event: any, name: string) {
    // console.log('vsViewDataList / doCustomButtonClick($event)');
    (this.ViewProperties.Instance as TvsViewDataList).doCustomButtonClick(event, name);
  }

  protected doActionEditClick(event: any) {
    // console.log('vsViewDataList / doCustomButtonClick($event)');
    this.PKValue = event.PKValue;
    console.log(this.ProgFuncID);
    (this.ViewProperties.Instance as TvsViewDataList).actionEdit(this);
  }

  protected doActionDeleteClick(event: any) {
    // console.log('vsViewDataList / doCustomButtonClick($event)');
    this.PKValue = event.PKValue;
    (this.ViewProperties.Instance as TvsViewDataList).actionDelete(this);
  }


  //====== Methods: Hooks / Event handler


  protected doAfterRefresh() {
    super.doAfterRefresh();

    // if(vsCommon.vsVarAssigend(this.TabItem)){
    //   this.TabItem.Caption = this.ViewProperties.ViewTitle + ' [' + this.RetrieveRecordTitleInfo() + ']';
    // }
    // let search = this.LookupLinie.map(el => el.Linie_Nr);
    // let i = search.indexOf(this.DOMain.Dataset.Data[0].Vorf_Linie_Nr);

    (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.FieldDefs = [];

// console.log('Select: ', (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.TableList.selectionMode);


    if ((this.ViewProperties.Instance as TvsViewDataList).ViewProperties.TableList.selectionMode == 'single') {
      if (this.colEditButtonVisible) {
        (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.FieldDefs.push(
          { Name: "Edit",    Type: "ftEditButton",   Caption: "", Size: "40",  Sort: false, Alignment: vsCommon.TvsAlignment.alLeft
          ,ImgSrc: "/assets/img/Icons/vs-edit-circle.svg"}
        );
      }
      if (this.colDeleteButtonVisible) {
        (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.FieldDefs.push(
          { Name: "Delete",  Type: "ftDeleteButton",   Caption: "", Size: "40",  Sort: false, Alignment: vsCommon.TvsAlignment.alLeft
          ,ImgSrc: "/assets/img/Icons/vs-delete-circle.svg"}
        );
      }
    }
    this.ToolbarConfig();


    // console.log('#####DoAfterRefresh() ######################', this);

    // console.log('Selected PKValue: ', this.SelectedPKValue);

    if (vsCommon.vsVarAssigend((this.ViewProperties.Instance as TvsView).ViewProperties.TableList) &&
      this.DOMain.Dataset.Data.length > 0) {
      if (vsCommon.vsStringIsNotNull(this.SelectedPKValue)) {
        let lastSelected: any = vsCommon.vsRetrieveRecordByPKValue(this.DOMain.Dataset.Data, this.SelectedPKValue);
        (this.ViewProperties.Instance as TvsView).ViewProperties.TableList.doSelect(lastSelected, this.SelectedPKValue);
      } else {
        (this.ViewProperties.Instance as TvsView).ViewProperties.TableList.doSelect();
      }
    }

    if (vsCommon.vsVarAssigend((this.ViewProperties.Instance as TvsViewDataList).TabItem) && (this.ViewProperties.Instance as TvsViewDataList).KennShowDetailTabRecordcount) {
      (this.ViewProperties.Instance as TvsViewDataList).TabItem.Caption = (this.ViewProperties.Instance as TvsViewDataList).DetailTabCaption + ' [' + this.DOMain.Dataset.Data.length + ']';
    }

    // if (vsCommon.vsVarAssigend(this.TabItem) && this.KennShowDetailTabRecordcount) {
    //   this.TabItem.Caption = this.DetailTabCaption + ' [' + this.DOMain.Dataset.Data.length + ']';

    //   console.log('this.TabItem.Caption: ',  this.TabItem.Caption);
    //   console.log('this.DetailTabCaption: ', this.DetailTabCaption);


    // }


  } // doAfterRefresh


  //====== [LABOR]

  public btnToolbar_OnClick(a_iItemIdx: number): void {
    const findItemInArrayByIdProp = (array: any, id: any, idProp: string): any => {
      let item = array.find((item) => {
        return item[idProp] === id;
      });
      return item;
    };
    // console.log('>>>> ' + this.constructor.name + '.btnToolbar_OnClick(): a_iItemIdx = ' + a_iItemIdx + ' / Item.Caption = ' + this.ViewProperties.ToolbarItems[a_iItemIdx].Caption);

    let sActionID: string = this.ViewProperties.ToolbarItems[a_iItemIdx].ID;
    switch (sActionID) {
      case 'tbNew':
        this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        // this.actionNew();
        break;
      case 'tbEdit':
        // this.actionEdit();
        this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        break;
      case 'tbDelete':
        // this.actionDelete();
        this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        break;
      case 'tbRefresh':
        // this.actionRefresh(TvsGetType.gtNormal);
        this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        break;
      case 'tbExport':
        this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        break;
      case 'tbActions':
        // (this.ViewProperties.Instance as TvsViewDataList).actionActions();
        break;
      case 'tbReports':
        // This toolbar item has sub-menus and no actions assigned
        this.actionTest();
        break;
      //$REV MP 2022-09-09: Platzhalter eingefügt um spezielle Buttons auf Listen einzubauen
      case 'tbCustom':
        console.log('tbCustom');
        // if(findItemInArrayByIdProp((this.ViewProperties.Instance as TvsViewDataList).globalResources.UserProfile.UserRights.progFuncList, (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.ProgFuncID, 'progFuncID').execute) {
        //   console.log("hallo");
          this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        // }

        break;

      default:
        alert('ERROR: Toolbar - Unsupported Action-ID: ' + sActionID);
        break;
    } // switch
  } // btnToolbar_OnClick


  // public doOnClickTest(): void {
  //   // this.actionTest();                                             // so funktioniert es nicht...
  //   (this.ViewProperties.Instance as TvsViewDataList).actionTest();   // ... aber so!
  // } // doOnClickTest

  public actionRefreshCall(a_caller: any) {
    a_caller.actionRefresh(TvsGetType.gtNormal);
  }


  public actionTest(): void {

    // let sMsg:   String;

    // sMsg = 'Hallo';
    // sMsg = this.retrieveTestMsg(sMsg);

    // alert(sMsg);

    //    this.DOMain.Dataset.Data.splice(0,1);
    this.DOMain.Dataset.Data.push({ Anrede_Kuerzel: "&&&" });

    // console.log('actionTest: ', this.DOMain.Dataset.Data);

  } // actionTest

  protected actionExport(a_caller: any): void {
      if(!this.export){
        this.export = new TvsExport(a_caller.DOMain);
      }
      this.export.a_caller = a_caller.DOMain;
      this.export.name = a_caller.ViewProperties.ViewTitle;
      this.export.csv = '';
      this.export.generateCSV();
      this.export.downloadCSV(a_caller.globalResources.ViewMain);
  }

  protected retrieveTestMsg(a_sMsg: String): String {
    // console.log('TvsViewDataList.retrieveTestMsg() / a_sMsg = "' + a_sMsg + '"');
    // return a_sMsg + ' Welt';
    return a_sMsg;
  } // retrieveTestMsg


  public tbElemByName(a_sName: string): any {
    let tb = this.ViewProperties.ToolbarItems;
    let x = tb.map(el => el.ID)
    let i = x.indexOf(a_sName);

    return tb[i];
  }

  private ToolbarConfig(): void {

    let x: any;
    // console.log('ToolbarConfig');

    // Daten aus Edit in Listen-Zeile übertragen

    let bDatenVorhanden: boolean = (this.ViewProperties.Instance as TvsViewDataList).DOMain.Dataset.Data.length > 0;
    let bEditOrDeleteOK = bDatenVorhanden && (this.dataSelected.length != 0);

    // console.log('this.DataSelected:', this.DataSelected);

    this.tbElemByName('tbEdit').Enabled = bEditOrDeleteOK;
    this.tbElemByName('tbDelete').Enabled = bEditOrDeleteOK;

    if (vsCommon.vsVarAssigend((this.ViewProperties.Instance as TvsViewDataList).ActionList)) {
      (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionEdit').disabled = !bEditOrDeleteOK;
      (this.ViewProperties.Instance as TvsViewDataList).ActionList.ItemByName('ActionDelete').disabled = !bEditOrDeleteOK;
    }
    // (this.ViewProperties.Instance as TvsViewDataList).LoadContextMenu(); // Damit die Statusänderung auch angezeigt wird, muss das Menü neu geladen werden.

    (this.ViewProperties.Instance as TvsViewDataList).LoadContextMenu(); // Damit die Statusänderung auch angezeigt wird, muss das Menü neu geladen werden.

  };

  public FilterStdMultiOnKeyDown(event: KeyboardEvent): boolean {
    let bOK: boolean;

    bOK = (event.key !== '<') &&
      (event.key !== '>') &&
      (event.key !== '%') &&
      (event.key !== '$');

    if (bOK && (event.key == "Enter") && (this.listForm.controls['FilterStdMulti'].value.length > 2)) {
      (this.ViewProperties.Instance as vsViewData.TvsViewData).actionRefresh();
    }

    return bOK;
  }

  protected checkIfDetailEditModified() :boolean{
    //$rev AK 2022-05-05: für dialog warnung anzeige wegen List/Edit Kombo im Schulenportal (check Schulenportal HomeView)
    // Diese Funktion dient der übergabe des IsModified Status von Edit an List, sodass bei
    // TableRowSelect eine Auswahländerung während des Editierens abgefangen werden kann
    // Konkreter Fall: homeView (AntragList) - AntragEdit im Schulenportal

    let bIsModified: boolean = false;
    return bIsModified;
  }

  //------------------------------------------------------------------------------------------------
  // Method:  ValidataDelete
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   ValidateDelete Check, if the record can be deleted or not.
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected ValidateDelete(): boolean {
    return true;
  }

  // public doTestUrl(){

  //   let blob = new Blob(["this.csv], {"type": "text/csv;charset=utf8;"});

  //   if (navigator.msSaveBlob) {
  //       let filename = '';
  //       navigator.msSaveBlob(blob, filename);
  //   } else {
  //       let uri = 'data:attachment/csv;charset=utf-8,' + encodeURI(this.csv);
  //       let link = document.createElement("a");

  //       link.href = URL.createObjectURL(blob);

  //       link.setAttribute('target', '_blank');
  //       link.setAttribute('visibility', 'hidden');
  //    //   link.download = this._options.filename.replace(/ /g, "_") + ".csv";

  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //   }

  // }

  public doDataExportCSV() {
    //   var options = {
    //     fieldSeparator: ';',
    //     quoteStrings: '"',
    //     // decimalseparator: '.',
    //     showLabels: true,
    //     showTitle: false,
    //     // title: 'Download',
    //     // useBom: true,
    //     // noDownload: false,
    //     headers:["First Name", "Last Name", "ID"],
    //     //useHeader: true,
    //     nullToEmptyString: false,
    //   };
    //   console.log('Export');
    //   console.log('Export Data', this.DOMain.Dataset.Data);
    //   // console.log('Export options', options);
    //   //new AngularCsv(this.DOMain.Dataset.Data, 'Export', options);


    //   // var data = [
    //   //   {
    //   //     name: "Test 1",
    //   //     age: 13,
    //   //     average: 8.2,
    //   //     approved: true,
    //   //     description: "using 'Content here, content here' "
    //   //   },
    //   //   {
    //   //     name: 'Test 2',
    //   //     age: 11,
    //   //     average: 8.2,
    //   //     approved: true,
    //   //     description: "using 'Content here, content here' "
    //   //   },
    //   //   {
    //   //     name: 'Test 4',
    //   //     age: 10,
    //   //     average: 8.2,
    //   //     approved: true,
    //   //     description: "using 'Content here, content here' "
    //   //   },
    //   // ];
    //   // console.log('Export Data', data);

    //   let s = new AngularCsv(this.DOMain.Dataset.Data, 'My Report', options);

    //   console.log('AngularCSV: ', s)



  }


} // TvsViewDataList



//#########################################################################


export class TestA {
  public TestIt(): void {
    console.log('TestIt()');
    this.DoIt();
  }

  protected DoIt(): void {
    console.log('TestA');
  }
}


export class TestB extends TestA {
  protected DoIt(): void {
    super.DoIt();
    console.log('TestB');
  }
}


export class TestC extends TestB {
  protected DoIt(): void {
    super.DoIt();
    console.log('TestC');
  }
}


