<form [formGroup]="this.FormGroupFileUpload">

    <vsLayoutGroup class="col" Orientation="horizontal">

      <vsLayoutGroup class="col-lg-8 col-md-6 col-sm-12">
        <vsInputText label="{{'dialog.file_name'| translate}}" labelWidth="130px" FrmCtlName="Doc_Name"></vsInputText>
      </vsLayoutGroup>
      <vsLayoutGroup class="col-lg-4 col-md-6 col-sm-12">

        <div *ngIf="this.config.data=='Pic'">
          <button pButton class="vsButton" (click)="fileInputPIC.click()">{{'dialog.choose_file'| translate}}</button>
        </div>
        <div *ngIf="this.config.data!='Pic'">
          <button pButton class="vsButton" (click)="fileInput.click()">{{'dialog.choose_file'| translate}}</button>
        </div>

      </vsLayoutGroup>

    </vsLayoutGroup>

    <vsLayoutGroup class="col-12" Orientation="horizontal">

      <!-- <div style="display: flex; align-items: center; justify-content: center; padding: 0.25rem; border-style: solid; border-width: 1px; border-color: gray; border-radius: 0.25rem; min-width: 300px; max-width: 300px; min-height: 200px; max-height: 200px">
        <img  *ngIf="FUploadFileDataURL"
              style="max-width: 100%; max-height: 100%"
              [src]="FUploadFileDataURL"
        >
        <div *ngIf="!FUploadFileDataURL" style="color: gray;">
          &lt;Bitte Datei auswählen&gt;
        </div>
      </div> -->

      <input #fileInputPIC style="display: none" type="file" accept="image/jpeg,image/gif,image/png,image/jpg," (change)="doOnFileSelected($event)">
     
      <input #fileInput style="display: none" type="file" accept="image/jpeg,image/gif,image/png,image/jpg,image/bmp,image/tif,application/pdf," (change)="doOnFileSelected($event)">

      <div *ngIf="this.config.data!='Pic'">
        <p style="margin-top: 0.75rem;">{{'dialog.text_file_type'| translate}}</p>
        <p>{{'dialog.text_file_size_1'| translate}} {{fileMaxUploadSizeDisplay}} {{'dialog.text_file_size_2'| translate}}</p>
        <p *ngIf="customText">{{customText}}</p>
      </div>

      <div *ngIf="this.config.data=='Pic'">
        <vsLayoutGroup class="col-12" Orientation="horizontal" marginTop="0.5rem">
          <!-- Alyle LyImageCropper -->

          <vsLayoutGroup class="col">

            <div class="vsCropperButtonContainer">

              <button pButton pRipple type="button" (click)="cropper.zoomIn()" class="vsCropperButtonRound"><img
                  src="/assets/img/Icons/magnify-plus-outline.svg"></button>
              <button pButton pRipple type="button" (click)="cropper.zoomOut()" class="vsCropperButtonRound"><img
                  src="/assets/img/Icons/magnify-minus-outline.svg"></button>
              <button pButton pRipple type="button" (click)="cropper.center()" class="vsCropperButtonRound"><img
                  src="/assets/img/Icons/image-filter-center-focus.svg"></button>
              <button pButton pRipple type="button" (click)="cropper.rotate(-90)" class="vsCropperButtonRound"><img
                  src="/assets/img/Icons/format-rotate-90.svg"></button>
              <button pButton type="button" label="{{'dialog.image_upload.button_fit'| translate}}" class="vsCropperButton" (click)="cropper.fit()"></button>
              <button pButton type="button" label="{{'dialog.image_upload.button_fit_container'| translate}}"  class="vsCropperButton"
                (click)="cropper.fitToScreen()"></button>
              <button pButton type="button" label="{{'dialog.image_upload.button_orig_size'| translate}}" class="vsCropperButton" (click)="cropper.setScale(1)"></button>
              <button pButton type="button" label="{{'dialog.image_upload.button_delete'| translate}}" class="vsCropperButton" (click)="cropper.clean()"></button>
            </div>
         
            <ly-img-cropper [config]="myConfig" [(scale)]="scale" (minScale)="minScale = $event" (ready)="ready = true"
              (cleaned)="ready = false" (cropped)="onCropped($event)" (loaded)="onLoaded($event)"
              (error)="onError($event)" style="width: 400px; height: 300px;">
              <span>{{'dialog.image_upload.text_upload'| translate}}</span>
            </ly-img-cropper>

            <div>

              <p-slider formControlName="scale" [step]="0.0001" [min]="minScale" [max]="1" (input)="scale = $event.value" (onChange)="scale = $event.value" styleClass="vsCropperSlider"></p-slider>

              <!-- <p-slider formControlName="scale" [(ngModel)]="scale" step="0.0001" [min]="0.1" [max]="1" (input)="scale = $event.value"
                styleClass="vsCropperSlider"></p-slider> -->

            </div>

            <button pButton pRipple label="{{'dialog.image_upload.button_crop'| translate}}" type="button" (click)="cropper.crop()"
              class="vsCropperButton p-button p-component vsMt-2"></button>

          </vsLayoutGroup>

          <vsLayoutGroup class="col" isPanel=true panelTitleVisible=true panelTitleText="{{'dialog.image_upload.heading_cropped_img'| translate}}">
            <div class="vsMb-2 vsCroppedImageContainer" style="height:300px; text-align: center;">
              <img *ngIf="croppedImage" [src]="croppedImage">
              <p *ngIf="!croppedImage" class="vsMt-2">{{'dialog.image_upload.text_cropped_img'| translate}}</p>
            </div>
          </vsLayoutGroup>


        </vsLayoutGroup>
      </div>

    </vsLayoutGroup>
    <div class="vsMb-2"></div>

    <div *ngIf="bKennUpload" style="float:right;">
      <button pButton pRipple type="button" (click)="onUpload()" label="{{'dialog.button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>

      <button pButton pRipple type="button" (click)="onAbbruch()" label="{{'dialog.button_cancel'| translate}}" class="vsToolbarItem vsToolbarItemCancel">
        <span>
          <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
    </div>
    
    <div *ngIf="!bKennUpload" style="float:right;">

      <button pButton pRipple disabled="true" type="button" (click)="onUpload()" label="{{'dialog.button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>

      <button pButton pRipple type="button" (click)="onAbbruch()" label="{{'dialog.button_cancel'| translate}}" class="vsToolbarItem vsToolbarItemCancel">
        <span>
          <img alt="logo" src="/assets/img/Icons/vs-close-circle.svg" />
        </span>
      </button>
    </div>


  </form>

  

  <p-dialog header="{{'dialog.error_file_size_heading'| translate}}" [(visible)]="displayDialogErrorFileSize" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p>{{'dialog.error_file_size_text_1'| translate}} {{fileMaxUploadSizeDisplay}} {{'dialog.error_file_size_text_2'| translate}}</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogErrorFileSize=false" label="{{'dialog.error_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>


  <p-dialog header="{{'dialog.error_file_type_heading'| translate}}"  [(visible)]="displayDialogErrorFileType" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_file_type_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogErrorFileType=false" label="{{'dialog.error_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>

  <!-- KennUpload: {{bKennUpload}} -->


