<div class="vsInputDateTime">
  <div class="row vsMb-2">
    <label *ngIf="!NoLabel" class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col">
        <div *ngIf="Disabled == 'true'">
          <p-calendar
            class="vsForm"
            inputStyleClass="vsFormControl"
            formControlName="{{FrmCtlName}}"
            [dateFormat]="customDateFormat"
            [showIcon]="true"
            iconAriaLabel="open calendar popup"
            [monthNavigator]="true"
            [yearNavigator]="true"
            yearRange="{{YearRange}}"
            inputId="input-{{FrmCtlName}}"
            [showOnFocus]="false"
            class="{{InvalidCSSClass}}"
            dataType="string"
            placeholder="{{placeholder}}"
            [readonlyInput]="ReadOnly == 'true'"
            [timeOnly]="KennTimeOnly"
            [disabled]="Disabled == 'true'"
          ></p-calendar>
        </div>
        <div *ngIf="Disabled == 'false'">
          <p-calendar
            class="vsForm"
            inputStyleClass="vsFormControl"
            formControlName="{{FrmCtlName}}"
            [dateFormat]="customDateFormat"
            [showIcon]="true"
            iconAriaLabel="open calendar popup"
            [monthNavigator]="true"
            [yearNavigator]="true"
            yearRange="{{YearRange}}"
            inputId="input-{{FrmCtlName}}"
            [showOnFocus]="false"
            class="{{InvalidCSSClass}}"
            dataType="string"
            placeholder="{{placeholder}}"
            [readonlyInput]="ReadOnly == 'true'"
            (onInput)="handleMultipleInputEvents($event)"
            [timeOnly]="KennTimeOnly"
          ></p-calendar>
        </div>
      </div>
    </div>
</div>