//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component
  , ViewChild
  , OnInit
  , ComponentFactoryResolver
  , ElementRef}                             from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';
import { TvsDataFilter }                    from '@vsLib/vsDataFilter';


interface Article {
  id:             number | string;
  title:          string;
  bezeichnung:    string;
  amount:         number;
  price:          number;
  streckenlaenge: number;
  totalprice:     number;
  frmCtrlNames:   string[];
  index:          number;
}

@Component({
  selector:       'schoolViewAntragEdit_ErstattungList',
  templateUrl:  './schoolViewAntragEdit_ErstattungList.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_ErstattungList.component.scss']
})
export class TschoolViewAntragEdit_ErstattungList extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)
  private xTotalprice:        number                = 0;

  //====== Properties (PUBLIC)

  public editForm:            FormGroup             = new FormGroup({});
  public articles:            Article[]             = [{
                                                          id: ''
                                                        , title: "Artikel 1"
                                                        , bezeichnung: ''
                                                        , amount: 20
                                                        , price: 2.7
                                                        , streckenlaenge: 0
                                                        , totalprice: 54
                                                        , frmCtrlNames: ['Artikel_ID_0', 'Amount_ID_0', 'Price_ID_0', 'Streckenlaenge_ID_0', 'TotalPrice_ID_0']
                                                        , index: 0
                                                        }];
  public frmCtlIndex                                = 0;
  public limit:               number                = 10;

  public articleOptions:      any[]                 = [];

  public lookupSchuljahr:               any[];

  public lookupSchulHalbjahr:           any[];

  public filteredLookupSchulHalbjahr:   any[];

  //$REV MP 2023-09-25: Bei einigen Kunden soll das aktuell laufende Halbjahr angezeigt werden (SAMR), bei einigen nicht (RTK).
  public KennErstattungRunningHalfYear: boolean = false;

  public startDate_Grundantrag:         Date;
  public endDate_Grundantrag:           Date;
  public kostenUebernahme_ab:           Date;
  public kostenUebernahme_bis:          Date;
  public printStartDate:                string;
  public printEndDate:                  string;

  public displayDateRangeFromError:     boolean   = false;
  public displayDateRangeFromTillError: boolean   = false;

  // public articleOptions:      any[]                 = [ {name: 'Schüler-Monatsticket',   PKValue: 'SCHOOL_OPV_TICKET_MONAT' }
  //                                                      ,{name: 'Schüler-Wochenticket',   PKValue: 'SCHOOL_OPV_TICKET_WOCHE'}
  //                                                      ,{name: 'Einzelfahrkarte',        PKValue: 'SCHOOL_OPV_TICKET_EINZEL'}
  //                                                      ,{name: 'Private PKW-Fahrt',      PKValue: 'SCHOOL_PRIV_PKW'}
  //                                                      ,{name: 'Taxi-Fahrt',             PKValue: 'SCHOOL_PRIV_TAXI'}];

  public displayDialogError:  boolean               = false;

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Besondere Umstände';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    let DataFilter: TvsDataFilter = new TvsDataFilter();

    DataFilter.ItemsClear();
    let item = DataFilter.ItemAdd('Filter Obj_ID_Art');
    item.CriteriaAdd('Obj_ID_Art', 'SCHOOL_ART_ERSTATT');

    this.HttpServiceComponent.dataGet('objDoStammBase', false, [], DataFilter)
    .subscribe(data => {
      this.articleOptions =  data['Dataset'].Data;
    });

    this.lookupSchuljahr = vsCommon.RetrieveSchoolYearLookup();
    this.lookupSchulHalbjahr = vsCommon.RetrieveSchoolHalfYearLookup();

  } // constructor

//====== Methods: NG event handler

  ngOnInit(): void {

    if(vsCommon.vsVarAssigend(this.dataModel.KennErstattungRunningHalfYear)) {
      this.KennErstattungRunningHalfYear = this.dataModel.KennErstattungRunningHalfYear;
    }

    //Berechnung geändert, TODO evtl. über Variable gesteuert für RTK nur zurückliegende, für Marburg zusätzlich das aktuell laufende Schulhalbjahr anzeigen lassen.
    //TODO Datumsbereich um Abgleich mit Grundantrag erweitern, Abgleich sollte "Kostenübernahme ab" berücksichtigen.
    const currentDate = new Date();
    //Testfall für zweites Halbjahr ab 01.02
    // const currentDate = new Date(2023, 1, 1);

    // Filter for school years ending in the current year
    const schoolYearsEndingThisYear = this.lookupSchulHalbjahr.filter(({ Enddatum }) => {
      const endDate = new Date(vsCommon.DateStrToISO8061(Enddatum));
      return endDate.getFullYear() === currentDate.getFullYear();
    });
    
    // Filter for currently running school half-years
    const currentlyRunningHalfYears = this.lookupSchulHalbjahr.filter(({ Startdatum, Enddatum }) => {
      const startDate = new Date(vsCommon.DateStrToISO8061(Startdatum));
      const endDate = new Date(vsCommon.DateStrToISO8061(Enddatum));
      return startDate <= currentDate && endDate >= currentDate;
    });
    
    // Combine the results
    if(this.KennErstattungRunningHalfYear) {
      this.filteredLookupSchulHalbjahr = schoolYearsEndingThisYear.concat(currentlyRunningHalfYears);
    } 
    else {
      this.filteredLookupSchulHalbjahr = schoolYearsEndingThisYear
    }
    
    
    //$REV MP 2023-06-22: Extrahieren des aktuellsten der zurückliegenden Schul-Halbjahre um nur das letzte zurückliegende zu bekommen.
    // this.filteredLookupSchulHalbjahr = this.filteredLookupSchulHalbjahr.pop();

    if(!this.dataModel.article_Data){
      this.editForm.addControl('SchoolYear',                              new FormControl(null));
      this.editForm.addControl('Refund_from',                             new FormControl(null));
      this.editForm.addControl('Refund_till',                             new FormControl(null));
      this.editForm.addControl('Artikel_ID_0',                            new FormControl(null));
      this.editForm.addControl('Amount_ID_0',                             new FormControl(null));
      this.editForm.addControl('Price_ID_0',                              new FormControl(null));
      this.editForm.addControl('Streckenlaenge_ID_0',                     new FormControl(null));
      this.editForm.addControl('TotalPrice_ID_0',                         new FormControl(this.xTotalprice));

      if(this.editForm.controls['TotalPrice_ID_0']) {
          this.editForm.controls['TotalPrice_ID_0'].disable();
      }

    }
    else {
      this.frmCtlIndex = this.dataModel.articles[this.dataModel.articles.length - 1].index;
      this.generateFromDataModel();
    };

    this.frmCtlIndex += 1;
  } // ngOnInit


  ngAfterViewInit(): void {

    //$REV MP 2023-03-20: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }

  }

  doSchuljahrSelect(data) {

    if(vsCommon.vsStringHasValue(data)) {
      this.editForm.controls['Refund_from'].setValue(data.Startdatum);
      this.editForm.controls['Refund_till'].setValue(data.Enddatum);
    }
  }

  public actionStepPrev(): void {
    this.dataSave();

    // school_attendance
    if (this.dataModel.antrag_Art === 'EA') {
      this.dataSave();
      this.router.navigate(['antragEdit/school_attendance']);
    }

    if (this.dataModel.antrag_Art === 'E') {
      this.dataSave();
      this.router.navigate(['antragEdit/antrag']);
    }

  }

  public actionStepNext(): void {

    this.setAndUpdateValidators();
    const dateRangeCheckPassed = this.checkDateRange();
  
    if (!this.editForm.invalid && dateRangeCheckPassed) {
      this.dataSave();
      this.router.navigate(['antragEdit/doc']);
    } else {
      this.displayDialogError = true;
    }
  }

  //$REV MP 2023-02-10: Vorbereitung für Check der Datumsrange, ob Erstattungsbereich innerhalb des Grundantragsbereiches liegt.
  checkDateRange() {

    console.log('checkDateRange');

    var erstattAntragStartDate = new Date(vsCommon.DateStrToISO8061(this.editForm.controls['Refund_from'].value));
    var erstattAntragEndDate = new Date(vsCommon.DateStrToISO8061(this.editForm.controls['Refund_till'].value));

    this.startDate_Grundantrag = new Date(vsCommon.vsVarCvNull(this.dataModel.kostenUebernahme_ab, this.dataModel.startDate_Grundantrag))

    this.printStartDate = vsCommon.ISO8061ToDateStr(vsCommon.vsVarCvNull(this.dataModel.kostenUebernahme_ab, this.dataModel.startDate_Grundantrag));
    
    //Checken ob Enddatum oder Kostenübernahme bis für den Grundantrag befüllt ist.
    if(vsCommon.vsStringHasValue(this.dataModel.endDate_Grundantrag) || vsCommon.vsStringHasValue(this.dataModel.kostenUebernahme_bis)){

      this.endDate_Grundantrag   = new Date(vsCommon.vsVarCvNull(this.dataModel.kostenUebernahme_bis, this.dataModel.endDate_Grundantrag))
      this.printEndDate = vsCommon.ISO8061ToDateStr(vsCommon.vsVarCvNull(this.dataModel.kostenUebernahme_bis, this.dataModel.endDate_Grundantrag));

      //Wenn ja checken auf gesamte Datumsrange.
      if ((erstattAntragStartDate >= this.startDate_Grundantrag) && (erstattAntragEndDate <= this.endDate_Grundantrag)) {
        // this.displayDateRangeFromTillError = false;
        console.log('Both start and end dates are within the range of erstattAntrag');
      } else {
        this.editForm.get('Refund_from').setErrors({ 'invalid': true });
        this.editForm.get('Refund_till').setErrors({ 'invalid': true });
        this.displayDateRangeFromTillError = true;
  
      }
    } else {
      //Wenn nicht checken auf Startdatum. Müsste in jedem Fall befüllt sein.
      if (erstattAntragStartDate >= this.startDate_Grundantrag) {
        // this.displayDateRangeFromError = false;
        console.log('Start date is earlier than erstattAntragStartDate');
      } else {
        this.editForm.get('Refund_from').setErrors({ 'invalid': true });
        this.displayDateRangeFromError = true;
      }

    }

    // Check if any errors were triggered
    return !(this.displayDateRangeFromError || this.displayDateRangeFromTillError);
  }

  setAndUpdateValidators() {

    if(this.articles && this.dataModel.articles.length === 0) {
      this.articles.map((value) => {
        this.editForm.get("Refund_from").setValidators(Validators.required);
        this.editForm.get("Refund_from").updateValueAndValidity();
        this.editForm.get("Refund_till").setValidators(Validators.required);
        this.editForm.get("Refund_till").updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[0]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[0]).updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[1]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[1]).updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[2]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[2]).updateValueAndValidity();

        if (this.editForm.get(value.frmCtrlNames[0]).value.Obj_ID === "SCHOOL_PRIV_PKW") {
          this.editForm.get(value.frmCtrlNames[3]).setValidators(Validators.required);
          this.editForm.get(value.frmCtrlNames[3]).updateValueAndValidity();
        }
        else {
          this.editForm.get(value.frmCtrlNames[3]).setValidators(Validators.nullValidator);
          this.editForm.get(value.frmCtrlNames[3]).updateValueAndValidity();
        };

        this.editForm.get(value.frmCtrlNames[4]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[4]).updateValueAndValidity();
      });
    };

    if(this.dataModel.articles.length > 0) {
      this.dataModel.articles.map((value) => {
        this.editForm.get("Refund_from").setValidators(Validators.required);
        this.editForm.get("Refund_from").updateValueAndValidity();
        this.editForm.get("Refund_till").setValidators(Validators.required);
        this.editForm.get("Refund_till").updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[0]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[0]).updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[1]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[1]).updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[2]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[2]).updateValueAndValidity();

        if (this.editForm.get(value.frmCtrlNames[0]).value.Obj_ID === "SCHOOL_PRIV_PKW") {
          this.editForm.get(value.frmCtrlNames[3]).setValidators(Validators.required);
          this.editForm.get(value.frmCtrlNames[3]).updateValueAndValidity();
        }
        else {
          this.editForm.get(value.frmCtrlNames[3]).setValidators(Validators.nullValidator);
          this.editForm.get(value.frmCtrlNames[3]).updateValueAndValidity();
        };

        // this.editForm.get(value.frmCtrlNames[3]).setValidators(Validators.required);
        // this.editForm.get(value.frmCtrlNames[3]).updateValueAndValidity();
        this.editForm.get(value.frmCtrlNames[4]).setValidators(Validators.required);
        this.editForm.get(value.frmCtrlNames[4]).updateValueAndValidity();
      });
    };

  }

  addArticleRowAndControl() {

    if(this.articles.length < this.limit) {

      this.editForm.addControl('Artikel_ID_'       + this.frmCtlIndex, new FormControl(null));
      this.editForm.addControl('Amount_ID_'        + this.frmCtlIndex, new FormControl(null));
      this.editForm.addControl('Price_ID_'         + this.frmCtlIndex, new FormControl(null));
      this.editForm.addControl('Streckenlaenge_ID_'+ this.frmCtlIndex, new FormControl(null));
      this.editForm.addControl('TotalPrice_ID_'    + this.frmCtlIndex, new FormControl(null));

      this.articles.push({
                            id: ''
                          , title:          `Artikel ${this.frmCtlIndex + 1}`
                          , bezeichnung:    ""
                          , amount:         0
                          , price:          0
                          , streckenlaenge: 0
                          , totalprice:     0
                          , frmCtrlNames:
                            [
                                'Artikel_ID_'          + this.frmCtlIndex
                              , 'Amount_ID_'           + this.frmCtlIndex
                              , 'Price_ID_'            + this.frmCtlIndex
                              , 'Streckenlaenge_ID_'   + this.frmCtlIndex
                              , 'TotalPrice_ID_'       + this.frmCtlIndex
                            ]
                          , index:        this.frmCtlIndex
                        });

        this.editForm.controls['TotalPrice_ID_' + this.frmCtlIndex].disable();

      this.frmCtlIndex += 1;
    }
  }

  removeArticleRowAndControl(articleObject): void {

    if(this.articles.length !== 1) {
      let controlNameArtikel = articleObject.frmCtrlNames[0]
      // this.editForm.get('controlNameArtikel').enable(); // Enable the control
      this.editForm.removeControl(controlNameArtikel);

      let controlNameAmount = articleObject.frmCtrlNames[1]
      // this.editForm.get(controlNameAmount).enable(); // Enable the control
      this.editForm.removeControl(controlNameAmount);

      let controlNamePrice = articleObject.frmCtrlNames[2];
      // this.editForm.get(controlNamePrice).enable(); // Enable the control
      this.editForm.removeControl(controlNamePrice);

      let controlNameStreckenlaenge = articleObject.frmCtrlNames[3];
      this.editForm.get(controlNameStreckenlaenge).enable(); // Enable the control
      this.editForm.removeControl(controlNameStreckenlaenge);

      let controlNameTotalPrice = articleObject.frmCtrlNames[4];
      this.editForm.get(controlNameTotalPrice).enable(); // Enable the control
      this.editForm.removeControl(controlNameTotalPrice);

      this.articles = this.articles.filter(item => {
        return item.index !== articleObject.index;
      });

      if(this.dataModel.articles.length > 0) {
        this.dataModel.articles = this.dataModel.articles.filter(item => {
          return item.index !== articleObject.index;
        });
      };
    };

  }

  generateFromDataModel() {

    [this.dataModel.article_Data].map((data) => {
      let values = Object.values(data);
      Object.keys(data).map((key, index) => {

        this.editForm.addControl(key, new FormControl(values[index]));
        let matchingValue = key.match(/TotalPrice_ID_(.*)/g);
        if(vsCommon.vsVarAssigend(matchingValue)) {
          this.editForm.controls[matchingValue[0]].disable();
        }
      });
    });
    this.articles = this.dataModel.articles;
  }

  combineFormValuesWithArticlesArr() {
    this.articles.map((article) => {

      article.id               = this.editForm.controls[article.frmCtrlNames[0]].value?.PKValue;
      article.bezeichnung      = this.editForm.controls[article.frmCtrlNames[0]].value?.Obj_Name;
      article.amount           = this.editForm.controls[article.frmCtrlNames[1]].value;
      article.price            = this.editForm.controls[article.frmCtrlNames[2]].value;
      article.streckenlaenge   = this.editForm.controls[article.frmCtrlNames[3]].value;
      article.totalprice       = this.editForm.controls[article.frmCtrlNames[4]].value;
    });
  }

  setDataModel() {

    //TotalPrice muss enabled werden, da die Values von disabled-FormControls ansonsten nicht ausgelesen werden können.
    Object.keys(this.editForm.controls).map((value) =>{
      let matchingValue = value.match(/TotalPrice_ID_(.*)/g);
      if(vsCommon.vsVarAssigend(matchingValue)) {
        this.editForm.controls[matchingValue[0]].enable();
      }
    })

    this.editForm.enable();

    this.dataModel.article_Data = this.editForm.value;
    this.dataModel.articles     = this.articles;
  }

  public dataSave() :void{

    this.combineFormValuesWithArticlesArr();
    this.setDataModel();
  }

  public calcTotalprice(article){

    // if (vsCommon.vsVarAssigend(this.editForm.controls[article?.frmCtrlNames[4]].value)) {
      if(this.editForm.controls[article?.frmCtrlNames[4]] && this.editForm.controls[article?.frmCtrlNames[1]]) {
        this.editForm.controls[article?.frmCtrlNames[4]].setValue(
          this.editForm.controls[article?.frmCtrlNames[1]].value * this.editForm.controls[article?.frmCtrlNames[2]].value
        );
      }
    // }


    //Berechnung des Wertes bei privater Fahrt
    if (vsCommon.vsVarAssigend(this.editForm.controls[article?.frmCtrlNames[0]].value)) {
      if(this.editForm.controls[article?.frmCtrlNames[0]].value.Obj_ID === "SCHOOL_PRIV_PKW") {
        this.editForm.controls[article?.frmCtrlNames[4]].setValue(
          this.editForm.controls[article?.frmCtrlNames[1]].value * this.editForm.controls[article?.frmCtrlNames[2]].value * this.editForm.controls[article?.frmCtrlNames[3]].value
        );
      }
    }

  }


  public disableEnableEditForm(article) {

    if (this.editForm.controls[article?.frmCtrlNames[0]].value.Obj_ID === "SCHOOL_PRIV_PKW") {

      //Kilometerfeld
      this.editForm.controls[article?.frmCtrlNames[3]].enable();

      //Einzelpreis
      this.editForm.controls[article?.frmCtrlNames[2]].setValue(0.38);
      this.editForm.controls[article?.frmCtrlNames[2]].disable();
    }

    // TODO: sperren der Elementen nach dem initialen laden wenn der value noch auf null steht

    if(this.editForm.controls[article?.frmCtrlNames[0]].value.Obj_ID !== "SCHOOL_PRIV_PKW") {

      //Kilometerfeld
      this.editForm.controls[article?.frmCtrlNames[3]].setValue(0);
      this.editForm.controls[article?.frmCtrlNames[3]].disable();

      //Einzelpreis
      this.editForm.controls[article?.frmCtrlNames[2]].enable();
      this.editForm.controls[article?.frmCtrlNames[2]].setValue(0);
    }

   }

} // schoolViewAntragEdit_Student


