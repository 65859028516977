
<!-- Rev 2021-07-12 MP Klasse .vsForm hinzugefügt und InputStyleClass entfernt -->
<!-- $rev AK 2022-07-28: inputId für Barrierefreiheit ergänzt. Auf property gesetzt zur übergabe eigener IDs -->

<div class="vsInputRadioButton">
  <div class="row vsMb-2">
    <div class="col">
      <div *ngIf="ReadOnly=='true'">
        <p-radioButton
                class="vsForm"
                formControlName={{FrmCtlName}}
                label="{{labelContent}}"
                value={{Value}}
                disabled="true"
        ></p-radioButton>
      </div>
      <div *ngIf="ReadOnly=='false'">
        <p-radioButton
                class="vsForm"
                formControlName={{FrmCtlName}}
                label="{{labelContent}}"
                value={{Value}}
                (onClick)=doRadioButtonClick($event)
        ></p-radioButton>
      </div>
    </div>
  </div>
</div>


