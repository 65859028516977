//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, AbstractControl }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// [ngx-translate]
import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import {  DialogService
        , DynamicDialogRef
        , DynamicDialogConfig }             from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import { TSearchDlgData, TvsSearchDlg }     from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';


type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}


type TranslationHeadingType = {
  name: string;
  type: string;
  translationCaption: string;
  size: string;
  sort: boolean;
  alignment: any;
}


@Component({
  selector:       'schoolViewAntragEdit_School',
  templateUrl:  './schoolViewAntragEdit_School.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_School.component.scss']
  providers:  [DialogService, DynamicDialogRef, DynamicDialogConfig]
})
export class TschoolViewAntragEdit_School extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  // ./.
  public      editForm:                     FormGroup               = new FormGroup({});
  // public      Schulen:                      any[];
  // public      Schularten:                   any[];

  public      schulID:                      any;

  // public      schuleComplete:                   any;

  public      displayDialogError:           boolean                 = false;
  public      displayErrorValidationKlasse: boolean                 = false;

  public      refSearchDlg:                 DynamicDialogRef;
  public      schoolData:                   any[];
  public      translationDialogItems:       TranslationDialogItemsType[]                          = [];
  public      translationHeadingItems:      TranslationHeadingType[]                              = [];

  // Falls die property KennFilterSearchAll von außen gesetzt wird, wird vor jeden Suchparamenter automatisch der Joker * gesetzt - hier der letzte Wert nach den FieldDefs in der Auflistung "true"
  public      searchDlgData: TSearchDlgData = new TSearchDlgData(
    'ausbDOStammStaettLinkArt',
    [
        { Name: "AusbStaett_Name",      Type: "ftString",   Caption: "Name der Schule", Size: "380",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft }
      , { Name: "Art_Name",             Type: "ftString",   Caption: "Schulform",       Size: "380",     Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
      , { Name: "Adr_Ort",              Type: "ftString",   Caption: "Ort",             Size: "200",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
      , { Name: "Adr_PLZ",              Type: "ftString",   Caption: "PLZ",             Size: "60",     Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft  }
    ], false, null, this.DataFilter, false, true
  );

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;


  //====== Constructor

  constructor(protected router:                     Router,
                public    globalResources:            vsGlobalResources.TvsGlobalResources
              , public    dataModel:                  schoolDataModel.TschoolDataModel
              , protected messageService:             MessageService
              , protected HttpServiceComponent:       TvsHttpServiceComponent
              , public    dialogService:              DialogService
              , public    translate:                  TranslateService     // [ngx-translate]

  ) {
    super(router, globalResources);

    console.log('#### TschoolViewAntragEdit_School: constructor');

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schule';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.translationHeadingItems = [
        { name: 'AusbStaett_Name',      type: 'ftString',   translationCaption: 'dialog.text_name_school',     size: '380',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft }
      , { name: 'Art_Name',             type: 'ftString',   translationCaption: 'dialog.text_type_school',     size: '380',     sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
      , { name: 'Adr_Ort',              type: 'ftString',   translationCaption: 'dialog.text_city_school',     size: '200',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
      , { name: 'Adr_PLZ',              type: 'ftString',   translationCaption: 'dialog.text_zip_code_school', size: '60',     sort: false,   alignment: vsCommon.TvsAlignment.alLeft  }
    ];

  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {


   this.editForm.addControl('AusbStaett_Name',                new FormControl(this.dataModel.antr_Ausb_Staett_Rec?.AusbStaett_Name));

   this.editForm.addControl('Antr_ID_Ausb_Staett_Art',        new FormControl(this.dataModel.antr_Ausb_Staett_Rec?.Art_Name));

   //Disable des Ausb-Stätten-Art Feldes, wird auch durch den Auswahldialog der Schule mitbefüllt.
   this.editForm.controls['Antr_ID_Ausb_Staett_Art'].disable();

   this.editForm.addControl('Antr_Klasse_Beg_Nr',             new FormControl(this.dataModel.antr_Klasse_Beg_Nr));
   this.editForm.addControl('Antr_Klasse_Beg_Zusatz',         new FormControl(this.dataModel.antr_Klasse_Beg_Zusatz));

  } // ngOnInit


  ngAfterViewInit(): void {

    // if(vsCommon.vsStringHasValue(this.dataModel.antr_Ausb_Staett_Rec)) {
    //   console.log('this.dataModel.antr_Ausb_Staett_Rec' , this.dataModel.antr_Ausb_Staett_Rec);
    // }
    
    // if(vsCommon.vsStringHasValue(this.dataModel.antr_Ausb_Staett_Rec?.AusbStaett_Name)) {
     
    //   this.editForm.controls['AusbStaett_Name'].setValue(this.dataModel.antr_Ausb_Staett_Rec.AusbStaett_Name);
    // }
    // if(vsCommon.vsStringHasValue(this.dataModel.antr_Ausb_Staett_Rec?.Art_Name)) {
    //   this.editForm.controls['Antr_ID_Ausb_Staett_Art'].setValue(this.dataModel.antr_Ausb_Staett_Rec.Art_Name);
    // }


    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }

  }



  public actionStepPrev(): void {
    this.dataSave();
    this.router.navigate(['antragEdit/student']);
  } // actionStepPrev()


  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK) {

      this.editForm.get('AusbStaett_Name').setValidators(Validators.required);
      this.editForm.get('AusbStaett_Name').updateValueAndValidity();

      this.editForm.get('Antr_ID_Ausb_Staett_Art').setValidators(Validators.required);
      this.editForm.get('Antr_ID_Ausb_Staett_Art').updateValueAndValidity();

      this.editForm.get('Antr_Klasse_Beg_Nr').setValidators([Validators.required, this.validateSchoolGrade()]);
      this.editForm.get('Antr_Klasse_Beg_Nr').updateValueAndValidity();

      bOK = !this.editForm.invalid;

      if(this.editForm.invalid){
        if(vsCommon.vsVarAssigend(this.editForm.controls['Antr_Klasse_Beg_Nr'].errors)){
          this.displayErrorValidationKlasse = true;
        } else {
          this.displayDialogError = !bOK;
        }
      } 

      if (bOK) {
        this.dataSave();

        switch (this.dataModel.antrag_Art) {
          case 'E':
            this.router.navigate(['antragEdit/erstattung']);
            break;
          
          case 'U':
            this.router.navigate(['antragEdit/besondere']);
            break;

          case 'I':
            this.router.navigate(['antragEdit/pickup']);
            break;
          
          default:
            this.router.navigate(['antragEdit/besondere']);
        }
      }
    }
  } // actionStepNext()





doSchoolSearch(event:  any) {

  this.DataFilter.ItemsClear();

  //Übersetzungen in TSearchDlgData pushen
  //Items initial leeren damit die übersetzten Items nicht dazu kommen.
  this.searchDlgData.FieldDefs = [];
  console.log('searchDlgData', this.searchDlgData.FieldDefs);
  this.translationHeadingItems.map((item) => {
    this.translate.get(item.translationCaption).subscribe((res)=>{
      this.searchDlgData.FieldDefs.push(
            { Name:       item.name,    
              Type:       item.type,      
              Caption:    res,
              Size:       item.size,
              Sort:       item.sort,
              Alignment:  item.alignment,
            }
        );
    });
  });

  console.log('searchDlgData', this.searchDlgData.FieldDefs);

  this.translationDialogItems = [
    { data:  this.searchDlgData, 
      header: 'dialog.title_search_school', 
      styleClass: 'vsDialog vsFullscreenDialog',
      baseZIndex: 10000}
  ]

  this.translationDialogItems.map((item) => {
    this.translate.get(item.header).subscribe((res)=>{

        this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
          data:         item.data,
          header:       res,
          width:        item.width,
          styleClass:   item.styleClass,
          style:        item.style,
          baseZIndex:   item.baseZIndex

        });
      });
    });


  this.refSearchDlg.onClose.subscribe((Data: any) => {
    if (vsCommon.vsVarAssigend(Data)) {
      this.schoolData = Data;
      this.editForm.controls['AusbStaett_Name'].setValue(Data.AusbStaett_Name);
      this.editForm.controls['Antr_ID_Ausb_Staett_Art'].setValue(Data.Art_Name);
      }
  });

}


public dataSave() :void{

  if (vsCommon.vsVarAssigend(this.editForm.controls['AusbStaett_Name'].value)) {
    //Wenn das Feld schon gefüllt ist, wird der Datensatz hier sonst durch einen leeren Datensatz überschrieben
    //Wenn schoolData leer ist, bleibt dataModel.antr_Ausb_Staett_Rec unverändert
    if(vsCommon.vsVarAssigend(this.schoolData)) {
      this.dataModel.antr_Ausb_Staett_Rec  =  this.schoolData;
    }

  }

  // $Rev MP 2021-06-28: Überflüssige Datenmengen entfernt

  this.dataModel.antr_ID_Ausb_Staett_Name      = this.editForm.controls['AusbStaett_Name'].value;

  this.dataModel.antr_Klasse_Beg_Nr            = this.editForm.controls['Antr_Klasse_Beg_Nr'].value;
  this.dataModel.antr_Klasse_Beg_Zusatz        = this.editForm.controls['Antr_Klasse_Beg_Zusatz'].value;

}


private validateSchoolGrade() :ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let valid:    boolean;
    let gradeMin: number  = this.schoolData ? this.schoolData['Art_Klasse_Beg']
                                            : this.dataModel.antr_Ausb_Staett_Rec 
                                                ? this.dataModel.antr_Ausb_Staett_Rec.Art_Klasse_Beg
                                                : 1;
    let gradeMax: number  = this.schoolData ? this.schoolData['Art_Klasse_End']
                                            : this.dataModel.antr_Ausb_Staett_Rec
                                                ? this.dataModel.antr_Ausb_Staett_Rec.Art_Klasse_End
                                                : 13;
    
    valid = ((control.value >= gradeMin) && (control.value <= gradeMax));
    // console.log('AK 2022-11-18: ', gradeMin, '<=', control.value, '<=', gradeMax, valid);
    
    return valid ? null: {gradeValidator: {errorMsg: 'Value does not fit grade range.', min: gradeMin, max: gradeMax, value: control.value}};
    
  };
}




} // schoolViewAntragEdit_School



