
<!-- Header: Standard ----------------------------------------------------------------------------->
<appHeader></appHeader>

<!-- Main Content --------------------------------------------------------------------------------->
<div class="container-xxl akMainContent vsMainContentContainer"  style="margin-bottom: 4.5rem !important;">

  <h4 class="mb-4 vsElternLoginTitle">
    {{'antrlist.heading' | translate}}
  </h4>




  <div class="row vsElternPortalAntrList vsMt-2">
    <schoolViewAntragList #antrList></schoolViewAntragList>
  </div>
</div> <!-- Ende container-xxl -->

<!-- Footer --------------------------------------------------------------------------------------->
  <appFooter></appFooter>
<!-- Messages (Toasts) ---------------------------------------------------------------------------->

<p-toast></p-toast>


