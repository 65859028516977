//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsInputTreeMenu
//  Notes:  Input mit angefügtem Button der ein Tiered Menu aufruft
//
//
//  H I S T O R Y
//
//  2021-09-30  MP   Genesis
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';
       import {  FormBuilder
        , FormControl
        , ControlContainer, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';


// vsLib
import * as vsCommon                                from '@vsLib/vsCommon';
import * as vsInput                                 from '@vsLib/Components/vsInput/vsInput.component';
import { asLiteral }                                from '@angular/compiler/src/render3/view/util';
import { connectableObservableDescriptor }          from 'rxjs/internal/observable/ConnectableObservable';



//PrimeNG 
//import { MenuItem }                        from 'primeng/api'; //Notwendig für TieredMenu


// TvsInputTreeMenu

@Component({
  selector:     'vsInputTreeMenu',
  templateUrl:  './vsInputTreeMenu.component.html',
  styleUrls:   ['./vsInputTreeMenu.component.scss'],

  viewProviders: [
    {
        provide:      ControlContainer,
        useExisting:  FormGroupDirective
    }
  ]

})
export class TvsInputTreeMenu extends vsInput.TvsInput implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input()    TieredMenuData:          any[]               = [];

  @Input()    placeholder:             string             = '';

  //====== Constructor

  constructor(protected FrmGrpDirective: FormGroupDirective) {
    super(FrmGrpDirective);
 } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {
    // ...
  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
  } // args


  public test() {
  }

} // TvsInputText


