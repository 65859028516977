//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsDataFilter.ts
//  Notes:  Container for filter items
//
//
//  H I S T O R Y
//
//  2020-12-20  TC   Genesis
//--------------------------------------------------------------------------------------------------

import * as vsCommon        from './vsCommon';

//
// TvsOperatorType
//
export enum TvsOperatorType {
  opEqual     = ""
, opGT        = "[GT]"
, opGTE       = "[GTE]"
, opLT        = "[LT]"
, opLTE       = "[LTE]"
, opLike      = "[LIKE]"
}


//
// TvsFilterCriteria
//
export class TvsFilterCriteria {

  public  readonly    Name:             String;
  public  readonly    Value:            any;

  constructor(a_sName: String, a_vValue: any) {
    this.Name   = a_sName;
    this.Value  = a_vValue;
  } // contructor

} // TvsFilterCriteria



//
// TvsDataFilterItem
//
// Filter Item
//
export class TvsDataFilterItem {

  //====== Properties (PRIVATE)
  // ./.


  //====== Properties (PUBLIC)

  public  readonly    FilterName:             String;
  public  readonly    Criterias:              TvsFilterCriteria[] = [];


  //====== Constructor

  constructor(a_sFilterName: String) {
      this.FilterName          = a_sFilterName;
  } // contructor



  public CriteriaAdd(a_sName: String, a_vValue: any, a_sOperator: TvsOperatorType = TvsOperatorType.opEqual): void {
    let crit: TvsFilterCriteria =  new TvsFilterCriteria(a_sName + a_sOperator, a_vValue);
    this.Criterias.push(crit);
  }

} // TvsDataFilterItem



//
// TvsDataFilter
//
// Container for filter items
//
export class TvsDataFilter extends Array {


  //====== Properties (PRIVATE)
  // ./.


  //====== Properties (PROTECTED)
  // ./.


  //====== Properties (PUBLIC)
  // ./.



  //====== Methods (PUBLIC)


  //----------------------------------------------------------------------------------------------
  // Method:  ItemByName
  //
  // Args:    a_sFilterName:  Filtername
  //
  // Notes:   Retrieve item
  //----------------------------------------------------------------------------------------------

  public ItemByName(a_sFilterName: String): TvsDataFilterItem {
    let result: any = null;
    this.forEach(item => {
      if (item.FilterName == a_sFilterName) {
        result = item;
      }
    });
    return result;
  } // ItemByName


  //----------------------------------------------------------------------------------------------
  // Method:  ItemAdd
  //
  // Args:    a_sFilterName:  Filtername (wird über eine Enumeration pro Liste gefüllt )
  //          a_vValue:       Wert       (null ist möglich, muss jedoch explizit angegeben werden)
  //
  // Notes:   Add a new item
  //----------------------------------------------------------------------------------------------

  public ItemAdd(a_sFilterName: String): TvsDataFilterItem {

      let bOK:        Boolean                 = true;
      let item:       TvsDataFilterItem       = null;


      //---- Validierungen

      // Anmerkungen:
      // a_vValue     darf null sein
      // a_dfo        optionales Argument, wird initialisiert mit dfoEqual

      // a_sFieldName angegeben?
      if (bOK) {
          bOK = (a_sFilterName !== '');
          if (!bOK) {
              //... Exception auslösen ...
          }
      }


      //---- Item erzeugen

      if (bOK) {
          item    = new TvsDataFilterItem(a_sFilterName);
          this.push(item);
      }


      return item;

  } // ItemAdd


  //----------------------------------------------------------------------------------------------
  // Method:  ItemsClear
  //
  // Args:    none
  //
  // Notes:   Remove all items
  //----------------------------------------------------------------------------------------------

  public ItemsClear(): void {

    //
    // Clearing an array
    //
    // (1) Don't use the following method to clear an array:  a = []
    //
    //     This code will set the variable "a" to a new empty array.
    //     if code references this array from another variable or property, the original
    //     array will remain unchanged!
    //
    // (2) a.length = 0;
    //
    //     This code works fine in all circumstances. It's also as fast as method (1).
    //
    // Siehe: https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    //

    this.length = 0;

  } // ItemsClear


} // TvsDataFilter


