

<div *ngIf="false" class="container"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; color: #808080; ">

  <div style="display: flex; font-size: 1.5rem; font-weight: bold; color: #f07918;  margin-top: 3rem; margin-bottom: 3rem; justify-content: center">
    App Footer | Work In Progress
  </div>

</div> <!-- Ende container-xxl -->



<div class="vsFooter">
  <div class="container">

    <div class="row">
      
      <!-- <div class="col-lg-4">
        <h4 [innerHtml]="'footer.website_howto'| translate"></h4>
        <ul class="vsFooterList">
          <li><a style="cursor: pointer" (click)="actionInfoAntrag()" [innerHtml]="'footer.application_howto'| translate" tabindex="0"></a></li>
          <li><a style="cursor: pointer" (click)="actionInfoLeistungen()" [innerHtml]="'footer.service_description'| translate" tabindex="0"></a></li>
        </ul>
      </div> -->

      <div class="col-lg-6">
        <h4 [innerHtml]="'footer.questions_answers'| translate"></h4>
        <ul class="vsFooterList">
          <li><a style="cursor: pointer" [innerHtml]="'footer.FAQ'| translate" href="{{dataModel.footerLinkFrequentQuestions}}" target="_blank" tabindex="0"></a></li>
          <li><a style="cursor: pointer" [innerHtml]="'footer.contact_us'| translate" href="{{dataModel.footerLinkKontakt}}" target="_blank" tabindex="0"></a></li>
        </ul>
      </div><!--col-lg-4 Ende-->

      <div class="col-lg-6">
        <ul class="vsFooterMenu">

          <ng-template [ngIf]="KennImprint" [ngIfElse]="elseBlockImprint">
            <li><a style="cursor: pointer" [innerHtml]="'footer.imprint'| translate" target="_blank" (click)="navImprint()" tabindex="0"></a></li>
          </ng-template>
          <ng-template #elseBlockImprint>
            <li><a style="cursor: pointer" [innerHtml]="'footer.imprint'| translate" href="{{dataModel.footerLinkImprint}}" target="_blank" tabindex="0"></a></li>
          </ng-template>

          <ng-template [ngIf]="KennDataProtection" [ngIfElse]="elseBlockDataProtection">
            <li><a style="cursor: pointer" [innerHtml]="'footer.data_privacy'| translate" (click)="navDataProtection()" tabindex="0"></a></li>
          </ng-template>
          <ng-template #elseBlockDataProtection>
            <li><a style="cursor: pointer" [innerHtml]="'footer.data_privacy'| translate" href="{{dataModel.footerLinkDataSecurity}}" target="_blank" tabindex="0"></a></li>
          </ng-template>

          
          
        </ul>
      </div><!--col-lg-4 Ende-->

    </div><!--row Ende-->


    <div class="row" (click)="navVedisys()">
      <div class="col-lg-12">
        <div class="vsCopyright" style="cursor: pointer">© Copyright - vedisys AG</div>
      </div><!--col-lg-12 Ende-->  
    </div><!--row Ende-->


    <div class="vsImageCreatorContainer">
      <div class="vsImageCreator"><a href="{{dataModel.footerLinkImageCreator}}" target="_blank">{{dataModel.footerLinkImageCreatorText}}</a></div>
    </div>

  </div><!--container Ende-->
</div><!--vsFooter Ende--> 



<div *ngIf="false" class="container"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; color: #808080; ">

  <div style="display: flex; font-size: 1.5rem; font-weight: bold; color: #f07918;  margin-top: 3rem; margin-bottom: 3rem; justify-content: center">
    App Footer | END
  </div>

</div> <!-- Ende container-xxl -->


