
import {  NgModule, OnInit
        , Injector
        , CUSTOM_ELEMENTS_SCHEMA
        , APP_INITIALIZER }                   from '@angular/core';
import { AppConfigService }                   from 'AMIS7school/src/$vsLib_Candidates/Services/vsAppConfigService';
import { BrowserModule }                      from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { BrowserAnimationsModule }            from '@angular/platform-browser/animations';


// PrimeNG
//import { AutoComplete }                       from 'primeng/autocomplete'; // $Rev MP 2021-06-16: Scheint falsch eingetragen worden zu sein, siehe Zeile 23
import { BadgeModule }                        from 'primeng/badge';
import { ButtonModule }                       from 'primeng/button';
import { CalendarModule }                     from 'primeng/calendar';
import { DialogModule }                       from 'primeng/dialog';
import { DynamicDialogModule }                from 'primeng/dynamicdialog';
import { DropdownModule }                     from 'primeng/dropdown';
import { GMapModule }                         from 'primeng/gmap';
import { MegaMenuModule }                     from 'primeng/megamenu';
import { MenuModule }                         from 'primeng/menu';
import { ContextMenuModule }                  from 'primeng/contextmenu';
import { ProgressSpinnerModule }              from 'primeng/progressspinner';
import { RadioButtonModule }                  from 'primeng/radiobutton';
import { StepsModule }                        from 'primeng/steps';
import { TableModule }                        from 'primeng/table';
import { ToastModule }                        from 'primeng/toast';
// PrimeNG [TEST]
import { AutoCompleteModule }                 from 'primeng/autocomplete';
import { FileUploadModule }                   from 'primeng/fileupload';
import { TooltipModule }                      from 'primeng/tooltip';             // $Rev MP 2021-06-16: Tooltip Module hinzugefügt
import { CheckboxModule }                     from 'primeng/checkbox';            // $Rev MP 2021-06-24: Checkbox Module hinzugefügt
import { PasswordModule }                     from 'primeng/password';            // $Rev MP 2021-06-24: PasswordModule hinzugefügt
import { ProgressBarModule }                  from 'primeng/progressbar';         // $Rev MP 2021-06-25: ProgressBarModule hinzugefügt
import { InputNumberModule }                  from 'primeng/inputnumber';
import { InputTextModule }                    from 'primeng/inputtext';
import { InputTextareaModule }                from 'primeng/inputtextarea';
import {InputSwitchModule}                    from 'primeng/inputswitch';         //$Rev MP 2022-06-14: InputSwitchModule hinzugefügt
import { SliderModule }                       from 'primeng/slider';              // $Rev MP 2021-08-19: SliderModule hinzugefügt, wird für den Image Cropper benötigt

// [ngx-translate] Translation (multilingualism)
// import { Http }                               from '@angular/http';
import { HttpClient }                         from '@angular/common/http';
import { HttpClientModule }                   from '@angular/common/http';
import { TranslateModule, TranslateLoader }   from '@ngx-translate/core';
import { TranslateHttpLoader }                from '@ngx-translate/http-loader';


// vsLib
import * as vsGlobalResources                 from '@vsLib/Services/vsGlobalResources.service';
import * as vsCommon                          from '@vsLib/vsCommon';
import * as vsProgFunc                        from '@vsLib/vsProgFunc';
import { TvsDropdownSelectComponent }         from '@vsLib/Components/vsDropdownSelect/vsDropdownSelect.component';
import { TvsTableComponent }                  from '@vsLib/Components/vsTable/vsTable.component';
import { TvsView }                            from '@vsLib/View/components/vsView/vsView.component';
import { TvsViewData }                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import { TvsViewDataList }                    from '@vsLib/View/components/vsViewDataList/vsViewDataList.component';
import { TvsViewDataEdit }                    from '@vsLib/View/components/vsViewDataEdit/vsViewDataEdit.component';
import { TvsTabControl }                      from '@vsLib/Components/vsTab/vsTabControl.component';
import { TvsLayoutGroup }                     from '@vsLib/Components/vsLayoutGroup/vsLayoutGroup.component';
import { TvsInput }                           from '@vsLib/Components/vsInput/vsInput.component';
import { TvsInputText }                       from '@vsLib/Components/vsInput/vsInputText.component';
import { TvsInputNumber }                     from '@vsLib/Components/vsInput/vsInputNumber.component';
import { TvsInputDateTime }                   from '@vsLib/Components/vsInput/vsInputDateTime.component';
import { TvsInputDropDown }                   from '@vsLib/Components/vsInput/vsInputDropDown.component';
import { TvsInputCheckBox }                   from '@vsLib/Components/vsInput/vsInputCheckBox.component';
import { TvsInputRadioButton }                from '@vsLib/Components/vsInput/vsInputRadioButton.component';
import { TvsInputMemo }                       from '@vsLib/Components/vsInput/vsInputMemo.component';
import { TvsInputSearch }                     from '@vsLib/Components/vsInput/vsInputSearch.component';
import { TvsInputPwd }                        from '@vsLib/Components/vsInput/vsInputPwd.component';
import { TvsInputAutoComplete }               from '@vsLib/Components/vsInput/vsInputAutoComplete.component';
import { TvsInputTreeMenu }                   from '@vsLib/Components/vsInput/vsInputTreeMenu.component';
import { TvsSearchDlg }                       from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';
import { TvsInputButtonEdit }                 from '@vsLib/Components/vsInput/vsInputButtonEdit.component';
import { TvsInputChips }                      from '@vsLib/Components/vsInput/vsInputChips.component';

// AMIS7core
import * as AMIS7coreConst                    from '@AMIS7core/$Common/comConst';


// App Components
import { AppComponent }                       from 'AMIS7school_Portal_Eltern/src/app/app.component';
import { AppRoutingModule }                   from './app-routing.module';
import { TappHeader }                         from 'AMIS7school_Portal_Eltern/src/app/appHeader.component';
import { TappFooter }                         from 'AMIS7school_Portal_Eltern/src/app/appFooter.component';


// App Components: Main
import { MainView }                           from 'AMIS7school_Portal_Eltern/src/app/$main/mainView.component';
import { TmainViewLanding }                   from 'AMIS7school_Portal_Eltern/src/app/$main/mainViewLanding.component';

// App Components: CRM
import { TdlgDoc }                            from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';

// App Components: System
import { TsysViewUserLogin }                  from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserLogin.component';
import { TsysViewUserReg }                    from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserReg.component';
import { TsysViewUserRegLinkAccount }         from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserRegLinkAccount.component';
import { TsysViewUserRegSelection }           from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserRegSelection.component';
import { TsysViewUserReg_Confirmation }       from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewUserReg_Confirmation.component';

// App Components: School
import { TschoolViewAntragList }                     from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragList.component';
import { TschoolViewAntragEdit }                     from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit.component';
import { TschoolViewAntragEdit_Student }             from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Student.component';
import { TschoolViewAntragEdit_School }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_School.component';
import { TschoolViewAntragEdit_SEPA }                from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_SEPA.component';
import { TschoolViewAntragEdit_Doc }                 from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Doc.component';
import { TschoolViewAntragEdit_Commit }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Commit.component';
import { TschoolViewAntragEdit_Antrag }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag.component';
import { TschoolViewAntragEdit_Antrag_Uebernahme }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Uebernahme.component';
import { TschoolViewAntragEdit_Antrag_Erstattung }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Erstattung.component';
import { TschoolViewAntragEdit_Antrag_Individual }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Individual.component';
import { TschoolViewAntragEdit_Antrag_Grundantrag }  from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Antrag_Grundantrag.component';
import { TschoolViewNachrichtenList }                from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewNachrichtenList.component';
import { TschoolViewAntragEdit_StudentAddress }      from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_StudentAddress.component';
import { TschoolViewAntragEdit_StudentName }         from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_StudentName.component';
import { TschoolViewAntragEdit_StudentSelection }    from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_StudentSelection.component';
import { TschoolViewAntragEdit_VertretungAddress }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_VertretungAddress.component';
import { TschoolViewAntragEdit_Bankverb }            from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Bankverb.component';
import { TschoolViewAntragEdit_Schule }              from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Schule.component';
//REV MP 2023-08-15: Auswahl für Ausbildungstätte und Berufsschule hinzugefügt
import { TschoolViewAntragEdit_Schule_Ausb }         from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Schule_Ausb.component';
import { TschoolViewAntragEdit_School_Attendance }   from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_School_Attendance.component';

import { TschoolViewAntragLanding }                  from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragLanding.component';
import { TschoolViewAntragEdit_Besondere }           from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Besondere.component';
import { TschoolViewAntragEdit_Confirmation }        from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_Confirmation.component';
import { TschoolViewAntragEdit_ErstattungList }      from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_ErstattungList.component';
import { TschoolViewNachrichtEdit }                  from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewNachrichtEdit.component';
import { TschoolViewMeineNachrichtenList }           from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewMeineNachrichtenList.component';



import { TschoolViewAntrag }                         from './school/schoolViewAntrag.component';

import { TieredMenuModule }                            from 'primeng/tieredmenu';
import { TschoolViewAntragEdit_PickDate }            from 'AMIS7school_Portal_Eltern/src/app/school/schoolViewAntragEdit_PickDate.component';
import { TsysViewAccPwdChangeDlg }                   from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewAccPwdChangeDlg.component';

import { TsysViewProfileData }                       from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewProfileData.component';

import { TsysViewDataProtection}                     from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewDataProtection.component';
import { TsysViewImprint}                            from 'AMIS7school_Portal_Eltern/src/app/sys/sysViewImprint.component';

// [LABOR]
import { MessageService }                     from 'primeng/api';
import { EditorModule }                       from 'primeng/editor'; //$Rev MP 2021-07-21: EditorModule hinzugefügt

// Alyle Test - Image Cropper

import { CommonModule }                       from '@angular/common';
import { LyImageCropperModule }               from '@alyle/ui/image-cropper';

/** Import Alyle UI für Image Cropper */

 import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,

 } from '@alyle/ui';

 export function appInit(appConfigService: AppConfigService) {
  return () => appConfigService.load(true);
}

/** Import themes Alyle Image Cropper */
 import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

@NgModule({
  declarations: [
      AppComponent
    , TappHeader
    , TappFooter

    //---- vsLib
    , TvsView
    , TvsViewData
    , TvsViewDataList
    , TvsViewDataEdit
    , TvsTabControl
    , TvsTableComponent
    , TvsDropdownSelectComponent
    , TvsLayoutGroup
    , TvsInput
    , TvsInputText
    , TvsInputNumber
    , TvsInputDateTime
    , TvsInputDropDown
    , TvsInputCheckBox
    , TvsInputRadioButton
    , TvsInputMemo
    , TvsInputSearch
    , TvsInputPwd
    , TvsInputAutoComplete
    , TvsInputTreeMenu
    , TvsSearchDlg
    , TvsInputButtonEdit
    , TvsInputChips

    //---- app: Main
    , MainView
    , TmainViewLanding


    //---- app: System
    , TsysViewUserLogin
    , TsysViewUserReg
    , TsysViewUserRegLinkAccount
    , TsysViewUserRegSelection
    , TsysViewUserReg_Confirmation

    //---- app: school
    , TschoolViewAntragList
    , TschoolViewAntragEdit
    , TschoolViewAntragEdit_Student
    , TschoolViewAntragEdit_School
    , TschoolViewAntragEdit_SEPA
    , TschoolViewAntragEdit_Doc
    , TschoolViewAntragEdit_Commit
    , TdlgDoc
    , TschoolViewAntragEdit_Antrag
    , TschoolViewAntragEdit_Antrag_Uebernahme
    , TschoolViewAntragEdit_Antrag_Erstattung
    , TschoolViewAntragEdit_Antrag_Individual
    , TschoolViewAntragEdit_Antrag_Grundantrag
    , TschoolViewAntragLanding
    , TschoolViewAntragEdit_Besondere
    , TschoolViewNachrichtenList
    , TschoolViewAntragEdit_Confirmation
    , TschoolViewAntragEdit_PickDate
    , TschoolViewAntragEdit_ErstattungList
    , TschoolViewAntragEdit_StudentAddress
    , TschoolViewAntragEdit_StudentName
    , TschoolViewAntragEdit_StudentSelection
    , TschoolViewAntragEdit_VertretungAddress
    , TschoolViewAntragEdit_Bankverb
    , TschoolViewAntragEdit_Schule
    , TschoolViewAntragEdit_Schule_Ausb
    , TschoolViewAntragEdit_School_Attendance
    , TschoolViewNachrichtEdit
    , TschoolViewMeineNachrichtenList
    , TschoolViewAntrag
    , TsysViewAccPwdChangeDlg
    , TsysViewProfileData
    , TsysViewDataProtection
    , TsysViewImprint
  ],

  imports: [
      AppRoutingModule
    , BrowserAnimationsModule
    , BrowserModule
    , FormsModule
    , HttpClientModule
    , ReactiveFormsModule

    // Angular Components für Image Cropper Test
    , CommonModule


    // [ngx-translate]
    , TranslateModule.forRoot({
          loader: {
            provide:      TranslateLoader,
            useFactory:   (createTranslateLoader),
            deps:         [HttpClient, vsGlobalResources.TvsGlobalResources]
          }
    }) // TranslateModule.forRoot

    // PrimeNG
    , AutoCompleteModule
    , BadgeModule
    , ButtonModule
    , CalendarModule
    , CheckboxModule
    , DialogModule
    , DynamicDialogModule
    , DropdownModule
    , FileUploadModule
    , GMapModule
    , MegaMenuModule
    , MenuModule
    , ContextMenuModule
    , PasswordModule
    , ProgressBarModule
    , ProgressSpinnerModule
    , StepsModule
    , TableModule
    , ToastModule
    , TooltipModule
    , RadioButtonModule
    , InputNumberModule
    , InputTextModule
    , InputTextareaModule
    , EditorModule
    , SliderModule
    , TieredMenuModule
    , InputSwitchModule

     // Alyle Image Cropper Test
    , LyImageCropperModule

  ],

  providers: [
    [AppConfigService,
      {
        provide: APP_INITIALIZER,
        useFactory: appInit,
        multi: true,
        deps: [AppConfigService]
      }],
    MessageService,

    // $Rev MP: Alyle Image Cropper Test ---------- BEG ----------
    // Themes können scheinbar nicht ausgeschaltet werden, die Seite kompiliert zwar aber der Workspace mit dem Image Cropper lädt nicht.
    [ LyTheme2 ],
    [ StyleRenderer ],
    // // Theme that will be applied to this module Alyle Image Cropper
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // $Rev MP: Alyle Image Cropper Test ---------- BEG ----------
  ],

  bootstrap: [AppComponent]

  // , schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements OnInit {

  constructor(  private globalResources:    vsGlobalResources.TvsGlobalResources
              , private appInjector:        Injector
             ) {

    this.globalResources.AppInjector  = appInjector;



  } // constructor



  //====== NG event handler

  ngOnInit(): void {

    console.log('AppConfigData', this.globalResources.AppConfigData);

  } // ngOnInit


} // AppModule



// [ngx-translate] Factory method for TranslateLoader
export function createTranslateLoader(http: HttpClient, x: vsGlobalResources.TvsGlobalResources) {

  //Pfad geladen aus app.config.json
  return new TranslateHttpLoader(http, x.AppConfigData.requestAssetPath, '.json');

  //Standardpfad
  // return new TranslateHttpLoader(http, '/assets/translation/', '.json');

} // createTranslateLoader


