//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsLayoutGroup
//  Notes:  Dient zum Aufbau einer aus DIVs bestehenden Kachelstruktur für das Layout von Edit Views
//
//
//  H I S T O R Y
//
//  2021-06-29  TC   Genesis
//--------------------------------------------------------------------------------------------------

import { asLiteral } from '@angular/compiler/src/render3/view/util';
import {  Component
        , OnInit, AfterViewInit
        , Input, Output
        , EventEmitter
        , ViewChild, TemplateRef
       }                                    from '@angular/core';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';



export enum TvsLayoutGroupOrientation {
    orVertical          = 'vertical'
  , orHorizontal        = 'horizontal'
} // TvsLayoutGroupOrientation


export class TMatrixElement {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}


//
// TvsLayoutGroup
//
// Layout component for edit view layouts - contains other layout groups or input controls
//
@Component({
  selector:     'vsLayoutGroup',
  templateUrl:  './vsLayoutGroup.component.html',
  styleUrls:   ['./vsLayoutGroup.component.scss']
})
export class TvsLayoutGroup implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  colMatrix = [ { "colCount": 1,   "xs": 1,   "sm": 1,   "md": 1,   "lg": 1,  "xl": 1,  "xxl": 1 },
                { "colCount": 2,   "xs": 2,   "sm": 2,   "md": 2,   "lg": 2,  "xl": 2,  "xxl": 2 },
                { "colCount": 3,   "xs": 3,   "sm": 3,   "md": 3,   "lg": 3,  "xl": 3,  "xxl": 3 },
                { "colCount": 4,   "xs": 4,   "sm": 4,   "md": 4,   "lg": 4,  "xl": 4,  "xxl": 4 },
                { "colCount": 5,   "xs": 5,   "sm": 5,   "md": 5,   "lg": 5,  "xl": 5,  "xxl": 5 },
                { "colCount": 6,   "xs": 6,   "sm": 6,   "md": 6,   "lg": 6,  "xl": 6,  "xxl": 6 },
                { "colCount": 7,   "xs": 7,   "sm": 7,   "md": 7,   "lg": 7,  "xl": 7,  "xxl": 7 },
                { "colCount": 8,   "xs": 8,   "sm": 8,   "md": 8,   "lg": 8,  "xl": 8,  "xxl": 8 },
                { "colCount": 9,   "xs": 9,   "sm": 9,   "md": 9,   "lg": 9,  "xl": 9,  "xxl": 9 },
                { "colCount": 10,  "xs": 10,  "sm": 10,  "md": 10,  "lg": 10, "xl": 10, "xxl": 10 },
                { "colCount": 11,  "xs": 11,  "sm": 11,  "md": 11,  "lg": 11, "xl": 11, "xxl": 11 },
                { "colCount": 12,  "xs": 12,  "sm": 12,  "md": 12,  "lg": 12, "xl": 12, "xxl": 12 }
              ];


  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  @Input()    Orientation:          TvsLayoutGroupOrientation     = TvsLayoutGroupOrientation.orVertical;
  @Input()    Caption:              string                        = null;
  @Input()    BGColor:              string                        = null;

  @Input()    gridColCount:         number                        = null;
  @Input()    gridColCount_xs:      number                        = null;
  @Input()    gridColCount_sm:      number                        = null;
  @Input()    gridColCount_md:      number                        = null;
  @Input()    gridColCount_lg:      number                        = null;
  @Input()    gridColCount_xl:      number                        = null;
  @Input()    gridColCount_xxl:     number                        = null;

  @Input()    isPanel:              boolean                       = false;
  @Input()    panelTitleVisible:    boolean                       = false;
  @Input()    panelTitleText:       string                        = null;
  @Input()    panelFooterVisible:   boolean                       = false;
  @Input()    panelFooterText:      string                        = null;

  @Input()    marginTop:            string                        = null;

  @Input()    HasPaddingBottom:     boolean                       = false;

  @Input()    MediumGutterWidth:    boolean                       = false;


  



  public get  CSSClassContent(): string {

    // let sOrientation:   string          =  (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) ? 'vsOrientationHorizontal' : 'vsOrientationVertical';
    let sOrientation:   string          =  '';
    let sCol:           string          = null;
    let elem:           TMatrixElement  = new TMatrixElement();

    if (vsCommon.vsVarAssigend(this.gridColCount)) {
      elem = this.colMatrix[this.gridColCount - 1];
    }
    elem.xs   = vsCommon.vsVarAssigend(this.gridColCount_xs)  ? this.gridColCount_xs : elem.xs;
    elem.sm   = vsCommon.vsVarAssigend(this.gridColCount_sm)  ? this.gridColCount_sm : elem.sm;
    elem.md   = vsCommon.vsVarAssigend(this.gridColCount_md)  ? this.gridColCount_md : elem.md;
    elem.lg   = vsCommon.vsVarAssigend(this.gridColCount_lg)  ? this.gridColCount_lg : elem.lg;
    elem.xl   = vsCommon.vsVarAssigend(this.gridColCount_xl)  ? this.gridColCount_xl : elem.xl;
    elem.xxl  = vsCommon.vsVarAssigend(this.gridColCount_xxl) ? this.gridColCount_xxl : elem.xxl;

    sCol      =       (vsCommon.vsVarAssigend(elem.xs) ?  ' col-xs-'  + elem.xs : '')
                    + (vsCommon.vsVarAssigend(elem.sm) ?  ' col-sm-'  + elem.sm : '')
                    + (vsCommon.vsVarAssigend(elem.md) ?  ' col-md-'  + elem.md : '')
                    + (vsCommon.vsVarAssigend(elem.lg) ?  ' col-lg-'  + elem.lg : '')
                    + (vsCommon.vsVarAssigend(elem.xl) ?  ' col-xl-'  + elem.xl : '')
                    + (vsCommon.vsVarAssigend(elem.xxl) ? ' col-xxl-' + elem.xxl : '')

    if (!vsCommon.vsStringHasValue(sCol)) {
       sCol = 'col'
    }

    return sOrientation + ' ' + sCol;

  } // get CSSClassContent


  public get  CSSClassBody(): string {
    let sResult:        string        = '';

    // sResult = (this.isPanel ? 'vsCardBody' : '');

    // if (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) {
    //   sResult = sResult + ' ' + (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) ? 'row' : '';
    // }
    
    //$Rev MP 2021-11-24 Case für this.HasPaddingBottom hinzugefügt um unnötiges Padding bei vsLayoutgroups mit vsInputs zu vermeiden, da vsInputs ein margin-bottom haben, welches zusammen mit dem padding dann doppelt ist.
    

    if (this.isPanel) {
      if (this.HasPaddingBottom) {
        sResult = 'vsCardBody';
      }

      else {
        sResult = 'vsCardBody vsFormContainer';
      }
    }

    else {
      sResult = '';
    }

    return sResult;

    // return  (this.isPanel ? 'vsCardBody' : '');  // $Rev TC 2021-07-07: vsPanel gibt es noch nicht, deshalb vorerst vsCardBody

  } // get CSSClassPanel


  public get  CSSClassPanel(): string {
    let sResult:        string        = '';

    sResult = (this.isPanel ? 'vsCard' : '');

    // if (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) {
    //   sResult = sResult + ' ' + (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) ? 'vsOrientationHorizontal' : 'vsOrientationVertical';
    // }

    return sResult;

    // return  (this.isPanel ? 'vsCard' : '');      // $Rev TC 2021-07-07: vsPanel gibt es noch nicht, deshalb vorerst vsCard

  } // get CSSClassPanel


  public get  CSSClassHorizontal() {
    
    if (this.MediumGutterWidth) {
      return (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) ? 'row vsGutterWidth-2' : '';
    }
    
    else {
      return (this.Orientation == TvsLayoutGroupOrientation.orHorizontal.toString()) ? 'row' : '';
    }
  } // CSSClassHorizontal



  //====== ViewChilds

  // ./.



  //====== Constructor

  constructor() {
  } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler


  ngOnInit(): void {

  } // ngOnInit



  //====== Methods: Common

  // ./.



  //====== Methods: Event Handler


  public doOnXYZ(args: any): void {
  } // args



} // TvsLayoutGroup


