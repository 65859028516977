<vsView>
  [ViewProperties]            = "ViewProperties"
</vsView>


<div class="vsWrapper">


<!-- Header: Standard ----------------------------------------------------------------------------->


<appHeader></appHeader>


<!-- Main ----------------------------------------------------------------------------------------->


<div class="vsMainContent" style="min-height:624px;"><!--vsMainContent Start-->
  <div class="container-xxl"><!--container-xxl Start-->

    <div class="row">

      <div class="col-lg-3">
      </div>  <!--col-lg-3 Ende-->

       <!-- Confirmation Message ----------->

      <div class="col-lg-6">

        <img src="\assets\img\Images\Grafik_Message_Sent_320px.png">

        <h4 [innerHtml]="'register.text_registration_processing'| translate"></h4>

        <p [innerHtml]="'register.text_registration_confirmation'| translate"></p>
        <p [innerHtml]="'register.text_registration_activation'| translate"></p>

        <button pButton label="{{'application.button_forward'| translate}}"  (click)="actionForward()"  class="vsElternButton" style="margin-right: 10px;"></button>
    
      </div>  <!--col-lg-6 Ende-->

      <div class="col-lg-3">
      </div>  <!--col-lg-3 Ende-->

    </div>  <!--Ende row-->


  </div>  <!--container-xxl Ende-->
</div>  <!--vsTopContentEnde-->








<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



</div>  <!--vsWrapper Ende-->



<!-- Messages (Toasts) ---------------------------------------------------------------------------->

<p-toast></p-toast>


