//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsParamList.ts
//  Notes:  Container Param items
//
//
//  H I S T O R Y
//
//  2021-09-14  MH   Genesis
//--------------------------------------------------------------------------------------------------

import { ThrowStmt } from '@angular/compiler';
import * as vsCommon        from './vsCommon';


export class TvsParamList<T> {
  private items: { [key: string]: T };

  constructor() {
      this.items = {};
  }

  add(key: string, value: T): void {
      this.items[key] = value;
  }

  has(key: string): boolean {
      return key in this.items;
  }

  get(key: string): T {
      if (this.has(key)) {
        return this.items[key];
      } else {
        return null;
      }
  }
  clear() {
    this.items = {};
  }
}
