//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   System
//  Title:    User Account: Registration
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-08-12  TC   Initial Version
//--------------------------------------------------------------------------------------------------


import {  Component, OnInit, AfterViewInit, ComponentFactoryResolver
        , ViewChild, ElementRef
       }                                    from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators,
  ValidatorFn, ValidationErrors, AbstractControl }    from '@angular/forms';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MessageService }                   from 'primeng/api';
import { ProgressSpinnerModule }            from 'primeng/progressspinner';



// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                          from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent } from '@vsLib/Services/vsHttp.service.component';


// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}



@Component({
  selector:       'sysViewUserRegLinkAccount',
  templateUrl:  './sysViewUserRegLinkAccount.component.html',
  // styleUrls:   ['./TsysViewUserRegLinkAccount.component.scss'],
})
export class TsysViewUserRegLinkAccount extends vsViewData.TvsViewData implements OnInit, AfterViewInit {



  //====== Properties (PRIVATE)

  FRegistrationInProgess:               boolean             = false;



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public      editForm:                               FormGroup               = new FormGroup({});
  public      Anrede:                                 any[];
  // public  get registrationInProgess():              boolean             {return this.FRegistrationInProgess};
  public      GUILanguageList:                        IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:                    IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  public      displayDialogError:                     boolean                 = false;

  // public      displayDialogRegInProgress:            boolean                 = false;

  public      displayDialogSameEmail:                 boolean                 = false;

  public      displayDialogNoContact:                 boolean                 = false;

  public      displayDialogNoRegistrationPossible:    boolean                 = false;

  public      displayDialogEmailDifferent:            boolean                 = false;

  public      displayDialogPasswordDifferent:         boolean                 = false;

  public      displaySpinner:                         boolean                 = false;

  public      kontaktID:                              string;

  public      KennKontaktID:                          boolean                 = false;

  public      KennStrassenVerz:                       boolean                 = false;
  public      KennAdrNotFoundReg:                     boolean                 = false;

  public      lookupAdresse:                          any[];

  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected componentFactoryResolver: ComponentFactoryResolver
            , public    translate:                  TranslateService      // [ngx-translate]
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel

) {
  super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'User Account: Registration';

    this.ViewProperties.ProgFuncID    = null;
    this.ViewProperties.DataSourceID  = 'webDoStammUserAccountNoPWD';
    this.ViewProperties.QueryFields   = [];

    this.Anrede = [
      {Anrede: 'Herr',      Anrede_ID: 'HERR'},
      {Anrede: 'Frau',      Anrede_ID: 'FRAU'},
      // {Anrede: 'Divers',    Anrede_ID: 'DIVERS'},
      {Anrede: '<Unbek>',   Anrede_ID: 'UNBEK'}
    ];

    if(vsCommon.vsVarAssigend(this.dataModel.KennStrassenVerz)) {
      this.KennStrassenVerz = this.dataModel.KennStrassenVerz;
    }

    this.HttpServiceComponent.dataGet('comDoStammStrVerzKomplett')
    .subscribe(data => {
    this.lookupAdresse =  data['Dataset'].Data;

    });

  } // constructor



  //====== NG event handler


  ngOnInit(): void {

    this.editForm.addControl('edtUserEMail',              new FormControl(null));
    this.editForm.addControl('edtUserEMail_Wdh',          new FormControl(null));

    this.editForm.addControl('User_User_Pwd',             new FormControl(null));
    this.editForm.addControl('User_User_Pwd_Wdh',         new FormControl(null));
    this.editForm.addControl('Kontakt_ID_Anrede',         new FormControl(null));
    this.editForm.addControl('Kontakt_Name1',             new FormControl(null));
    this.editForm.addControl('Kontakt_Name2',             new FormControl(null));
    this.editForm.addControl('Kontakt_Geb_Datum',         new FormControl(null));

    //$REV MP 2024-05-22: Adressdaten hinzugefügt
    this.editForm.addControl('Adr_Strasse',                  new FormControl(null));
    this.editForm.addControl('Adr_HausNr',                   new FormControl(null));
    this.editForm.addControl('Adr_PLZ',                      new FormControl(null));
    this.editForm.addControl('Adr_Ort',                      new FormControl(null));
    this.editForm.addControl('Adr_Ortsteil',                 new FormControl(null));
    this.editForm.addControl('Adr_Zusatz',                   new FormControl(null));
    
    if(this.KennStrassenVerz) {
      this.editForm.addControl('AntrStell_StrassenVerz',       new FormControl(null));
      this.editForm.addControl('KennAdrNotFoundReg',       new FormControl(this.KennAdrNotFoundReg));
    }

    this.editForm.addControl('checkbox1',                 new FormControl(null));
    // this.editForm.addControl('checkbox2',                 new FormControl(null));

    this.editForm.addControl('Azubi_Vorname',             new FormControl(null));
    this.editForm.addControl('Azubi_Nachname',            new FormControl(null));

//    this.editForm.addControl('Antr_Nr',                   new FormControl(null));
    this.editForm.addControl('Azubi_Nr',                  new FormControl(null));

    this.editForm.addControl('Azubi_Geb_Datum',           new FormControl(null));

    // Azubi_Vorname


    this.HttpServiceComponent.dataGet(this.ViewProperties.DataSourceID
      , false
      , this.ViewProperties.QueryFields
      , null
      , TvsGetType.gtNewRecord)
      .subscribe(
        data => {
           this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);
        });
  } // ngOnInit

  ngAfterViewInit(): void {
    
    if(this.KennStrassenVerz) {
      this.editForm.controls['Adr_Ort'].disable();
      this.editForm.controls['Adr_PLZ'].disable();
      this.editForm.controls['Adr_Ortsteil'].disable();
      this.editForm.controls['Adr_Strasse'].disable();
    }

  } // ngAfterViewInit

  //$REV MP 2022-11-10: Navigation zurück zur Login-Seite
  public actionStepPrev(): void {
    // this.router.navigate(['login']);
    this.router.navigate(['regSelection']);
  }

  actionSearchAccount() {

    this.HttpServiceComponent.HttpServiceGetRequest('webActionUserAccConGesVertr',
    '&GesVertrVorname='+this.editForm.controls['Kontakt_Name2'].value
    +'&GesVertrNachname='+this.editForm.controls['Kontakt_Name1'].value
    +'&AzubiVorname='+this.editForm.controls['Azubi_Vorname'].value
    +'&AzubiNachname='+this.editForm.controls['Azubi_Nachname'].value
    +'&AzubiGebDatum='+vsCommon.DateStrToISO8061(this.editForm.controls['Azubi_Geb_Datum'].value)
    //+'&AntrNr='+this.editForm.controls['Antr_Nr'].value)
    +'&AzubiNr='+this.editForm.controls['Azubi_Nr'].value)
    .subscribe((response: any) => {


      if(response.result === false) {
        this.displayDialogNoContact = true;
        //Button für die Registrierung deaktivieren.
        this.KennKontaktID = false;
      }

      if(response.result === true) {
        //Sichtbar in der GUI Hinweis einstellen
        //Button für die Registrierung aktivieren
        this.kontaktID = response.kontaktID;
        this.KennKontaktID = true;
      }

    });//Ende HttpService

    }

    doActivateManualInput() {

      //Freischalten der Input-Felder für die manuelle Eingabe, Wechsel von html-hidden zu visible
      if(this.editForm.controls['KennAdrNotFoundReg'].value) {
        this.KennAdrNotFoundReg = true;
  
        this.editForm.controls['Adr_Ort'].enable();
        this.editForm.controls['Adr_PLZ'].enable();
        this.editForm.controls['Adr_Ortsteil'].enable();
        this.editForm.controls['Adr_Strasse'].enable();
      }
  
      if(!this.editForm.controls['KennAdrNotFoundReg'].value) {
        this.KennAdrNotFoundReg = false;
  
        this.editForm.controls['Adr_Ort'].disable();
        this.editForm.controls['Adr_PLZ'].disable();
        this.editForm.controls['Adr_Ortsteil'].disable();
        this.editForm.controls['Adr_Strasse'].disable();
      }
  
    }

    doSelectAdresse(data) {

      // this.dataModel.antrStell_StrVerz = data;
  
      if (vsCommon.vsStringHasValue(data.OrtPLZ_PLZ)) {
        this.editForm.controls['Adr_PLZ'].setValue(data.OrtPLZ_PLZ);
      }
      if (vsCommon.vsStringHasValue(data.Ortsteil_Name)) {
        this.editForm.controls['Adr_Ortsteil'].setValue(data.Ortsteil_Name);
      }
      if (vsCommon.vsStringHasValue(data.Ort_Name)) {
        this.editForm.controls['Adr_Ort'].setValue(data.Ort_Name);
      }
      if (vsCommon.vsStringHasValue(data.Str_Name)) {
        this.editForm.controls['Adr_Strasse'].setValue(data.Str_Name);
      }
  
    }
  
    // Custom validator function for checking if two fields are equal
    public matchFieldsValidator(field1: string, field2: string): ValidatorFn {

      return () => {
        const control1 = this.editForm.controls[field1].value;
        const control2 = this.editForm.controls[field2].value;

        if (!control1 || !control2) {
          return null;
        }

        const areEqual = control1 === control2;
        return areEqual ? null : {fieldsNotMatch: false};
      };
    }

  public register() {
    let bOK: boolean;

    // Add required validator to email controls
    this.editForm.get('edtUserEMail').setValidators(Validators.required);
    this.editForm.get('edtUserEMail').updateValueAndValidity();

    // Add custom validator to check if email fields match
    this.editForm.get('edtUserEMail_Wdh').setValidators([Validators.required, this.matchFieldsValidator('edtUserEMail', 'edtUserEMail_Wdh')]);
    this.editForm.get('edtUserEMail_Wdh').updateValueAndValidity();

    // Add required validator to password controls
    this.editForm.get('User_User_Pwd').setValidators(Validators.required);
    this.editForm.get('User_User_Pwd').updateValueAndValidity();

    // Add custom validator to check if password fields match
    this.editForm.get('User_User_Pwd_Wdh').setValidators([Validators.required, this.matchFieldsValidator('User_User_Pwd', 'User_User_Pwd_Wdh')]);
    this.editForm.get('User_User_Pwd_Wdh').updateValueAndValidity();

    this.editForm.get('Kontakt_Name1').setValidators(Validators.required);
    this.editForm.get('Kontakt_Name1').updateValueAndValidity();
    this.editForm.get('Kontakt_Name2').setValidators(Validators.required);
    this.editForm.get('Kontakt_Name2').updateValueAndValidity();

    //Validierung für die Schülerdaten
    this.editForm.get('Azubi_Vorname').setValidators(Validators.required);
    this.editForm.get('Azubi_Vorname').updateValueAndValidity();
    this.editForm.get('Azubi_Nachname').setValidators(Validators.required);
    this.editForm.get('Azubi_Nachname').updateValueAndValidity();

    this.editForm.get('checkbox1').setValidators(Validators.requiredTrue);
    this.editForm.get('checkbox1').updateValueAndValidity();
    // this.editForm.get('checkbox2').setValidators(Validators.requiredTrue);
    // this.editForm.get('checkbox2').updateValueAndValidity();

    //Validierung für Adressdaten
    this.editForm.get('Adr_Strasse').setValidators(Validators.required);
    this.editForm.get('Adr_Strasse').updateValueAndValidity();
    this.editForm.get('Adr_HausNr').setValidators(Validators.required);
    this.editForm.get('Adr_HausNr').updateValueAndValidity();
    this.editForm.get('Adr_PLZ').setValidators(Validators.required);
    this.editForm.get('Adr_PLZ').updateValueAndValidity();
    this.editForm.get('Adr_Ort').setValidators(Validators.required);
    this.editForm.get('Adr_Ort').updateValueAndValidity();

    // Trigger Fehlermeldung
    if (this.editForm.controls['edtUserEMail'].value !== this.editForm.controls['edtUserEMail_Wdh'].value) {
      this.displayDialogEmailDifferent = true;
    }

    // Trigger Fehlermeldung
    if (this.editForm.controls['User_User_Pwd'].value !== this.editForm.controls['User_User_Pwd_Wdh'].value) {
      this.displayDialogPasswordDifferent = true;
    }

    bOK = this.editForm.valid;
    this.displayDialogError = !bOK;

    if(bOK) {
      bOK = this.KennKontaktID;
      this.displayDialogNoRegistrationPossible = !this.KennKontaktID;
    }


    if (bOK) {

      // this.DOMain.Dataset.Data[0].Acc_Login_ID                = this.editForm.controls['edtUserEMail'].value;
      // this.DOMain.Dataset.Data[0].Acc_Login_Pwd               = this.editForm.controls['User_User_Pwd'].value;
      // this.DOMain.Dataset.Data[0].eMail                       = this.editForm.controls['edtUserEMail'].value;

      // if (vsCommon.vsVarAssigend(this.kontaktID)) {
      //   this.DOMain.Dataset.Data[0].Acc_ID_Kontakt                  = this.kontaktID;
      // }

      // this.DOMain.Dataset.Data[0].Kontakt_ID_Anrede           = this.editForm.controls['Kontakt_ID_Anrede'].value;
      // this.DOMain.Dataset.Data[0].Kontakt_Name1               = this.editForm.controls['Kontakt_Name1'].value;
      // this.DOMain.Dataset.Data[0].Kontakt_Name2               = this.editForm.controls['Kontakt_Name2'].value;

      // if (vsCommon.vsVarAssigend(this.editForm.controls['Kontakt_Geb_Datum'].value)) {
      //   this.DOMain.Dataset.Data[0].Kontakt_Geb_Datum         = vsCommon.DateStrToISO8061(this.editForm.controls['Kontakt_Geb_Datum'].value);
      // }

      // //$REV MP 2023-04-03: Da die Registrierungsseiten als Ausnahmen im Routing zugelassen werden können, wird die location falsch gespeichert
      // // und muss hier korrigiert werden, damit die Accountaktivierung korrekt funktionieren kann.
      // let sLocation =  window.location.href.replace('/regLinkAcc', '');
      // localStorage.setItem('location', sLocation);

      // this.DOMain.Dataset.Data[0].xURL_Confirm                = localStorage.getItem('location');

      // // Prüfung, ob E-Mail schon existiert

      // this.DataFilter.ItemsClear();
      // // let item = this.DataFilter.ItemAdd('LogIn Check');
      // // item.CriteriaAdd('Acc_Login_ID', this.editForm.controls['edtUserEMail'].value);

      // //GDS-Aufruf 20 [OK] webDoStammUserAccount
      // console.log('GDS_WebUserAccount Acc_Login_ID');
//********************************** */


      let acc: vsCommon.TWebRegister = new vsCommon.TWebRegister();

      acc.kontaktID           = this.kontaktID;
      acc.eMail               = this.editForm.controls['edtUserEMail'].value;
      acc.accLoginID          = this.editForm.controls['edtUserEMail'].value;
      acc.accLoginPWD         = this.editForm.controls['User_User_Pwd'].value;

      //$REV MP 2024-05-22: Erweiterung um Adressdaten
      acc.adrPLZ              = this.editForm.controls['Adr_PLZ'].value;
      acc.adrOrt              = this.editForm.controls['Adr_Ort'].value;
      acc.adrOrtsteil         = this.editForm.controls['Adr_Ortsteil'].value;
      acc.adrStrasse          = this.editForm.controls['Adr_Strasse'].value;
      acc.adrHausNr           = this.editForm.controls['Adr_HausNr'].value;
      acc.adrNameZusatz1      = this.editForm.controls['Adr_Zusatz'].value;

      let sLocation =  window.location.href.replace('/regLinkAcc', '');
      localStorage.setItem('location', sLocation);
      acc.xURLConfirm = localStorage.getItem('location');
      acc.forceRegister       = true;

      let sBody = JSON.stringify(acc);
      let bRegistered: boolean = false;
      let bResultOK:  boolean = false;
      let bGesVertrFound: boolean = false;

      this.displaySpinner = true;

//************************ */

      //this.HttpServiceComponent.dataGet(this.ViewProperties.DataSourceID, false, [], this.DataFilter
    //   this.HttpServiceComponent.HttpServiceGetRequest('school/data/webUserAccount', '')
    //        .subscribe(data => {
    //         console.log('GDS_WebUserAccount 20 [OK] Acc_Login_ID');
    //         let x: any = data;
    //       if (x.Dataset.RecordCount > 0) {
    //         //Meldung wenn Email-Adresse schon in Verwendung ist.
    //         this.displayDialogSameEmail = true;
    //         this.displayDialogError = true;
    //       }

    //       else {
    //         // Register Account

    //         //Spinner startet hier
    //         this.displaySpinner = true;

    //         this.HttpServiceComponent.dataUpdate(this.ViewProperties.DataSourceID,
    //         this.ViewProperties.DOMain,
    //         null)
    //         .subscribe(response =>{

    //         },
    //         error => {
    //         //TODO MP Alert durch Message ersetzen.
    //         alert(  'Bei der Registrierung ist ein Fehler aufgetreten:\n'
    //         + error.error.Error.ErrCode + '\n'
    //         + error.error.Error.ErrMsg + '\n\n'
    //         + error.message );
    //         this.DOMain.IsRefreshing = false;
    //         },
    //         () => {
    //             this.displaySpinner = false;

    //             //$REV MP 2023-04-03: Beim abschließen localStorage leeren, kann sonst mehrfach befüllt werden
    //             localStorage.removeItem('accConfirm');
    //             localStorage.removeItem('location');

    //             // Forwarding zur Confirmation-Page
    //             this.router.navigate(['regConfirmation']);
    //             // this.displayDialogRegInProgress = true;
    //           }
    //         );

    //       }
    //   });
    // }

      this.HttpServiceComponent.HttpServicePostRequest('school/portal/regStd', '', sBody)
      .subscribe((response: any) => {

          console.log('Response: ', response);
          bRegistered     = response.kennRegistered;
          bResultOK       = response.result;

          if (!bRegistered && bResultOK)
          {
            console.log('!KennRegisterd');
            if (response.kennLoginIDFound) {
              this.displayDialogError = true;
              this.displayDialogSameEmail = true;
            }
          }
        }
        ,error => {
            //TODO MP Alert durch Message ersetzen.
            alert(  'Bei der Registrierung ist ein Fehler aufgetreten:\n'
            + error.error.errMsg );
            this.DOMain.IsRefreshing = false;
            this.displaySpinner = false;
          }
        ,() => {
            if (bRegistered && bResultOK)
            {
              //$REV MP 2023-04-03: Beim abschließen localStorage leeren, kann sonst mehrfach befüllt werden
              localStorage.removeItem('accConfirm');
              localStorage.removeItem('location');

              // Forwarding zur Confirmation-Page
              this.router.navigate(['regConfirmation']);
            }
            this.displaySpinner = false;
          }
      );
    } // register
  } // if bOK
} // TsysViewUserReg


