//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';

import {  forkJoin }                        from 'rxjs';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';
import { TvsDataFilter }                    from '@vsLib/vsDataFilter';

import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


import { TFileData }                        from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { DatePipe, formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { HttpClient } from '@angular/common/http';


type lookupVorgangFilesType = {
  Vorgang : {
    PKValue : string;
    Datum :   string;
    Uhrzeit:  string;
    Files:    any[];
  }
}


@Component({
  selector:       'schoolViewAntrag',
  templateUrl:  './schoolViewAntrag.component.html',
  styleUrls:   ['./schoolViewAntrag.component.scss']
})
export class TschoolViewAntrag extends vsViewData.TvsViewData implements OnInit {

  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  // ./.

  public      editForm:                     FormGroup               = new FormGroup({});
  public      antrDatum:                    string;
  public      gebDatumAzubi:                string;
  public      gebDatumGesVertr:            string;

  public      schulart:                     any;

  public      lookupSchulart:               any[];

  public      lookupPosList:                any[];

  public      dataIndiv:                    any;      
  
  public      lookupVorgang:                any;

  public      lookupAntrag:                 any[];

  public      lookupVorgangFiles:           lookupVorgangFilesType[] = [];

  public      FileData:                     TFileData = new TFileData();

  public      kennDisplayPersData:          boolean = false;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected httpClient:                 HttpClient
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antragsübersicht';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = 'schoolDOStammAntrag';
    this.ViewProperties.QueryFields     = [];


  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {


    console.log('WebAccProfile', this.globalResources.WebAccProfile.accErfassDatum);

    this.editForm.addControl('Zust_Box', new FormControl(null));

    let DataFilter: TvsDataFilter = new TvsDataFilter();

    //$REV MP 2023-03-07: Da das DO der Antragsliste geändert wurde, muss hier der Antrag nachgeladen werden um alle Informationen anzuzeigen und zu laden.
    DataFilter.ItemsClear();
    let item = DataFilter.ItemAdd('Filter Antr_ID');
    item.CriteriaAdd('PKValue', this.dataModel.antrag.Antr_ID);

    this.HttpServiceComponent.dataGet('schoolDOStammAntrag', false, [], DataFilter)
    .subscribe(data => {
      this.lookupAntrag =  data['Dataset'].Data;

      console.log('this.lookupAntrag', this.lookupAntrag);

      if(this.globalResources.WebAccProfile.accErfassDatum <= this.lookupAntrag[0].Antr_Erfass_Datum) {
        this.kennDisplayPersData = true;
        console.log('kennDisplayPersData', this.kennDisplayPersData);
      }
      
    }, 
    error => {

    console.log( 'Bei der Abfrage ist ein Fehler aufgetreten:\n'
    + error.error.Error.ErrCode + '\n'
    + error.error.Error.ErrMsg + '\n\n'
    + error.message );
    this.DOMain.IsRefreshing = false;
    },
    () => {

      //Starten von dataDisplay erst wenn der Antrag geladen wurde, dataLoad()/forkJoin funktioniert sonst nicht, da Antragsdaten fehlen.
      this.dataModel.antrag = this.lookupAntrag[0];
      this.dataDisplay();

      }    
    );

  } // ngOnInit


  ngAfterViewInit(): void {


  }


  public actionStepPrev(): void {
    this.dataModel.EmptyData();
    this.router.navigate(['main']);
  } // actionStepPrev()
  


  async dataLoad() {

    const ausbDOKatStaettArt = this.HttpServiceComponent.dataGet('ausbDOKatStaettArt', false, [], null, TvsGetType.gtNormal, false);


    let DataFilter: TvsDataFilter = new TvsDataFilter();
    DataFilter.ItemsClear();
    let item = DataFilter.ItemAdd('Filter Pos_ID_Antrag');
    item.CriteriaAdd('Pos_ID_Antrag', this.dataModel.antrag.PKValue);

    const schoolDOStammAntragPos = this.HttpServiceComponent.dataGet('schoolDOStammAntragPos', true, [], DataFilter, TvsGetType.gtNormal, false);

    DataFilter.ItemsClear();
    item = DataFilter.ItemAdd('Filter Vorfall-ID');
    item.CriteriaAdd('Vorf_ID', this.dataModel.antrag.Antr_ID_CRM_Vorf);

    let item2 = DataFilter.ItemAdd('Filter Visibility = Public');
    item2.CriteriaAdd('Vorg_ID_Visibility', 'PUBLIC');

    console.log('this.DataFilter', DataFilter);

    const crmDODataVorg = this.HttpServiceComponent.dataGet('crmDODataVorg', true, [], DataFilter, TvsGetType.gtNormal, false);

    forkJoin([ausbDOKatStaettArt, schoolDOStammAntragPos, crmDODataVorg ]).subscribe((results: any) => {
      this.lookupSchulart        = results[0]['Dataset'].Data;
      this.lookupPosList         = results[1]['Dataset'].Data;
      this.lookupVorgang         = results[2]['Dataset'].Data;

      console.log('this.lookupVorgang', this.lookupVorgang);

      this.ViewProperties.SubjInitLoad.next();
    });

} // dataLoad


dataDisplay(): void {
    
  this.dataLoad().then(() => {

    let array = [];

    this.ViewProperties.SubjInitLoad.subscribe((args) => {

      this.antrDatum          = vsCommon.ISO8061ToDateStr(this.lookupAntrag[0].Antr_Datum_Antrag);
      this.gebDatumAzubi      = vsCommon.ISO8061ToDateStr(this.lookupAntrag[0].Azubi_Geb_Datum);
      this.gebDatumGesVertr   = vsCommon.ISO8061ToDateStr(this.lookupAntrag[0].GesVertr_Geb_Datum);
      
      this.schulart           = vsCommon.vsRetrieveRecordByPKValue(this.lookupSchulart, this.lookupAntrag[0].Antr_ID_Ausb_Staett_Art);

      //$REV MP 2023-03-09: Verlagerung aus ngOnInit, weil this.dataModel.antrag erst später, beim nachladen des Antrages gefüllt wird.
      if(this.dataModel.antrag.Antr_ID_Art === "I") {

        this.dataIndiv = vsCommon.vsStringHasValue(this.dataModel.antrag.Antr_Data_Individual)
        ? JSON.parse(this.dataModel.antrag.Antr_Data_Individual)
        :{
          pickupHome:   {
            Time_PickupHome_Monday:         null
          , Time_PickupHome_Tuesday:        null
          , Time_PickupHome_Wednesday:      null
          , Time_PickupHome_Thursday:       null
          , Time_PickupHome_Friday:         null
          , Time_PickupHome_Saturday:       null
          , Time_PickupHome_Sunday:         null
          }
        , pickupSchool: {
            Time_PickupSchool_Monday:       null
          , Time_PickupSchool_Tuesday:      null
          , Time_PickupSchool_Wednesday:    null
          , Time_PickupSchool_Thursday:     null
          , Time_PickupSchool_Friday:       null
          , Time_PickupSchool_Saturday:     null
          , Time_PickupSchool_Sunday:       null
        }
        , additInfo: {
            Wheelchair:                     false
          , Assistant:                      false
          , AdditInfoMsg:                   null
        }
      };
      }

      if (vsCommon.vsVarAssigend(this.lookupVorgang)) {
        for (let i = 0; i < this.lookupVorgang.length; i++) {

          // this.HttpServiceComponent.dataGet('crmDODataVorgPortal', false, [], this.DataFilter).subscribe((data: any) => {

          this.HttpServiceComponent.HttpServiceGetRequest('crmDataDocumentGet', '&crmEventID=' + this.lookupVorgang[i].PKValue)
            .subscribe(response => {

            for (let j = 0; j < ((response as unknown) as vsCommon.TVorg).document.documents.length; j++) {

              this.FileData = {
                UploadFileName: ((response as unknown) as vsCommon.TVorg).document.documents[j].docOrigFileName,
                UploadFileDataBase64: ((response as unknown) as vsCommon.TVorg).document.documents[j].docData,
                UploadFile: null,
                UploadFileDataURL: '',
                // UploadFileDataBinary: null,
                // croppedImage: null
              }

              console.log('fileData', this.FileData);

              array.push(this.FileData);

              }
            }, () =>{}, ()=>{

              this.lookupVorgangFiles.push(
                {
                  Vorgang: {
                  PKValue:  this.lookupVorgang[i].PKValue,
                  Datum:    vsCommon.ISO8061ToDateStr(this.lookupVorgang[i].Vorg_Erfass_Datum),
                  Uhrzeit:  vsCommon.ISO8061ToTimeStr(this.lookupVorgang[i].Vorg_Uhrzeit),
                  Files: [...array]
                }
              }
              )
              array = [];
            });
        }
      }
        
      this.ViewProperties.SubjDisplay.next();
      this.ViewProperties.SubjDisplay.complete();
      })
  })

}

//Wenn Dokument vorhanden ist, laden des Dokumentes auf Button-Click
docDownload(a_file: TFileData){
  let sExt = vsCommon.vsStringFileExtractExt(a_file.UploadFileName);
  if (vsCommon.vsStringSameText(sExt,'pdf')) {
    var a = document.createElement("a");
    a.href = "data:application/octet-stream;base64," + a_file.UploadFileDataBase64;
  }
  else {
    var a = document.createElement("a");
      a.href = "data:image/" + sExt + ";base64," + a_file.UploadFileDataBase64;
  }
  a.download = a_file.UploadFileName;
  a.click();
}

} // schoolViewAntragEdit_Commit


