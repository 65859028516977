//------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsCommon.ts
//  Notes:  Common types, functions, classes etc.
//
//
//  H I S T O R Y
//
//  2021-06-18  TC   Initial Release
//------------------------------------------------------------------------------

import { toNumber } from "@alyle/ui";
import { TvsDataSet } from "./vsDataSet";


export function RetrieveURLBase1(a_bGenericDataRequest: boolean = true): string {

  let sRequestPath            = '';


  //---- local (Entwicklung)

  let sRequestProtocol      = 'http';
  let sRequestIPAddress     = 'localhost';
  let sRequestPortNumber    = '2001';


  //---- APPS (Development)

  // let sRequestProtocol      = 'https';
  // let sRequestIPAddress     = '192.168.115.39';
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'servicesDev/';


  //---- APPS (QS)

  // let sRequestProtocol      = 'https';
  // let sRequestIPAddress     = '192.168.115.39';
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'servicesQS/';


  //---- APPS (Demo)

  // let sRequestProtocol      = 'https';
  // let sRequestIPAddress     = '192.168.115.39';
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'servicesDemo/';



  //---- Cloud Server: RTK | Test (QWERTIKO)

  // let sRequestProtocol      = 'http';
  // let sRequestIPAddress     = '185.30.157.135';
  // let sRequestPortNumber    = '80';
  // sRequestPath              = 'services/';

  // let sRequestProtocol      = 'https';
  // let sRequestIPAddress     = '<domain>>';
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'services/';



  //---- Cloud Server: VBK | Test (QWERTIKO)

  // let sRequestProtocol      = 'http';
  // let sRequestIPAddress     = '185.30.157.133';
  // let sRequestPortNumber    = '80';
  // sRequestPath              = 'services/';

  // let sRequestProtocol      = 'https';
  // let sRequestIPAddress     = '<domain>>';
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'services/';



  //---- Cloud Server: VRT | Test (IONOS)

  // let sRequestProtocol      = 'https';
  // let sRequestIPAddress     = '217.160.27.29';
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'services/';                // $Rev TC 2021-10-18: added to support reverse proxying


  //---- Cloud Server: VRT | Produktiv (QWERTIKO)

  // let sRequestProtocol      = 'https';
  // // let sRequestIPAddress     = '185.30.157.114';
  // let sRequestIPAddress     = 'bm.vrt-info.de';           // $Rev TC 2021-12-03: bei Verwendung eines offiziellen SSL-Zertifikates, muss der domain name verwendet werden
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'services/';



  //---- Cloud Server: Staging (IONOS) | mobility portal

  // let sRequestProtocol      = 'https';
  // // let sRequestIPAddress     = '217.160.27.29';
  // let sRequestIPAddress     = 'staging.mobility-portal.net';      // Bei Verwendung eines offiziellen SSL-Zertifikates, muss der domain name verwendet werden
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'api/';


  //---- Cloud Server: Staging (IONOS) | mobility portal | Apps

  // let sRequestProtocol      = 'https';
  // // let sRequestIPAddress     = '217.160.27.29';
  // let sRequestIPAddress     = 'staging.mobility-portal.net';     // Bei Verwendung eines offiziellen SSL-Zertifikates, muss der domain name verwendet werden
  // let sRequestPortNumber    = '443';
  // sRequestPath              = 'apiApps/';



  //---- Service Name
  let sRequestServiceName   = sRequestPath;                         // $Rev TC 2021-10-18: added to support reverse proxying


  if (a_bGenericDataRequest) {
    // let sRequestServiceName   = 'genericData';                   // $Rev TC 2021-06-09: added: generischer Data Service
    sRequestServiceName   = sRequestServiceName + 'genericData';    // $Rev TC 2021-10-18: added to support reverse proxying

    // return 'https://' + sRequestIPAddress + ':' + sRequestPortNumber + '/' + sRequestServiceName;
    return sRequestProtocol + '://' + sRequestIPAddress + ':' + sRequestPortNumber + '/' + sRequestServiceName;

  } else {

    // return 'https://' + sRequestIPAddress + ':' + sRequestPortNumber;
    return sRequestProtocol + '://' + sRequestIPAddress + ':' + sRequestPortNumber + '/' + sRequestServiceName;

  } // if

} // RetrieveURLBase





export interface TvsDialogResult  {
  dialogOK:        boolean;
  data:            any;
}


export enum TvsAlignment {
  alNone      = "alNone"
, alRight     = "alRight"
, alLeft      = "alLeft"
, alCenter    = "alCenter"
}

export enum TvsDateTimeInterval {
   dtiMinute    = "dtiMinute"
  ,dtiHour      = "dtiHout"
  ,dtiDay       = "dtiDay"
  ,dtiWeek      = "dtiWeek"
  ,dtiMonth     = "dtiMonth"
  ,dtiQuarter   = "dtiQuarter"
  ,dtiHalfYear  = "dtiHalfYear"
  ,dtiYear      = "dtiYear"
  ,dtiUndefined = "dtiUndefined"
}

//
// TvsAppViewType
//
// Enumeration
//
export enum TvsAppViewType {
  avtUndefined      = "avtUndefined"
, avtHome           = "avtHome"
, avtList           = "avtList"
, avtEdit           = "avtEdit"
} // TvsAppViewType

//
// TvsVorgArt
//
//
// Enumeration
//

export enum TschoolBestellArt {
  baBestellung          = "NEW"
, baAenderung           = "CHG"
, baRueckgabe           = "TERM"
} // TinvBestellArt

export function invBestellName(a_ba: TschoolBestellArt): string {
  switch(a_ba) {
    case TschoolBestellArt.baBestellung: {return "Bestellung"; break;}
    case TschoolBestellArt.baAenderung:  {return "Änderung"; break;}
    case TschoolBestellArt.baRueckgabe:  {return "Rückgabe"; break;}
    default:  {return "";}
  }
}

export enum TcrmVorgangArt {
  vaUndefined           = "Undefined"
, vaDokumentSonstiges   = "DOK"
, vaKommunikation       = "KOMM"
// , vaEMail               = "EMAIL"
, vaNotiz               = "NOTIZ"
} // TvsVorgArtType

export class TWebRegister {
    accLoginID:        String;
    accLoginPWD:       String;
    eMail:             String;
    kontaktID:         String;
    kontaktIDAnrede:   String;
    kontaktName1:      String;
    kontaktName2:      String;
    kontaktGebDatum:   String;
    adrStrasse:        String;
    adrHausNr:         String;
    adrPLZ:            String;
    adrOrt:            String;
    adrOrtsteil:       String;
    adrNameZusatz1:    String;
    xURLConfirm:       String;

    forceRegister:     Boolean;
}


export class TVorgMain {
  crmActivityType: string;
  crmTicketID: string;
  crmContactID: string;
  crmActivityID: string;
  crmActivityMultiDoc: boolean;
  crmActivityIsIncoming: boolean;
  crmActivityDate: string;
  crmActivityTime: string;
  crmActivityName: string;
  crmActivityDesc: string;

  //$REV MP 2023-08-23 Visibility und DocumentType hinzugefügt.
  crmVisibilityID: string;
  crmDocumentType: string;

  documents:  TVorgFile[] = [];
  email:      TVorgEMail  = new TVorgEMail();
}

export class TVorgFile {
  docOrigFileName: string;
  docKindID: string;
  docTypeID: string;
  docData: string;
}

export class TVorgEMail {
  ID:                 String;
  ActivityImpID:      String;
  ReporterID:         String;
  Date:               String;
  Time:               String;
  ImpDate:            String;
  ImpTime:            String;
  AccID:              String;
  From:               String;
  To:                 String;
  CC:                 String;
  BCC:                String;
  IsHtml:             Boolean;
  IsProcessed:        Boolean;
  IsSent:             Boolean;
  MsgID:              String;
  Subject:            String;
  Content:            String;
}

//
// TvsCommonControlType     [OBSOLETE?]
//
// Enumeration of control types
//
export enum TvsCommonControlType {
    ctUndefined     = "ctUndefined",
    ctTextBox       = "ctTextBox",
    ctComboBox      = "ctComboBox",
    ctDatePicker    = "ctDatePicker",
    ctCheckBox      = "ctCheckBox"
} // TvsCommonControlType



//
// TvsVorfType
//
// Enumeration für die in BM feste Auswahl der Vorfallsart "Ticket"
//
export enum TvsVorfType {
  vtUndefined = "vtUndefined",
  vtTicket    = "TICKET",         // BM-Tickets
  vtEBE       = "OPV_EBE",        // FAP-EBE
} // TvsDataSourceType


export class TWebParamPKValuesSelected_Envelope {
  data: TWebParamPKValuesSelected;
  constructor () {
    this.data = new TWebParamPKValuesSelected();
  }
  public Add(a_pkvalues: any) {
    this.data.pkValues.push(a_pkvalues);
  }
}

export class TWebParamPKValuesSelected {
  pkValues: string[] = [];
}

// Trias-Parameter für Web-Service

export class TWebParamTrias_Envelope {
  data: TWebParamTrias;
  constructor () {
    this.data = new TWebParamTrias();
  }
}

export class TWebParamTrias {
  stopPointRefStart:   String;
  stopPointRefDest:    String;
  dataTimeStamp:       String;
}

export class TWebParamPwdChange_Envelope {
  data: TWebParamPwdChange;
  constructor () {
    this.data = new TWebParamPwdChange();
  }
}

export class TWebParamPwdChange {
  userID:   String;
  password: String;
}


export class TVorg {
  document: TVorgMain;
  constructor (a_doc: TVorgMain) {
    this.document = a_doc;
  }
  public DocAdd(a_docOrigFileName: string, a_docKindID: string, a_docTypeID: string, a_docData: string) {
    let doc: TVorgFile = new TVorgFile();
    doc.docOrigFileName = a_docOrigFileName;
    doc.docKindID       = a_docKindID;
    doc.docTypeID       = a_docTypeID;
    doc.docData         = a_docData;

    this.document.documents.push(doc);
  }
}

// export class TDialogParams {
//   pkValue: string;
//   data:    any;
// }





// Beispiel für Konstanten:
export const  gc_vsCompanyName:       string        = 'vedisys AG';

//======[ Functions ]===============================================================================



//------ Miscellaneous


export function vsDelay(a_iDelayMS: number, a_action: () => void): void {

  (async () => {
    await new Promise(f => setTimeout(f, a_iDelayMS));
    a_action();
  })();

} // vsDelay

export function RetrieveSchoolYearBegin(a_sSchuljahr: String): string  {
  let sBegJahr = toNumber(a_sSchuljahr.substring(0,4))
  return this.DateStrToISO8061('01.08.' + sBegJahr);
}

// UsrRights vsViewDataList
export function findItemInArrayByProgFuncID(array: any, id: any) {
  let item = array.find((item) => {
    return item["progFuncID"] === id;
  });
  return item;
};

// UsrRights mainView
export const findItemInArrayByIdProp = (array: any, id: any, idProp: string): any => {
  let item = array.find((item) => {
    return item[idProp] === id;
  });
  return item;
};

// UsrRights AppDispatcher
export const find = function(array, id) {
  let foundItem = array.find((item) => {
    return item.progFuncID === id;
  });

  return foundItem || false;
};

  // Funktionsansatz um die ToolbarItems zu enablen/disablen - funktioniert noch nicht richtig. Muss korrigiert werden.
  // NICHT VERWENDEN
  // public restrictToolbarItemsByUserRights() {

  //   this.globalResources.UserProfile.UserRights.progFuncList.map((progFuncItems) => {
  //     if(progFuncItems.id === (this.ViewProperties.Instance as TvsViewDataList).ViewProperties.ProgFuncID) {
  //       this.ViewProperties.ToolbarItems.map((item) => {
  //         if(!progFuncItems.edit && item.ID === "tbEdit") {
  //           item.Enabled = false;
  //         }
  //         if(!progFuncItems.delete && item.ID === "tbDelete") {
  //           item.Enabled = false;
  //         }
  //         if(!progFuncItems.new && item.ID === "tbNew") {
  //           item.Enabled = false;
  //         }
  //         if(!progFuncItems.custom && item.ID === "tbCustom") {
  //           item.Enabled = false;
  //         }
  //       })
  //     }
  //   })
  // }

//Original
// export function RetrieveSchoolYear(a_sSchuljahr: string, a_bPrev: boolean): string {
//   let iEndYear: number;
//   let iBegYear: number;

//   if (a_bPrev) {
//     iEndYear = toNumber(a_sSchuljahr.substring(0,4))
//     iBegYear = iEndYear - 1;
//   }
//   else {
//     iBegYear = toNumber(a_sSchuljahr.substring(0,4)) + 1;
//     iEndYear = iBegYear + 1;
//   }
//   return iBegYear.toString() + '/' + iEndYear.toString();
// }

// $REV MP 2023-07-12: Erweiterung um mögliche Anzahl für die Menge der letzten Jahre.
export function RetrieveSchoolYear(a_sSchuljahr: string, a_bPrev: boolean, pastYears: number = 0): string[] {
  let result: string[] = [];
  
  if (pastYears > 0 && a_bPrev) {
    // Berechne mehrere vergangene Schuljahre
    let iEndYear: number = toNumber(a_sSchuljahr.substring(0, 4));
    for (let i = 0; i < pastYears; i++) {
      let iBegYear: number = iEndYear - 1;
      result.push(iBegYear.toString() + '/' + iEndYear.toString());
      iEndYear = iBegYear; // Update iEndYear for the next past year
    }
  } else {
    // Berechne nur ein einzelnes Schuljahr (vorheriges oder nächstes)
    let iEndYear: number;
    let iBegYear: number;

    if (a_bPrev) {
      iEndYear = toNumber(a_sSchuljahr.substring(0, 4));
      iBegYear = iEndYear - 1;
    } else {
      iBegYear = toNumber(a_sSchuljahr.substring(0, 4)) + 1;
      iEndYear = iBegYear + 1;
    }
    result.push(iBegYear.toString() + '/' + iEndYear.toString());
  }

  return result;
}

//$REV MP 2023-06-13: Schuljahre um Start- und Enddatum erweitert.
// export function RetrieveSchoolYearLookup(): any {
//   let dt = RetrieveDateStrNow();
//   let syear: string = dt.toString().substring(6,10);
//   let year: number = toNumber(syear);

//   let sj = (year-1).toString() + '/' + (year).toString();
//   let sjNext = RetrieveSchoolYear(sj, false);
//   let sjPrev = RetrieveSchoolYear(sj, true);

//   let result: object[]  = [];
//   result.push({'Schuljahr': sjPrev, 'Startdatum': '01.08.'+(year-2).toString(), 'Enddatum': '31.07.'+(year).toString()});
//   result.push({'Schuljahr': sj, 'Startdatum': '01.08.'+(year).toString(), 'Enddatum': '31.07.'+(year+1).toString()});
//   result.push({'Schuljahr': sjNext, 'Startdatum': '01.08.'+(year+1).toString(), 'Enddatum': '31.07.'+(year+2).toString()});
//   return result;
// }

// $REV MP 2023-07-12: Erweiterung um mögliche Anzahl für die Menge der letzten Jahre.
export function RetrieveSchoolYearLookup(pastYears: number = 0): any {
  let dt = RetrieveDateStrNow();
  let syear: string = dt.toString().substring(6, 10);
  let year: number = toNumber(syear);

  let sj = (year - 1).toString() + '/' + year.toString();
  let sjNext = RetrieveSchoolYear(sj, false)[0]; // Get the first item from the array
  let sjPrevArray = RetrieveSchoolYear(sj, true, pastYears);

  let result: object[] = [];

  // Schuljahre für die angegebenen Jahre in der Vergangenheit hinzufügen
  sjPrevArray.forEach(pastSj => {
    let pastYear = toNumber(pastSj.split('/')[1]);
    result.push({'Schuljahr': pastSj, 'Startdatum': '01.08.' + (pastYear - 1).toString(), 'Enddatum': '31.07.' + pastYear.toString()});
  });

  //$REV MP 2024-07-17: push durch unshift ersetzt, damit die Daten oben angehängt werden.
  // Aktuelles Jahr hinzufügen
  result.unshift({'Schuljahr': sj, 'Startdatum': '01.08.' + (year - 1).toString(), 'Enddatum': '31.07.' + year.toString()});

  // Nächstes Jahr hinzufügen
  result.unshift({'Schuljahr': sjNext, 'Startdatum': '01.08.' + year.toString(), 'Enddatum': '31.07.' + (year + 1).toString()});

  return result;
}

//$REV MP 2023-06-13: Aktuelle Halbjahre des Schuljahres, wichtig für Erstattungen.
// export function RetrieveSchoolHalfYearLookup(): any {
//   let dt = RetrieveDateStrNow();
//   let syear: string = dt.toString().substring(6,10);
//   let year: number = toNumber(syear);

//   let sj = (year-1).toString() + '/' + (year).toString();
//   let sjNext = RetrieveSchoolYear(sj, false);
//   let sjPrev = RetrieveSchoolYear(sj, true);

//   let result: object[]  = [];
//   result.push({'Halbjahr': '1. Halbjahr ' + sjPrev, 'Startdatum': '01.08.'+(year-2).toString(), 'Enddatum': '31.01.'+(year-1).toString()});
//   result.push({'Halbjahr': '2. Halbjahr ' + sjPrev, 'Startdatum': '01.02.'+(year-1).toString(), 'Enddatum': '31.07.'+(year-1).toString()});
//   result.push({'Halbjahr': '1. Halbjahr ' + sj, 'Startdatum': '01.08.'+(year-1).toString(), 'Enddatum': '31.01.'+(year).toString()});
//   result.push({'Halbjahr': '2. Halbjahr ' + sj, 'Startdatum': '01.02.'+(year).toString(), 'Enddatum': '31.07.'+(year).toString()});
//   result.push({'Halbjahr': '1. Halbjahr ' + sjNext, 'Startdatum': '01.08.'+(year).toString(), 'Enddatum': '31.01.'+(year+1).toString()});
//   result.push({'Halbjahr': '2. Halbjahr ' + sjNext, 'Startdatum': '01.02.'+(year+1).toString(), 'Enddatum': '31.07.'+(year+1).toString()});
//   return result;
// }

// $REV MP 2023-07-12: Erweiterung um mögliche Anzahl für die Menge der letzten Jahre.
export function RetrieveSchoolHalfYearLookup(pastYears: number = 0): any {
  let dt = RetrieveDateStrNow();
  let syear: string = dt.toString().substring(6, 10);
  let year: number = toNumber(syear);

  let sj = (year - 1).toString() + '/' + year.toString();
  let sjNext = RetrieveSchoolYear(sj, false)[0]; // Get the first item from the array
  let sjPrevArray = RetrieveSchoolYear(sj, true, pastYears);

  let result: object[] = [];

  // Halbjahre für die angegebenen Jahre in der Vergangenheit hinzufügen
  sjPrevArray.forEach(pastSj => {
    let pastYear = toNumber(pastSj.split('/')[1]);
    result.push({ 'Halbjahr': '1. Halbjahr ' + pastSj, 'Startdatum': '01.08.' + (pastYear - 1).toString(), 'Enddatum': '31.01.' + pastYear.toString() });
    result.push({ 'Halbjahr': '2. Halbjahr ' + pastSj, 'Startdatum': '01.02.' + pastYear.toString(), 'Enddatum': '31.07.' + pastYear.toString() });
  });

  // Aktuelles Jahr hinzufügen
  result.push({ 'Halbjahr': '1. Halbjahr ' + sj, 'Startdatum': '01.08.' + (year - 1).toString(), 'Enddatum': '31.01.' + year.toString() });
  result.push({ 'Halbjahr': '2. Halbjahr ' + sj, 'Startdatum': '01.02.' + year.toString(), 'Enddatum': '31.07.' + year.toString() });

  // Nächstes Jahr hinzufügen
  result.push({ 'Halbjahr': '1. Halbjahr ' + sjNext, 'Startdatum': '01.08.' + year.toString(), 'Enddatum': '31.01.' + (year + 1).toString() });
  result.push({ 'Halbjahr': '2. Halbjahr ' + sjNext, 'Startdatum': '01.02.' + (year + 1).toString(), 'Enddatum': '31.07.' + (year + 1).toString() });

  return result;
}


export function DateToString(a_dt: Date, a_bYearFirst: boolean): string {
  var sDate:    string = a_dt.toISOString();
  var sYear:    string = sDate.substring(0,4);
  var sMonth:   string = sDate.substring(5,7);
  var sDay:     string = sDate.substring(8,10);
  return a_bYearFirst ? (sYear + '-' + sMonth + '-' + sDay) : (sDay + '-' + sMonth + '-' + sYear);

}

export function RetrieveDateNow(): Date {
  let today = new Date();
  today.setTime(today.getTime() - (1000 * 60 * today.getTimezoneOffset()));
  return today;
}

export function VSDateFirstOfMonth(a_dt: Date) : Date{
  let iMonth = a_dt.getMonth();
  let iYear  = a_dt.getFullYear();
  let day = new Date(iYear, iMonth, 1);
  return day;
}

export function VSDateLastOfMonth(a_dt: Date) : Date{
  let iMonth = a_dt.getMonth();
  let iYear  = a_dt.getFullYear();
  if (iMonth < 12) {
    let day = new Date(iYear, iMonth + 1);
    return DateAdd(day, TvsDateTimeInterval.dtiDay, -1);
  }
  else {
    let day = new Date(iYear, 11, 30);
    return day;
  }
}


export function DateAdd(a_dt: Date, a_interval: TvsDateTimeInterval, a_iNumber: number): Date {
  let dtResult: Date;

  if (a_interval == TvsDateTimeInterval.dtiMinute) {
     dtResult =new Date(a_dt.getTime() + (a_iNumber * 60 * 1000) );
   }
   else if (a_interval == TvsDateTimeInterval.dtiHour) {
    dtResult =new Date(a_dt.getTime() + (a_iNumber * 60 * 60 * 1000) );
   }
   else if (a_interval == TvsDateTimeInterval.dtiDay) {
    dtResult =new Date(a_dt.getTime() + (a_iNumber * 24 * 60 * 60 * 1000) );
   }
  //  else if (a_interval = TvsDateTimeInterval.dtiWeek) {
  //   dtResult =new Date(a_dt.getTime() + (a_iNumber * 7 * 24 * 60 * 60 * 1000) );
  //  }
    // else if (a_interval = TvsDateTimeInterval.dtiMonth) {
    //  dtResult = new Date(a_dt.getMonth() + a_iNumber);
    // }
  //  else if (a_interval = TvsDateTimeInterval.dtiQuarter) {
  //   dtResult =new Date(a_dt.getTime() + (a_iNumber * 60 * 1000) );
  //  }
  //  else if (a_interval = TvsDateTimeInterval.dtiHalfYear) {
  //   dtResult =new Date(a_dt.getTime() + (a_iNumber * 60 * 1000) );
  //  }
  //  else if (a_interval = TvsDateTimeInterval.dtiYear) {
  //   dtResult =new Date(a_dt.getTime() + (a_iNumber * 60 * 1000) );
  //  }

  return dtResult;

} // VSDateAdd


export function RetrieveDateStrNow(): string {
  let today = new Date();
  today.setTime(today.getTime() - (1000 * 60 * today.getTimezoneOffset()));
  return ISO8061ToDateStr(today.toISOString());
}

export function RetrieveTimeStrNow(): string {
  let today = new Date();
  today.setTime(today.getTime() - (1000 * 60 * today.getTimezoneOffset()));
  return ISO8061ToTimeStr(today.toISOString());
}

export function ISO8061ToDateStr(a_sValue: string): string {
  return vsStringHasValue(a_sValue) ? a_sValue.substring(8,10) + "." + a_sValue.substring(5,7) + "." + a_sValue.substring(0,4) : null;
}

export function ISO8061ToTimeStr(a_sValue: string): string {
  return vsStringHasValue(a_sValue) ? a_sValue.substring(11,16) : null;
}

export function DateStrToISO8061(a_sValue: string): string {
  return vsStringHasValue(a_sValue) ? a_sValue.substring(6,10) + "-" + a_sValue.substring(3,5) + "-" + a_sValue.substring(0,2) + "T00:00:00.000Z" : null;
}

export function TimeStrToISO8061(a_sValue: string): string {
  return vsStringHasValue(a_sValue) ? "2021-12-07T" + a_sValue.substring(0,2) + ":" + a_sValue.substring(3,5) + ":00.000Z" : null;
}

export function DateTimeStrToISO8061(a_sDate, a_sTime: string): string {
  return (vsStringHasValue(a_sDate) && vsStringHasValue(a_sTime)) ?
  a_sDate.substring(6,10) + "-" + a_sDate.substring(3,5) + "-" + a_sDate.substring(0,2) + "T" + a_sTime.substring(0,2) + ":" + a_sTime.substring(3,5) + ":00.000Z" : null;
}


export function vsRound(a_nValue: number, a_iDecimalPlaces: number): number {
  let nResult: number;
  let nFaktor: number;
  let iSign:   number;

  (a_nValue >= 0) ?  iSign = 1   : iSign = -1;

  nFaktor = 10 ** a_iDecimalPlaces;
  nResult = Math.trunc((Math.abs(a_nValue) * nFaktor) + 0.5);
  nResult = nResult / nFaktor;
  nResult = nResult * iSign;

  return nResult;

} // vsRound

export function vsCvIntToCurrency(a_iValue: number): number {
  return vsRound(a_iValue / 100.0, 2);
} // vsCvIntToCurrency

export function vsCvCurrencyToInt(a_nValue: number): number {
  return Math.trunc(vsRound(a_nValue * 100.0, 0));
} // VSCvCurrencyToInt

export function vsCvIntToKilometers(a_iValue: number): number {
  return vsRound(a_iValue / 1000.0, 2);
} // vsCvIntToCurrency

export function vsCvKilometersToInt(a_nValue: number): number {
  return Math.trunc(vsRound(a_nValue * 1000.0, 0));
} // VSCvCurrencyToInt





//------ Var Functions (for arguments of type any)


export function vsVarAssigend(a_var: any): boolean {
  return (a_var != undefined) && (a_var != null);
} // vsVarAssigend



//------ String Functions


export function vsStringHasValue(a_sValue: string): boolean {
  return (a_sValue != undefined) && (a_sValue != null) && (a_sValue != '');
} // vsStringHasValue


export function vsStringIsNotNull(a_sValue: string): boolean {
  return (a_sValue != undefined) && (a_sValue != null);
} // vsStringHasValue


// Reasons for converting to uppercaes instead of lowercase:
// https://docs.microsoft.com/de-de/previous-versions/visualstudio/visual-studio-2015/code-quality/ca1308-normalize-strings-to-uppercase?view=vs-2015&redirectedfrom=MSDN
// https://en.wikipedia.org/wiki/%C3%9F#Capital_form
//
// While it is recommended to use localCompare() instead of toUpperCase() as of:
// https://stackoverflow.com/questions/2140627/how-to-do-case-insensitive-string-comparison
// ... but not all browsers do support this corectly.
//
export function vsStringSameText(a_sValue1: string, a_sValue2: string): boolean {
  if (vsStringIsNotNull(a_sValue1) && vsStringIsNotNull(a_sValue2)) {
    return (a_sValue1.toUpperCase() === a_sValue2.toUpperCase());
  } else {
    return false;
  }
} // vsStringSameText


export function vsStringFileExtractExt(a_sFileName: string) {
  let sFileExt:       string;

  sFileExt    = a_sFileName.substring(a_sFileName.lastIndexOf('.') + 1);
  if ((sFileExt == a_sFileName) || ('.' + sFileExt == a_sFileName)) {
    sFileExt  = '';
  }

  return sFileExt;

} // vsStringFileExtractExt

export function vsVarCvNull(a_vValue, a_vReplace) {
  if (a_vValue === null || a_vValue === undefined || a_vValue === '') {
    return a_vReplace;
  } else {
    return a_vValue;
  }
}



export function vsStringReplaceHTMLSymbols(a_sValue:string) :string {
  // - & ; lass ich raus, sind standard zeichen und/oder werden für die html codes benötigt

   const symbolsMap :Map<string, string> = new Map();

  symbolsMap.set('<', '&lt;');
  symbolsMap.set('>', '&gt;');
  symbolsMap.set('"', '&quot;');
  symbolsMap.set('\'','&apos;');
  symbolsMap.set('/', '&sol;');
  symbolsMap.set('\\','&bsol;');
  symbolsMap.set('(', '&lpar;');
  symbolsMap.set(')', '&rpar;');
  symbolsMap.set('[', '&lbrack;');
  symbolsMap.set(']', '&rbrack;');
  symbolsMap.set('{', '&lbrace;');
  symbolsMap.set('}', '&rbrace;');
  symbolsMap.set('^', '&circ;');
  symbolsMap.set('´', '&acute;');
  symbolsMap.set('`', '&grave;');
  symbolsMap.set('~', '&tilde;');
  symbolsMap.set('?', '&quest;');
  symbolsMap.set('!', '&excl;');
  symbolsMap.set(',', '&comma;');
  symbolsMap.set('.', '&period;');
  symbolsMap.set(':', '&colon;');
  symbolsMap.set('|', '&vert;');
  symbolsMap.set('_', '&lowbar;');
  symbolsMap.set('#', '&num;');
  symbolsMap.set('°', '&deg;');
  symbolsMap.set('§', '&sect;');
  symbolsMap.set('$', '&dollar;');
  symbolsMap.set('%', '&percnt;');
  symbolsMap.set('=', '&equals;');
  symbolsMap.set('+', '&plus;');
  symbolsMap.set('@', '&commat;');
  symbolsMap.set('*', '&ast;');
  symbolsMap.set('²', '&sup2;');
  symbolsMap.set('³', '&sup3;');
  symbolsMap.set('€', '&euro;');

  // UMLAUTE
  symbolsMap.set('ß', '&szlig;')
  symbolsMap.set('Ä', '&Auml;');
  symbolsMap.set('ä', '&auml;');
  symbolsMap.set('Ö', '&Ouml;');
  symbolsMap.set('ö', '&ouml;');
  symbolsMap.set('Ü', '&Uuml;');
  symbolsMap.set('ü', '&uuml;');

  for(let key of symbolsMap.keys()) {
    while (a_sValue.includes(key)) {
      a_sValue = a_sValue.replace(key, symbolsMap.get(key));
    }
  }

  return a_sValue;
} // vsStringReplaceHTMLSymbols



export function vsStringRemoveSpecialChars(a_sValue:string) :string {
  a_sValue = a_sValue.replace('<', '');
  a_sValue = a_sValue.replace('>', '');
  a_sValue = a_sValue.replace(';', '');

  return a_sValue;
} // vsStringRemoveHTMLSymbols

export function crmEMailAddressRemoveSpecialChars(a_sValue:string) :string {
  a_sValue = vsStringRemoveSpecialChars(a_sValue);
  a_sValue = a_sValue.replace(' ', '');

  return a_sValue;
} // vsStringRemoveSpecialEMailChars



export function arrayToString(arr: string[]): string {
  if (arr != null) {
    let s: string = '';

    for (let i = 0; i < arr.length; i++) {
      if (vsStringHasValue(s)) {
        s = s + ';' + arr[i];
      } else {
        s = arr[i];
      }
    }
    return s;
  } else {
    return '';
  }
} // arrayToString()



export function stringToArray(a_sText: string): string[] {

  let result: string[] = [];
  if (this.vsStringHasValue(a_sText)) {

    let prev: string[] = a_sText.split(';');
    prev.forEach((element) => {
      vsStringRemoveSpecialChars(element);
      result.push(element);
    });
  }

  return result;
}



export function vsRetrieveRecordByPKValue(a_Lookup: any[], a_sPKValue: string): any[] {
  let search = a_Lookup.map(el => el.PKValue);
  let i      = search.indexOf(a_sPKValue);
  return a_Lookup[i];
} // vsRetrieveRecordByPKValue



export function vsDateToVsDate(a_sDate: string): any {
  let result: any = null;

  if (vsStringIsNotNull(a_sDate))  {
    result = a_sDate.substring(6,10) + a_sDate.substring(3,5) + a_sDate.substring(0,2);
  }
  return result;
} // vsDateToVsDate



export function vsStrPadLeft(a_sValue: string, a_sPadValue: string, a_iLength: number): any {
  let sRetVal: string = a_sValue;
  let iLength: number = Math.max(a_iLength, 0);

  if (a_sPadValue.length > 0) {
    while (sRetVal.length < iLength) {
      sRetVal = a_sPadValue + sRetVal;
      if (sRetVal.length > iLength) {
        sRetVal = VSStrRight(sRetVal, iLength);
      }
    }
  }
  return sRetVal;
} // vsStrPadLeft



export function VSStrRight(a_sValue: string, a_iCount: number): string {
  let result: string;
  if (a_iCount > 0) {
    if (a_iCount <= a_sValue.length) {
      result = a_sValue.substring(a_sValue.length - a_iCount, 10000);
    } else {
      result = a_sValue;
    }
  } else {
    result = '';
  }
  return result;
} // VSStrRight


export const sortArrayOfObjects = <T>(data: T[], keyToSort: keyof T, direction: 'ascending' | 'descending' | 'none') => {
  if (direction === 'none') {
    return data
  }
  let result : number;
  const compare = (objectA: T, objectB: T) => {
    const A =  objectA[keyToSort]
    const B =  objectB[keyToSort]

    const valueA = vsStrPadLeft(JSON.stringify(A), '0', 10).replace('"', '');
    const valueB = vsStrPadLeft(JSON.stringify(B), '0', 10).replace('"', '');

    if (valueA === valueB) {
      result = 0
    }

    if (valueA > valueB) {
      direction === 'ascending' ? result =1 : result = -1
    } else {
      direction === 'ascending' ? result = -1 : result = 1
    }

    return result;
  }

  return data.slice().sort(compare)
} // sortArrayOfObjects


export class TModuleVars {
  private sVarBeg: string = '#';
  private sVarEnd: string = '#';
  private FieldMappings: any[] = [
          {PKValue: "Vorf_Nr",                    Name: this.sVarBeg + "Ticket_Nr"                + this.sVarEnd  , Type:"ftString"}
        , {PKValue: "Vorf_Datum",                 Name: this.sVarBeg + "Ticket_Datum"             + this.sVarEnd   , Type:"ftDate"}
        , {PKValue: "Vorf_Uhrzeit",               Name: this.sVarBeg + "Ticket_Uhrzeit"           + this.sVarEnd   , Type:"ftTime"}
        , {PKValue: "Vorf_Eingang_Datum",         Name: this.sVarBeg + "Ticket_Eingang_Datum"     + this.sVarEnd   , Type:"ftDate"}
        , {PKValue: "Vorf_Eingang_Uhrzeit",       Name: this.sVarBeg + "Ticket_Eingang_Uhrzeit"   + this.sVarEnd   , Type:"ftTime"}
        , {PKValue: "Thema_Name_HPath",           Name: this.sVarBeg + "Ticket_Thema"             + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_Titel",                 Name: this.sVarBeg + "Ticket_Betreff"           + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_Beschreib",             Name: this.sVarBeg + "Ticket_Beschreibung"      + this.sVarEnd   , Type:"ftString"}

        , {PKValue: "Vorf_Linie_Nr",              Name: this.sVarBeg + "Linie_Nr"                 + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_HSt_Start_Name",        Name: this.sVarBeg + "Haltestelle_Start"        + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_HSt_Ziel_Name",         Name: this.sVarBeg + "Haltestelle_Ziel"         + this.sVarEnd   , Type:"ftString"}

        , {PKValue: "Kontakt_Name1",              Name: this.sVarBeg + "Reporter_Nachname"        + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Kontakt_Name2",              Name: this.sVarBeg + "Reporter_Vorname"         + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Adr_Strasse",                Name: this.sVarBeg + "Reporter_Straße"          + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Adr_HausNr",                 Name: this.sVarBeg + "Reporter_HausNr"          + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Adr_PLZ",                    Name: this.sVarBeg + "Reporter_PLZ"             + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Adr_Ort",                    Name: this.sVarBeg + "Reporter_Wohnort"         + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Adr_co_Name",                Name: this.sVarBeg + "Reporter_CO_Name"         + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Anrede_Kuerzel",             Name: this.sVarBeg + "Reporter_Anrede"          + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Titel_Kuerzel",              Name: this.sVarBeg + "Reporter_Titel"           + this.sVarEnd   , Type:"ftString"}

        , {PKValue: "Phone",                      Name: this.sVarBeg + "Reporter_Telefon"         + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Mobile",                     Name: this.sVarBeg + "Reporter_MobilNr"         + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "EMail",                      Name: this.sVarBeg + "Reporter_EMail"           + this.sVarEnd   , Type:"ftString"}

        , {PKValue: "Kontakt_Geb_Datum",          Name: this.sVarBeg + "Reporter_GebDatum"        + this.sVarEnd   , Type:"ftDate"}

        , {PKValue: "Vorf_Ausb_Azubi_Name1",      Name: this.sVarBeg + "Schüler_Nachname"         + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_Ausb_Azubi_Name2",      Name: this.sVarBeg + "Schüler_Vorname"          + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_Ausb_Staett_Name",      Name: this.sVarBeg + "Schule_Name"              + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_Ausb_SchKlasse",        Name: this.sVarBeg + "Schüler_Klasse"           + this.sVarEnd   , Type:"ftString"}
        , {PKValue: "Vorf_Ausb_SchKlasse_Zusatz", Name: this.sVarBeg + "Schüler_Klassenzusatz"    + this.sVarEnd   , Type:"ftString"}

        ];

  private dataVorf:   any;
  private FieldList:  any[];

  public  VarList: TvsDataSet = new TvsDataSet();

  constructor(a_dataVorf: any) {
    this.dataVorf = a_dataVorf;

    this.VarList.Data       = this.FieldMappings; //this.FieldMappings.map(el => el.Name);
    this.VarList.FieldDefs  = [ {  Name: "Name", Type: "ftString", Caption: "Variable", Size: "150", Sort: false, Alignment:"alLeft" }];
    this.FieldList = this.FieldMappings.map(el => el.Name);

  }

  // Sucht nach dem Namen im Container und gibt den Wert des FieldNames zurück
  private RetrieveValue(a_Name: string): string {
    let i = this.FieldList.indexOf(a_Name);
    if (i > -1) {

      let sErg: string;

      switch (this.FieldMappings[i].Type) {
        case "ftString": { sErg = this.dataVorf[this.FieldMappings[i].PKValue]; }
        break;
        case "ftDate": { sErg =   ISO8061ToDateStr(this.dataVorf[this.FieldMappings[i].PKValue]);}
        break;
        case "ftTime": { sErg =   ISO8061ToTimeStr(this.dataVorf[this.FieldMappings[i].PKValue]);}
        break;
        default: {}
        break;
      }


      return sErg;
    }
    else {return ''};
  }

  public ExtractVarFromContent(a_sContent: string) : string {
    let sContentNew = a_sContent;

    this.FieldMappings.forEach(element => {

      sContentNew = sContentNew.replace(new RegExp(element.Name, 'g'), this.RetrieveValue(element.Name));
    });

    return sContentNew;
  }
} // TModuleVars


export class TModuleSchoolVars {
  private sVarBeg: string = '#';
  private sVarEnd: string = '#';
  private FieldMappings: any[] = [
            {PKValue: "Azubi_Nr",                   Name: this.sVarBeg + "Azubi_Nr"                     + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "SchuleAntr_Name",            Name: this.sVarBeg + "SchuleAntr_Name"              + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "SchuleAntr_Ort",             Name: this.sVarBeg + "SchuleAntr_Ort"               + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "SchuleZust_Name",            Name: this.sVarBeg + "SchuleZust_Name"              + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "SchuleZust_Ort",             Name: this.sVarBeg + "SchuleZust_Ort"               + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_Nachname",             Name: this.sVarBeg + "Azubi_Nachname"               + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_Vorname",              Name: this.sVarBeg + "Azubi_Vorname"                + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_PLZ",                  Name: this.sVarBeg + "Azubi_PLZ"                    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_Ort",                  Name: this.sVarBeg + "Azubi_Ort"                    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_Ortsteil",             Name: this.sVarBeg + "Azubi_Ortsteil"               + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_Strasse",              Name: this.sVarBeg + "Azubi_Strasse"                + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Azubi_HausNr",               Name: this.sVarBeg + "Azubi_HausNr"                 + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Sachbearb_Name",             Name: this.sVarBeg + "Sachbearb_Name"               + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Sachbearb_Tel",              Name: this.sVarBeg + "Sachbearb_Tel"                + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Sachbearb_Fax",              Name: this.sVarBeg + "Sachbearb_Fax"                + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Sachbearb_EMail",            Name: this.sVarBeg + "Sachbearb_EMail"              + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Sachbearb_Name_Komplett",    Name: this.sVarBeg + "Sachbearb_Name_Komplett"      + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Datum_Guelt_Beg",       Name: this.sVarBeg + "Antr_Datum_Guelt_Beg"         + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "GesVertr_Anrede_Brief",      Name: this.sVarBeg + "GesVertr_Anrede_Brief"        + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "GesVertr_Anrede_Brief_Ohne_Vorname", Name: this.sVarBeg + "GesVertr_Anrede_Brief_Ohne_Vorname"    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Schuljahr",                  Name: this.sVarBeg + "Schuljahr"                    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Nr",                    Name: this.sVarBeg + "Antr_Nr"                      + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Datum_Guelt_End",       Name: this.sVarBeg + "Antr_Datum_Guelt_End"         + this.sVarEnd   , Type:"ftDate"}
          , {PKValue: "Azubi_Geb_Datum",            Name: this.sVarBeg + "Azubi_Geb_Datum"              + this.sVarEnd   , Type:"ftDate"}
          , {PKValue: "Antr_Klasse_Beg_Nr",         Name: this.sVarBeg + "Antr_Klasse_Beg_Nr"           + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Datum_Antrag",          Name: this.sVarBeg + "Antr_Datum_Antrag"            + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "SchuleAntr_Nr",              Name: this.sVarBeg + "SchuleAntr_Nr"                + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Bescheid_Datum",        Name: this.sVarBeg + "Antr_Bescheid_Datum"          + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Klasse_Beg_Zusatz",     Name: this.sVarBeg + "Antr_Klasse_Beg_Zusatz"       + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Antr_Kost_Ueber_Datum_Beg",  Name: this.sVarBeg + "Antr_Kostenübernahme_ab"      + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Freitext1",                  Name: this.sVarBeg + "Freitext1"                    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Freitext2",                  Name: this.sVarBeg + "Freitext2"                    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Freitext3",                  Name: this.sVarBeg + "Freitext3"                    + this.sVarEnd   , Type:"ftString"}
          , {PKValue: "Freitext4",                  Name: this.sVarBeg + "Freitext4"                    + this.sVarEnd   , Type:"ftString"}


        ];

  private FieldList:  any[];
  public  VarList: TvsDataSet = new TvsDataSet();

  constructor() {

    this.VarList.Data       = this.FieldMappings; //this.FieldMappings.map(el => el.Name);
    this.VarList.FieldDefs  = [ {  Name: "Name", Type: "ftString", Caption: "Variable", Size: "280", Sort: false, Alignment:"alLeft" }];
    this.FieldList = this.FieldMappings.map(el => el.Name);

  }
} // TModuleVars
