//--------------------------------------------------------------------------------------------------
//  AMIS7core
//
//  Domain:   Elternportal
//  Title:    Nachrichtenliste
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-06-15  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit
        , ComponentFactoryResolver
        , ViewChild}                        from '@angular/core';

import { Router }                           from '@angular/router';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';

// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import { TvsDataObject }                    from '@vsLib/vsDataObject';
import { TvsViewData }                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsViewDataList                  from '@vsLib/View/components/vsViewDataList/vsViewDataList.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import { TvsTableComponent }                from '@vsLib/Components/vsTable/vsTable.component';
import * as vsTable                         from '@vsLib/Components/vsTable/vsTable.component';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


type TranslationMessageItemsType = {
  severity: string;
  summary: string;
  detail: string;
}



@Component({
  selector:       'schoolViewNachrichtenList',
  templateUrl:  './schoolViewNachrichtenList.component.html',
  styleUrls:   ['./schoolViewNachrichtenList.component.scss']
})
export class TschoolViewNachrichtenList extends vsViewDataList.TvsViewDataList implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  public      filterVorfID:                   string;

  public      filterVorgID:                   string;

  public      MenuUser:                       MenuItem[];           // PrimeNG
  public      dataObject:                     TvsDataObject              = new TvsDataObject();

  public      translationMessageItems:        TranslationMessageItemsType[]                          = [];

  public      antrDatum:                      string;
  public      antrAzubiName:                  string;

  public      secondDataSet:                  any[];

  @ViewChild('table') table: TvsTableComponent;


  //====== Constructor

  constructor(protected router:                     Router
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService     // [ngx-translate]
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected componentFactoryResolver:   ComponentFactoryResolver
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Anträge';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = "crmDODataVorg";
    this.ViewProperties.QueryFields     = [];
    this.ViewProperties.DOMain.KennFieldDefs = false;

  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    this.HintVisibleFilterRequired = false;
    this.FilterRequired            = false;


    this.filterVorfID = this.dataModel.antragCRMTicketID;
    this.antrAzubiName = this.dataModel.antrag?.xAzubi_Name;

    this.DataFilter.ItemsClear();

    // let item = this.DataFilter.ItemAdd('Filter Vorfall-ID');
    // item.CriteriaAdd('Vorf_ID', this.filterVorfID);


    // let item2 = this.DataFilter.ItemAdd('Filter Visibility = Public');
    // item2.CriteriaAdd('Vorg_ID_Visibility', 'PUBLIC');
    //Einschränkung sollte später noch auf Kommunikation und Dokumente gesetzt werden
    // let item2 = this.DataFilter.ItemAdd('Filter keine Notiz');
    // item2.CriteriaAdd('Vorg_ID_Art[ne]', 'NOTIZ');
    // let item3 = this.DataFilter.ItemAdd('Filter keine Email');
    // item3.CriteriaAdd('Vorg_ID_Art[ne]', 'EMAIL');
    // //$REV MP 2023-05-25: Filterungen zum Ausschluss weiterer Kommunikationsarten, nur Art:Web-Portal sollte für Web-Portal-Kommunikation zugelassen sein.
    // let item4 = this.DataFilter.ItemAdd('Filter keine Persönliche Nachricht');
    // item4.CriteriaAdd('Vorg_ID_Komm_Art[ne]', 'PERS');
    // let item5 = this.DataFilter.ItemAdd('Filter keine Telefonische Nachricht');
    // item5.CriteriaAdd('Vorg_ID_Komm_Art[ne]', 'TEL');
    // let item6 = this.DataFilter.ItemAdd('Filter keine Fax Nachricht');
    // item6.CriteriaAdd('Vorg_ID_Komm_Art[ne]', 'FAX');
    // let item7 = this.DataFilter.ItemAdd('Filter keine Postalische Nachricht');
    // item7.CriteriaAdd('Vorg_ID_Komm_Art[ne]', 'POST');

    //Zu Testzwecken mit einem Fall gefüllt der mehrere Vorgänge hat
    // item.CriteriaAdd('Vorf_ID', 'B2F1C34CA1AD47F8BCBCBDA547E3A1BB');



//GDS-Aufruf 15 [OK] crmDODataVorgPortal
// console.log('GDS_VorgPortal Vorf_ID, Visib = PUBLIC');
// this.HttpServiceComponent.dataGet('crmDODataVorgPortal', false, [], this.DataFilter)
    this.HttpServiceComponent.HttpServiceGetRequest('school/data/vorgPortal', '&Vorf_ID='+this.filterVorfID)
    .subscribe((data: any) => {
      // console.log('GDS_VorgPortal 15 [OK] Vorf_ID, Visib = PUBLIC');
      this.dataObject.Dataset.Data =  data['Dataset'].Data;

      //Zweite Anfrage um auch Dokumente anzuzeigen, werden ansonsten durch Filterung nach Kommunikationsart ausgeschlossen.
      // this.DataFilter.ItemsClear();
      // let item = this.DataFilter.ItemAdd('Filter Vorfall-ID');
      // item.CriteriaAdd('Vorf_ID', this.filterVorfID);
      //Filterung Dokumente auskommentiert, Wird in Zukunft alles nach visibility gefiltert.
      // let item2 = this.DataFilter.ItemAdd('Filter Vorg_ID_Art = DOK');
      // item2.CriteriaAdd('Vorg_ID_Art', 'DOK');

      //Filter nach visibility einbauen

      // this.HttpServiceComponent.dataGet('crmDODataVorg', false, [], this.DataFilter).subscribe((data: any) => {
      //   this.secondDataSet = data['Dataset'].Data;

        // Combine the first and second data sets
        // this.dataObject.Dataset.Data = [...this.dataObject.Dataset.Data, ...this.secondDataSet];

        // Automatisches Auswählen des ersten Datensatzes
        this.table.dataSelected = this.dataObject.Dataset.Data[0];

        this.initialiseTable(this.dataObject);
      // });
    });

    this.MenuUser = [
        {label: 'Mein Profil',    icon: 'pi pi-fw pi-user',       command: event => {this.mnuUserMyProfile_OnClick()}}
      , {label: 'Einstellungen',  icon: 'pi pi-fw pi-cog',        command: event => {this.mnuUserConfig_OnClick()}}
      , {separator: true}
      , {label: 'Abmelden',       icon: 'pi pi-fw pi-power-off',  command: event => {this.actionLogout()}}
    ] // User Menu

    this.translationMessageItems = [
      {severity:'success',  summary: 'Info', detail: 'messages.message_sent_confirmation'}
    ];


    this.initialiseTable(this.dataObject);


  } // ngOnInit

  ngAfterViewInit() {

    this.antrDatum = vsCommon.ISO8061ToDateStr(this.dataModel.antrag.Antr_Datum_Antrag);

    //Da das NachrichtenEdit nach Absenden wieder direkt zur NachrichtenListe weiterleitet soll die Toastmessage zur Bestätigung hier im File verschickt werden.
    if(this.dataModel.KennMessageSent)  {

      // ToDo: MP Messages übersetzen

      // this.messageService.add({severity: 'success',  summary: 'Info',  detail: 'Ihre Nachricht wurde verschickt'});

      this.translationMessageItems.map((item) => {
        //if-Abfrage falls noch andere Message-Items hinzukommen
        if (item.detail === 'messages.message_sent_confirmation') {
          this.translate.get(item.detail).subscribe((res)=>{
            this.messageService.add(
               {severity: item.severity,
                icon: item.summary,
                detail: res
              });
          });
        }
      });


      this.dataModel.KennMessageSent = false;
    }
  }


  public initialiseTable(dataObject: TvsDataObject): void {

        dataObject.Dataset.FieldDefs = [
              {Name: "Vorg_Kenn_Eingehend",     Type: "ftInOutEP",       Caption: "messages.columns.in-out",            Size: "160",    Sort: false,   Alignment: vsCommon.TvsAlignment.alCenter }
            , {Name: "Vorg_Kenn_Komm_Gelesen",  Type: "ftReadUnread",    Caption: "messages.columns.read",              Size: "100",    Sort: false,   Alignment: vsCommon.TvsAlignment.alCenter }
            , {Name: "Vorg_Datum",              Type: "ftDate",          Caption: "messages.columns.date",              Size: "100",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            // , {Name: "Vorg_Uhrzeit",            Type: "ftTime",          Caption: "messages.columns.time",          Size: "80",    Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            , {Name: "xDisplayArt",             Type: "ftString",        Caption: "messages.columns.type",              Size: "120",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }
            // , {Name: "Vorg_Kenn_Eingehend",  Type: "ftBoolean",  Caption: "Eingehend?",    Size: "80",    Sort: false,   Alignment: vsCommon.TvsAlignment.alCenter }
            , {Name: "Vorg_Name",               Type: "ftString",        Caption: "messages.columns.title",             Size: "400",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft   }

          ];
    }

    //$REV MP 2023-02-21: Muss eingefügt werden um den Standard der vsViewDataList zu überschreiben, führt sonst zu Fehlern mit den nicht vorhandenen Toolbaritems.
    public doTableRowSelect(args: vsTable.TvsTableComponentEventArgs) {

      this.dataModel.vorgID             = args.Data.Vorg_ID;
      this.antrAzubiName                = args.Data.Azubi_Nachname +', '+ args.Data.Azubi_Vorname;
      this.dataModel.antrag.xAzubi_Name = args.Data.Azubi_Nachname +', '+ args.Data.Azubi_Vorname;

    } // doTableRowSelect



  public actionNewMessage(): void {

    this.router.navigate(['/messageedit']);
    this.dataModel.KennMessageEdit = false;
    //$REV MP 2023-03-09: Leeren der vorgID, wurde sonst beim anklicken einer Zeile über doTableRowSelect gefüllt.
    this.dataModel.vorgID = null;

  }


  public actionReadMessage(): void {

    // if(!this.table.dataSelected.Vorg_ID) {
    //   console.log('Keine Vorg_ID vorhanden');
    // }

    this.dataModel.vorgID = this.table.dataSelected?.Vorg_ID;

    this.dataModel.KennMessageEdit = true;
    this.router.navigate(['/messageedit']);

  }


  public actionStepPrev(): void {

    this.router.navigate(['/main']);

  }


  // protected RetrieveFilter(): string {
  //   return '';
  // } // RetrieveFilter


  //------------------------------------------------------------------------------------------------
  // Method:  mnuUserMyProfile_OnClick
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: User Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuUserMyProfile_OnClick(): void {
    alert('Mein Benutzerprofil');
  } // mnuUserMyProfile_OnClick


  public mnuUserConfig_OnClick(): void {
    alert('Einstellungen');
  } // mnuUserConfig_OnClick


  public mnuUserLogout_OnClick(): void {
    this.globalResources.UserProfile.Logout();
  } // mnuUserLogout_OnClick


  // $Rev TC 2021-07-30
  //
  // Das wird von dem vorübergehenden zusätzlichem Button "Abmelden" aufgerufen.
  // Dieser Button ist momentan erforderlich, da das User Menu aktuell nicht funktioniert (wegen a href)
  public actionLogout(): void {
    this.globalResources.UserProfile.Logout();
    this.router.navigateByUrl('/home');
  } // actionLogout


} // schoolViewAntragList


