//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsDropdownSelect
//  Notes:  vsDropdownSelect: Component
//
//
//  H I S T O R Y
//
//  2021-03-22  MHauck  Genesis
//--------------------------------------------------------------------------------------------------

import {  Component, Input, Output
        , OnInit, AfterViewInit
        , EventEmitter
       }                                          from '@angular/core';



//
// TvsDropdownSelectEventArgs
//
// Event arguments for TvsDropdownSelectComponent events
//
export class TvsDropdownSelectEventArgs {

  public  readonly      Data:           any                         = null;

  constructor(a_data:  any
  ) {
    this.Data           = a_data;
  } // constructor

} // TvsDropdownSelectEventArgs



//
// TvsDropdownSelectComponent
//
// vsLib Wrapper für PrimeNG dropdown
//
@Component({
  selector:       'vsDropdownSelect',
  templateUrl:  './vsDropdownSelect.component.html',
  styleUrls:   ['./vsDropdownSelect.component.scss']
})
export class TvsDropdownSelectComponent implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PROTECTED)

  // ./.



  //====== Properties (PUBLIC)

  public      SelectedData:         any                       = [];

  @Input()    placeholder:          string                    = '';
                                                                          
  @Input()    LookupData:           any[];
  @Input()    LookupFieldDisplay:   string                    = null;   // Lookup-Feld für den anzuzeigenden Wert
  @Input()    LookupFieldPK:        string                    = null;   // Lookup-Feld für den Primary Key


  //---- Events

  @Output() public  OnItemSelect: EventEmitter<TvsDropdownSelectEventArgs>    = new EventEmitter<TvsDropdownSelectEventArgs>();



  //====== Constructor

  constructor() {
  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {
    console.log(this.constructor.name + '.ngOnInit():  LookupData         = ',  this.LookupData);
    console.log(this.constructor.name + '.ngOnInit():  LookupFieldDisplay = ',  this.LookupFieldDisplay);
  } // ngOnInit


  ngAfterViewInit(){
    console.log(this.constructor.name + '.ngAfterViewInit():  LookupData         = ',   this.LookupData);
    console.log(this.constructor.name + '.ngAfterViewInit():  LookupFieldDisplay = ',   this.LookupFieldDisplay);
  } // ngAfterViewInit



  //====== Methods: Event handler


  public onItemClick(){
    console.log(this.constructor.name + '.onItemClick():  SelectedData = ',  this.SelectedData);
    this.OnItemSelect.emit(new TvsDropdownSelectEventArgs(this.SelectedData));
  } // onItemClick


} // TvsDropdownComponent


