<!-- $Rev MP 2021-09-21 Property NoLabel sowie labelWidth hinzugefügt (NoLabel=true). Bei labelWidth muss die Einheit mit dazugeschrieben werden. (labelWidth="200px") -->

<div class="vsInputMemo">
  <div class="row vsMb-2" *ngIf="!NoLabel">
    <label class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col">

      <div *ngIf="ReadOnly=='true'">
        <textarea  #charCounter pInputTextarea formControlName={{FrmCtlName}} placeholder="{{placeholder}}" 
                  [rows]=Rows readonly (ngModelChange)='handleCharCount($event)' class={{Class}} 
                  id="input-{{FrmCtlName}}"
        ></textarea>
        <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
      </div>

      <div *ngIf="ReadOnly=='false'">

        <textarea #charCounter *ngIf="Disabled=='true'" pInputTextarea formControlName={{FrmCtlName}} placeholder="{{placeholder}}" [rows]=Rows class={{InvalidCSSClass}}{{Class}}
        disabled (ngModelChange)='handleCharCount($event)' id="input-{{FrmCtlName}}"></textarea>
        <div *ngIf="Disabled=='true'">
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>

        <textarea #charCounter *ngIf="Disabled=='false'" maxLength="{{maxLength}}" pInputTextarea formControlName={{FrmCtlName}} placeholder="{{placeholder}}" [rows]=Rows class={{InvalidCSSClass}}{{Class}}
        (ngModelChange)='handleCharCount($event)' id="input-{{FrmCtlName}}"></textarea>
        <div *ngIf="Disabled=='false'">
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>

      </div>

    </div>
  </div>

  <div class="row vsMb-2" *ngIf="NoLabel">
    <div class="col">

      <div *ngIf="ReadOnly=='true'">
        <textarea #charCounter pInputTextarea formControlName={{FrmCtlName}} placeholder="{{placeholder}}" [rows]=Rows
        readonly (ngModelChange)='handleCharCount($event)' class={{Class}} aria-label=ariaLabel></textarea>
        <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
      </div>

      <div *ngIf="ReadOnly=='false'">
        <textarea #charCounter *ngIf="Disabled=='true'" pInputTextarea formControlName={{FrmCtlName}} placeholder="{{placeholder}}" [rows]=Rows class={{InvalidCSSClass}}{{Class}} 
        disabled (ngModelChange)='handleCharCount($event)' aria-label=ariaLabel></textarea>
        <div *ngIf="Disabled=='true'">
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>

        <textarea #charCounter *ngIf="Disabled=='false'" maxLength="{{maxLength}}" pInputTextarea formControlName={{FrmCtlName}} placeholder="{{placeholder}}" [rows]=Rows class={{InvalidCSSClass}}{{Class}} maxLength="20"
        (ngModelChange)='handleCharCount($event)' aria-label=ariaLabel></textarea>
        <div *ngIf="Disabled=='false'">
          <div *ngIf="counter=='true'" class="vsCounter">{{inputCharsLeft}}: {{availChars}}</div>
        </div>
      </div>

    </div>
  </div>

</div>


