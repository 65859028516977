//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup
       , FormControl
       , Validators }                       from '@angular/forms';

// [ngx-translate]
import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

import {  forkJoin }                        from 'rxjs';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import {  DialogService
        , DynamicDialogRef
        , DynamicDialogConfig }       from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsViewDataEdit                  from '@vsLib/View/components/vsViewDataEdit/vsViewDataEdit.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType
        ,TvsHTTPMethod
        ,TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import { TvsDataFilter }                    from '@vsLib/vsDataFilter';
import { TvsDataObject }                    from '@vsLib/vsDataObject';


import { TSearchDlgData, TvsSearchDlg } from '@vsLib/Components/vsSearchDlg/vsSearchDlg.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';


type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}


type TranslationHeadingType = {
  name: string;
  type: string;
  translationCaption: string;
  size: string;
  sort: boolean;
  alignment: any;
}



@Component({
  selector:       'sysViewProfileData',
  templateUrl:  './sysViewProfileData.component.html',
  // styleUrls:   ['./sysViewProfileData.component.scss']
  providers:  [DialogService, DynamicDialogRef, DynamicDialogConfig]
})
export class TsysViewProfileData extends vsViewDataEdit.TvsViewDataEdit implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup                     = new FormGroup({});

  public      Anrede:                       any[];

  public      lookupAnrede:                 any[];
  public      lookupTitel:                  any[];
  public      lookupWebAccount:             any[];
  public      lookupKontakt:                any[];
  public      lookupAdresse:                any[];
  public      lookupAdresseFiltered:        any[];

  public      displayDialogError:           boolean                         = false;

  public      refSearchDlg:                 DynamicDialogRef;

  public      translationDialogItems:       TranslationDialogItemsType[]    = [];

  public      translationHeadingItems:      TranslationHeadingType[]        = [];

  // public      ibanValid:                    boolean                         = true;

  public      KennStrassenVerz:             boolean                         = false;

  public      KennAdrNotFoundProfile:       boolean                         = false;

  public      searchDlgData: TSearchDlgData = new TSearchDlgData(
    'adrDoKatBank',
    [
        { Name: "xBank_Info",      Type: "ftString", Caption: "Name der Bank",      Size: "600",   Sort: false,   Alignment: vsCommon.TvsAlignment.alLeft }
    ], true, null, this.DataFilter, false, true
  );


  // public DOMain: TvsDataObject = new TvsDataObject();

  //====== Constructor

  constructor(protected router:                     Router
            , protected globalResources:            vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    ref:                        DynamicDialogRef
            , public    config:                     DynamicDialogConfig
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , public    dialogService:              DialogService
            , public    translate:                  TranslateService     // [ngx-translate]
) {
  super(router, globalResources, HttpServiceComponent, ref, config, dialogService);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Mein Profil';

    this.ViewProperties.ProgFuncID      =  null;
    this.ViewProperties.DataSourceID    = 'adrDOStammKontaktList';
    this.ViewProperties.QueryFields     = [];

    this.translationHeadingItems = [
      { name: 'xBank_Info',      type: 'ftString',   translationCaption: 'dialog.text_name_bank',     size: 'width:600px',    sort: false,   alignment: vsCommon.TvsAlignment.alLeft }
    ];


    //$REV MP 2023-08-23: KennStrassenVerz kann in der app.config.json auf true oder false gesetzt werden
    if(vsCommon.vsVarAssigend(this.dataModel.KennStrassenVerz)) {
      this.KennStrassenVerz = this.dataModel.KennStrassenVerz;
    }

    // this.HttpServiceComponent.dataGet('comDoStammStrVerzKomplett')
    // .subscribe(data => {
    // this.lookupAdresse =  data['Dataset'].Data;

    // });


  } // constructor

  //====== Methods: NG event handler


  ngOnInit(): void {

    // console.log('this.globalResources.WebAccProfile' , this.globalResources.WebAccProfile);

    this.editForm.addControl('Profile_ID_Anrede',     new FormControl(null));

    this.editForm.addControl('Profile_ID_Titel',      new FormControl(null));

    this.editForm.addControl('Profile_Vorname',       new FormControl(null));
    this.editForm.addControl('Profile_Nachname',      new FormControl(null));
    this.editForm.addControl('Profile_Geb_Datum',     new FormControl(null));
    this.editForm.addControl('Profile_PLZ',           new FormControl(null));
    this.editForm.addControl('Profile_Ort',           new FormControl(null));
    this.editForm.addControl('Profile_Strasse',       new FormControl(null));
    this.editForm.addControl('Profile_Ortsteil',      new FormControl(null));
    this.editForm.addControl('Profile_HausNr',        new FormControl(null));
    this.editForm.addControl('Profile_Adr_Zusatz',    new FormControl(null));
    this.editForm.addControl('Profile_Phone',         new FormControl(null));
    this.editForm.addControl('Profile_eMail',         new FormControl(null));
    this.editForm.addControl('Profile_Mobile',        new FormControl(null));

    //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
    // this.editForm.addControl('Kto_Inh',               new FormControl(null));
    // this.editForm.addControl('IBAN',                  new FormControl(null));
    // this.editForm.addControl('Bank_Name',             new FormControl(null));
    // this.editForm.addControl('Bank_ID_Bank',          new FormControl(null));
    // this.editForm.addControl('Bank_BIC',              new FormControl(null));

    if(this.KennStrassenVerz) {
      this.editForm.addControl('AntrStell_StrassenVerz',       new FormControl(this.dataModel?.antrStell_StrVerz));
      this.editForm.addControl('KennAdrNotFoundProfile',       new FormControl(this.KennAdrNotFoundProfile));
    }

    this.dataDisplay();

  } // ngOnInit


    //$REV MP 2022-08-05: dataLoad mit forkJoin eingebaut, da es immer wieder dazu kam das lookupDatenmengen nicht rechtzeitig verfügbar waren.
    async dataLoad() {

      const adrDoKatPersAnrede = this.HttpServiceComponent.dataGet('adrDoKatPersAnrede', false, [], null, TvsGetType.gtNormal, false);
      const adrDoKatPersTitel = this.HttpServiceComponent.dataGet('adrDoKatPersTitel', false, [], null, TvsGetType.gtNormal, false);

      console.log('WebAccProfile.KontaktID', this.globalResources.WebAccProfile.KontaktID);

      let DataFilter: TvsDataFilter = new TvsDataFilter();
      DataFilter.ItemsClear();
      // let item = DataFilter.ItemAdd('Filter Kontakt-ID');
      // item.CriteriaAdd('Acc_ID_Kontakt', this.globalResources.WebAccProfile.KontaktID);
//GDS-Aufruf 16 [OK]  DataWebDoStammUserAccount
// console.log('GDS_WebUserAccount Acc_ID_Kontakt');
//const webDoStammUserAccount = this.HttpServiceComponent.dataGet('webDoStammUserAccount', true, [], DataFilter, TvsGetType.gtNormal, false);
const webDoStammUserAccountNoPWD = this.HttpServiceComponent.HttpServiceGetRequest('school/data/webUserAccount', '');
// console.log('GDS_WebUserAccount 16 [OK] Acc_ID_Kontakt');

      DataFilter.ItemsClear();
      // item = DataFilter.ItemAdd('Filter Kontakt-ID');
      // item.CriteriaAdd('Kontakt_ID', this.globalResources.WebAccProfile.KontaktID);
//GDS-Aufruf 17 [OK] adrDOStammKontaktList
      //const adrDOStammKontaktList = this.HttpServiceComponent.dataGet('adrDOStammKontaktList', true, [], DataFilter, TvsGetType.gtNormal, false);
      // console.log('GDS_Kontakt Kontakt_ID');
      const adrDOStammKontaktList = this.HttpServiceComponent.HttpServiceGetRequest('school/data/kontakt', '')
      // console.log('GDS_Kontakt 17 [OK] Kontakt_ID');
      //$REV MP 2023-08-24: Straßenverzeichnis hinzugefügt
      DataFilter.ItemsClear();

      const comDoStammStrVerzKomplett = this.HttpServiceComponent.dataGet('comDoStammStrVerzKomplett', true, [], DataFilter, TvsGetType.gtNormal, false);

      forkJoin([adrDoKatPersAnrede, adrDoKatPersTitel, webDoStammUserAccountNoPWD, adrDOStammKontaktList, comDoStammStrVerzKomplett]).subscribe((results: any) => {
        this.lookupAnrede        = results[0]['Dataset'].Data;
        this.lookupTitel         = results[1]['Dataset'].Data;
        this.lookupWebAccount    = results[2]['Dataset'].Data;
        this.lookupKontakt       = results[3]['Dataset'].Data;
        this.lookupAdresse       = results[4]['Dataset'].Data;

        Object.assign(this.DOMain.Dataset, results[3]['Dataset']);

        this.ViewProperties.SubjInitLoad.next();
      });

  } // dataLoad


  dataDisplay(): void {

    this.dataLoad().then(() => {

      this.ViewProperties.SubjInitLoad.subscribe((args) => {

          if (vsCommon.vsStringHasValue(this.DOMain.Dataset.Data[0]?.Kontakt_ID_Anrede) && vsCommon.vsStringIsNotNull(this.DOMain.Dataset.Data[0]?.Kontakt_ID_Anrede)) {
            this.editForm.controls['Profile_ID_Anrede'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.lookupAnrede, this.DOMain.Dataset.Data[0]?.Kontakt_ID_Anrede));
          }

          if (vsCommon.vsStringHasValue(this.DOMain.Dataset.Data[0]?.Kontakt_ID_Titel) && vsCommon.vsStringIsNotNull(this.DOMain.Dataset.Data[0]?.Kontakt_ID_Titel)) {
            this.editForm.controls['Profile_ID_Titel'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.lookupTitel, this.DOMain.Dataset.Data[0]?.Kontakt_ID_Titel));
          }

          //Vergleich der Daten mit dem Straßenverzeichnis
          if (vsCommon.vsStringHasValue(this.DOMain.Dataset.Data[0]?.Adr_PLZ)
          && vsCommon.vsStringIsNotNull(this.DOMain.Dataset.Data[0]?.Adr_Ort)
          && vsCommon.vsStringIsNotNull(this.DOMain.Dataset.Data[0]?.Adr_Ortsteil)
          && vsCommon.vsStringIsNotNull(this.DOMain.Dataset.Data[0]?.Adr_Strasse)
          && this.lookupAdresse.length > 0) {

            const filterCondition =
            `${this.DOMain.Dataset.Data[0]?.Adr_PLZ} | ` +
            `${this.DOMain.Dataset.Data[0]?.Adr_Ort} | ` +
            `${this.DOMain.Dataset.Data[0]?.Adr_Ortsteil} | ` +
            `${this.DOMain.Dataset.Data[0]?.Adr_Strasse}`;

          this.lookupAdresseFiltered = this.lookupAdresse.filter(data => {
            return data.xAnzeige === filterCondition;
          });

          //Wenn die gespeicherte Adresse nicht im Straßenverzeichnis gefunden werden kann, wird die Checkbox "Adresse ist im Straßenverzeichnis nicht vorhanden" automatisch aktiviert.
            if (this.lookupAdresseFiltered.length === 0 && this.KennStrassenVerz) {
              this.KennAdrNotFoundProfile = true;
              this.editForm.controls['KennAdrNotFoundProfile'].setValue(true);
            }
          //Füllen des Adressfeldes aus den gepeicherten Daten
          if(this.KennStrassenVerz) {
            this.editForm.controls['AntrStell_StrassenVerz'].setValue(this.lookupAdresseFiltered[0]);
          }

          }


          this.editForm.controls['Profile_Vorname'].setValue(this.DOMain.Dataset.Data[0]?.Kontakt_Name2);
          this.editForm.controls['Profile_Nachname'].setValue(this.DOMain.Dataset.Data[0]?.Kontakt_Name1);
          this.editForm.controls['Profile_Geb_Datum'].setValue(vsCommon.ISO8061ToDateStr(this.DOMain.Dataset.Data[0]?.Kontakt_Geb_Datum));
          this.editForm.controls['Profile_PLZ'].setValue(this.DOMain.Dataset.Data[0]?.Adr_PLZ);
          this.editForm.controls['Profile_Ort'].setValue(this.DOMain.Dataset.Data[0]?.Adr_Ort);
          this.editForm.controls['Profile_Ortsteil'].setValue(this.DOMain.Dataset.Data[0]?.Adr_Ortsteil);
          this.editForm.controls['Profile_Strasse'].setValue(this.DOMain.Dataset.Data[0]?.Adr_Strasse);
          this.editForm.controls['Profile_HausNr'].setValue(this.DOMain.Dataset.Data[0]?.Adr_HausNr);

          this.editForm.controls['Profile_Adr_Zusatz'].setValue(this.DOMain.Dataset.Data[0]?.Adr_Name_Zusatz_1);
          this.editForm.controls['Profile_Phone'].setValue(this.DOMain.Dataset.Data[0]?.Phone);
          this.editForm.controls['Profile_eMail'].setValue(this.DOMain.Dataset.Data[0]?.eMail);
          this.editForm.controls['Profile_Mobile'].setValue(this.DOMain.Dataset.Data[0]?.Mobile);

          //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
          // this.editForm.controls['Kto_Inh'].setValue(this.DOMain.Dataset.Data[0]?.Bank_Inhaber_Name);
          // this.editForm.controls['IBAN'].setValue(this.DOMain.Dataset.Data[0]?.Bank_IBAN);
          // this.editForm.controls['Bank_Name'].setValue(this.DOMain.Dataset.Data[0]?.Bank_Name);
          // this.editForm.controls['Bank_ID_Bank'].setValue(this.DOMain.Dataset.Data[0]?.Bank_ID_Bank);
          // this.editForm.controls['Bank_BIC'].setValue(this.DOMain.Dataset.Data[0]?.Bank_BIC)


          this.ViewProperties.SubjDisplay.next();
          this.ViewProperties.SubjDisplay.complete();
        })
    })
  }



  public actionSave(): boolean {

    let bOK:        boolean       = true;

    if (bOK) {

      this.editForm.get('Profile_ID_Anrede').setValidators(Validators.required);
      this.editForm.get('Profile_ID_Anrede').updateValueAndValidity();

      this.editForm.get('Profile_Vorname').setValidators(Validators.required);
      this.editForm.get('Profile_Vorname').updateValueAndValidity();

      this.editForm.get('Profile_Nachname').setValidators(Validators.required);
      this.editForm.get('Profile_Nachname').updateValueAndValidity();

      this.editForm.get('Profile_Geb_Datum').setValidators(Validators.required);
      this.editForm.get('Profile_Geb_Datum').updateValueAndValidity();

      this.editForm.get('Profile_PLZ').setValidators(Validators.required);
      this.editForm.get('Profile_PLZ').updateValueAndValidity();

      this.editForm.get('Profile_Ort').setValidators(Validators.required);
      this.editForm.get('Profile_Ort').updateValueAndValidity();

      this.editForm.get('Profile_Strasse').setValidators(Validators.required);
      this.editForm.get('Profile_Strasse').updateValueAndValidity();

      this.editForm.get('Profile_HausNr').setValidators(Validators.required);
      this.editForm.get('Profile_HausNr').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      this.dataSave();
      this.router.navigate(['main']);

    }

    return bOK;

  } // actionStepNext()


  public actionStepPrev(): void {
    // this.dataSave();
    this.router.navigate(['main']);
  }


  doBankSearch(event:  any) {

    this.DataFilter.ItemsClear();

    //Übersetzungen in TSearchDlgData pushen
    //Items initial leeren damit die übersetzten Items nicht dazu kommen.
    this.searchDlgData.FieldDefs = [];
    this.translationHeadingItems.map((item) => {
      this.translate.get(item.translationCaption).subscribe((res)=>{
        this.searchDlgData.FieldDefs.push(
              { Name:       item.name,
                Type:       item.type,
                Caption:    res,
                Size:       item.size,
                Sort:       item.sort,
                Alignment:  item.alignment,
              }
          );
      });
    });


  this.translationDialogItems = [
    { data:  this.searchDlgData,
      header: 'dialog.title_search_bank',
      styleClass: 'vsDialog vsFullscreenDialog',
      baseZIndex: 10000}
  ]

  this.translationDialogItems.map((item) => {
    this.translate.get(item.header).subscribe((res)=>{

        this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
          data:         item.data,
          header:       res,
          width:        item.width,
          styleClass:   item.styleClass,
          style:        item.style,
          baseZIndex:   item.baseZIndex

        });
      });
    });

    this.refSearchDlg.onClose.subscribe((Data: any) => {
      if (vsCommon.vsVarAssigend(Data)) {
        this.editForm.controls['Bank_ID_Bank'].setValue(Data.Bank_ID);
        // this.editForm.controls['Bank_BIC'].setValue(Data.Bank_BIC);
        this.editForm.controls['Bank_Name'].setValue(Data.Bank_Name);
        }
    });

  }

  //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
  // validateIBAN = () => {
  //   //TEST für IBAN-Check
  //   if(this.editForm.controls['IBAN'].value){
  //     this.HttpServiceComponent.HttpServiceGetRequest('invActionCheckIBAN', '&IBAN='+this.editForm.controls['IBAN'].value)
  //       .subscribe((response: any) => {

  //         this.editForm.controls['Bank_Name'].setValue(response.bankName);
  //         this.editForm.controls['Bank_ID_Bank'].setValue(response.bankID);
  //         this.editForm.controls['Bank_BIC'].setValue(response.bic);

  //         if(response.error.errCode) {
  //           this.ibanValid = false;
  //         }
  //         if(response.error.errCode === "") {
  //           this.ibanValid = true;
  //         }
  //       });//Ende HttpService
  //    }
  //    if(this.editForm.controls['IBAN'].value === '') {
  //     this.editForm.controls['Bank_BIC'].setValue('');
  //     this.editForm.controls['Bank_Name'].setValue('');
  //     this.editForm.controls['Bank_ID_Bank'].setValue('');
  //   }
  //   if(this.editForm.controls['Bank_Name'].value === "" && this.editForm.controls['IBAN'].value === "") {
  //     this.ibanValid = true;
  //   }
  // }



  dataSave(): boolean {

    let bOK = true;

    //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
    // if(!this.ibanValid) {
    //   this.messageService.add({severity: 'error',  summary: 'Info',  detail: 'IBAN ist nicht gültig. Bitte geben Sie eine gütlige IBAN an.'});
    // }

    // TODO: Titel lässt sich nicht über die gleiche Art füllen wie Anrede
    console.log('this.editForm.controls.Profile_ID_Titel.value' , this.editForm.controls['Profile_ID_Titel'].value);

    //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
    // if (bOK && this.ibanValid) {
    if (bOK) {

      if (vsCommon.vsStringHasValue(this.editForm.controls['Profile_ID_Anrede'].value?.Anrede_ID)) {
        this.DOMain.Dataset.Data[0].Kontakt_ID_Anrede = this.editForm.controls['Profile_ID_Anrede'].value?.Anrede_ID;
      }
      //Damit man die Anrede auch wieder leeren & speichern kann
      else {
        this.DOMain.Dataset.Data[0].Kontakt_ID_Anrede = "";
      }

      if (vsCommon.vsStringHasValue(this.editForm.controls['Profile_ID_Titel'].value?.Titel_ID)) {
        this.DOMain.Dataset.Data[0].Kontakt_ID_Titel = this.editForm.controls['Profile_ID_Titel'].value?.Titel_ID;
      }
      //Damit man den Titel auch wieder leeren & speichern kann
      else {
        this.DOMain.Dataset.Data[0].Kontakt_ID_Titel = "";
      }

      this.DOMain.Dataset.Data[0].Kontakt_Name2 = this.editForm.controls['Profile_Vorname'].value;
      this.DOMain.Dataset.Data[0].Kontakt_Name1 = this.editForm.controls['Profile_Nachname'].value;

      this.DOMain.Dataset.Data[0].Kontakt_Geb_Datum = vsCommon.DateStrToISO8061(this.editForm.controls['Profile_Geb_Datum'].value);

      this.DOMain.Dataset.Data[0].Adr_PLZ = this.editForm.controls['Profile_PLZ'].value;
      this.DOMain.Dataset.Data[0].Adr_Ort = this.editForm.controls['Profile_Ort'].value;
      this.DOMain.Dataset.Data[0].Adr_Ortsteil = this.editForm.controls['Profile_Ortsteil'].value;
      this.DOMain.Dataset.Data[0].Adr_Strasse = this.editForm.controls['Profile_Strasse'].value;
      this.DOMain.Dataset.Data[0].Adr_HausNr = this.editForm.controls['Profile_HausNr'].value;
      this.DOMain.Dataset.Data[0].Adr_Name_Zusatz_1 = this.editForm.controls['Profile_Adr_Zusatz'].value;
      this.DOMain.Dataset.Data[0].Phone = this.editForm.controls['Profile_Phone'].value;

      //Dieser Wert sollte gleichzeitig auch den Webuser-Accountnamen ersetzen?
      this.DOMain.Dataset.Data[0].eMail = this.editForm.controls['Profile_eMail'].value;
      this.DOMain.Dataset.Data[0].Mobile = this.editForm.controls['Profile_Mobile'].value;

      //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
      // this.DOMain.Dataset.Data[0].Bank_Inhaber_Name   = this.editForm.controls['Kto_Inh'].value;
      // this.DOMain.Dataset.Data[0].Bank_IBAN           = this.editForm.controls['IBAN'].value;
      // this.DOMain.Dataset.Data[0].Bank_Name           = this.editForm.controls['Bank_Name'].value;
      // this.DOMain.Dataset.Data[0].Bank_ID_Bank        = this.editForm.controls['Bank_ID_Bank'].value;
      // this.DOMain.Dataset.Data[0].Bank_BIC        = this.editForm.controls['Bank_BIC'].value;

      //TODO Muss laut DO noch gefüllt werden - Bankliste wie im adrViewKontaktEdit einbauen?
      // this.DOMain.Dataset.Data[0].Bank_ID_Bank = this.editForm.controls['Bank_Name'].value;

      this.HttpServiceComponent.dataUpdate(this.ViewProperties.DataSourceID,
      this.DOMain,
      this.DOMain.Dataset.Data[0].PKValue)
      .subscribe(
        dataPost => {
        },
          error => {
          console.log('Error Put-Request: ', error);
          // alert(  'Bei dem Put-Request ist ein Fehler aufgetreten:\n'
          // + error.error.Error.ErrCode + '\n'
          // + error.error.Error.ErrMsg + '\n\n'
          // + error.message );
          this.DOMain.IsRefreshing = false;
          // ToDo: MP Messages übersetzen
          this.messageService.add({severity: 'error',  summary: 'Info',  detail: 'Es ist ein Fehler aufgetreten'});
          },

          () => {
            this.messageService.add({severity: 'success',  summary: 'Info',  detail: 'Die Änderungen wurden gespeichert'});
            console.log('Put Request completed');

            // this.router.navigate(['messages']);
            }
        );
    }
      //$REV MP 2024-05-27: Kontodaten wegen Datenschutzbedenken entfernt.
      // return bOK && this.ibanValid;
      return bOK;

  }


  doSelectAdresse(data) {

    // this.dataModel.antrStell_StrVerz = data;
    console.log('data', data);

    if (vsCommon.vsStringHasValue(data.OrtPLZ_PLZ)) {
      this.editForm.controls['Profile_PLZ'].setValue(data.OrtPLZ_PLZ);
    }
    if (vsCommon.vsStringHasValue(data.Ortsteil_Name)) {
      this.editForm.controls['Profile_Ortsteil'].setValue(data.Ortsteil_Name);
    }
    if (vsCommon.vsStringHasValue(data.Ort_Name)) {
      this.editForm.controls['Profile_Ort'].setValue(data.Ort_Name);
    }
    if (vsCommon.vsStringHasValue(data.Str_Name)) {
      this.editForm.controls['Profile_Strasse'].setValue(data.Str_Name);
    }

  }



  doActivateManualInput() {

    //Freischalten der Input-Felder für die manuelle Eingabe, Wechsel von html-hidden zu visible
    if(this.editForm.controls['KennAdrNotFoundProfile'].value) {
      this.KennAdrNotFoundProfile = true;
    }

    if(!this.editForm.controls['KennAdrNotFoundProfile'].value) {
      this.KennAdrNotFoundProfile = false;
    }

  }



} // schoolViewAntragEdit_Student


