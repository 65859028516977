import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TvsGlobalResources } from './vsGlobalResources.service';
import { DOCUMENT } from   '@angular/common';
import { vsStringHasValue, vsVarAssigend } from '@vsLib/vsCommon';

// import { TranslateHttpLoader }                from '@ngx-translate/http-loader';


@Injectable()
export class AppConfigService {

  version:                     string;
  requestProtocol:             string;
  requestIPAddress:            string;
  requestPortNumber:           string;
  requestPath:                 string;
  requestAssetPath:            string;
  styles:                      string;

  KennUserImage:                 boolean;
  KennAntragIndividual:          boolean;
  KennGrundantragErstattung:     boolean;
  KennAntragErstattung:          boolean;
  KennAntragTicket:              boolean;
  KennAntragAzubi:               boolean;
  KennStrassenVerz:              boolean;
  //$REV MP 2023-09-25: Ausbildungsstättenangabe ist bei einigen Kunden nicht notwendig (VBK), bei anderen schon (SAMR)
  KennAusbStaettRequired:        boolean;
  //$REV MP 2023-09-25: Bei SAMR soll das aktuell laufende Halbjahr mit angezeigt werden, bei anderen Kunden nicht (RTK)
  KennErstattungRunningHalfYear: boolean;

  KennTransportOptions:               boolean;
  //$REV MP 2024-05-16: Beim RTK ist eine Anfrage nach dem Antragsgrund notwendig.
  KennApplicationReason:              boolean;

  //$REV MP 2023-11-20: Liste für CMIS Migration & nicht übertragene Akten
  KennCMISList:                  boolean;

  //$REV MP 2024-03-08: Beim VBK ist ein Impressum und eine Datenschutzseite notwendig
  KennImprint:                   boolean;
  KennDataProtection:            boolean;

  footerLinkFrequentQuestions: string;
  footerLinkKontakt:           string;
  footerLinkImprint:           string;
  footerLinkDataSecurity:      string;
  footerLinkImageCreator:      string;
  footerLinkImageCreatorText:  string;

  public appType:              string;

  public schoolIndividual?:     boolean;

  test:                        any = {};


  constructor(private http: HttpClient
              ,private globalResources: TvsGlobalResources
              ,@Inject(DOCUMENT) private document) {}

  //$REV MP 2023-03-15: loadAppStyle hinzugefügt, wenn von der app.module.ts als true übergeben (z.B. Elternportal) wird der in der app.config.json bei stlyes eingetragene Wert geladen.
  //Im Falle von AMIS7school wird in der app.module.ts einfach false übergeben.
  load(loadAppStyle) :Promise<any>  {

      console.log('loadAppStyle true/false', loadAppStyle);

      //VBK TEST
      // const promise = this.http.get('/assets/app_VBK.config.json')
      //Nordhausen TEST
      // const promise = this.http.get('/assets/app_LNH.config.json')
      //RTK TEST
      // const promise = this.http.get('/assets/app_RTK.config.json')
      //Marburg TEST
      const promise = this.http.get('/assets/app_SAMR.config.json')
      //Nordhausen TEST
      // const promise = this.http.get('/assets/app_LNH.config.json')
      //Landkreis Harz TEST
      // const promise = this.http.get('/assets/app_LKH.config.json')
      //VRT bm TEST
      // const promise = this.http.get('/assets/app_VRT.config.json')
      //Standard
      //RNN Test
      // const promise = this.http.get('/assets/app.config_RNN.json')
      //VRM Test
      // const promise = this.http.get('/assets/app.config.json')

        .toPromise()
        .then(data => {
          Object.assign(this.globalResources.AppConfigData, data);

          // Load Styles
          // Muss nur geladen werden wenn Kundenspezifische Stylings vorhanden sind, z.B. Elternportal.
          // Dazu müssen auch die entsprechenden Einträge in der angular.json vorhanden sein (Suche nach extractCss)
          if (vsStringHasValue(this.globalResources.AppConfigData.styles && loadAppStyle)) {

            console.log('loadAppStyle', loadAppStyle);

            const headEl = this.document.getElementsByTagName('head')[0];
            const newLinkEl = this.document.createElement('link');
            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = this.globalResources.AppConfigData.styles;

            headEl.appendChild(newLinkEl);
          }

          return data;
        });

      return promise;
  }
}




